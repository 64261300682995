import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

import "../styles/ToolBar.css";

interface ToolBarProps {
  adminMode: any;
  setShowGroupModal: any;
  setGroupModalHeader: any;
  setAllGroupData: any;
  allSearchData: any;
  setSelectedGroupData: any;
  setSelectedGroupDataType: any;
  setGroupDataType: any;
  allEmailData: any;
  allFlaggedData: any;
  allAdditionalInfoReviewData: any;
  allImpAdditionalInfoReviewData: any;
  allGroupInfoReviewData: any;
  setShowEditConfigModal: any;
  allRescrapeData: any;
  allHideDoctorData: any;
  allMetaSpecialtiesData: any;
  setShowCreateModal: any;
  mode: any;
  setSelectedSearchData: any;
}

function ToolBar(props: ToolBarProps) {
  const handleSearchClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setShowGroupModal(true);
    props.setGroupModalHeader("Doctor Search");
    props.setAllGroupData(props.allSearchData);
    props.setSelectedGroupData(null);
    props.setSelectedSearchData(null);
    props.setSelectedGroupDataType("");
    props.setGroupDataType("search");
  };

  const handleReviewEmailClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setShowGroupModal(true);
    props.setGroupModalHeader("Email Doctors");
    props.setAllGroupData(props.allEmailData);
    props.setSelectedGroupData(null);
    props.setSelectedGroupDataType("");
    props.setGroupDataType("email");
  };

  const handleRescrapeInfoClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setShowGroupModal(true);
    // let numScrape = 0;
    // props.allRescrapeData["doctors"].forEach(
    //   (groupData: any, index: number) => {
    //     numScrape += groupData.length;
    //   }
    // );
    props.setGroupModalHeader("Marked to Scrape Doctors");
    props.setAllGroupData(props.allRescrapeData);
    props.setSelectedGroupData(null);
    props.setSelectedGroupDataType("");
    props.setGroupDataType("rescrape");
  };

  const handleHideDoctorInfoClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setShowGroupModal(true);
    // let numHidden = 0;
    // props.allHideDoctorData["doctors"].forEach(
    //   (groupData: any, index: number) => {
    //     numHidden += groupData.length;
    //   }
    // );
    props.setGroupModalHeader("Hidden Doctors");
    props.setAllGroupData(props.allHideDoctorData);
    props.setSelectedGroupData(null);
    props.setSelectedGroupDataType("");
    props.setGroupDataType("hidden");
  };

  const handleMetaSpecialtiesInfoClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setShowGroupModal(true);
    // let newMetaSpecialties = 0;
    // props.allMetaSpecialtiesData["doctors"].forEach(
    //   (groupData: any, index: number) => {
    //     newMetaSpecialties += groupData.length;
    //   }
    // );
    props.setGroupModalHeader(
      "Doctors with Meta Specialties"
    );
    props.setAllGroupData(props.allMetaSpecialtiesData);
    props.setSelectedGroupData(null);
    props.setSelectedGroupDataType("");
    props.setGroupDataType("meta-specialties");
  };

  const handleReviewFlagClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setShowGroupModal(true);
    // let numFlagged = 0;
    // props.allFlaggedData["doctors"].forEach((groupData: any, index: number) => {
    //   numFlagged += groupData.length;
    // });
    props.setGroupModalHeader("Flagged Doctors");
    props.setAllGroupData(props.allFlaggedData);
    props.setSelectedGroupData(null);
    props.setSelectedGroupDataType("");
    props.setGroupDataType("flagged");
  };

  const handleReviewAdditionalInfoClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setShowGroupModal(true);
    // let newReview = 0;
    // props.allAdditionalInfoReviewData["doctors"].forEach(
    //   (groupData: any, index: number) => {
    //     newReview += groupData.length;
    //   }
    // );
    props.setGroupModalHeader(
      "Review Other Additional Info"
    );
    props.setAllGroupData(props.allAdditionalInfoReviewData);
    props.setSelectedGroupData(null);
    props.setSelectedGroupDataType("");
    props.setGroupDataType("additionalinfo");
  };

  const handleReviewImpAdditionalInfoClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setShowGroupModal(true);
    // let newReview = 0;
    // props.allImpAdditionalInfoReviewData["doctors"].forEach(
    //   (groupData: any, index: number) => {
    //     newReview += groupData.length;
    //   }
    // );
    props.setGroupModalHeader(
      "Review Important Additional Info"
    );
    props.setAllGroupData(props.allImpAdditionalInfoReviewData);
    props.setSelectedGroupData(null);
    props.setSelectedGroupDataType("");
    props.setGroupDataType("impadditionalinfo");
  };

  const handleReviewGroupInfoClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setShowGroupModal(true);
    let newReview = props.allGroupInfoReviewData["doctors"].size;
    props.setGroupModalHeader("Review Group Info (" + newReview + ")");
    props.setAllGroupData(props.allGroupInfoReviewData);
    props.setSelectedGroupData(null);
    props.setSelectedGroupDataType("");
    props.setGroupDataType("reviewgroupinfo");
  };

  const handleEditConfigClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setShowEditConfigModal(true);
  };

  const handleCreateClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setShowCreateModal(true);
  };

  return (
    <>
      <Button onClick={handleSearchClick} className="searchBtn" variant="dark">
        <FaSearch color="white" size={18} /> Search
      </Button>
      {props.mode !== "normal" && !props.adminMode && (
        <Button
          onClick={handleReviewEmailClick}
          className="reviewEmailBtn"
          variant="danger"
        >
          <FaSearch color="white" size={18} /> Emails
        </Button>
      )}
      {props.adminMode && (
        <Button
          onClick={handleReviewFlagClick}
          className="reviewFlagBtn"
          variant="danger"
        >
          <FaSearch color="white" size={18} /> Review Flagged
        </Button>
      )}
      {props.adminMode && (
        <Button
          onClick={handleReviewImpAdditionalInfoClick}
          className="reviewImpAddInfo"
          variant="primary"
        >
          <FaSearch color="white" size={18} /> Review Important Additional Info
        </Button>
      )}
      {props.adminMode && (
        <Button
          onClick={handleReviewAdditionalInfoClick}
          className="reviewAddInfo"
          variant="primary"
        >
          <FaSearch color="white" size={18} /> Review Other Additional Info
        </Button>
      )}
      {props.adminMode && (
        <Button
          onClick={handleReviewGroupInfoClick}
          className="reviewGroupInfo"
          variant="primary"
        >
          <FaSearch color="white" size={18} /> Review Group Info
        </Button>
      )}
      {props.adminMode && (
        <Button
          onClick={handleMetaSpecialtiesInfoClick}
          className="metaSpecialtiesInfo"
          variant="secondary"
        >
          <FaSearch color="white" size={18} /> View Meta Specialties
        </Button>
      )}
      {props.adminMode && (
        <Button
          onClick={handleRescrapeInfoClick}
          className="rescrapeInfo"
          variant="secondary"
        >
          <FaSearch color="white" size={18} /> View To Rescrape
        </Button>
      )}
      {props.adminMode && (
        <Button
          onClick={handleHideDoctorInfoClick}
          className="hiddenInfo"
          variant="secondary"
        >
          <FaSearch color="white" size={18} /> View Hidden
        </Button>
      )}
      {props.adminMode && (
        <Button
          onClick={handleEditConfigClick}
          className="editConfig"
          variant="warning"
        >
          <FaSearch color="white" size={18} /> Edit Config
        </Button>
      )}
      {props.adminMode && (
        <Button
          onClick={handleCreateClick}
          className="createInfo"
          variant="success"
        >
          <FaSearch color="white" size={18} /> Services
        </Button>
      )}
    </>
  );
}

export default ToolBar;
