import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from "firebase/firestore";

import {
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";

import {
  db
} from "../firebase.config";

import {
  retireDoctorAction,
  flagAction,
  faxAction,
  emailAction,
  rescheduleAction,
  completeAction,
  updateAvailabilityAction,
  saveAction,
  saveAdminAction,
  removeAction,
  reviewedAction,
  rescrapeAction,
  updateCentralServiceAction,
  createCentralServiceAction,
  hideDoctorAction,
} from "./actions";

import { fetchIndiviualSchedule } from "./schedulesSlice";

const initialState = {
  admin: [],
  adminMap: new Map(),
  status: "idle",
  error: null,
};

export const fetchAdmin = createAsyncThunk(
  "doctors/fetchAdmin",
  async (dataType: number) => {
    console.log("Loading admin data");

    const q = query(collection(db, "doctor-admin"), where("adminLoad", "array-contains", dataType));
    const querySnapshot = await getDocs(q);

    const newAdminData: any[] = [];

    querySnapshot.forEach((adminObj) => {
      let newAdminDataObj = adminObj.data()
      if (newAdminDataObj["rescrapeDate"] != null) {
        newAdminDataObj["rescrapeDate"] = newAdminDataObj["rescrapeDate"].toDate();
        newAdminDataObj["rescrapeDate"].setMilliseconds(0);
      }
      newAdminData.push(newAdminDataObj);
    });

    console.log("Loaded admin data");
    return newAdminData;
  }
);

export const fetchIndiviualAdmin = createAsyncThunk(
  "doctors/fetchIndiviualAdmin",
  async (cpsoNums: string[], thunkAPI) => {
    console.log("Loading individual admin");
    console.log(cpsoNums);

    let allAdminData = [];
    let scheduleIds = [];

    for (const cpsoNum of cpsoNums) {

    const docRef = doc(db, "doctor-admin", cpsoNum);
    const docSnap = await getDoc(docRef);

    let newAdminData: any = null;

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      newAdminData = docSnap.data();
      newAdminData["scheduleHistory"].forEach((scheduleRef: any, index: number) => {
        scheduleIds.push(scheduleRef.id);
      });

      if (newAdminData["rescrapeDate"] != null) {
        newAdminData["rescrapeDate"] = newAdminData["rescrapeDate"].toDate();
        newAdminData["rescrapeDate"].setMilliseconds(0);
      }
      allAdminData.push(newAdminData);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  thunkAPI.dispatch(fetchIndiviualSchedule(scheduleIds));

    console.log("Loaded individual admin");
    return {
      "admins": allAdminData
    };
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    computeMap: (state, {
      paylod
    }) => {
      state.adminMap = new Map();
      state.admin.forEach((admin: any) => {
        state.adminMap.set(admin["cpsoNum"], admin);
      });
    },
    setRetired: (state, {
      payload
    }) => {
      const existingAdmin = state.admin.find(adminSingle => adminSingle["cpsoNum"] === payload["cpsoNum"]);
      existingAdmin["retired"] = true;
    },
    setRemoved: (state, {
      payload
    }) => {
      const existingAdmin = state.admin.find(adminSingle => adminSingle["cpsoNum"] === payload["cpsoNum"]);
      existingAdmin["active"] = false;
    },
    setNewSchedule: (state, {
      payload
    }) => {
      const existingAdmin = state.admin.find(adminSingle => adminSingle["cpsoNum"] === payload["cpsoNum"]);
      existingAdmin["toReview"] = payload["toReview"];
      if (payload["newScheduleRef"] != null) {
        existingAdmin["currScheduleRef"] = payload["newScheduleRef"];
        existingAdmin["scheduleHistory"].push(payload["newScheduleRef"]);
      }
    },
    setUsual: (state, {
      payload
    }) => {
      const existingAdmin = state.admin.find(adminSingle => adminSingle["cpsoNum"] === payload["cpsoNum"]);
      existingAdmin["meta"] = payload["meta"];
      existingAdmin["additionalInfo"] = payload["additionalInfo"];
      existingAdmin["impAdditionalInfo"] = payload["impAdditionalInfo"];
      if (payload["toReview"] !== undefined) {
        existingAdmin["toReview"] = payload["toReview"];
      }
      if (payload["impToReview"] !== undefined) {
        existingAdmin["impToReview"] = payload["impToReview"];
      }
    },
    setAvailability: (state, {
      payload
    }) => {
      const existingAdmin = state.admin.find(adminSingle => adminSingle["cpsoNum"] === payload["cpsoNum"]);
      existingAdmin["availability"] = payload["availability"];
      existingAdmin["hasSpecialAvailability"] = payload["hasSpecialAvailability"];
    },
    setReviewed: (state, {
      payload
    }) => {
      const existingAdmin = state.admin.find(adminSingle => adminSingle["cpsoNum"] === payload["cpsoNum"]);
      existingAdmin["toReview"] = false;
      existingAdmin["impToReview"] = false;
      existingAdmin["meta"] = payload["meta"];
    },
    setSaveAdmin: (state, {
      payload
    }) => {
      const existingAdmin = state.admin.find(adminSingle => adminSingle["cpsoNum"] === payload["cpsoNum"]);
      existingAdmin["meta"] = payload["meta"];
    },
    setRescrape: (state, {
      payload
    }) => {
      const existingAdmin = state.admin.find(adminSingle => adminSingle["cpsoNum"] === payload["cpsoNum"]);
      existingAdmin["rescrape"] = true;
      existingAdmin["rescrapeDate"] = payload["rescrapeDate"];
    },
    newAdmin: (state, {
      payload
    }) => {
      if (payload["newAdminData"] != null) {
        state.admin.push(payload["newAdminData"]);
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAdmin.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAdmin.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        state.admin = state.admin.concat(action.payload);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "loaded";
      })
      .addCase(fetchAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchIndiviualAdmin.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(fetchIndiviualAdmin.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        // filter out if already exists
        action.payload["admins"].forEach((actionAdmin: any) => {
          state.admin = state.admin.filter(admin => admin["cpsoNum"] !== actionAdmin["cpsoNum"]);
          state.admin.push(actionAdmin);
        });
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(fetchIndiviualAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(retireDoctorAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(flagAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(faxAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(rescheduleAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(completeAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(updateAvailabilityAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(saveAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(removeAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(reviewedAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(saveAdminAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(rescrapeAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(emailAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(updateCentralServiceAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(createCentralServiceAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(hideDoctorAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(retireDoctorAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.setRetired(state, action);
        adminSlice.caseReducers.setUsual(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(flagAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.setUsual(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(faxAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.setUsual(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(rescheduleAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.setUsual(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(completeAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.setNewSchedule(state, action);
        adminSlice.caseReducers.setUsual(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(updateAvailabilityAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.setAvailability(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(saveAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.setUsual(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(removeAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.setRemoved(state, action);
        adminSlice.caseReducers.setUsual(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(reviewedAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.setReviewed(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(saveAdminAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.setSaveAdmin(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(rescrapeAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.setRescrape(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(emailAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.setUsual(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(updateCentralServiceAction.fulfilled, (state, action) => {
        state.status = "updated";
      })
      .addCase(createCentralServiceAction.fulfilled, (state, action) => {
        adminSlice.caseReducers.newAdmin(state, action);
        adminSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(hideDoctorAction.fulfilled, (state, action) => {
        state.status = "updated";
      });
  },
});

export default adminSlice.reducer;

export const selectAllAdmin = state => state.admins.admin;
export const selectAllAdminMap = state => state.admins.adminMap;