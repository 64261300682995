import {
  createAsyncThunk
} from "@reduxjs/toolkit";

import {
  collection,
  doc,
  updateDoc,
  increment,
  arrayUnion,
  arrayRemove,
  setDoc
} from "firebase/firestore";

import {
  db
} from "../firebase.config";

import {
  apiDB
} from "../firebase.config";

export const retireDoctorAction = createAsyncThunk(
  'doctors/retireDoctor',
  async (retireDoctorFields: any) => {
    console.log("retireDoctorAction start");
    console.log(retireDoctorFields);

    let docRef = null;
    if (retireDoctorFields["isService"]) {
      docRef = doc(db, "central-services", retireDoctorFields["cpsoNum"].replace("service-", ""));
    } else {
      docRef = doc(db, "doctor-profiles", retireDoctorFields["cpsoNum"]);
    }

    const adminRef = doc(db, "doctor-admin", retireDoctorFields["cpsoNum"]);
    const scheduleRef = doc(
      db,
      "schedules",
      retireDoctorFields["scheduleId"]
    );

    let recordedDate = new Date();

    let metaInfoValues: number[] = [];
    retireDoctorFields["meta"].forEach((sdMetaValue) => {
      metaInfoValues.push(sdMetaValue.value);
    });

    let newProcedures: string[] = [];
    retireDoctorFields["procedures"].forEach((procedureOption) => [
      newProcedures.push(procedureOption.value),
    ]);

    await updateDoc(scheduleRef, {
      waitTimeMsg: "Retired",
      waitTimeStart: null,
      waitTimeEnd: null,
      waitTimeUnit: null,
      waitTimeRecorded: recordedDate,
    });

    let compareDate = new Date(retireDoctorFields["scheduleCurrentDate"].getTime());
    compareDate.setHours(0, 0, 0, 0);
    var todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    let setNewScheduleDates = false;
    let newDateHistory = [];
    let newScheduleDate = null;
    if (compareDate > todayDate) {
      // set to today date;
      setNewScheduleDates = true;

      retireDoctorFields["scheduleCurrentDateHistory"].forEach((previousDate) => {
        if (previousDate <= todayDate) {
          newDateHistory.push(previousDate);
        }
      });

      newScheduleDate = new Date();
      newScheduleDate.setMinutes(0, 0, 0);
      newDateHistory.push(newScheduleDate);

      await updateDoc(scheduleRef, {
        date: newScheduleDate,
        dateHistory: newDateHistory,
      });
    }

    if (retireDoctorFields["flagResolved"]) {
      await updateDoc(scheduleRef, {
        isFlagged: false,
        flagResolved: true,
      });
    }

    await updateDoc(adminRef, {
      retired: true,
      meta: metaInfoValues,
      additionalInfo: retireDoctorFields["additionalInfo"],
      impAdditionalInfo: retireDoctorFields["impAdditionalInfo"],
    });

    await updateDoc(docRef, {
      waitTimeMsg: "Retired",
      waitTimeStart: null,
      waitTimeEnd: null,
      waitTimeUnit: null,
      procedures: newProcedures,
      customForm: retireDoctorFields["customForm"],
      hideCard: false,
      phone: retireDoctorFields["phone"],
      phoneExt: retireDoctorFields["phoneExt"],
      fax: retireDoctorFields["fax"],
      faxExt: retireDoctorFields["faxExt"],
    });

    console.log("retireDoctorAction done");

    return {
      cpsoNum: retireDoctorFields["cpsoNum"],
      isService: retireDoctorFields["isService"],
      scheduleId: retireDoctorFields["scheduleId"],
      waitTimeMsg: "Retired",
      waitTimeRecorded: recordedDate,
      meta: metaInfoValues,
      additionalInfo: retireDoctorFields["additionalInfo"],
      impAdditionalInfo: retireDoctorFields["impAdditionalInfo"],
      procedures: newProcedures,
      flagResolved: retireDoctorFields["flagResolved"],
      customForm: retireDoctorFields["customForm"],
      setNewScheduleDates: setNewScheduleDates,
      newDateHistory: newDateHistory,
      newScheduleDate: newScheduleDate,
      phone: retireDoctorFields["phone"],
      phoneExt: retireDoctorFields["phoneExt"],
      fax: retireDoctorFields["fax"],
      faxExt: retireDoctorFields["faxExt"],
    };
  }
);

export const flagAction = createAsyncThunk(
  'doctors/flag',
  async (flagActionFields: any) => {
    console.log("flagAction start");
    console.log(flagActionFields);

    let docRef = null;
    if (flagActionFields["isService"]) {
      docRef = doc(db, "central-services", flagActionFields["cpsoNum"].replace("service-", ""));
    } else {
      docRef = doc(db, "doctor-profiles", flagActionFields["cpsoNum"]);
    }

    const adminRef = doc(db, "doctor-admin", flagActionFields["cpsoNum"]);
    const scheduleRef = doc(
      db,
      "schedules",
      flagActionFields["scheduleId"]
    );

    let metaInfoValues: number[] = [];
    flagActionFields["meta"].forEach((sdMetaValue) => {
      metaInfoValues.push(sdMetaValue.value);
    });

    await updateDoc(scheduleRef, {
      flagMsg: flagActionFields["flagMsg"],
      isFlagged: true,
      flagResolved: false,
    });

    let compareDate = new Date(flagActionFields["scheduleCurrentDate"].getTime());
    compareDate.setHours(0, 0, 0, 0);
    var todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    let setNewScheduleDates = false;
    let newDateHistory = [];
    let newScheduleDate = null;
    if (compareDate > todayDate) {
      // set to today date;
      setNewScheduleDates = true;

      flagActionFields["scheduleCurrentDateHistory"].forEach((previousDate) => {
        if (previousDate <= todayDate) {
          newDateHistory.push(previousDate);
        }
      });

      newScheduleDate = new Date();
      newScheduleDate.setMinutes(0, 0, 0);
      newDateHistory.push(newScheduleDate);

      await updateDoc(scheduleRef, {
        date: newScheduleDate,
        dateHistory: newDateHistory,
      });
    }

    await updateDoc(adminRef, {
      meta: metaInfoValues,
      additionalInfo: flagActionFields["additionalInfo"],
      impAdditionalInfo: flagActionFields["impAdditionalInfo"],
      toReview: flagActionFields["toReview"],
      impToReview: flagActionFields["impToReview"],
    });

    let newProcedures: string[] = [];
    flagActionFields["procedures"].forEach((procedureOption) => [
      newProcedures.push(procedureOption.value),
    ]);

    await updateDoc(docRef, {
      customForm: flagActionFields["customForm"],
      procedures: newProcedures,
      phone: flagActionFields["phone"],
      phoneExt: flagActionFields["phoneExt"],
      fax: flagActionFields["fax"],
      faxExt: flagActionFields["faxExt"],
    });

    console.log("flagAction done");

    return {
      cpsoNum: flagActionFields["cpsoNum"],
      scheduleId: flagActionFields["scheduleId"],
      flagMsg: flagActionFields["flagMsg"],
      isService: flagActionFields["isService"],
      meta: metaInfoValues,
      additionalInfo: flagActionFields["additionalInfo"],
      impAdditionalInfo: flagActionFields["impAdditionalInfo"],
      toReview: flagActionFields["toReview"],
      impToReview: flagActionFields["impToReview"],
      customForm: flagActionFields["customForm"],
      procedures: newProcedures,
      setNewScheduleDates: setNewScheduleDates,
      newDateHistory: newDateHistory,
      newScheduleDate: newScheduleDate,
      phone: flagActionFields["phone"],
      phoneExt: flagActionFields["phoneExt"],
      fax: flagActionFields["fax"],
      faxExt: flagActionFields["faxExt"],
    };
  }
);

export const faxAction = createAsyncThunk(
  'doctors/fax',
  async (faxActionFields: any) => {
    console.log("faxAction start");
    console.log(faxActionFields);

    let docRef = null;
    if (faxActionFields["isService"]) {
      docRef = doc(db, "central-services", faxActionFields["cpsoNum"].replace("service-", ""));
    } else {
      docRef = doc(db, "doctor-profiles", faxActionFields["cpsoNum"]);
    }

    const adminRef = doc(db, "doctor-admin", faxActionFields["cpsoNum"]);
    const scheduleRef = doc(
      db,
      "schedules",
      faxActionFields["scheduleId"]
    );

    let metaInfoValues: number[] = [];
    faxActionFields["meta"].forEach((sdMetaValue) => {
      metaInfoValues.push(sdMetaValue.value);
    });

    await updateDoc(scheduleRef, {
      numCalls: increment(1),
      sendFax: true,
    });

    if (faxActionFields["flagResolved"]) {
      await updateDoc(scheduleRef, {
        isFlagged: false,
        flagResolved: true,
      });
    }

    let newProcedures: string[] = [];
    faxActionFields["procedures"].forEach((procedureOption) => [
      newProcedures.push(procedureOption.value),
    ]);


    await updateDoc(docRef, {
      customForm: faxActionFields["customForm"],
      procedures: newProcedures,
      phone: faxActionFields["phone"],
      phoneExt: faxActionFields["phoneExt"],
      fax: faxActionFields["fax"],
      faxExt: faxActionFields["faxExt"],
    });


    await updateDoc(adminRef, {
      meta: metaInfoValues,
      additionalInfo: faxActionFields["additionalInfo"],
      impAdditionalInfo: faxActionFields["impAdditionalInfo"],
      toReview: faxActionFields["toReview"],
      impToReview: faxActionFields["impToReview"],
    });

    console.log("faxAction done");

    return {
      cpsoNum: faxActionFields["cpsoNum"],
      scheduleId: faxActionFields["scheduleId"],
      isService: faxActionFields["isService"],
      meta: metaInfoValues,
      additionalInfo: faxActionFields["additionalInfo"],
      impAdditionalInfo: faxActionFields["impAdditionalInfo"],
      flagResolved: faxActionFields["flagResolved"],
      toReview: faxActionFields["toReview"],
      impToReview: faxActionFields["impToReview"],
      customForm: faxActionFields["customForm"],
      procedures: newProcedures,
      phone: faxActionFields["phone"],
      phoneExt: faxActionFields["phoneExt"],
      fax: faxActionFields["fax"],
      faxExt: faxActionFields["faxExt"],
    };
  }
);


export const emailAction = createAsyncThunk(
  'doctors/email',
  async (emailActionFields: any) => {
    console.log("emailAction start");
    console.log(emailActionFields);

    let docRef = null;
    if (emailActionFields["isService"]) {
      docRef = doc(db, "central-services", emailActionFields["cpsoNum"].replace("service-", ""));
    } else {
      docRef = doc(db, "doctor-profiles", emailActionFields["cpsoNum"]);
    }

    const adminRef = doc(db, "doctor-admin", emailActionFields["cpsoNum"]);
    const scheduleRef = doc(
      db,
      "schedules",
      emailActionFields["scheduleId"]
    );

    let metaInfoValues: number[] = [];
    emailActionFields["meta"].forEach((sdMetaValue) => {
      metaInfoValues.push(sdMetaValue.value);
    });

    const emailSentDate = new Date();

    await updateDoc(scheduleRef, {
      numCalls: increment(1),
      emailSent: true,
      faxDate: emailSentDate,
    });

    if (emailActionFields["flagResolved"]) {
      await updateDoc(scheduleRef, {
        isFlagged: false,
        flagResolved: true,
      });
    }

    await updateDoc(adminRef, {
      meta: metaInfoValues,
      additionalInfo: emailActionFields["additionalInfo"],
      impAdditionalInfo: emailActionFields["impAdditionalInfo"],
      toReview: emailActionFields["toReview"],
      impToReview: emailActionFields["impToReview"],
    });

    let newProcedures: string[] = [];
    emailActionFields["procedures"].forEach((procedureOption) => [
      newProcedures.push(procedureOption.value),
    ]);


    await updateDoc(docRef, {
      customForm: emailActionFields["customForm"],
      procedures: newProcedures,
      phone: emailActionFields["phone"],
      phoneExt: emailActionFields["phoneExt"],
      fax: emailActionFields["fax"],
      faxExt: emailActionFields["faxExt"],
    });


    console.log("emailAction done");

    return {
      cpsoNum: emailActionFields["cpsoNum"],
      scheduleId: emailActionFields["scheduleId"],
      isService: emailActionFields["isService"],
      meta: metaInfoValues,
      additionalInfo: emailActionFields["additionalInfo"],
      impAdditionalInfo: emailActionFields["impAdditionalInfo"],
      toReview: emailActionFields["toReview"],
      impToReview: emailActionFields["impToReview"],
      flagResolved: emailActionFields["flagResolved"],
      emailSentDate: emailSentDate,
      customForm: emailActionFields["customForm"],
      procedures: newProcedures,
      phone: emailActionFields["phone"],
      phoneExt: emailActionFields["phoneExt"],
      fax: emailActionFields["fax"],
      faxExt: emailActionFields["faxExt"],
    };
  }
);


export const rescheduleAction = createAsyncThunk(
  'doctors/reschedule',
  async (rescheduleActionFields: any) => {
    console.log("rescheduleAction start");
    console.log(rescheduleActionFields);

    let docRef = null;
    if (rescheduleActionFields["isService"]) {
      docRef = doc(db, "central-services", rescheduleActionFields["cpsoNum"].replace("service-", ""));
    } else {
      docRef = doc(db, "doctor-profiles", rescheduleActionFields["cpsoNum"]);
    }

    const adminRef = doc(db, "doctor-admin", rescheduleActionFields["cpsoNum"]);
    const scheduleRef = doc(
      db,
      "schedules",
      rescheduleActionFields["scheduleId"]
    );

    let metaInfoValues: number[] = [];
    rescheduleActionFields["meta"].forEach((sdMetaValue) => {
      metaInfoValues.push(sdMetaValue.value);
    });

    await updateDoc(scheduleRef, {
      numCalls: increment(1),
      numRescheduled: increment(1),
      date: rescheduleActionFields["newDate"],
      dateHistory: arrayUnion(rescheduleActionFields["newDate"]),
    });

    if (rescheduleActionFields["flagResolved"]) {
      await updateDoc(scheduleRef, {
        isFlagged: false,
        flagResolved: true,
      });
    }

    await updateDoc(adminRef, {
      meta: metaInfoValues,
      additionalInfo: rescheduleActionFields["additionalInfo"],
      impAdditionalInfo: rescheduleActionFields["impAdditionalInfo"],
      toReview: rescheduleActionFields["toReview"],
      impToReview: rescheduleActionFields["impToReview"],
    });

    let newProcedures: string[] = [];
    rescheduleActionFields["procedures"].forEach((procedureOption) => [
      newProcedures.push(procedureOption.value),
    ]);


    await updateDoc(docRef, {
      customForm: rescheduleActionFields["customForm"],
      procedures: newProcedures,
      phone: rescheduleActionFields["phone"],
      phoneExt: rescheduleActionFields["phoneExt"],
      fax: rescheduleActionFields["fax"],
      faxExt: rescheduleActionFields["faxExt"],
    });


    const kickDoctorDetails = rescheduleActionFields["kickDoctorDetails"];
    if (kickDoctorDetails != null) {
      const kickDoctorScheduleRef = doc(
        db,
        "schedules",
        kickDoctorDetails["scheduleId"]
      );
      const oldKickDoctorDate = kickDoctorDetails["oldDate"];
      const newKickDoctorDate = kickDoctorDetails["newDate"];

      await updateDoc(kickDoctorScheduleRef, {
        dateHistory: arrayRemove(oldKickDoctorDate),
      });

      await updateDoc(kickDoctorScheduleRef, {
        date: newKickDoctorDate,
        dateHistory: arrayUnion(newKickDoctorDate),
      });
    }

    console.log("rescheduleAction done");

    return {
      cpsoNum: rescheduleActionFields["cpsoNum"],
      scheduleId: rescheduleActionFields["scheduleId"],
      newDate: rescheduleActionFields["newDate"],
      isService: rescheduleActionFields["isService"],
      kickDoctorDetails: rescheduleActionFields["kickDoctorDetails"],
      meta: metaInfoValues,
      additionalInfo: rescheduleActionFields["additionalInfo"],
      impAdditionalInfo: rescheduleActionFields["impAdditionalInfo"],
      toReview: rescheduleActionFields["toReview"],
      impToReview: rescheduleActionFields["impToReview"],
      flagResolved: rescheduleActionFields["flagResolved"],
      customForm: rescheduleActionFields["customForm"],
      procedures: newProcedures,
      phone: rescheduleActionFields["phone"],
      phoneExt: rescheduleActionFields["phoneExt"],
      fax: rescheduleActionFields["fax"],
      faxExt: rescheduleActionFields["faxExt"],
    };
  }
);

export const completeAction = createAsyncThunk(
  'doctors/complete',
  async (completeActionFields: any) => {
    console.log("completeAction start");
    console.log(completeActionFields);

    let newScheduleRef = null;
    let newScheduleData: any = null;
    if (completeActionFields["waitTimeMsg"] == null || completeActionFields["waitTimeMsg"] === "Pending Update" ||
      completeActionFields["waitTimeMsg"] === "Not Accepting" || completeActionFields["waitTimeMsg"] === "Urgent Only"
      || completeActionFields["waitTimeMsg"] === "Walk-In") {
      // Create new schedule
      newScheduleData = {
        cpsoNum: completeActionFields["cpsoNum"],
        date: completeActionFields["newDate"],
        dateHistory: [completeActionFields["newDate"]],
        emailSent: false,
        faxDate: null,
        faxSent: false,
        flagMsg: null,
        flagResolved: false,
        isCurrent: true,
        isFlagged: false,
        numCalls: 0,
        numFaxes: 0,
        numRescheduled: 0,
        scheduleId: null,
        sendFax: false,
        waitTimeEnd: null,
        waitTimeMsg: null,
        waitTimeRecorded: null,
        waitTimeStart: null,
        waitTimeUnit: null,
        adminLoad: [],
      };

      newScheduleRef = doc(collection(db, "schedules"));
      newScheduleData["scheduleId"] = newScheduleRef.id;
      await setDoc(newScheduleRef, newScheduleData);
    }

    let docRef = null;
    if (completeActionFields["isService"]) {
      docRef = doc(db, "central-services", completeActionFields["cpsoNum"].replace("service-", ""));
    } else {
      docRef = doc(db, "doctor-profiles", completeActionFields["cpsoNum"]);
    }

    const adminRef = doc(db, "doctor-admin", completeActionFields["cpsoNum"]);
    const scheduleRef = doc(
      db,
      "schedules",
      completeActionFields["scheduleId"]
    );

    let metaInfoValues: number[] = [];
    completeActionFields["meta"].forEach((sdMetaValue) => {
      metaInfoValues.push(sdMetaValue.value);
    });

    let newProcedures: string[] = [];
    completeActionFields["procedures"].forEach((procedureOption) => [
      newProcedures.push(procedureOption.value),
    ]);

    let recordedDate = new Date();

    let waitTimeMsg = null;
    let waitTimeStart = null;
    let waitTimeEnd = null;
    let waitTimeUnit = null;

    if (completeActionFields["waitTimeMsg"] != null && completeActionFields["waitTimeMsg"] !== "Pending Update") {
      waitTimeMsg = completeActionFields["waitTimeMsg"];
    } else {
      waitTimeStart = completeActionFields["waitTimeStart"];
      waitTimeEnd = completeActionFields["waitTimeEnd"];
      waitTimeUnit = completeActionFields["waitTimeUnit"];
    }

    await updateDoc(scheduleRef, {
      waitTimeMsg: waitTimeMsg,
      waitTimeStart: waitTimeStart,
      waitTimeEnd: waitTimeEnd,
      waitTimeUnit: waitTimeUnit,
      waitTimeRecorded: recordedDate,
      isCurrent: false,
    });

    let compareDate = new Date(completeActionFields["scheduleCurrentDate"].getTime());
    compareDate.setHours(0, 0, 0, 0);
    var todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    let setNewScheduleDates = false;
    let newDateHistory = [];
    let newScheduleDate = null;
    if (compareDate > todayDate) {
      // set to today date;
      setNewScheduleDates = true;

      completeActionFields["scheduleCurrentDateHistory"].forEach((previousDate) => {
        if (previousDate <= todayDate) {
          newDateHistory.push(previousDate);
        }
      });

      newScheduleDate = new Date();
      newScheduleDate.setMinutes(0, 0, 0);
      newDateHistory.push(newScheduleDate);

      await updateDoc(scheduleRef, {
        date: newScheduleDate,
        dateHistory: newDateHistory,
      });
    }


    if (completeActionFields["flagResolved"]) {
      await updateDoc(scheduleRef, {
        isFlagged: false,
        flagResolved: true,
      });
    }

    if (completeActionFields["from"] === "call") {
      await updateDoc(scheduleRef, {
        numCalls: increment(1),
      });
    }

    await updateDoc(docRef, {
      waitTimeMsg: waitTimeMsg,
      waitTimeStart: waitTimeStart,
      waitTimeEnd: waitTimeEnd,
      waitTimeUnit: waitTimeUnit,
      procedures: newProcedures,
      customForm: completeActionFields["customForm"],
      phone: completeActionFields["phone"],
      phoneExt: completeActionFields["phoneExt"],
      fax: completeActionFields["fax"],
      faxExt: completeActionFields["faxExt"],
      hideCard: false,
    });

    await updateDoc(adminRef, {
      meta: metaInfoValues,
      additionalInfo: completeActionFields["additionalInfo"],
      impAdditionalInfo: completeActionFields["impAdditionalInfo"],
      toReview: completeActionFields["toReview"],
      impToReview: completeActionFields["impToReview"],
    });

    if (newScheduleRef != null) {
      await updateDoc(adminRef, {
        currScheduleRef: newScheduleRef,
        scheduleHistory: arrayUnion(newScheduleRef),
      });
    }

    const kickDoctorDetails = completeActionFields["kickDoctorDetails"];
    if (kickDoctorDetails != null) {
      const kickDoctorScheduleRef = doc(
        db,
        "schedules",
        kickDoctorDetails["scheduleId"]
      );
      const oldKickDoctorDate = kickDoctorDetails["oldDate"];
      const newKickDoctorDate = kickDoctorDetails["newDate"];

      await updateDoc(kickDoctorScheduleRef, {
        dateHistory: arrayRemove(oldKickDoctorDate),
      });

      await updateDoc(kickDoctorScheduleRef, {
        date: newKickDoctorDate,
        dateHistory: arrayUnion(newKickDoctorDate),
      });
    }

    console.log("completeAction done");

    return {
      cpsoNum: completeActionFields["cpsoNum"],
      scheduleId: completeActionFields["scheduleId"],
      newDate: completeActionFields["newDate"],
      isService: completeActionFields["isService"],
      newScheduleRef: newScheduleRef,
      kickDoctorDetails: completeActionFields["kickDoctorDetails"],
      meta: metaInfoValues,
      from: completeActionFields["from"],
      additionalInfo: completeActionFields["additionalInfo"],
      impAdditionalInfo: completeActionFields["impAdditionalInfo"],
      toReview: completeActionFields["toReview"],
      impToReview: completeActionFields["impToReview"],
      procedures: newProcedures,
      isNotAccepting: completeActionFields["isNotAccepting"],
      waitTimeStart: waitTimeStart,
      waitTimeEnd: waitTimeEnd,
      waitTimeUnit: waitTimeUnit,
      waitTimeMsg: waitTimeMsg,
      waitTimeRecorded: recordedDate,
      newScheduleData: newScheduleData,
      flagResolved: completeActionFields["flagResolved"],
      customForm: completeActionFields["customForm"],
      setNewScheduleDates: setNewScheduleDates,
      newDateHistory: newDateHistory,
      newScheduleDate: newScheduleDate,
      phone: completeActionFields["phone"],
      phoneExt: completeActionFields["phoneExt"],
      fax: completeActionFields["fax"],
      faxExt: completeActionFields["faxExt"],
    };
  }
);

export const updateGroupInfoAction = createAsyncThunk(
  'groupInfo/update',
  async (updateGroupInfoActionFields: any) => {
    console.log("updateGroupInfoAction start");
    console.log(updateGroupInfoActionFields);

    const groupInfoRef = doc(
      db,
      "doctor-group-info",
      updateGroupInfoActionFields["key"]
    );

    await setDoc(groupInfoRef, {
      groupInfo: updateGroupInfoActionFields["groupInfo"],
      toReview: updateGroupInfoActionFields["toReview"],
    });

    console.log("updateGroupInfoAction done");

    return {
      key: updateGroupInfoActionFields["key"],
      groupInfo: updateGroupInfoActionFields["groupInfo"],
      toReview: updateGroupInfoActionFields["toReview"],
    };
  }
);

export const markGroupInfoReviewAction = createAsyncThunk(
  'groupInfo/markReviewed',
  async (markGroupInfoReviewActionFields: any) => {
    console.log("markGroupInfoReviewActionFields start");
    console.log(markGroupInfoReviewActionFields);

    const groupInfoRef = doc(
      db,
      "doctor-group-info",
      markGroupInfoReviewActionFields["key"]
    );

    await updateDoc(groupInfoRef, {
      toReview: false,
    });

    console.log("markGroupInfoReviewActionFields done");

    return {
      key: markGroupInfoReviewActionFields["key"],
    };
  }
);


export const updateAvailabilityAction = createAsyncThunk(
  'doctors/updateAvailability',
  async (updateAvailabilityActionFields: any) => {
    console.log("updateAvailabilityAction start");
    console.log(updateAvailabilityActionFields);

    const adminRef = doc(db, "doctor-admin", updateAvailabilityActionFields["cpsoNum"]);

    await updateDoc(adminRef, {
      availability: updateAvailabilityActionFields["availability"],
      hasSpecialAvailability: updateAvailabilityActionFields["hasSpecialAvailability"]
    });

    console.log("updateAvailabilityAction done");

    return {
      cpsoNum: updateAvailabilityActionFields["cpsoNum"],
      availability: updateAvailabilityActionFields["availability"],
      hasSpecialAvailability: updateAvailabilityActionFields["hasSpecialAvailability"],
    };
  }
);

export const reviewedAction = createAsyncThunk(
  'doctors/reviewed',
  async (reviewedActionFields: any) => {
    console.log("reviewedAction start");
    console.log(reviewedActionFields);

    let docRef = null;
    if (reviewedActionFields["isService"]) {
      docRef = doc(db, "central-services", reviewedActionFields["cpsoNum"].replace("service-", ""));
    } else {
      docRef = doc(db, "doctor-profiles", reviewedActionFields["cpsoNum"]);
    }

    const adminRef = doc(db, "doctor-admin", reviewedActionFields["cpsoNum"]);


    let newProcedures: string[] = [];
    reviewedActionFields["procedures"].forEach((procedureOption) => [
      newProcedures.push(procedureOption.value),
    ]);

    let metaInfoValues: number[] = [];
    reviewedActionFields["meta"].forEach((sdMetaValue) => {
      metaInfoValues.push(sdMetaValue.value);
    });

    let newMetaSpecialties: string[] = [];
    reviewedActionFields["metaSpecialties"].forEach((metaSpecialityOption) => [
      newMetaSpecialties.push(metaSpecialityOption.label),
    ]);

    let newSearchSpecialties: string[] = [];
    reviewedActionFields["searchSpecialties"].forEach((searchSpecialityOption) => [
      newSearchSpecialties.push(searchSpecialityOption.label),
    ]);

    await updateDoc(docRef, {
      additionalInfo: reviewedActionFields["finalAdditionalInfo"],
      customForm: reviewedActionFields["customForm"],
      procedures: newProcedures,
      metaSpecialties: newMetaSpecialties,
      searchSpecialties: newSearchSpecialties,
      phone: reviewedActionFields["phone"],
      phoneExt: reviewedActionFields["phoneExt"],
      fax: reviewedActionFields["fax"],
      faxExt: reviewedActionFields["faxExt"],
    })

    await updateDoc(adminRef, {
      meta: metaInfoValues,
      toReview: false,
      impToReview: false,
      reviewedOnce: true,
    });

    console.log("reviewedAction done");

    return {
      cpsoNum: reviewedActionFields["cpsoNum"],
      isService: reviewedActionFields["isService"],
      meta: metaInfoValues,
      finalAdditionalInfo: reviewedActionFields["finalAdditionalInfo"],
      metaSpecialties: newMetaSpecialties,
      searchSpecialties: newSearchSpecialties,
      customForm: reviewedActionFields["customForm"],
      procedures: newProcedures,
      phone: reviewedActionFields["phone"],
      phoneExt: reviewedActionFields["phoneExt"],
      fax: reviewedActionFields["fax"],
      faxExt: reviewedActionFields["faxExt"],
    };
  }
);

export const saveAction = createAsyncThunk(
  'doctors/save',
  async (saveActionFields: any) => {
    console.log("saveAction start");
    console.log(saveActionFields);

    let docRef = null;
    if (saveActionFields["isService"]) {
      docRef = doc(db, "central-services", saveActionFields["cpsoNum"].replace("service-", ""));
    } else {
      docRef = doc(db, "doctor-profiles", saveActionFields["cpsoNum"]);
    }

    const adminRef = doc(db, "doctor-admin", saveActionFields["cpsoNum"]);

    let metaInfoValues: number[] = [];
    saveActionFields["meta"].forEach((sdMetaValue) => {
      metaInfoValues.push(sdMetaValue.value);
    });

    await updateDoc(adminRef, {
      meta: metaInfoValues,
      additionalInfo: saveActionFields["additionalInfo"],
      impAdditionalInfo: saveActionFields["impAdditionalInfo"],
      toReview: saveActionFields["toReview"],
      impToReview: saveActionFields["impToReview"],
    });

    let newProcedures: string[] = [];
    saveActionFields["procedures"].forEach((procedureOption) => [
      newProcedures.push(procedureOption.value),
    ]);


    await updateDoc(docRef, {
      customForm: saveActionFields["customForm"],
      procedures: newProcedures,
      phone: saveActionFields["phone"],
      phoneExt: saveActionFields["phoneExt"],
      fax: saveActionFields["fax"],
      faxExt: saveActionFields["faxExt"],
    });


    console.log("saveAction done");

    return {
      cpsoNum: saveActionFields["cpsoNum"],
      isService: saveActionFields["isService"],
      meta: metaInfoValues,
      additionalInfo: saveActionFields["additionalInfo"],
      impAdditionalInfo: saveActionFields["impAdditionalInfo"],
      toReview: saveActionFields["toReview"],
      impToReview: saveActionFields["impToReview"],
      customForm: saveActionFields["customForm"],
      procedures: newProcedures,
      phone: saveActionFields["phone"],
      phoneExt: saveActionFields["phoneExt"],
      fax: saveActionFields["fax"],
      faxExt: saveActionFields["faxExt"],
    };
  }
);


export const saveAdminAction = createAsyncThunk(
  'doctors/saveAdmin',
  async (saveAdminActionFields: any, thunkAPI: any) => {
    console.log("saveAdminAction start");
    console.log(saveAdminActionFields);

    let docRef = null;
    if (saveAdminActionFields["isService"]) {
      docRef = doc(db, "central-services", saveAdminActionFields["cpsoNum"].replace("service-", ""));
    } else {
      docRef = doc(db, "doctor-profiles", saveAdminActionFields["cpsoNum"]);
    }

    const adminRef = doc(db, "doctor-admin", saveAdminActionFields["cpsoNum"]);

    let newProcedures: string[] = [];
    saveAdminActionFields["procedures"].forEach((procedureOption) => [
      newProcedures.push(procedureOption.value),
    ]);

    let metaInfoValues: number[] = [];
    saveAdminActionFields["meta"].forEach((sdMetaValue) => {
      metaInfoValues.push(sdMetaValue.value);
    });

    let newMetaSpecialties: string[] = [];
    saveAdminActionFields["metaSpecialties"].forEach((metaSpecialityOption) => [
      newMetaSpecialties.push(metaSpecialityOption.label),
    ]);

    let newSearchSpecialties: string[] = [];
    saveAdminActionFields["searchSpecialties"].forEach((searchSpecialityOption) => [
      newSearchSpecialties.push(searchSpecialityOption.label),
    ]);


    await updateDoc(docRef, {
      additionalInfo: saveAdminActionFields["finalAdditionalInfo"],
      customForm: saveAdminActionFields["customForm"],
      procedures: newProcedures,
      metaSpecialties: newMetaSpecialties,
      searchSpecialties: newSearchSpecialties,
      phone: saveAdminActionFields["phone"],
      phoneExt: saveAdminActionFields["phoneExt"],
      fax: saveAdminActionFields["fax"],
      faxExt: saveAdminActionFields["faxExt"],
    })

    await updateDoc(adminRef, {
      meta: metaInfoValues,
      toReview: false,
    });

    console.log("saveAdminAction done");

    return {
      cpsoNum: saveAdminActionFields["cpsoNum"],
      isService: saveAdminActionFields["isService"],
      meta: metaInfoValues,
      finalAdditionalInfo: saveAdminActionFields["finalAdditionalInfo"],
      metaSpecialties: newMetaSpecialties,
      searchSpecialties: newSearchSpecialties,
      customForm: saveAdminActionFields["customForm"],
      procedures: newProcedures,
      phone: saveAdminActionFields["phone"],
      phoneExt: saveAdminActionFields["phoneExt"],
      fax: saveAdminActionFields["fax"],
      faxExt: saveAdminActionFields["faxExt"],
    };
  }
);

export const removeAction = createAsyncThunk(
  'doctors/remove',
  async (removeActionFields: any) => {
    console.log("removeAction start");
    console.log(removeActionFields);

    let docRef = null;
    if (removeActionFields["isService"]) {
      docRef = doc(db, "central-services", removeActionFields["cpsoNum"].replace("service-", ""));
    } else {
      docRef = doc(db, "doctor-profiles", removeActionFields["cpsoNum"]);
    }

    const adminRef = doc(db, "doctor-admin", removeActionFields["cpsoNum"]);
    const scheduleRef = doc(
      db,
      "schedules",
      removeActionFields["scheduleId"]
    );

    let metaInfoValues: number[] = [];
    removeActionFields["meta"].forEach((sdMetaValue) => {
      metaInfoValues.push(sdMetaValue.value);
    });

    await updateDoc(adminRef, {
      active: false,
      meta: metaInfoValues,
      additionalInfo: removeActionFields["additionalInfo"],
      impAdditionalInfo: removeActionFields["impAdditionalInfo"],
    });

    if (removeActionFields["flagResolved"]) {
      await updateDoc(scheduleRef, {
        isFlagged: false,
        flagResolved: true,
      });
    }

    let newProcedures: string[] = [];
    removeActionFields["procedures"].forEach((procedureOption) => [
      newProcedures.push(procedureOption.value),
    ]);


    await updateDoc(docRef, {
      customForm: removeActionFields["customForm"],
      procedures: newProcedures,
      phone: removeActionFields["phone"],
      phoneExt: removeActionFields["phoneExt"],
      fax: removeActionFields["fax"],
      faxExt: removeActionFields["faxExt"],
    });


    console.log("removeAction done");

    return {
      cpsoNum: removeActionFields["cpsoNum"],
      scheduleId: removeActionFields["scheduleId"],
      isService: removeActionFields["isService"],
      meta: metaInfoValues,
      additionalInfo: removeActionFields["additionalInfo"],
      impAdditionalInfo: removeActionFields["impAdditionalInfo"],
      flagResolved: removeActionFields["flagResolved"],
      customForm: removeActionFields["customForm"],
      procedures: newProcedures,
      phone: removeActionFields["phone"],
      phoneExt: removeActionFields["phoneExt"],
      fax: removeActionFields["fax"],
      faxExt: removeActionFields["faxExt"],
    };
  }
);

export const updateConfigSpecialityAction = createAsyncThunk(
  'config/updateConfigSpeciality',
  async (updateConfigSpecialityFields: any) => {
    console.log("updateConfigSpeciality start");
    console.log(updateConfigSpecialityFields);

    let specialityDocRef = doc(
      db,
      "config-specialties",
      updateConfigSpecialityFields["id"]
    );


    let newAliases: string[] = [];
    updateConfigSpecialityFields["aliases"].forEach((csAlias) => {
      newAliases.push(csAlias.value.toLowerCase());
    });

    let newFaxUnderlines: string[] = [];
    updateConfigSpecialityFields["faxUnderlines"].forEach((csFaxUnderline) => {
      newFaxUnderlines.push(csFaxUnderline.value);
    });

    let newProcedures: any[] = [];
    let newProcedureRefs: any[] = [];

    updateConfigSpecialityFields["procedures"].forEach((csProcedure) => {
      let procedureData = updateConfigSpecialityFields["configProcedures"].find((configProcedure: any) => configProcedure.id === csProcedure.value)
      if (
        procedureData &&
        procedureData["specialties"].find(
          (elem: any) => elem.id === updateConfigSpecialityFields["id"]
        ) === undefined
      ) {
        newProcedureRefs.push(
          doc(db, "config-procedures", csProcedure.value)
        );
      }
      newProcedures.push(doc(db, "config-procedures", csProcedure.value));
    });

    await updateDoc(specialityDocRef, {
      schedule: updateConfigSpecialityFields["schedule"],
      faxVerb: updateConfigSpecialityFields["faxVerb"],
      aliases: newAliases,
      faxUnderlines: newFaxUnderlines,
      procedures: newProcedures,
    });

    for (var i = 0; i < newProcedureRefs.length; i++) {
      let newProcedureRef: any = newProcedureRefs[i];
      await updateDoc(newProcedureRef, {
        specialties: arrayUnion(specialityDocRef),
      });
    }


    let removedProcedures = updateConfigSpecialityFields["configProcedures"].filter(
      (elem: any) =>
      elem["specialties"].find(
        (elemRef: any) => elemRef.id === updateConfigSpecialityFields["id"]
      ) !== undefined &&
      !updateConfigSpecialityFields["procedures"].find((csProcedure) => csProcedure.value === elem["id"])
    );

    let removedProcedureRefs = [];

    for (var i = 0; i < removedProcedures.length; i++) {
      let removedProcedureRef: any = doc(
        db,
        "config-procedures",
        removedProcedures[i]["id"]
      );
      removedProcedureRefs.push(removedProcedureRef);
      await updateDoc(removedProcedureRef, {
        specialties: arrayRemove(specialityDocRef),
      });
    }

    console.log("updateConfigSpeciality done");

    return {
      id: updateConfigSpecialityFields["id"],
      specialityDocRef: specialityDocRef,
      schedule: updateConfigSpecialityFields["schedule"],
      faxVerb: updateConfigSpecialityFields["faxVerb"],
      aliases: newAliases,
      faxUnderlines: newFaxUnderlines,
      procedures: newProcedures,
      newProcedureRefs: newProcedureRefs,
      removedProcedureRefs: removedProcedureRefs,
    };
  }
);

export const createConfigProcedureAction = createAsyncThunk(
  'config/createConfigProcedure',
  async (createConfigProcedureFields: any) => {
    console.log("createConfigProcedure start");
    console.log(createConfigProcedureFields);

    const newProcedureDocRef = doc(collection(db, "config-procedures"));

    let newProcedureData = {
      "id": newProcedureDocRef.id,
      "active": false,
      "complete": false,
      "procedure": createConfigProcedureFields["procedure"],
      "aliases": [],
      "specialties": [],
      "type": createConfigProcedureFields["type"],
    };

    await setDoc(newProcedureDocRef, newProcedureData);

    console.log("createConfigProcedure done");

    return {
      newProcedureData: newProcedureData,
    };
  }
);

export const updateConfigProcedureAction = createAsyncThunk(
  'config/updateConfigProcedure',
  async (updateConfigProcedureFields: any) => {
    console.log("updateConfigProcedure start");
    console.log(updateConfigProcedureFields);

    let procedureDocRef = doc(
      db,
      "config-procedures",
      updateConfigProcedureFields["id"]
    );

    let newAliases: string[] = [];
    updateConfigProcedureFields["aliases"].forEach((csAlias) => {
      newAliases.push(csAlias.value.toLowerCase());
    });

    let newSpecialties: any[] = [];
    let newSpecialtiesRefs: any[] = [];

    updateConfigProcedureFields["specialties"].forEach((cpSpeciality) => {
      let specialityData = updateConfigProcedureFields["configSpecialties"].find((configSpeciality: any) => configSpeciality.id === cpSpeciality.value)
      if (
        specialityData &&
        specialityData["procedures"].find(
          (elem: any) => elem.id === updateConfigProcedureFields["id"]
        ) === undefined
      ) {
        newSpecialtiesRefs.push(
          doc(db, "config-specialties", cpSpeciality.value)
        );
      }
      newSpecialties.push(doc(db, "config-specialties", cpSpeciality.value));
    });

    await updateDoc(procedureDocRef, {
      active: updateConfigProcedureFields["active"],
      complete: updateConfigProcedureFields["complete"],
      aliases: newAliases,
      specialties: newSpecialties,
    });

    for (var i = 0; i < newSpecialtiesRefs.length; i++) {
      let newSpecialityRefs: any = newSpecialtiesRefs[i];
      await updateDoc(newSpecialityRefs, {
        procedures: arrayUnion(procedureDocRef),
      });
    }

    let removedSpecialties = updateConfigProcedureFields["configSpecialties"].filter(
      (elem: any) =>
      elem["procedures"].find(
        (elemRef: any) => elemRef.id === updateConfigProcedureFields["id"]
      ) !== undefined &&
      !updateConfigProcedureFields["specialties"].find((cpSpecialist) => cpSpecialist.value === elem["id"])
    );

    let removedSpecialtiesRefs = [];

    for (var i = 0; i < removedSpecialties.length; i++) {
      let removedSpecialityRef: any = doc(
        db,
        "config-specialties",
        removedSpecialties[i]["id"]
      );
      removedSpecialtiesRefs.push(removedSpecialityRef);
      await updateDoc(removedSpecialityRef, {
        procedures: arrayRemove(procedureDocRef),
      });
    }

    console.log("updateConfigProcedure done");

    return {
      id: updateConfigProcedureFields["id"],
      procedureDocRef: procedureDocRef,
      active: updateConfigProcedureFields["active"],
      complete: updateConfigProcedureFields["complete"],
      aliases: newAliases,
      specialties: newSpecialties,
      newSpecialtiesRefs: newSpecialtiesRefs,
      removedSpecialtiesRefs: removedSpecialtiesRefs,
    };
  }
);


export const rescrapeAction = createAsyncThunk(
  'doctors/rescapre',
  async (rescrapeActionFields: any) => {
    console.log("rescrapeAction start");
    console.log(rescrapeActionFields);

    const adminRef = doc(db, "doctor-admin", rescrapeActionFields["cpsoNum"]);
    const scheduleRef = doc(
      db,
      "schedules",
      rescrapeActionFields["scheduleId"]
    );

    await updateDoc(adminRef, {
      rescrape: true,
      rescrapeDate: rescrapeActionFields["rescrapeDate"],
    });

    console.log("rescrapeAction done");

    if (rescrapeActionFields["flagResolved"]) {
      await updateDoc(scheduleRef, {
        isFlagged: false,
        flagResolved: true,
      });
    }

    return {
      cpsoNum: rescrapeActionFields["cpsoNum"],
      scheduleId: rescrapeActionFields["scheduleId"],
      rescrapeDate: rescrapeActionFields["rescrapeDate"],
      flagResolved: rescrapeActionFields["flagResolved"],
    };
  }
);

export const updateCentralServiceAction = createAsyncThunk(
  'centralServices/update',
  async (updateCentralServiceActionFields: any) => {
    console.log("updateCentralServiceAction start");
    console.log(updateCentralServiceActionFields);

    const centralServiceRef = doc(db, "central-services", updateCentralServiceActionFields["serviceId"]);

    let hasIntakeWaitTimeMsg = updateCentralServiceActionFields["intakeWaitTimeMsg"] != null;
    let hasWaitTimeMsg = updateCentralServiceActionFields["waitTimeMsg"] != null;

    await updateDoc(centralServiceRef, {
      fullName: updateCentralServiceActionFields["fullName"],
      location: updateCentralServiceActionFields["location"],
      isVirtual: updateCentralServiceActionFields["isVirtual"],
      phone: updateCentralServiceActionFields["phone"],
      phoneExt: updateCentralServiceActionFields["phoneExt"],
      fax: updateCentralServiceActionFields["fax"],
      faxExt: updateCentralServiceActionFields["faxExt"],
      specialties: updateCentralServiceActionFields["specialties"],
      metaSpecialties: updateCentralServiceActionFields["metaSpecialties"],
      procedures: updateCentralServiceActionFields["procedures"],
      link: updateCentralServiceActionFields["link"],
      customForm: updateCentralServiceActionFields["customForm"],
      additionalInfo: updateCentralServiceActionFields["additionalInfo"],
      intakeWaitTimeStart: hasIntakeWaitTimeMsg ? null : updateCentralServiceActionFields["intakeWaitTimeStart"],
      intakeWaitTimeEnd: hasIntakeWaitTimeMsg ? null : updateCentralServiceActionFields["intakeWaitTimeEnd"],
      intakeWaitTimeUnit: hasIntakeWaitTimeMsg ? null : updateCentralServiceActionFields["intakeWaitTimeUnit"],
      intakeWaitTimeMsg: updateCentralServiceActionFields["intakeWaitTimeMsg"],
      waitTimeStart: hasWaitTimeMsg ? null : updateCentralServiceActionFields["waitTimeStart"],
      waitTimeEnd: hasWaitTimeMsg ? null : updateCentralServiceActionFields["waitTimeEnd"],
      waitTimeUnit: hasWaitTimeMsg ? null : updateCentralServiceActionFields["waitTimeUnit"],
      waitTimeMsg: updateCentralServiceActionFields["waitTimeMsg"],
    });

    console.log("updateCentralServiceAction done");

    return {
      serviceId: updateCentralServiceActionFields["serviceId"],
      fullName: updateCentralServiceActionFields["fullName"],
      location: updateCentralServiceActionFields["location"],
      phone: updateCentralServiceActionFields["phone"],
      phoneExt: updateCentralServiceActionFields["phoneExt"],
      fax: updateCentralServiceActionFields["fax"],
      faxExt: updateCentralServiceActionFields["faxExt"],
      specialties: updateCentralServiceActionFields["specialties"],
      metaSpecialties: updateCentralServiceActionFields["metaSpecialties"],
      procedures: updateCentralServiceActionFields["procedures"],
      link: updateCentralServiceActionFields["link"],
      customForm: updateCentralServiceActionFields["customForm"],
      additionalInfo: updateCentralServiceActionFields["additionalInfo"],
      intakeWaitTimeStart: hasIntakeWaitTimeMsg ? null : updateCentralServiceActionFields["intakeWaitTimeStart"],
      intakeWaitTimeEnd: hasIntakeWaitTimeMsg ? null : updateCentralServiceActionFields["intakeWaitTimeEnd"],
      intakeWaitTimeUnit: hasIntakeWaitTimeMsg ? null : updateCentralServiceActionFields["intakeWaitTimeUnit"],
      intakeWaitTimeMsg: updateCentralServiceActionFields["intakeWaitTimeMsg"],
      waitTimeStart: hasWaitTimeMsg ? null : updateCentralServiceActionFields["waitTimeStart"],
      waitTimeEnd: hasWaitTimeMsg ? null : updateCentralServiceActionFields["waitTimeEnd"],
      waitTimeUnit: hasWaitTimeMsg ? null : updateCentralServiceActionFields["waitTimeUnit"],
      waitTimeMsg: updateCentralServiceActionFields["waitTimeMsg"],
    };
  }
);

export const createCentralServiceAction = createAsyncThunk(
  'centralServices/create',
  async (createCentralServiceActionFields: any) => {
    console.log("createCentralServiceAction start");
    console.log(createCentralServiceActionFields);

    const newCentralServiceRef = doc(collection(db, "central-services"));

    let hasIntakeWaitTimeMsg = createCentralServiceActionFields["intakeWaitTimeMsg"] != null;
    let hasWaitTimeMsg = createCentralServiceActionFields["waitTimeMsg"] != null;
    let newServiceId = newCentralServiceRef.id;
    let newCPSONum = "service-" + newServiceId;

    let newServiceData = {
      serviceId: newServiceId,
      cpsoNum: newCPSONum,
      fullName: createCentralServiceActionFields["fullName"],
      location: createCentralServiceActionFields["location"],
      isVirtual: createCentralServiceActionFields["isVirtual"],
      phone: createCentralServiceActionFields["phone"],
      phoneExt: createCentralServiceActionFields["phoneExt"],
      fax: createCentralServiceActionFields["fax"],
      faxExt: createCentralServiceActionFields["faxExt"],
      specialties: createCentralServiceActionFields["specialties"],
      metaSpecialties: createCentralServiceActionFields["metaSpecialties"],
      procedures: createCentralServiceActionFields["procedures"],
      link: createCentralServiceActionFields["link"],
      customForm: createCentralServiceActionFields["customForm"],
      additionalInfo: createCentralServiceActionFields["additionalInfo"],
      intakeWaitTimeStart: hasIntakeWaitTimeMsg ? null : createCentralServiceActionFields["intakeWaitTimeStart"],
      intakeWaitTimeEnd: hasIntakeWaitTimeMsg ? null : createCentralServiceActionFields["intakeWaitTimeEnd"],
      intakeWaitTimeUnit: hasIntakeWaitTimeMsg ? null : createCentralServiceActionFields["intakeWaitTimeUnit"],
      intakeWaitTimeMsg: createCentralServiceActionFields["intakeWaitTimeMsg"],
      waitTimeStart: hasWaitTimeMsg ? null : createCentralServiceActionFields["waitTimeStart"],
      waitTimeEnd: hasWaitTimeMsg ? null : createCentralServiceActionFields["waitTimeEnd"],
      waitTimeUnit: hasWaitTimeMsg ? null : createCentralServiceActionFields["waitTimeUnit"],
      waitTimeMsg: createCentralServiceActionFields["waitTimeMsg"],
      doctors: [],
      gender: "",
      languages: [],
      searchSpecialties: [],
      subServices: [],
      hideCard: false,
    }

    await setDoc(newCentralServiceRef, newServiceData);

    let newScheduleRef = doc(collection(db, "schedules"));
    let today = new Date();
    today.setHours(10);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    let newScheduleData = {
      cpsoNum: newCPSONum,
      date: today,
      dateHistory: [today],
      emailSent: false,
      faxDate: null,
      faxSent: false,
      flagMsg: null,
      flagResolved: false,
      isCurrent: false,
      isFlagged: false,
      numCalls: 0,
      numFaxes: 0,
      numRescheduled: 0,
      scheduleId: newScheduleRef.id,
      sendFax: false,
      waitTimeEnd: hasWaitTimeMsg ? null : createCentralServiceActionFields["waitTimeEnd"],
      waitTimeMsg: createCentralServiceActionFields["waitTimeMsg"],
      waitTimeRecorded: today,
      waitTimeStart: hasWaitTimeMsg ? null : createCentralServiceActionFields["waitTimeStart"],
      waitTimeUnit: hasWaitTimeMsg ? null : createCentralServiceActionFields["waitTimeUnit"],
      adminLoad: [0, 1, 3],
    };
    await setDoc(newScheduleRef, newScheduleData);

    const newAdminRef = doc(db, "doctor-admin", newCPSONum);
    let newAdminAdditionalInfo = null;
    // let newAdminAdditionalInfo = createCentralServiceActionFields["additionalInfo"];
    // if(newAdminAdditionalInfo !== undefined && newAdminAdditionalInfo != null && newAdminAdditionalInfo !== ""){
    //   newAdminAdditionalInfo += "------------------- (do not edit above text) -------------------";
    // }
    let newAdminData = {
      "active": true,
      "additionalInfo": newAdminAdditionalInfo,
      "impAdditionalInfo": null,
      "availability": {
        "fri": [{
          "endTime": "17:00",
          "slotId": 0,
          "startTime": "09:00"
        }],
        "mon": [{
          "endTime": "17:00",
          "slotId": 0,
          "startTime": "09:00"
        }],
        "sat": [],
        "sun": [],
        "thu": [{
          "endTime": "17:00",
          "slotId": 0,
          "startTime": "09:00"
        }],
        "tue": [{
          "endTime": "17:00",
          "slotId": 0,
          "startTime": "09:00"
        }],
        "wed": [{
          "endTime": "17:00",
          "slotId": 0,
          "startTime": "09:00"
        }]
      },
      "cpsoNum": newCPSONum,
      "currScheduleRef": newScheduleRef,
      "hasSpecialAvailability": false,
      "meta": [],
      "minRescheduleDays": 60,
      "rescrape": false,
      "rescrapeDate": null,
      "retired": false,
      "scheduleHistory": [newScheduleRef],
      "toReview": false,
      "impToReview": false,
      "reviewedOnce": false,
      "adminLoad": [0, 1, 3],
    }
    await setDoc(newAdminRef, newAdminData);

    console.log("createCentralServiceAction done");

    return {
      newServiceData: newServiceData,
      newScheduleData: newScheduleData,
      newAdminData: newAdminData,
    };
  }
);

export const updateImagingAction = createAsyncThunk(
  'imaging/update',
  async (updateImagingActionFields: any) => {
    console.log("updateImagingAction start");
    console.log(updateImagingActionFields);

    const imagingRef = doc(db, "imaging-services", updateImagingActionFields["serviceId"]);

    let hasPriority2WaitTimeMsg = updateImagingActionFields["priority2WaitTimeMsg"] != null;
    let hasPriority3WaitTimeMsg = updateImagingActionFields["priority3WaitTimeMsg"] != null;
    let hasWaitTimeMsg = updateImagingActionFields["waitTimeMsg"] != null;

    await updateDoc(imagingRef, {
      fullName: updateImagingActionFields["fullName"],
      location: updateImagingActionFields["location"],
      phone: updateImagingActionFields["phone"],
      phoneExt: updateImagingActionFields["phoneExt"],
      fax: updateImagingActionFields["fax"],
      faxExt: updateImagingActionFields["faxExt"],
      specialties: updateImagingActionFields["specialties"],
      metaSpecialties: updateImagingActionFields["metaSpecialties"],
      procedures: updateImagingActionFields["procedures"],
      link: updateImagingActionFields["link"],
      customForm: updateImagingActionFields["customForm"],
      additionalInfo: updateImagingActionFields["additionalInfo"],
      priority2WaitTimeStart: hasPriority2WaitTimeMsg ? null : updateImagingActionFields["priority2WaitTimeStart"],
      priority2WaitTimeEnd: hasPriority2WaitTimeMsg ? null : updateImagingActionFields["priority2WaitTimeEnd"],
      priority2WaitTimeUnit: hasPriority2WaitTimeMsg ? null : updateImagingActionFields["priority2WaitTimeUnit"],
      priority2WaitTimeMsg: updateImagingActionFields["priority2WaitTimeMsg"],
      priority3WaitTimeStart: hasPriority3WaitTimeMsg ? null : updateImagingActionFields["priority3WaitTimeStart"],
      priority3WaitTimeEnd: hasPriority3WaitTimeMsg ? null : updateImagingActionFields["priority3WaitTimeEnd"],
      priority3WaitTimeUnit: hasPriority3WaitTimeMsg ? null : updateImagingActionFields["priority3WaitTimeUnit"],
      priority3WaitTimeMsg: updateImagingActionFields["priority3WaitTimeMsg"],
      waitTimeStart: hasWaitTimeMsg ? null : updateImagingActionFields["waitTimeStart"],
      waitTimeEnd: hasWaitTimeMsg ? null : updateImagingActionFields["waitTimeEnd"],
      waitTimeUnit: hasWaitTimeMsg ? null : updateImagingActionFields["waitTimeUnit"],
      waitTimeMsg: updateImagingActionFields["waitTimeMsg"],
      // waitTimeRecorded: updateImagingActionFields["waitTimeRecorded"],
    });

    console.log("updateImagingAction done");

    return {
      serviceId: updateImagingActionFields["serviceId"],
      fullName: updateImagingActionFields["fullName"],
      location: updateImagingActionFields["location"],
      phone: updateImagingActionFields["phone"],
      phoneExt: updateImagingActionFields["phoneExt"],
      fax: updateImagingActionFields["fax"],
      faxExt: updateImagingActionFields["faxExt"],
      specialties: updateImagingActionFields["specialties"],
      metaSpecialties: updateImagingActionFields["metaSpecialties"],
      procedures: updateImagingActionFields["procedures"],
      link: updateImagingActionFields["link"],
      customForm: updateImagingActionFields["customForm"],
      additionalInfo: updateImagingActionFields["additionalInfo"],
      priority2WaitTimeStart: hasPriority2WaitTimeMsg ? null : updateImagingActionFields["priority2WaitTimeStart"],
      priority2WaitTimeEnd: hasPriority2WaitTimeMsg ? null : updateImagingActionFields["priority2WaitTimeEnd"],
      priority2WaitTimeUnit: hasPriority2WaitTimeMsg ? null : updateImagingActionFields["priority2WaitTimeUnit"],
      priority2WaitTimeMsg: updateImagingActionFields["priority2WaitTimeMsg"],
      priority3WaitTimeStart: hasPriority3WaitTimeMsg ? null : updateImagingActionFields["priority3WaitTimeStart"],
      priority3WaitTimeEnd: hasPriority3WaitTimeMsg ? null : updateImagingActionFields["priority3WaitTimeEnd"],
      priority3WaitTimeUnit: hasPriority3WaitTimeMsg ? null : updateImagingActionFields["priority3WaitTimeUnit"],
      priority3WaitTimeMsg: updateImagingActionFields["priority3WaitTimeMsg"],
      waitTimeStart: hasWaitTimeMsg ? null : updateImagingActionFields["waitTimeStart"],
      waitTimeEnd: hasWaitTimeMsg ? null : updateImagingActionFields["waitTimeEnd"],
      waitTimeUnit: hasWaitTimeMsg ? null : updateImagingActionFields["waitTimeUnit"],
      waitTimeMsg: updateImagingActionFields["waitTimeMsg"],
      // waitTimeRecorded: updateImagingActionFields["waitTimeRecorded"],
    };
  }
);


export const markRequestReviewAction = createAsyncThunk(
  'requests/markReviewed',
  async (markRequestReviewActionFields: any) => {
    console.log("markRequestReviewAction start");
    console.log(markRequestReviewActionFields);

    const requestRef = doc(
      apiDB,
      "requests",
      markRequestReviewActionFields["key"]
    );

    await updateDoc(requestRef, {
      toReview: false,
      reviewedDate: markRequestReviewActionFields["date"],
    });

    console.log("markRequestReviewAction done");

    return {
      key: markRequestReviewActionFields["key"],
      reviewedDate: markRequestReviewActionFields["date"],
    };
  }
);

export const markTrialRequestReviewAction = createAsyncThunk(
  'requests/markTrialRequestReviewed',
  async (markTrialRequestReviewActionFields: any) => {
    console.log("markTrialRequestReviewAction start");
    console.log(markTrialRequestReviewActionFields);

    const requestRef = doc(
      apiDB,
      "trial-requests",
      markTrialRequestReviewActionFields["key"]
    );

    await updateDoc(requestRef, {
      toReview: false,
      reviewedDate: markTrialRequestReviewActionFields["date"],
      userType: markTrialRequestReviewActionFields["userType"],
    });

    console.log("markTrialRequestReviewAction done");

    return {
      key: markTrialRequestReviewActionFields["key"],
      reviewedDate: markTrialRequestReviewActionFields["date"],
      userType: markTrialRequestReviewActionFields["userType"],
    };
  }
);

export const hideDoctorAction = createAsyncThunk(
  'doctors/hide',
  async (hideDoctorFields: any) => {
    console.log("hideDoctor start");
    console.log(hideDoctorFields);

    let docRef = null;
    if (hideDoctorFields["isService"]) {
      docRef = doc(db, "central-services", hideDoctorFields["cpsoNum"].replace("service-", ""));
    } else {
      docRef = doc(db, "doctor-profiles", hideDoctorFields["cpsoNum"]);
    }

    await updateDoc(docRef, {
      hideCard: hideDoctorFields['hideCard'],
    });

    console.log("hideDoctor done");

    return {
      cpsoNum: hideDoctorFields["cpsoNum"],
      hideCard: hideDoctorFields['hideCard'],
      isService: hideDoctorFields["isService"],
    };
  }
);