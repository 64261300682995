import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  ButtonGroup,
  Tab,
  Spinner,
  Modal,
  Alert,
} from "react-bootstrap";

import { getPhoneNumber, getCallNumber } from "../utils";

import {
  renderLastCallDate,
  renderFlagMessage,
  renderFullName,
  renderPhone,
  renderSpeciality,
  renderRescrapeDate,
  renderNextCallDate,
} from "./FormElements";

import ProceduresForm from "./ProceduresForm";
import MetaTagsForm from "./MetaTagsForm";
import AdditionalInfoForm from "./AdditionalInfoForm";
import WaitTimeForm from "./WaitTimeForm";
import ActionSelect from "./ActionSelect";
import MetaSpecialtiesForm from "./MetaSpecialtiesForm";
import WaitTimeMsgForm from "./WaitTimeMsgForm";
import CustomFormForm from "./CustomFormForm";

import "../styles/SingleCard.css";
import StandardSelect from "./StandardSelect";
import { EmptySpecialityOption } from "../config";
import PhoneForm from "./PhoneForm";

interface SingleCardProps {
  groupDataType: any;
  selectedGroupData: any;
  isSavingGroupData: any;
  setIsSavingGroupData: any;
  dispatch: any;
  schedules: any;
  procedureList: any;
  admins: any;
  setShowFlagModal: any;
  sdMetaInfo: any;
  setSDMetaInfo: any;
  sdExtraInfo: any;
  setSDExtraInfo: any;
  sdImpExtraInfo: any;
  setSDImpExtraInfo: any;
  eventsMap: any;
  setShowFullCardModal: any;
  sdProcedures: any;
  setSDProcedures: any;
  disableEdits: any;
  sdWaitTimeStart: any;
  setSDWaitTimeStart: any;
  sdWaitTimeEnd: any;
  setSDWaitTimeEnd: any;
  sdWaitTimeUnit: any;
  setSDWaitTimeUnit: any;
  actionOptions: any;
  setActionOptions: any;
  actionValue: any;
  setActionValue: any;
  selectedGroupDataType: any;
  sdMetaSpecialties: any;
  setSDMetaSpecialties: any;
  adminMode: any;
  sdWaitTimeMsg: any;
  setSDWaitTimeMsg: any;
  sdFinalExtraInfo: any;
  setSDFinalExtraInfo: any;
  sdCustomForm: any;
  setSDCustomForm: any;
  allProcedureOptions: any;
  setShowEmailTemplateModal: any;
  sdSearchSpecialties: any;
  setSDSearchSpecialties: any;
  allSpecialityOptions: any;
  sdPhone: any;
  setSDPhone: any;
  sdPhoneExt: any;
  setSDPhoneExt: any;
  sdFax: any;
  setSDFax: any;
  sdFaxExt: any;
  setSDFaxExt: any;
  editPhoneFax: any;
  setEditPhoneFax: any;
}

function SingleCard(props: SingleCardProps) {
  // Form Control
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  useEffect(() => {
    clearMsgs();
  }, [props.selectedGroupData]);

  const clearMsgs = () => {
    if (errorMsg !== "") {
      setErrorMsg("");
    }
    if (successMsg !== "") {
      setSuccessMsg("");
    }
  };

  const renderSelectedGroupData = () => {
    if (
      props.selectedGroupData &&
      (props.groupDataType === "event" ||
        props.groupDataType === "email" ||
        props.groupDataType === "flagged" ||
        props.groupDataType === "additionalinfo" ||
        props.groupDataType === "impadditionalinfo" ||
        props.groupDataType === "reviewgroupinfo" ||
        props.groupDataType === "rescrape" ||
        props.groupDataType === "hidden" ||
        props.groupDataType === "meta-specialties")
    ) {
      const selectedDoctor = props.selectedGroupData;
      const selectedDoctorAdmin = props.admins.find(
        (adminData: any) => adminData["cpsoNum"] === selectedDoctor["cpsoNum"]
      );
      const selectedDoctorSchedule = props.schedules.find(
        (schedule: any) =>
          schedule["scheduleId"] === selectedDoctor["scheduleId"]
      );

      const allEdits =
        props.adminMode &&
        (props.selectedGroupDataType === "search-doctor" ||
          props.selectedGroupDataType === "additionalinfo-doctor" ||
          props.selectedGroupDataType === "impadditionalinfo-doctor" ||
          props.selectedGroupDataType === "reviewgroupinfo-doctor" ||
          props.selectedGroupDataType === "meta-specialties-doctor");

      const disableWaitTimeEdits =
        props.selectedGroupDataType === "additionalinfo-doctor" ||
        props.selectedGroupDataType === "impadditionalinfo-doctor" ||
        props.selectedGroupDataType === "reviewgroupinfo-doctor" ||
        props.selectedGroupDataType === "meta-specialties-doctor";

      return (
        <Tab.Content>
          {selectedDoctor["hideCard"] && (
            <Alert variant={"warning"}>
              <b>Note:</b> This doctor is hidden!
            </Alert>
          )}
          {!selectedDoctorAdmin["active"] && (
            <Alert variant={"danger"}>
              <b>Note:</b> This doctor has been removed!
            </Alert>
          )}
          {selectedDoctor["isUpdateCall"] &&
            selectedDoctor["nextCallDate"] != null && (
              <Row>{renderNextCallDate(selectedDoctor["nextCallDate"])}</Row>
            )}
          {selectedDoctor["isUpdateCall"] &&
            selectedDoctor["lastCallDate"] != null && (
              <Row>{renderLastCallDate(selectedDoctor["lastCallDate"])}</Row>
            )}
          {selectedDoctorSchedule &&
            selectedDoctorSchedule["flagMsg"] != null && (
              <Row>{renderFlagMessage(selectedDoctorSchedule["flagMsg"])}</Row>
            )}
          {props.selectedGroupDataType === "rescrape-doctor" &&
            selectedDoctorAdmin["rescrapeDate"] != null && (
              <Row>
                {renderRescrapeDate(selectedDoctorAdmin["rescrapeDate"])}
              </Row>
            )}
          <Row>
            {renderFullName(selectedDoctor["fullName"])}
            {renderPhone(
              getPhoneNumber(props.sdPhone, props.sdPhoneExt),
              getCallNumber(selectedDoctorSchedule),
              selectedDoctor["location"] !== null &&
                selectedDoctor["location"] !== undefined
                ? selectedDoctor["location"]["city"]
                : "",
              true,
              props.editPhoneFax,
              props.setEditPhoneFax,
              props.disableEdits,
            )}
          </Row>
          {props.editPhoneFax && <Row>
            <PhoneForm
              phoneLabel="Phone"
              phoneStr={props.sdPhone}
              setPhoneStr={props.setSDPhone}
              phoneExtStr={props.sdPhoneExt}
              setPhoneExtStr={props.setSDPhoneExt}
              disabled={props.disableEdits()}
            />
            <PhoneForm
              phoneLabel="Fax"
              phoneStr={props.sdFax}
              setPhoneStr={props.setSDFax}
              phoneExtStr={props.sdFaxExt}
              setPhoneExtStr={props.setSDFaxExt}
              disabled={props.disableEdits()}
            />
          </Row>}
          <Row>
            {renderSpeciality(selectedDoctor["specialties"], true)}
            <ProceduresForm
              procedureValue={props.sdProcedures}
              setProcedureValue={props.setSDProcedures}
              disable={props.disableEdits}
              hasInternalMedicine={selectedDoctor["specialties"].includes(
                "Internal Medicine"
              )}
              selectedSpecialties={
                props.selectedGroupData
                  ? props.selectedGroupData["specialties"].concat(
                      props.selectedGroupData["searchSpecialties"]
                    )
                  : null
              }
              procedureList={props.procedureList}
              clearMsgs={clearMsgs}
              allProcedureOptions={props.allProcedureOptions}
              adminMode={props.adminMode}
            />
          </Row>
          {props.adminMode && allEdits && (
            <Row>
              <StandardSelect
                label={"Search Specialities"}
                selectValue={props.sdSearchSpecialties}
                setSelectValue={props.setSDSearchSpecialties}
                selectOptions={props.allSpecialityOptions}
                selectType={EmptySpecialityOption}
                disable={() => props.disableEdits()}
                clearMsgs={clearMsgs}
                isMulti={true}
              />
            </Row>
          )}
          <Row>
            <WaitTimeForm
              waitTimeStartValue={props.sdWaitTimeStart}
              setWaitTimeStartValue={props.setSDWaitTimeStart}
              waitTimeEndValue={props.sdWaitTimeEnd}
              setWaitTimeEndValue={props.setSDWaitTimeEnd}
              waitTimeUnitValue={props.sdWaitTimeUnit}
              setWaitTimeUnitValue={props.setSDWaitTimeUnit}
              disable={() => disableWaitTimeEdits || props.disableEdits()}
              selectedGroupData={props.selectedGroupData}
              clearMsgs={clearMsgs}
              isAdminMode={props.adminMode}
            />
            {props.adminMode && (
              <WaitTimeMsgForm
                waitTimeMsgValue={props.sdWaitTimeMsg}
                setWaitTimeMsgValue={props.setSDWaitTimeMsg}
                disable={() => disableWaitTimeEdits || props.disableEdits()}
                selectedGroupData={props.selectedGroupData}
                clearMsgs={clearMsgs}
              />
            )}
            {!props.adminMode && (
              <MetaTagsForm
                metaTagsValue={props.sdMetaInfo}
                setMetaTagsValue={props.setSDMetaInfo}
                disable={() => disableWaitTimeEdits || props.disableEdits()}
                selectedGroupData={props.selectedGroupData}
                clearMsgs={clearMsgs}
                sdExtraInfo={props.sdExtraInfo}
                setSDExtraInfo={props.setSDExtraInfo}
              />
            )}
          </Row>
          {props.adminMode && !allEdits && (
            <Row>
              <MetaTagsForm
                metaTagsValue={props.sdMetaInfo}
                setMetaTagsValue={props.setSDMetaInfo}
                disable={() => disableWaitTimeEdits || props.disableEdits()}
                selectedGroupData={props.selectedGroupData}
                clearMsgs={clearMsgs}
                sdExtraInfo={props.sdExtraInfo}
                setSDExtraInfo={props.setSDExtraInfo}
              />
            </Row>
          )}
          {allEdits && (
            <Row>
              <MetaSpecialtiesForm
                metaSpecialtiesValue={props.sdMetaSpecialties}
                setMetaSpecialtiesValue={props.setSDMetaSpecialties}
                disable={props.disableEdits}
                clearMsgs={clearMsgs}
              />
              <MetaTagsForm
                metaTagsValue={props.sdMetaInfo}
                setMetaTagsValue={props.setSDMetaInfo}
                disable={() => disableWaitTimeEdits || props.disableEdits()}
                selectedGroupData={props.selectedGroupData}
                clearMsgs={clearMsgs}
                sdExtraInfo={props.sdExtraInfo}
                setSDExtraInfo={props.setSDExtraInfo}
              />
            </Row>
          )}
          <Row>
            <AdditionalInfoForm
              extraInfoValue={props.sdImpExtraInfo}
              setExtraInfoValue={props.setSDImpExtraInfo}
              disable={
                props.adminMode && props.groupDataType !== "flagged"
                  ? () => true
                  : props.disableEdits
              }
              selectedGroupData={props.selectedGroupData}
              clearMsgs={clearMsgs}
              headerLabel={"Important Additional Information"}
            />
          </Row>
          <Row>
            <AdditionalInfoForm
              extraInfoValue={props.sdExtraInfo}
              setExtraInfoValue={props.setSDExtraInfo}
              disable={
                props.adminMode && props.groupDataType !== "flagged"
                  ? () => true
                  : () => props.disableEdits()
              }
              selectedGroupData={props.selectedGroupData}
              clearMsgs={clearMsgs}
              headerLabel={"Other Additional Information"}
            />
          </Row>
            <Row>
              <AdditionalInfoForm
                extraInfoValue={props.sdFinalExtraInfo}
                setExtraInfoValue={props.setSDFinalExtraInfo}
                disable={props.adminMode ? () => props.disableEdits() : () => true}
                selectedGroupData={props.selectedGroupData}
                clearMsgs={clearMsgs}
                headerLabel={"Final Additional Information"}
              />
            </Row>
          <Row>
            <CustomFormForm
              customFormValue={props.sdCustomForm}
              setCustomFormValue={props.setSDCustomForm}
              disable={props.disableEdits}
              selectedGroupData={props.selectedGroupData}
              clearMsgs={clearMsgs}
            />
          </Row>
          <Row>
            <Col md="2">
              <Button
                variant="outline-secondary"
                onClick={() => props.setShowFullCardModal(true)}
                // disabled={props.disableEdits()}
              >
                Expand
              </Button>
            </Col>
            <Col className="previewButtonGroup" md="10">
              {props.isSavingGroupData && (
                <Spinner animation="border" className="loadingSpinner" />
              )}
              {props.actionValue !== undefined &&
                props.actionValue.value === "Email Sent" && (
                  <Button
                    variant="outline-primary"
                    onClick={() => props.setShowEmailTemplateModal(true)}
                    className="emailTemplateBtn"
                    // disabled={props.disableEdits()}
                  >
                    Email Template
                  </Button>
                )}
              <ActionSelect
                actionValue={props.actionValue}
                setActionValue={props.setActionValue}
                disableEdits={props.disableEdits}
                clearMsgs={clearMsgs}
                actionOptions={props.actionOptions}
                sdMetaInfo={props.sdMetaInfo}
                setIsSavingGroupData={props.setIsSavingGroupData}
                setErrorMsg={setErrorMsg}
                dispatch={props.dispatch}
                selectedGroupData={props.selectedGroupData}
                admins={props.admins}
                schedules={props.schedules}
                eventsMap={props.eventsMap}
                sdExtraInfo={props.sdExtraInfo}
                sdImpExtraInfo={props.sdImpExtraInfo}
                sdWaitTimeStart={props.sdWaitTimeStart}
                sdWaitTimeEnd={props.sdWaitTimeEnd}
                sdWaitTimeUnit={props.sdWaitTimeUnit}
                sdProcedures={props.sdProcedures}
                setShowFlagModal={props.setShowFlagModal}
                selectedGroupDataType={props.selectedGroupDataType}
                sdWaitTimeMsg={props.sdWaitTimeMsg}
                sdMetaSpecialties={props.sdMetaSpecialties}
                sdSearchSpecialties={props.sdSearchSpecialties}
                sdFinalExtraInfo={props.sdFinalExtraInfo}
                sdCustomForm={props.sdCustomForm}
                adminMode={props.adminMode}
                groupDataType={props.groupDataType}
                sdPhone={props.sdPhone}
                sdPhoneExt={props.sdPhoneExt}
                sdFax={props.sdFax}
                sdFaxExt={props.sdFaxExt}
              />
            </Col>
          </Row>
          <Row>
            <Col className="previewButtonGroup">
              <p className="successMsg">{successMsg}</p>
              <p className="errorMsg">{errorMsg}</p>
            </Col>
          </Row>
        </Tab.Content>
      );
    } else {
      return null;
    }
  };

  return renderSelectedGroupData();
}

export default SingleCard;
