import React, { useState, useEffect } from "react";
import { Col, Row, Form, InputGroup, FormControl } from "react-bootstrap";

import "../styles/StandardTextInput.css";

interface StandardTextInputProps {
  label: string;
  textValue: string | null;
  setTextValue: any;
  clearMsgs: any;
  istextArea?: boolean;
  textAreaLines?: number;
  disabled: boolean;
  labelColor?: string;
}

function StandardTextInput(props: StandardTextInputProps) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.clearMsgs();
    props.setTextValue(event.currentTarget.value);
  };

  return (
    <Col>
      {props.label.length === 0 ? null : (
        <Form.Label
          htmlFor="extra"
          className="formLabel"
          style={{ color: props.labelColor ? props.labelColor : "black" }}
        >
          {props.label}
        </Form.Label>
      )}
      <InputGroup className={"mb-3"}>
        <FormControl
          value={props.textValue == null ? "" : props.textValue}
          onChange={onChange}
          disabled={props.disabled}
          as={props.istextArea ? "textarea" : undefined}
          rows={props.istextArea ? props.textAreaLines : undefined}
        />
      </InputGroup>
    </Col>
  );
}

export default StandardTextInput;
