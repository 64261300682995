import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  InputGroup,
  FormControl,
  Modal,
  Tabs,
  Tab,
  Button,
  ListGroup,
  ButtonGroup,
  Spinner,
} from "react-bootstrap";
import Select, {
  OnChangeValue,
  ActionMeta,
  StylesConfig,
  GroupBase,
  SingleValue,
} from "react-select";
import CreatableSelect from "react-select/creatable";

import {
  WaitTimeMsgOption,
  ProcedureSpecialityOption,
  MetaSpecialityOption,
  SpecialityOption,
  CityOption,
  CreateSearchOption,
  ProcedureOption,
} from "../interfaces";

import {
  EmptySpecialityOption,
  EmptyCityOption,
  EmptyWaitTimeMsgOption,
  WaitTimeMsgOptions,
  MetaSpecialityOptions,
} from "../config";

import {
  updateCentralServiceAction,
  updateImagingAction,
  createCentralServiceAction,
} from "../app/actions";

import "../styles/CreateModal.css";
import StandardTextInput from "./StandardTextInput";
import WaitTimeForm from "./WaitTimeForm";
import WaitTimeMsgForm from "./WaitTimeMsgForm";
import ProceduresForm from "./ProceduresForm";
import StandardSelect from "./StandardSelect";
import MetaSpecialtiesForm from "./MetaSpecialtiesForm";
import PhoneForm from "./PhoneForm";

interface CreateModalProps {
  showCreateModal: any;
  handleCloseCreateModal: any;
  procedureList: any;
  allSpecialityOptions: any;
  allProcedureOptions: any;
  allCityOptions: any;
  adminMode: any;
  allCentralSearchData: any;
  centralServicesMap: any;
  allImagingSearchData: any;
  imagingMap: any;
  selectedGroupData: any;
  setSelectedGroupData: any;
  selectedGroupDataType: any;
  setSelectedGroupDataType: any;
  isSavingGroupData: any;
  setIsSavingGroupData: any;
  dispatch: any;
}

const CREATE_TYPES = [
  {
    key: "central-service",
    label: "Central Services",
  },
  {
    key: "imaging-service",
    label: "Imaging Services",
  },
];

function CreateModal(props: CreateModalProps) {
  const [selectedCreateType, setSelectedCreateType] = useState<any>(null);
  const [additionalInfo, setAdditionalInfo] = useState<string | null>(null);
  const [fax, setFax] = useState<string>("");
  const [faxExt, setFaxExt] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [phoneExt, setPhoneExt] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [customForm, setCustomForm] = useState<string>("");
  const [link, setLink] = useState<string | null>(null);
  const [intakeWaitTimeStart, setIntakeWaitTimeStart] = useState<number | null>(
    null
  );
  const [intakeWaitTimeEnd, setIntakeWaitTimeEnd] = useState<number | null>(
    null
  );
  const [intakeWaitTimeUnit, setIntakeWaitTimeUnit] = useState<string>("weeks");
  const [intakeWaitTimeMsg, setIntakeWaitTimeMsg] = useState<
    WaitTimeMsgOption | undefined
  >(undefined);

  const [priority2WaitTimeStart, setPriority2WaitTimeStart] = useState<
    number | null
  >(null);
  const [priority2WaitTimeEnd, setPriority2WaitTimeEnd] = useState<
    number | null
  >(null);
  const [priority2WaitTimeUnit, setPriority2WaitTimeUnit] =
    useState<string>("weeks");
  const [priority2WaitTimeMsg, setPriority2WaitTimeMsg] = useState<
    WaitTimeMsgOption | undefined
  >(undefined);

  const [priority3WaitTimeStart, setPriority3WaitTimeStart] = useState<
    number | null
  >(null);
  const [priority3WaitTimeEnd, setPriority3WaitTimeEnd] = useState<
    number | null
  >(null);
  const [priority3WaitTimeUnit, setPriority3WaitTimeUnit] =
    useState<string>("weeks");
  const [priority3WaitTimeMsg, setPriority3WaitTimeMsg] = useState<
    WaitTimeMsgOption | undefined
  >(undefined);

  const [waitTimeStart, setWaitTimeStart] = useState<number | null>(null);
  const [waitTimeEnd, setWaitTimeEnd] = useState<number | null>(null);
  const [waitTimeUnit, setWaitTimeUnit] = useState<string>("weeks");
  const [waitTimeMsg, setWaitTimeMsg] = useState<WaitTimeMsgOption | undefined>(
    undefined
  );
  const [procedures, setProcedures] = useState<ProcedureSpecialityOption[]>([]);
  const [specialties, setSpecialties] = useState<SpecialityOption[]>([]);
  const [specialtiesText, setSpecialtiesText] = useState<string[]>([]);
  const [metaSpecialties, setMetaSpecialties] = useState<
    MetaSpecialityOption[]
  >([]);
  const [firstLine, setFirstLine] = useState<string>("");
  const [secondLine, setSecondLine] = useState<string>("");
  const [thirdLine, setThirdLine] = useState<string>("");
  const [fourthLine, setFourthLine] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [searchCity, setSearchCity] = useState<CityOption | null>(null);
  const [city, setCity] = useState<string>("");

  const [successMsg, setSuccessMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const clearMsgs = () => {
    if (errorMsg !== "") {
      setErrorMsg("");
    }
    if (successMsg !== "") {
      setSuccessMsg("");
    }
  };

  const resetState = () => {
    // Reset state
    setAdditionalInfo(null);
    setFax("");
    setFaxExt("");
    setPhone("");
    setPhoneExt("");
    setFullName("");
    setCustomForm("");
    setLink(null);
    setIntakeWaitTimeStart(null);
    setIntakeWaitTimeEnd(null);
    setIntakeWaitTimeUnit("weeks");
    setIntakeWaitTimeMsg(undefined);

    setPriority2WaitTimeStart(null);
    setPriority2WaitTimeEnd(null);
    setPriority2WaitTimeUnit("weeks");
    setPriority2WaitTimeMsg(undefined);

    setPriority3WaitTimeStart(null);
    setPriority3WaitTimeEnd(null);
    setPriority3WaitTimeUnit("weeks");
    setPriority3WaitTimeMsg(undefined);

    setWaitTimeStart(null);
    setWaitTimeEnd(null);
    setWaitTimeUnit("weeks");
    setWaitTimeMsg(undefined);
    setProcedures([]);
    setSpecialties([]);
    setMetaSpecialties([]);
    setFirstLine("");
    setSecondLine("");
    setThirdLine("");
    setFourthLine("");
    setPostalCode("");
    setSearchCity(null);
    setCity("");
  };

  useEffect(() => {
    if (props.selectedGroupData !== null) {
      let selectedService: any = undefined;
      if (props.selectedGroupDataType === "central-service") {
        selectedService = props.centralServicesMap.get(
          props.selectedGroupData["value"]
        );
      } else if (props.selectedGroupDataType === "imaging-service") {
        selectedService = props.imagingMap.get(
          props.selectedGroupData["value"]
        );
      }

      if (selectedService !== undefined) {
        setAdditionalInfo(selectedService["additionalInfo"]);
        setFax(selectedService["fax"]);
        setFaxExt(selectedService["faxExt"]);
        setPhone(selectedService["phone"]);
        setPhoneExt(selectedService["phoneExt"]);
        setFullName(selectedService["fullName"]);
        setCustomForm(selectedService["customForm"]);
        setLink(selectedService["link"]);

        if (props.selectedGroupDataType === "central-service") {
          setIntakeWaitTimeStart(selectedService["intakeWaitTimeStart"]);
          setIntakeWaitTimeEnd(selectedService["intakeWaitTimeEnd"]);
          if (selectedService["intakeWaitTimeUnit"] == null) {
            setIntakeWaitTimeUnit("weeks");
          } else {
            setIntakeWaitTimeUnit(selectedService["intakeWaitTimeUnit"]);
          }
          let newIntakeWaitTimeMsg: WaitTimeMsgOption | undefined = undefined;
          if (selectedService["intakeWaitTimeMsg"] != null) {
            newIntakeWaitTimeMsg = WaitTimeMsgOptions.find(
              (elem) => elem.label === selectedService["intakeWaitTimeMsg"]
            );
          }
          setIntakeWaitTimeMsg(newIntakeWaitTimeMsg);
        } else if (props.selectedGroupDataType === "imaging-service") {
          setPriority2WaitTimeStart(selectedService["priority2WaitTimeStart"]);
          setPriority2WaitTimeEnd(selectedService["priority2WaitTimeEnd"]);
          if (selectedService["priority2WaitTimeUnit"] == null) {
            setPriority2WaitTimeUnit("weeks");
          } else {
            setPriority2WaitTimeUnit(selectedService["priority2WaitTimeUnit"]);
          }
          let newPriority2WaitTimeMsg: WaitTimeMsgOption | undefined =
            undefined;
          if (selectedService["priority2WaitTimeMsg"] != null) {
            newPriority2WaitTimeMsg = WaitTimeMsgOptions.find(
              (elem) => elem.label === selectedService["priority2WaitTimeMsg"]
            );
          }
          setPriority2WaitTimeMsg(newPriority2WaitTimeMsg);

          setPriority3WaitTimeStart(selectedService["priority3WaitTimeStart"]);
          setPriority3WaitTimeEnd(selectedService["priority3WaitTimeEnd"]);
          if (selectedService["priority3WaitTimeUnit"] == null) {
            setPriority3WaitTimeUnit("weeks");
          } else {
            setPriority3WaitTimeUnit(selectedService["priority3WaitTimeUnit"]);
          }
          let newPriority3WaitTimeMsg: WaitTimeMsgOption | undefined =
            undefined;
          if (selectedService["priority3WaitTimeMsg"] != null) {
            newPriority3WaitTimeMsg = WaitTimeMsgOptions.find(
              (elem) => elem.label === selectedService["priority3WaitTimeMsg"]
            );
          }
          setPriority3WaitTimeMsg(newPriority3WaitTimeMsg);
        }

        setWaitTimeStart(selectedService["waitTimeStart"]);
        setWaitTimeEnd(selectedService["waitTimeEnd"]);
        if (selectedService["waitTimeUnit"] == null) {
          setWaitTimeUnit("weeks");
        } else {
          setWaitTimeUnit(selectedService["waitTimeUnit"]);
        }

        let newWaitTimeMsg: WaitTimeMsgOption | undefined = undefined;
        if (selectedService["waitTimeMsg"] != null) {
          newWaitTimeMsg = WaitTimeMsgOptions.find(
            (elem) => elem.label === selectedService["waitTimeMsg"]
          );
        }
        setWaitTimeMsg(newWaitTimeMsg);

        let newProcedureOptions: ProcedureSpecialityOption[] = [];
        selectedService["procedures"].forEach((procedure: any) => {
          newProcedureOptions.push({
            label: procedure,
            value: procedure,
          });
        });
        setProcedures(newProcedureOptions);

        let newSpecialityOptions: SpecialityOption[] = [];
        selectedService["specialties"].forEach((speciality: any) => {
          newSpecialityOptions.push({
            label: speciality,
            value: speciality,
          });
        });
        setSpecialties(newSpecialityOptions);

        let newMetaSpecialityInfo: MetaSpecialityOption[] = [];
        selectedService["metaSpecialties"].forEach(
          (metaSpecialVal: any, index: number) => {
            let metaSpecialOption: any = MetaSpecialityOptions.find(
              (elem: any) => elem.label === metaSpecialVal
            );
            if (metaSpecialOption !== undefined) {
              newMetaSpecialityInfo.push(metaSpecialOption);
            } else {
              newMetaSpecialityInfo.push({
                value: MetaSpecialityOptions.length + index,
                label: metaSpecialVal,
              });
            }
          }
        );
        setMetaSpecialties(newMetaSpecialityInfo);
        const scsLocation = selectedService["location"];
        if (scsLocation !== null) {
          if (scsLocation["0Line"] !== undefined) {
            setFirstLine(scsLocation["0Line"]);
          }
          if (scsLocation["1Line"] !== undefined) {
            setSecondLine(scsLocation["1Line"]);
          }
          if (scsLocation["2Line"] !== undefined) {
            setThirdLine(scsLocation["2Line"]);
          }
          if (scsLocation["3Line"] !== undefined) {
            setFourthLine(scsLocation["3Line"]);
          }
          setPostalCode(scsLocation["postalCode"]);
          let scsCity = null;
          props.allCityOptions.forEach((cityOption: CityOption) => {
            if (cityOption.value === scsLocation["searchCity"]) {
              scsCity = cityOption;
            }
          });
          setSearchCity(scsCity);
          setCity(scsLocation["city"]);
        }
      }
    } else {
      resetState();
    }
    clearMsgs();
  }, [props.selectedGroupData, props.selectedGroupDataType]);

  useEffect(() => {
    let newSpecialtiesText: string[] = [];
    specialties.forEach((speciality: SpecialityOption) => {
      newSpecialtiesText.push(speciality.label);
    });
    setSpecialtiesText(newSpecialtiesText);
  }, [specialties]);

  const onSearchDataChange = (
    searchSelectValue: SingleValue<CreateSearchOption>,
    actionMeta: ActionMeta<CreateSearchOption>
  ) => {
    // clearMsgs();
    if (searchSelectValue) {
      props.setSelectedGroupData(searchSelectValue);
      props.setSelectedGroupDataType(selectedCreateType.key);
    } else {
      props.setSelectedGroupData(null);
      props.setSelectedGroupDataType("");
    }
  };

  const setCentralServiceFields = async (isUpdate: boolean) => {
    try {
      let searchCityText = null;
      if (searchCity !== null) {
        searchCityText = searchCity.value;
      }
      let metaSpecialtiesText: string[] = [];
      metaSpecialties.forEach((metaSpeciality: MetaSpecialityOption) => {
        metaSpecialtiesText.push(metaSpeciality.label);
      });
      let proceduresText: string[] = [];
      procedures.forEach((procedure: ProcedureSpecialityOption) => {
        proceduresText.push(procedure.label);
      });

      let intakeWaitTimeMsgText = null;
      if (intakeWaitTimeMsg !== undefined) {
        intakeWaitTimeMsgText = intakeWaitTimeMsg.label;
      }

      let waitTimeMsgText = null;
      if (waitTimeMsg !== undefined) {
        waitTimeMsgText = waitTimeMsg.label;
      }

      let location: any = {
        city: city === "" ? searchCityText : city,
        searchCity: searchCityText,
        isPrimary: true,
        postalCode: postalCode,
        province: "ON",
      };
      if (firstLine.length > 0) {
        location["0Line"] = firstLine;
      }
      if (secondLine.length > 0) {
        location["1Line"] = secondLine;
      }
      if (thirdLine.length > 0) {
        location["2Line"] = thirdLine;
      }
      if (fourthLine.length > 0) {
        location["3Line"] = fourthLine;
      }
      let actionFields: any = {
        fullName: fullName,
        location: location,
        phone: phone,
        phoneExt: phoneExt,
        fax: fax,
        faxExt: faxExt,
        specialties: specialtiesText,
        metaSpecialties: metaSpecialtiesText,
        procedures: proceduresText,
        intakeWaitTimeStart: intakeWaitTimeStart,
        intakeWaitTimeEnd: intakeWaitTimeEnd,
        intakeWaitTimeUnit: intakeWaitTimeUnit,
        intakeWaitTimeMsg: intakeWaitTimeMsgText,
        waitTimeStart: waitTimeStart,
        waitTimeEnd: waitTimeEnd,
        waitTimeUnit: waitTimeUnit,
        waitTimeMsg: waitTimeMsgText,
        link: link,
        customForm: customForm,
        additionalInfo: additionalInfo,
        isVirtual: searchCityText === "Virtual",
      };
      if (isUpdate) {
        actionFields["serviceId"] = props.selectedGroupData["value"];
        await props.dispatch(updateCentralServiceAction(actionFields)).unwrap();
      } else {
        await props.dispatch(createCentralServiceAction(actionFields)).unwrap();
        resetState();
      }
    } catch (err) {
      console.error("Failed to update/create central service: ", err);
      setErrorMsg("Failed to update/create central service.");
      props.setIsSavingGroupData(false);
    }
  };

  const setImagingFields = async (isUpdate: boolean) => {
    try {
      let searchCityText = null;
      if (searchCity !== null) {
        searchCityText = searchCity.value;
      }
      let metaSpecialtiesText: string[] = [];
      metaSpecialties.forEach((metaSpeciality: MetaSpecialityOption) => {
        metaSpecialtiesText.push(metaSpeciality.label);
      });
      let proceduresText: string[] = [];
      procedures.forEach((procedure: ProcedureSpecialityOption) => {
        proceduresText.push(procedure.label);
      });

      let priority2WaitTimeMsgText = null;
      if (priority2WaitTimeMsg !== undefined) {
        priority2WaitTimeMsgText = priority2WaitTimeMsg.label;
      }

      let priority3WaitTimeMsgText = null;
      if (priority3WaitTimeMsg !== undefined) {
        priority3WaitTimeMsgText = priority3WaitTimeMsg.label;
      }

      let waitTimeMsgText = null;
      if (waitTimeMsg !== undefined) {
        waitTimeMsgText = waitTimeMsg.label;
      }

      let location: any = {
        city: city === "" ? searchCityText : city,
        searchCity: searchCityText,
        isPrimary: true,
        postalCode: postalCode,
        province: "ON",
      };
      if (firstLine.length > 0) {
        location["0Line"] = firstLine;
      }
      if (secondLine.length > 0) {
        location["1Line"] = secondLine;
      }
      if (thirdLine.length > 0) {
        location["2Line"] = thirdLine;
      }
      if (fourthLine.length > 0) {
        location["3Line"] = fourthLine;
      }
      let actionFields: any = {
        fullName: fullName,
        location: location,
        phone: phone,
        phoneExt: phoneExt,
        fax: fax,
        faxExt: faxExt,
        specialties: specialtiesText,
        metaSpecialties: metaSpecialtiesText,
        procedures: proceduresText,
        priority2WaitTimeStart: priority2WaitTimeStart,
        priority2WaitTimeEnd: priority2WaitTimeEnd,
        priority2WaitTimeUnit: priority2WaitTimeUnit,
        priority2WaitTimeMsg: priority2WaitTimeMsgText,
        priority3WaitTimeStart: priority3WaitTimeStart,
        priority3WaitTimeEnd: priority3WaitTimeEnd,
        priority3WaitTimeUnit: priority3WaitTimeUnit,
        priority3WaitTimeMsg: priority3WaitTimeMsgText,
        waitTimeStart: waitTimeStart,
        waitTimeEnd: waitTimeEnd,
        waitTimeUnit: waitTimeUnit,
        waitTimeMsg: waitTimeMsgText,
        link: link,
        customForm: customForm,
        additionalInfo: additionalInfo,
      };
      if (isUpdate) {
        actionFields["serviceId"] = props.selectedGroupData["value"];
        await props.dispatch(updateImagingAction(actionFields)).unwrap();
      }
    } catch (err) {
      console.error("Failed to update/create central service: ", err);
      setErrorMsg("Failed to update/create central service.");
      props.setIsSavingGroupData(false);
    }
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setIsSavingGroupData(true);
    if (selectedCreateType.key === "central-service") {
      setCentralServiceFields(true);
    } else if (selectedCreateType.key === "imaging-service") {
      setImagingFields(true);
    }
  };

  const handleCreateCick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setIsSavingGroupData(true);
    setCentralServiceFields(false);
  };

  const onSelectCreateType = (createType: any) => {
    setSelectedCreateType(createType);
    props.setSelectedGroupData(null);
    props.setSelectedGroupDataType("");
  };

  return (
    <Modal
      show={props.showCreateModal}
      onHide={props.handleCloseCreateModal}
      dialogClassName="createModal"
      keyboard={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Services</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup horizontal className="createTypeGroup">
          {CREATE_TYPES.map((createType: any) => {
            return (
              <ListGroup.Item
                action
                variant={
                  selectedCreateType &&
                  selectedCreateType.key === createType.key
                    ? "primary"
                    : ""
                }
                eventKey={createType.key}
                key={createType.key}
                active={false}
                onClick={() => onSelectCreateType(createType)}
                disabled={props.isSavingGroupData}
              >
                {createType.label}
              </ListGroup.Item>
            );
          })}
        </ListGroup>

        {selectedCreateType && (
          <>
            {selectedCreateType.key === "central-service" && (
              <Select<CreateSearchOption>
                name="createSearch"
                className="createSearch"
                isClearable={true}
                isSearchable={true}
                options={props.allCentralSearchData}
                value={props.selectedGroupData}
                onChange={onSearchDataChange}
                placeholder={"Search for a centralized service..."}
              />
            )}
            {selectedCreateType.key === "imaging-service" && (
              <Select<CreateSearchOption>
                name="createSearch"
                className="createSearch"
                isClearable={true}
                isSearchable={true}
                options={props.allImagingSearchData}
                value={props.selectedGroupData}
                onChange={onSearchDataChange}
                placeholder={"Search for a imaging service..."}
              />
            )}
            <Row>
              <StandardTextInput
                label="Service Name"
                textValue={fullName}
                setTextValue={setFullName}
                clearMsgs={clearMsgs}
                disabled={props.isSavingGroupData}
              />
              {props.selectedGroupData != null && (
                <StandardTextInput
                  label="Service Id"
                  textValue={props.selectedGroupData["value"]}
                  setTextValue={() => null}
                  clearMsgs={clearMsgs}
                  disabled={true}
                />
              )}
            </Row>
            <Row>
              <Col>
                <label className="addressLabel">
                  <b>Address</b>
                </label>
                <Row>
                  <StandardTextInput
                    label=""
                    textValue={firstLine}
                    setTextValue={setFirstLine}
                    clearMsgs={clearMsgs}
                    disabled={
                      props.isSavingGroupData ||
                      props.selectedGroupDataType === "imaging-service"
                    }
                  />
                </Row>
                <Row>
                  <StandardTextInput
                    label=""
                    textValue={secondLine}
                    setTextValue={setSecondLine}
                    clearMsgs={clearMsgs}
                    disabled={props.isSavingGroupData}
                  />
                </Row>
                <Row>
                  <StandardTextInput
                    label=""
                    textValue={thirdLine}
                    setTextValue={setThirdLine}
                    clearMsgs={clearMsgs}
                    disabled={props.isSavingGroupData}
                  />
                </Row>
                <Row>
                  <StandardTextInput
                    label=""
                    textValue={fourthLine}
                    setTextValue={setFourthLine}
                    clearMsgs={clearMsgs}
                    disabled={props.isSavingGroupData}
                  />
                </Row>
              </Col>
              <Col>
                <Row>
                  <StandardSelect
                    label={"Search City"}
                    selectValue={searchCity}
                    setSelectValue={setSearchCity}
                    selectOptions={props.allCityOptions}
                    selectType={EmptyCityOption}
                    disable={() => props.isSavingGroupData}
                    clearMsgs={clearMsgs}
                    isMulti={false}
                  />
                </Row>
                <Row>
                  <StandardTextInput
                    label="Postal Code"
                    textValue={postalCode}
                    setTextValue={setPostalCode}
                    clearMsgs={clearMsgs}
                    disabled={props.isSavingGroupData}
                  />
                </Row>
                <Row>
                  <StandardTextInput
                    label="Province"
                    textValue={"ON"}
                    setTextValue={() => null}
                    clearMsgs={clearMsgs}
                    disabled={true}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
               <PhoneForm
                  phoneLabel="Phone"
                  phoneStr={phone}
                  setPhoneStr={setPhone}
                  phoneExtStr={phoneExt}
                  setPhoneExtStr={setPhoneExt}
                  disabled={props.isSavingGroupData}
                />
                <PhoneForm
                  phoneLabel="Fax"
                  phoneStr={fax}
                  setPhoneStr={setFax}
                  phoneExtStr={faxExt}
                  setPhoneExtStr={setFaxExt}
                  disabled={props.isSavingGroupData}
                />
            </Row>
            <Row>
              <StandardSelect
                label={"Speciality"}
                selectValue={specialties}
                setSelectValue={setSpecialties}
                selectOptions={props.allSpecialityOptions}
                selectType={EmptySpecialityOption}
                disable={() => props.isSavingGroupData}
                clearMsgs={clearMsgs}
                isMulti={true}
              />
              <MetaSpecialtiesForm
                metaSpecialtiesValue={metaSpecialties}
                setMetaSpecialtiesValue={setMetaSpecialties}
                disable={() => props.isSavingGroupData}
                clearMsgs={clearMsgs}
              />
            </Row>
            <Row>
              <ProceduresForm
                procedureValue={procedures}
                setProcedureValue={setProcedures}
                disable={() => props.isSavingGroupData}
                selectedSpecialties={specialtiesText}
                hasInternalMedicine={false}
                procedureList={props.procedureList}
                clearMsgs={clearMsgs}
                allProcedureOptions={props.allProcedureOptions}
                adminMode={props.adminMode}
              />
            </Row>
            {selectedCreateType &&
              selectedCreateType.key === "central-service" && (
                <Row>
                  <WaitTimeForm
                    label="Intake Wait Time"
                    waitTimeStartValue={intakeWaitTimeStart}
                    setWaitTimeStartValue={setIntakeWaitTimeStart}
                    waitTimeEndValue={intakeWaitTimeEnd}
                    setWaitTimeEndValue={setIntakeWaitTimeEnd}
                    waitTimeUnitValue={intakeWaitTimeUnit}
                    setWaitTimeUnitValue={setIntakeWaitTimeUnit}
                    disable={() =>
                      props.isSavingGroupData ||
                      props.selectedGroupData !== null
                    }
                    clearMsgs={clearMsgs}
                    isAdminMode={props.adminMode}
                  />
                  <StandardSelect
                    label="Intake Wait Time Msg"
                    selectValue={intakeWaitTimeMsg}
                    setSelectValue={setIntakeWaitTimeMsg}
                    selectOptions={WaitTimeMsgOptions}
                    selectType={EmptyWaitTimeMsgOption}
                    disable={() =>
                      props.isSavingGroupData ||
                      props.selectedGroupData !== null
                    }
                    clearMsgs={clearMsgs}
                    isMulti={false}
                  />
                </Row>
              )}
            {props.selectedGroupDataType === "imaging-service" && (
              <>
                <Row>
                  <WaitTimeForm
                    label="Priority 2 Wait Time"
                    waitTimeStartValue={priority2WaitTimeStart}
                    setWaitTimeStartValue={setPriority2WaitTimeStart}
                    waitTimeEndValue={priority2WaitTimeEnd}
                    setWaitTimeEndValue={setPriority2WaitTimeEnd}
                    waitTimeUnitValue={priority2WaitTimeUnit}
                    setWaitTimeUnitValue={setPriority2WaitTimeUnit}
                    disable={() =>
                      props.isSavingGroupData ||
                      props.selectedGroupData !== null
                    }
                    clearMsgs={clearMsgs}
                    isAdminMode={props.adminMode}
                  />
                  <StandardSelect
                    label="Priorirty 2 Wait Time Msg"
                    selectValue={priority2WaitTimeMsg}
                    setSelectValue={setPriority2WaitTimeMsg}
                    selectOptions={WaitTimeMsgOptions}
                    selectType={EmptyWaitTimeMsgOption}
                    disable={() =>
                      props.isSavingGroupData ||
                      props.selectedGroupData !== null
                    }
                    clearMsgs={clearMsgs}
                    isMulti={false}
                  />
                </Row>
                <Row>
                  <WaitTimeForm
                    label="Priority 3 Wait Time"
                    waitTimeStartValue={priority3WaitTimeStart}
                    setWaitTimeStartValue={setPriority3WaitTimeStart}
                    waitTimeEndValue={priority3WaitTimeEnd}
                    setWaitTimeEndValue={setPriority3WaitTimeEnd}
                    waitTimeUnitValue={priority3WaitTimeUnit}
                    setWaitTimeUnitValue={setPriority3WaitTimeUnit}
                    disable={() =>
                      props.isSavingGroupData ||
                      props.selectedGroupData !== null
                    }
                    clearMsgs={clearMsgs}
                    isAdminMode={props.adminMode}
                  />
                  <StandardSelect
                    label="Priorirty 3 Wait Time Msg"
                    selectValue={priority3WaitTimeMsg}
                    setSelectValue={setPriority3WaitTimeMsg}
                    selectOptions={WaitTimeMsgOptions}
                    selectType={EmptyWaitTimeMsgOption}
                    disable={() =>
                      props.isSavingGroupData ||
                      props.selectedGroupData !== null
                    }
                    clearMsgs={clearMsgs}
                    isMulti={false}
                  />
                </Row>
              </>
            )}
            <Row>
              <WaitTimeForm
                waitTimeStartValue={waitTimeStart}
                setWaitTimeStartValue={setWaitTimeStart}
                waitTimeEndValue={waitTimeEnd}
                setWaitTimeEndValue={setWaitTimeEnd}
                waitTimeUnitValue={waitTimeUnit}
                setWaitTimeUnitValue={setWaitTimeUnit}
                disable={() =>
                  props.isSavingGroupData || props.selectedGroupData !== null
                }
                clearMsgs={clearMsgs}
                isAdminMode={props.adminMode}
              />
              <StandardSelect
                label="Wait Time Msg"
                selectValue={waitTimeMsg}
                setSelectValue={setWaitTimeMsg}
                selectOptions={WaitTimeMsgOptions}
                selectType={EmptyWaitTimeMsgOption}
                disable={() =>
                  props.isSavingGroupData || props.selectedGroupData !== null
                }
                clearMsgs={clearMsgs}
                isMulti={false}
              />
            </Row>
            <Row>
              <StandardTextInput
                label="Website Link"
                textValue={link}
                setTextValue={setLink}
                clearMsgs={clearMsgs}
                disabled={props.isSavingGroupData}
              />
              <StandardTextInput
                label="Referral Form"
                textValue={customForm}
                setTextValue={setCustomForm}
                clearMsgs={clearMsgs}
                disabled={props.isSavingGroupData}
              />
            </Row>
            <Row>
              <StandardTextInput
                label="Additional Info"
                textValue={additionalInfo}
                setTextValue={setAdditionalInfo}
                clearMsgs={clearMsgs}
                disabled={props.isSavingGroupData}
                istextArea={true}
                textAreaLines={2}
              />
            </Row>
          </>
        )}
        <Row>
          <Col className="previewButtonGroup">
            <p className="successMsg">{successMsg}</p>
            <p className="errorMsg">{errorMsg}</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {props.isSavingGroupData && (
          <Spinner animation="border" className="savingSpinner" />
        )}
        <Button variant="secondary" onClick={props.handleCloseCreateModal}>
          Close
        </Button>
        {selectedCreateType && (
          <>
            {props.selectedGroupData !== null ? (
              <Button variant="primary" onClick={handleUpdateClick}>
                Update
              </Button>
            ) : (
              <Button
                variant="success"
                disabled={selectedCreateType.key !== "central-service"}
                onClick={handleCreateCick}
              >
                Create
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default CreateModal;
