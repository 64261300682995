export const EXTRA_CITIES = ["Virtual"];

export const RegionToRegionKey = new Map<string, string>([
  ["Toronto & York Region", "T"],
  ["Niagara Region", "N"],
  ["Hamilton Region", "H"],
  ["Simcoe Region", "S"],
  ["Other Region", "O"],
  ["GTA Region", "G"],
  ["Southwestern Ontario", "O"],
  ["Northern Ontario", "O"],
  ["Central/East Ontario", "O"],
]);

export const SpecialityColorMap = new Map<string, string>([
  ["Immunology & Allergy", "#000000"],
  ["Gastroenterology", "#222034"],
  ["Orthopedic Surgery", "#45283c"],
  ["Psychiatry", "#663931"],
  ["Neurology", "#8f563b"],
  ["Cardiology", "#df7126"],
  ["Internal Medicine", "#d9a066"],
  ["Plastic Surgery", "#4b692f"],
  ["Ophthalmology", "#6abe30"],
  ["Diagnostic Radiology", "#524b24"],
  ["Family Medicine", "#306082"],
  ["Family Medicine (Emergency Medicine)", "#3f3f74"],
  ["FCFP - Family Medicine", "#323c39"],
  ["Respirology", "#5b6ee1"],
  ["Emergency Medicine", "#639bff"],
  ["Community Medicine", "#5fcde4"],
  ["Obstetrics & Gynecology", "#36382E"],
  ["Anesthesiology", "#76428a"],
  ["Pediatrics", "#613F75"],
  ["Clinical Immunology", "#d95763"],
  ["Medical Oncology", "#d77bba"],
  ["No Speciality Reported", "#9badb7"],
  ["Geriatric Psychiatry", "#8f974a"],
  ["General Surgery", "#8a6f30"],
  ["Infectious Diseases", "#65743A"],
  ["Urology", "#6D597A"],
  ["Rheumatology", "#394F49"],
  ["Public Health and Preventive Medicine", "#9a031e"],
  ["Dermatology", "#e9b44c"],
  ["Geriatric Medicine", "#9381ff"],
  ["Otolaryngology (ENT)", "#64a6bd"],
  ["Child and Adolescent Psychiatry", "#008000"],

  ["Medical Biochemistry", "#005f73"],
  ["Clinical Immunology and Allergy", "#6b705c"],
  ["Cardiac Surgery", "#cb997e"],
  ["Anatomical Pathology", "#3a0ca3"],
  ["Nephrology", "#b5179e"],
  ["Clinical Pharmacology and Toxicology", "#f72585"],
  ["Critical Care Medicine", "#e63946"],
  ["Endocrinology", "#1d3557"],
  ["Pain Medicine", "#fb8500"],
  ["Thoracic Surgery", "#023047"],
  ["Physiatry", "#493B2A"],
  ["Hematology", "#0353a4"],
  ["Medical Genetics and Genomics", "#0466c8"],
  ["Radiation Oncology", "#003049"],
  ["Palliative Medicine", "#6d597a"],
  ["Forensic Psychiatry", "#b56576"],
  ["General Internal Medicine", "#2ec4b6"],
  ["Nuclear Medicine", "#e71d36"],
  ["Maternal Fetal Medicine", "#ff5d8f"],
  ["General Pathology", "#97a97c"],
  ["Medical Microbiology", "#555b6e"],
  ["Cardiothoracic Surgery", "#2b9348"],
  ["Neuropathology", "#80b918"],
  ["Neurosurgery", "#1565c0"],
  ["Hyperbaric Medicine", "#a26769"],
  ["Vascular Surgery", "#6d2e46"],
  ["Occupational Medicine", "#9381ff"],
  ["Hematological Pathology", "#00a896"],
  ["Interventional Radiology", "#05668d"],
  ["Gynecologic Oncology", "#7251b5"],
  ["Pediatric Radiology", "#b185db"],
  ["Cardiovascular and Thoracic Surgery", "#22333b"],
  ["Neuroradiology", "#ff8c00"],
  ["Clinical Pharmacology", "#669bbc"],
  ["General Surgical Oncology", "#3a6ea5"],
  ["Developmental Pediatrics", "#ff7900"],
  ["GP Sports Medicine", "#293F14"],
  ["GP Dermatology", "#38040E"],
  ["GP Psychotherapy", "#250902"],
  ["GP Obstetrics", "#9D8420"],
  ["GP Pain Management", "#86836D"],
  ["GP Addiction Medicine", "#2176FF"],
  ["GP Palliative Care", "#8FA998"],
  ["GP Gastroenterology", "#4F345A"],

  ["GP Geriatric Medicine", "#4D5061"],
  ["GP Varicose Veins", "#5C80BC"],
  ["GP Physiatry", "#4F345A"],
  ["GP Oncology", "#D64933"],
  ["Addiction Medicine", "#51291E"],
  ["Adolescent Medicine", "#301014"],

  ["Pediatric Emergency Medicine", "#3A506B"],
  ["Colorectal Surgery", "#98838F"],
  ["Pediatric Hematology/Oncology", "#653239"],
  ["Neonatal-Perinatal Medicine", "#0B132B"],

  ["Pediatric Cardiology", "#00072D"],
  ["Pediatric General Surgery", "#001C55"],
  ["Clinician Investigator Program", "#0E6BA8"],
  ["Pediatric Respirology", "#50808E"],
  ["Pediatric Infectious Diseases", "#531253"],
  ["Pediatric Neurology", "#0A2472"],
  ["Transfusion Medicine", "#69A297"],

  ["MRI", "#577399"],
  ["CT", "#33CA7F"],
  ["Adult", "#282F44"],
  ["Paediatric", "#7A5980"],

  ["Optometry", "#9799CA"],
  ["Physiotherapy", "#B47AEA"],
  ["Psychology & Psychotherapy", "#096B72"],
  ["Audiology", "#297373"],
  ["Chiropractic", "#39393A"],
  ["Speech Therapy", "#715AFF"],
  ["Podiatry", "#B47AEA"],
  ["Massage Therapy", "#102E4A"],
  ["Dietitian", "#493548"],
  ["Midwifery", "#A60067"],
  ["Occupational Therapy", "#D34E24"],
  ["Social Work", "#F28123"],

  ["Gynecologic Reproductive Endocrinology/Infertility", "#EF8354"],

  ["Pediatric Surgery", "#2D3047"],
  ["Pediatric Critical Care Medicine", "#048A81"],

  ["Sleep Disorder Medicine", "#466362"],
  ["Family Medicine (Palliative Care)'", "#048A81"],
  ["GP Sleep Medicine", "#938581"],

  ["GP Care of the Elderly", "#6A6B83"],
  ["GP Pain Management and Allergy", "#5F506B"],
  ["GP Ophthalmology", "#3C493F"],
  ["GP Diabetes", "#7E8D85"],
  ["GP Allergy Medicine", "#533747"],
]);
