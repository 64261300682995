import {
    collection,
    getDocs
} from "firebase/firestore";

import {
    markRequestReviewAction,
    markTrialRequestReviewAction,
} from "./actions"
import {
    createSlice,
    createAsyncThunk
} from "@reduxjs/toolkit";

import {
    apiDB
} from "../firebase.config";

const initialState = {
    requests: [],
    requestsMap: new Map(),
    trialRequests: [],
    trialRequestsMap: new Map(),
    dataRequests: [],
    dataRequestsMap: new Map(),
    status: "idle",
    error: null,
};

export const fetchRequests = createAsyncThunk(
    "requests/fetchRequests",
    async () => {
        console.log("Loading requests data");
        const querySnapshot = await getDocs(collection(apiDB, "requests"));
        const newRequestsData: any[] = [];

        querySnapshot.forEach((requestInfoObj) => {
            let requestData = requestInfoObj.data();
            if (requestData["date"] != null) {
                requestData["date"] = requestData["date"].toDate();
            }
            if (requestData["reviewedDate"] != null) {
                requestData["reviewedDate"] = requestData["reviewedDate"].toDate();
            }

            newRequestsData.push({
                key: requestInfoObj.id,
                data: requestData,
            });
        });
        console.log("Loaded requests data");

        console.log("Loading trial requests data");
        const tQuerySnapshot = await getDocs(collection(apiDB, "trial-requests"));
        const newTrialRequestsData: any[] = [];

        tQuerySnapshot.forEach((requestInfoObj) => {
            if (requestInfoObj.id !== "available-codes") {
                let requestData = requestInfoObj.data();
                if (requestData["created"] != null) {
                    requestData["created"] = requestData["created"].toDate();
                }
                if (requestData["reviewedDate"] != null) {
                    requestData["reviewedDate"] = requestData["reviewedDate"].toDate();
                }

                newTrialRequestsData.push({
                    key: requestInfoObj.id,
                    data: requestData,
                });
            }
        });
        console.log("Loaded trial requests data");

        console.log("Loading data requests data");
        const dQuerySnapshot = await getDocs(collection(apiDB, "data-requests"));
        const newDataRequestsData: any[] = [];

        dQuerySnapshot.forEach((requestInfoObj) => {
            let requestData = requestInfoObj.data();
            if (requestData["created"] != null) {
                requestData["created"] = requestData["created"].toDate();
            }
            if (requestData["reviewedDate"] != null) {
                requestData["reviewedDate"] = requestData["reviewedDate"].toDate();
            }
            let pagesRequested = requestData["pagesRequested"];
            pagesRequested.forEach((page: any) => {
                if (page["requestDate"] != null) {
                    page["requestDate"] = page["requestDate"].toDate();
                }
            });
            requestData["pagesRequested"] = pagesRequested;

            newDataRequestsData.push({
                key: requestInfoObj.id,
                data: requestData,
            });
        });
        console.log("Loaded data requests data");

        const allRequestData = {
            "requests": newRequestsData,
            "trialRequests": newTrialRequestsData,
            "dataRequests": newDataRequestsData,
        }

        return allRequestData;
    }
);

export const requestsSlice = createSlice({
    name: "requests",
    initialState,
    reducers: {
        computeMap: (state, {
            payload
        }) => {
            state.requestsMap = new Map();
            state.requests.forEach((group: any) => {
                state.requestsMap.set(group["key"], group["data"]);
            });

            state.trialRequestsMap = new Map();
            state.trialRequests.forEach((group: any) => {
                state.trialRequestsMap.set(group["key"], group["data"]);
            });

            state.dataRequestsMap = new Map();
            state.dataRequests.forEach((group: any) => {
                state.dataRequestsMap.set(group["key"], group["data"]);
            });
        },
        markReviewed: (state, {
            payload
        }) => {
            const existingRequest = state.requests.find(group => group["key"] === payload["key"])
            if (existingRequest) {
                existingRequest["data"]["toReview"] = false;
                existingRequest["data"]["reviewedDate"] = payload["reviewedDate"];
            }
        },
        markTrialRequestReviewed: (state, {
            payload
        }) => {
            const existingRequest = state.trialRequests.find(group => group["key"] === payload["key"])
            if (existingRequest) {
                existingRequest["data"]["toReview"] = false;
                existingRequest["data"]["reviewedDate"] = payload["reviewedDate"];
                existingRequest["data"]["userType"] = payload["userType"];
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchRequests.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchRequests.fulfilled, (state, action) => {
                // Add any fetched posts to the array
                let requests = action.payload["requests"];
                let trialRequests = action.payload["trialRequests"];
                state.requests = state.requests.concat(requests);
                state.trialRequests = state.trialRequests.concat(trialRequests);
                state.dataRequests = state.dataRequests.concat(action.payload["dataRequests"]);
                requestsSlice.caseReducers.computeMap(state, action);
                state.status = "loaded";
            })
            .addCase(fetchRequests.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(markRequestReviewAction.pending, (state, action) => {
                state.status = "updating";
            })
            .addCase(markRequestReviewAction.fulfilled, (state, action) => {
                requestsSlice.caseReducers.markReviewed(state, action);
                requestsSlice.caseReducers.computeMap(state, action);
                state.status = "updated";
            })
            .addCase(markTrialRequestReviewAction.pending, (state, action) => {
                state.status = "updating";
            })
            .addCase(markTrialRequestReviewAction.fulfilled, (state, action) => {
                requestsSlice.caseReducers.markTrialRequestReviewed(state, action);
                requestsSlice.caseReducers.computeMap(state, action);
                state.status = "updated";
            });
    },
});

export default requestsSlice.reducer;

export const selectAllRequests = state => state.requests.requests;
export const selectAllTrialRequests = state => state.requests.trialRequests;
export const selectAllRequestsMap = state => state.requests.requestsMap;
export const selectAllTrialRequestsMap = state => state.requests.trialRequestsMap;
export const selectAllDataRequests = state => state.requests.dataRequests;
export const selectAllDataRequestsMap = state => state.requests.dataRequestsMap;