import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import DatePicker from "react-datepicker";

import Select, {
  ActionMeta,
  StylesConfig,
  GroupBase,
  SingleValue,
} from "react-select";
import moment from "moment";

import {
  getNextFreeTimeSlot,
  getNextSpecialTimeSlot,
  checkToReview,
  getStrippedValue,
} from "../utils";

import {
  retireDoctorAction,
  faxAction,
  emailAction,
  rescheduleAction,
  completeAction,
  saveAction,
  saveAdminAction,
  removeAction,
  reviewedAction,
  rescrapeAction,
  hideDoctorAction,
} from "../app/actions";

import {
  NOT_ACCEPTING_DAYS,
  URGENT_ONLY_DAYS,
  NOT_ACCEPTING_VALUE,
  RETIRED_VALUE,
  WAIT_TIME_LOWER_PERCENT,
  MINIMUM_SCHEDULE_DAYS,
  FLAGGED_RESCRAPE_DAYS,
  WALK_IN_DAYS,
  URGENT_ONLY_VALUE,
  WALK_IN_VALUE,
  VARIABLE_VALUE,
} from "../config";

import { ActionOption } from "../interfaces";

import { START_HOUR, END_HOUR, LUNCH_HOUR } from "../utils";

import "../styles/ActionSelect.css";
import "react-datepicker/dist/react-datepicker.css";

interface ActionSelectProps {
  actionValue: any;
  setActionValue: any;
  disableEdits: any;
  clearMsgs: any;
  actionOptions: any;
  sdMetaInfo: any;
  setIsSavingGroupData: any;
  setErrorMsg: any;
  dispatch: any;
  selectedGroupData: any;
  admins: any;
  schedules: any;
  eventsMap: any;
  sdExtraInfo: any;
  sdImpExtraInfo: any;
  sdWaitTimeStart: any;
  sdWaitTimeEnd: any;
  sdWaitTimeUnit: any;
  sdProcedures: any;
  setShowFlagModal: any;
  selectedGroupDataType: any;
  sdWaitTimeMsg: any;
  sdMetaSpecialties: any;
  sdSearchSpecialties: any;
  sdFinalExtraInfo: any;
  sdCustomForm: any;
  adminMode: any;
  groupDataType: any;
  sdPhone: any;
  sdPhoneExt: any;
  sdFax: any;
  sdFaxExt: any;
}

function ActionSelect(props: ActionSelectProps) {
  const [showRescheduleDateSelect, setShowRescheduleDateSelect] =
    useState<boolean>(false);
  const [rescheduleDate, setRescheduleDate] = useState<Date | null>(null);

  useEffect(() => {
    if (
      props.actionValue !== undefined &&
      props.actionValue.value === "Reschedule w/ date"
    ) {
      setShowRescheduleDateSelect(true);
    } else {
      setShowRescheduleDateSelect(false);
      setRescheduleDate(null);
    }
  }, [props.actionValue]);

  const getActionColur = (actionValue: string) => {
    let textColor = "black";
    switch (actionValue) {
      case "Call Completed":
        textColor = "#198754";
        break;
      case "Flag":
      case "Remove Doctor":
        textColor = "#dc3545";
        break;
      case "Reschedule (auto)":
      case "Reschedule w/ date":
        textColor = "#EC9F05";
        break;
      case "Mark To Fax":
        textColor = "#166088";
        break;
      case "Email Sent":
        textColor = "#A87DF1";
        break;
      case "Hide Doctor":
      case "Unhide Doctor":
        textColor = "purple";
        break;
      default:
        textColor = "black";
    }
    return textColor;
  };

  const actionOptionStyles: StylesConfig<
    ActionOption,
    false,
    GroupBase<ActionOption>
  > = {
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        color: getActionColur(data.value),
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: getActionColur(data.value),
      };
    },
  };

  const handleRetireClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    let canRetire = false;
    props.sdMetaInfo.forEach((metaInfo: any) => {
      if (metaInfo.value === RETIRED_VALUE) {
        canRetire = true;
      }
    });
    if (props.adminMode) {
      if (
        props.sdWaitTimeMsg === undefined ||
        props.sdWaitTimeMsg.label !== "Retired"
      ) {
        canRetire = false;
      }
    }
    if (canRetire) {
      props.setIsSavingGroupData(true);
      setRetireFields();
    } else {
      props.setErrorMsg("Must choose Retired doctor tag");
    }
  };

  const handleReviewedClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setIsSavingGroupData(true);
    setReviewedFields();
  };

  const handleRescrapeClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setIsSavingGroupData(true);
    const now = new Date();
    const newRescrapeDate = moment(now)
      .add(FLAGGED_RESCRAPE_DAYS, "days")
      .toDate();
    setRescrapeFields(newRescrapeDate);
  };

  const handleSaveClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (checkUnsavedEmpty()) {
      props.setIsSavingGroupData(true);
      setSaveFields();
    }
  };

  const handleSaveAdminClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setIsSavingGroupData(true);
    setSaveAdminFields();
  };

  const handleHideDoctorClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setIsSavingGroupData(true);
    setSaveHideDoctorFields(true);
  };

  const handleUnhideDoctorClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setIsSavingGroupData(true);
    setSaveHideDoctorFields(false);
  };

  const handleFaxClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (checkUnsavedEmpty()) {
      props.setIsSavingGroupData(true);
      setFaxFields();
    }
  };

  const handleEmailClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (checkUnsavedEmpty()) {
      props.setIsSavingGroupData(true);
      setEmailFields();
    }
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (checkUnsavedEmpty()) {
      props.setIsSavingGroupData(true);
      setRemoveFields();
    }
  };

  const computeTimeSlotDetails = (baseDate: any) => {
    const selectedDoctor = props.selectedGroupData;
    const selectedDoctorAdmin = props.admins.find(
      (adminData: any) => adminData["cpsoNum"] === selectedDoctor["cpsoNum"]
    );

    let selectedTimeSlot = null;
    let kickDoctorDetails = null;
    if (selectedDoctorAdmin["hasSpecialAvailability"]) {
      let newFreeTimeSlotDetails: any = getNextSpecialTimeSlot(
        baseDate,
        selectedDoctorAdmin["availability"],
        props.eventsMap
      );
      selectedTimeSlot = newFreeTimeSlotDetails["newDate"];
      if (newFreeTimeSlotDetails["kickDoctorDetails"] != null) {
        const kickDoctorData =
          newFreeTimeSlotDetails["kickDoctorDetails"]["docData"];
        const kickDoctorSchedule = props.schedules.find(
          (schedule: any) =>
            schedule["scheduleId"] === kickDoctorData["scheduleId"]
        );
        const kickDoctorNewDate = getNextFreeTimeSlot(
          kickDoctorSchedule["date"],
          props.eventsMap
        );
        kickDoctorDetails = {
          cpsoNum: kickDoctorData["cpsoNum"],
          scheduleId: kickDoctorData["scheduleId"],
          newDate: kickDoctorNewDate,
          oldDate: kickDoctorSchedule["date"],
        };
      }
    } else {
      selectedTimeSlot = getNextFreeTimeSlot(baseDate, props.eventsMap);
    }
    console.log("selectedTimeSlot");
    console.log(selectedTimeSlot);
    console.log("kickDoctorDetails");
    console.log(kickDoctorDetails);

    return {
      selectedTimeSlot: selectedTimeSlot,
      kickDoctorDetails: kickDoctorDetails,
      toReview:
        selectedDoctorAdmin["toReview"] ||
        checkToReview(selectedDoctorAdmin["additionalInfo"], props.sdExtraInfo),
      impToReview:
        selectedDoctorAdmin["impToReview"] ||
        checkToReview(
          selectedDoctorAdmin["impAdditionalInfo"],
          props.sdImpExtraInfo
        ),
    };
  };

  const handleRescheduleClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (checkUnsavedEmpty()) {
      props.setIsSavingGroupData(true);
      const selectedDoctor = props.selectedGroupData;
      const selectedDoctorSchedule = props.schedules.find(
        (schedule: any) =>
          schedule["scheduleId"] === selectedDoctor["scheduleId"]
      );

      const timeSlotDetails = computeTimeSlotDetails(
        selectedDoctorSchedule["date"]
      );

      setRescheduleFields(
        timeSlotDetails["selectedTimeSlot"],
        timeSlotDetails["kickDoctorDetails"]
      );
    }
  };

  const setCompletedFields = async (
    selectedTimeSlot: any,
    kickDoctorDetails: any,
    toReview: any,
    impToReview: any,
    isNotAccepting: any,
    hasWaitTimeMsg: any
  ) => {
    try {
      const selectedDoctorSchedule = props.schedules.find(
        (schedule: any) =>
          schedule["scheduleId"] === props.selectedGroupData["scheduleId"]
      );

      await props
        .dispatch(
          completeAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            isService: props.selectedGroupData["isService"],
            scheduleId: props.selectedGroupData["scheduleId"],
            newDate: selectedTimeSlot,
            isNotAccepting: isNotAccepting,
            customForm: getStrippedValue(props.sdCustomForm),
            waitTimeStart: props.sdWaitTimeStart,
            waitTimeEnd: props.sdWaitTimeEnd,
            waitTimeUnit: props.sdWaitTimeUnit,
            waitTimeMsg: hasWaitTimeMsg ? props.sdWaitTimeMsg.label : null,
            kickDoctorDetails: kickDoctorDetails,
            meta: props.sdMetaInfo,
            additionalInfo: props.sdExtraInfo,
            impAdditionalInfo: props.sdImpExtraInfo,
            procedures: props.sdProcedures,
            phone: props.sdPhone,
            phoneExt: props.sdPhoneExt,
            fax: props.sdFax,
            faxExt: props.sdFaxExt,
            from: "call",
            toReview: toReview,
            impToReview: impToReview,
            flagResolved: props.selectedGroupDataType === "flagged-doctor",
            scheduleCurrentDate: selectedDoctorSchedule["date"],
            scheduleCurrentDateHistory: selectedDoctorSchedule["dateHistory"],
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Failed to mark as complete doctor: ", err);
      props.setErrorMsg("Failed to mark as complete doctor.");
      props.setIsSavingGroupData(false);
    }
  };

  const computeCompletedFields = (isNotAccepting: any, hasWaitTimeMsg: any) => {
    console.log("computeCompletedFields");
    props.setIsSavingGroupData(true);
    const now = new Date();

    let nextWaitTimeDays = null;
    if (isNotAccepting) {
      nextWaitTimeDays = NOT_ACCEPTING_DAYS;
    } else if (!hasWaitTimeMsg) {
      let waitTimeDays = props.sdWaitTimeStart;
      if (waitTimeDays != null) {
        if (props.sdWaitTimeUnit === "weeks") {
          waitTimeDays *= 7;
        } else if (props.sdWaitTimeUnit === "months") {
          waitTimeDays *= 30;
        }

        nextWaitTimeDays = Math.floor(waitTimeDays * WAIT_TIME_LOWER_PERCENT);

        // Less than a month, min is month
        if (nextWaitTimeDays < MINIMUM_SCHEDULE_DAYS) {
          nextWaitTimeDays = MINIMUM_SCHEDULE_DAYS;
        }
      }
    } else if (hasWaitTimeMsg) {
      if (props.sdWaitTimeMsg.label === "Urgent Only") {
        nextWaitTimeDays = URGENT_ONLY_DAYS;
      } else if (props.sdWaitTimeMsg.label === "Walk-In") {
        nextWaitTimeDays = WALK_IN_DAYS;
      }
    }

    if (nextWaitTimeDays != null) {
      if (props.selectedGroupData["minRescheduleDays"] > nextWaitTimeDays) {
        nextWaitTimeDays = props.selectedGroupData["minRescheduleDays"];
      }

      const newScheduleDate = moment(now)
        .add(nextWaitTimeDays, "days")
        .toDate();

      const timeSlotDetails = computeTimeSlotDetails(newScheduleDate);

      setCompletedFields(
        timeSlotDetails["selectedTimeSlot"],
        timeSlotDetails["kickDoctorDetails"],
        timeSlotDetails["toReview"],
        timeSlotDetails["impToReview"],
        isNotAccepting,
        hasWaitTimeMsg
      );
    } else {
      const selectedDoctorAdmin = props.admins.find(
        (adminData: any) =>
          adminData["cpsoNum"] === props.selectedGroupData["cpsoNum"]
      );
      setCompletedFields(
        null,
        null,
        selectedDoctorAdmin["toReview"] ||
          selectedDoctorAdmin["additionalInfo"] !== props.sdExtraInfo,
        selectedDoctorAdmin["impToReview"] ||
          selectedDoctorAdmin["impAdditionalInfo"] !== props.sdImpExtraInfo,
        isNotAccepting,
        hasWaitTimeMsg
      );
    }
  };

  const handleCompletedClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    let isNotAccepting = false;
    let isUrgentOnly = false;
    let isWalkIn = false;
    let isVariable = false;

    props.sdMetaInfo.forEach((metaInfo: any) => {
      if (metaInfo.value === NOT_ACCEPTING_VALUE) {
        isNotAccepting = true;
      }
      if (metaInfo.value === URGENT_ONLY_VALUE) {
        isUrgentOnly = true;
      }
      if (metaInfo.value === WALK_IN_VALUE) {
        isWalkIn = true;
      }
      if (metaInfo.value === VARIABLE_VALUE) {
        isVariable = true;
      }
    });

    if (props.adminMode) {
      if (
        props.sdWaitTimeMsg !== undefined &&
        props.sdWaitTimeMsg.label === "Not Accepting" &&
        !isNotAccepting
      ) {
        props.setErrorMsg("Wait time tag must be Not Accepting!");
        return;
      }

      if (
        props.sdWaitTimeMsg !== undefined &&
        props.sdWaitTimeMsg.label === "Urgent Only" &&
        !isUrgentOnly
      ) {
        props.setErrorMsg("Wait time tag must be Urgent Only");
        return;
      }

      if (
        props.sdWaitTimeMsg !== undefined &&
        props.sdWaitTimeMsg.label === "Walk-In" &&
        !isWalkIn
      ) {
        props.setErrorMsg("Wait time tag must be Walk-In!");
        return;
      }

      if (
        props.sdWaitTimeMsg !== undefined &&
        props.sdWaitTimeMsg.label === "Variable" &&
        !isVariable
      ) {
        props.setErrorMsg("Wait time tag must be Variable!");
        return;
      }
    }

    if (isNotAccepting || isUrgentOnly || isWalkIn || isVariable) {
      let useString = "";
      if (isNotAccepting) {
        useString = "Not Accepting";
      }
      if (isUrgentOnly) {
        useString = "Urgent Only";
      }
      if (isWalkIn) {
        useString = "Walk-In";
      }
      if (isVariable) {
        useString = "Variable";
      }

      if (props.sdWaitTimeStart != null || props.sdWaitTimeEnd != null) {
        props.setErrorMsg("Wait time must be empty if " + useString + "!");
      } else if (
        (props.sdWaitTimeMsg === undefined ||
          props.sdWaitTimeMsg.label !== useString) &&
        props.adminMode
      ) {
        props.setErrorMsg("Wait time message must be " + useString + "!");
      } else {
        computeCompletedFields(isNotAccepting, true);
      }
    } else {
      if (props.sdWaitTimeStart != null && props.sdWaitTimeEnd != null) {
        if (props.adminMode) {
          if (
            props.sdWaitTimeMsg !== undefined &&
            props.sdWaitTimeMsg.label !== "Pending Update"
          ) {
            props.setErrorMsg("Wait time message cannot be non-empty!");
          } else {
            computeCompletedFields(isNotAccepting, false);
          }
        } else {
          if (props.sdWaitTimeEnd < props.sdWaitTimeStart) {
            props.setErrorMsg(
              "Wait time end cannot be less than the wait time start!"
            );
          } else {
            computeCompletedFields(isNotAccepting, false);
          }
        }
      } else {
        if (props.adminMode) {
          if (
            props.sdWaitTimeMsg === undefined ||
            props.sdWaitTimeMsg.label === "Pending Update"
          ) {
            props.setErrorMsg("Wait time info cannot be empty!");
          } else {
            computeCompletedFields(isNotAccepting, true);
          }
        } else {
          props.setErrorMsg("Wait time info cannot be empty!");
        }
      }
    }
  };

  const checkUnsavedEmpty = () => {
    let isEmpty = true;
    if (props.selectedGroupData["isUpdateCall"]) {
      return isEmpty;
    }

    if (props.sdWaitTimeStart != null || props.sdWaitTimeEnd != null) {
      props.setErrorMsg("Wait time info will not be saved. Please clear.");
      isEmpty = false;
    }
    return isEmpty;
  };

  const handleFlagClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (checkUnsavedEmpty() || props.adminMode) {
      props.setShowFlagModal(true);
    }
  };

  const setReviewedFields = async () => {
    try {
      await props
        .dispatch(
          reviewedAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            isService: props.selectedGroupData["isService"],
            meta: props.sdMetaInfo,
            finalAdditionalInfo: props.sdFinalExtraInfo,
            procedures: props.sdProcedures,
            phone: props.sdPhone,
            phoneExt: props.sdPhoneExt,
            fax: props.sdFax,
            faxExt: props.sdFaxExt,
            metaSpecialties: props.sdMetaSpecialties,
            searchSpecialties: props.sdSearchSpecialties,
            customForm: getStrippedValue(props.sdCustomForm),
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Failed to mark as reviwed doctor: ", err);
      props.setErrorMsg("Failed to mark as reviwed doctor.");
      props.setIsSavingGroupData(false);
    }
  };

  const setSaveAdminFields = async () => {
    try {
      await props
        .dispatch(
          saveAdminAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            isService: props.selectedGroupData["isService"],
            meta: props.sdMetaInfo,
            finalAdditionalInfo: props.sdFinalExtraInfo,
            procedures: props.sdProcedures,
            phone: props.sdPhone,
            phoneExt: props.sdPhoneExt,
            fax: props.sdFax,
            faxExt: props.sdFaxExt,
            metaSpecialties: props.sdMetaSpecialties,
            searchSpecialties: props.sdSearchSpecialties,
            customForm: getStrippedValue(props.sdCustomForm),
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Failed to mark as saved doctor: ", err);
      props.setErrorMsg("Failed to mark as saved doctor.");
      props.setIsSavingGroupData(false);
    }
  };

  const setSaveHideDoctorFields = async (toHide: boolean) => {
    try {
      await props
        .dispatch(
          hideDoctorAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            isService: props.selectedGroupData["isService"],
            hideCard: toHide,
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Failed to hide/unhide doctor: ", err);
      props.setErrorMsg("Failed to hide/unhide doctor.");
      props.setIsSavingGroupData(false);
    }
  };

  const setRescrapeFields = async (rescrapeDate: Date) => {
    try {
      await props
        .dispatch(
          rescrapeAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            scheduleId: props.selectedGroupData["scheduleId"],
            rescrapeDate: rescrapeDate,
            flagResolved: props.selectedGroupDataType === "flagged-doctor",
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Failed to mark to rescrape doctor: ", err);
      props.setErrorMsg("Failed to mark to rescrape doctor.");
      props.setIsSavingGroupData(false);
    }
  };

  const setSaveFields = async () => {
    try {
      const selectedDoctorAdmin = props.admins.find(
        (adminData: any) =>
          adminData["cpsoNum"] === props.selectedGroupData["cpsoNum"]
      );

      await props
        .dispatch(
          saveAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            isService: props.selectedGroupData["isService"],
            meta: props.sdMetaInfo,
            customForm: getStrippedValue(props.sdCustomForm),
            additionalInfo: props.sdExtraInfo,
            impAdditionalInfo: props.sdImpExtraInfo,
            procedures: props.sdProcedures,
            phone: props.sdPhone,
            phoneExt: props.sdPhoneExt,
            fax: props.sdFax,
            faxExt: props.sdFaxExt,
            toReview:
              selectedDoctorAdmin["toReview"] ||
              checkToReview(
                selectedDoctorAdmin["additionalInfo"],
                props.sdExtraInfo
              ),
            impToReview:
              selectedDoctorAdmin["impToReview"] ||
              checkToReview(
                selectedDoctorAdmin["impAdditionalInfo"],
                props.sdImpExtraInfo
              ),
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Failed to save doctor: ", err);
      props.setErrorMsg("Failed to save doctor.");
      props.setIsSavingGroupData(false);
    }
  };

  const setRetireFields = async () => {
    try {
      const selectedDoctorSchedule = props.schedules.find(
        (schedule: any) =>
          schedule["scheduleId"] === props.selectedGroupData["scheduleId"]
      );

      await props
        .dispatch(
          retireDoctorAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            isService: props.selectedGroupData["isService"],
            scheduleId: props.selectedGroupData["scheduleId"],
            meta: props.sdMetaInfo,
            additionalInfo: props.sdExtraInfo,
            impAdditionalInfo: props.sdImpExtraInfo,
            procedures: props.sdProcedures,
            phone: props.sdPhone,
            phoneExt: props.sdPhoneExt,
            fax: props.sdFax,
            faxExt: props.sdFaxExt,
            flagResolved: props.selectedGroupDataType === "flagged-doctor",
            customForm: getStrippedValue(props.sdCustomForm),
            scheduleCurrentDate: selectedDoctorSchedule["date"],
            scheduleCurrentDateHistory: selectedDoctorSchedule["dateHistory"],
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Failed to retire doctor: ", err);
      props.setErrorMsg("Failed to mark to retire doctor.");
      props.setIsSavingGroupData(false);
    }
  };

  const setRemoveFields = async () => {
    try {
      await props
        .dispatch(
          removeAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            isService: props.selectedGroupData["isService"],
            scheduleId: props.selectedGroupData["scheduleId"],
            customForm: getStrippedValue(props.sdCustomForm),
            meta: props.sdMetaInfo,
            procedures: props.sdProcedures,
            phone: props.sdPhone,
            phoneExt: props.sdPhoneExt,
            fax: props.sdFax,
            faxExt: props.sdFaxExt,
            additionalInfo: props.sdExtraInfo,
            impAdditionalInfo: props.sdImpExtraInfo,
            flagResolved: props.selectedGroupDataType === "flagged-doctor",
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Failed to remove doctor: ", err);
      props.setErrorMsg("Failed to remove doctor.");
      props.setIsSavingGroupData(false);
    }
  };

  const setFaxFields = async () => {
    try {
      const selectedDoctorAdmin = props.admins.find(
        (adminData: any) =>
          adminData["cpsoNum"] === props.selectedGroupData["cpsoNum"]
      );

      await props
        .dispatch(
          faxAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            isService: props.selectedGroupData["isService"],
            scheduleId: props.selectedGroupData["scheduleId"],
            meta: props.sdMetaInfo,
            customForm: getStrippedValue(props.sdCustomForm),
            additionalInfo: props.sdExtraInfo,
            impAdditionalInfo: props.sdImpExtraInfo,
            procedures: props.sdProcedures,
            phone: props.sdPhone,
            phoneExt: props.sdPhoneExt,
            fax: props.sdFax,
            faxExt: props.sdFaxExt,
            flagResolved: props.selectedGroupDataType === "flagged-doctor",
            toReview:
              selectedDoctorAdmin["toReview"] ||
              checkToReview(
                selectedDoctorAdmin["additionalInfo"],
                props.sdExtraInfo
              ),
            impToReview:
              selectedDoctorAdmin["impToReview"] ||
              checkToReview(
                selectedDoctorAdmin["impAdditionalInfo"],
                props.sdImpExtraInfo
              ),
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Failed to mark to fax doctor: ", err);
      props.setErrorMsg("Failed to mark to fax doctor.");
      props.setIsSavingGroupData(false);
    }
  };

  const setEmailFields = async () => {
    try {
      const selectedDoctorAdmin = props.admins.find(
        (adminData: any) =>
          adminData["cpsoNum"] === props.selectedGroupData["cpsoNum"]
      );

      await props
        .dispatch(
          emailAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            scheduleId: props.selectedGroupData["scheduleId"],
            isService: props.selectedGroupData["isService"],
            meta: props.sdMetaInfo,
            customForm: getStrippedValue(props.sdCustomForm),
            additionalInfo: props.sdExtraInfo,
            impAdditionalInfo: props.sdImpExtraInfo,
            procedures: props.sdProcedures,
            phone: props.sdPhone,
            phoneExt: props.sdPhoneExt,
            fax: props.sdFax,
            faxExt: props.sdFaxExt,
            flagResolved: props.selectedGroupDataType === "flagged-doctor",
            toReview:
              selectedDoctorAdmin["toReview"] ||
              checkToReview(
                selectedDoctorAdmin["additionalInfo"],
                props.sdExtraInfo
              ),
            impToReview:
              selectedDoctorAdmin["impToReview"] ||
              checkToReview(
                selectedDoctorAdmin["impAdditionalInfo"],
                props.sdImpExtraInfo
              ),
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Failed to email doctor: ", err);
      props.setErrorMsg("Failed to email doctor.");
      props.setIsSavingGroupData(false);
    }
  };

  useEffect(() => {
    if (props.adminMode && props.actionOptions.length === 1) {
      props.setActionValue(props.actionOptions[0]);
    }
  }, [props.groupDataType, props.actionOptions]);

  const onActionValueChange = (
    actionValue: SingleValue<ActionOption>,
    actionMeta: ActionMeta<ActionOption>
  ) => {
    props.clearMsgs();
    if (actionValue) {
      props.setActionValue(actionValue);
    } else {
      props.setActionValue(undefined);
    }
  };

  const onRescheduleDateChange = (date: any) => {
    props.clearMsgs();
    let newDate: any = date;
    if (newDate != null && newDate !== undefined) {
      if (newDate.getHours() < START_HOUR || newDate.getHours() > END_HOUR) {
        newDate.setHours(START_HOUR);
      }
    }
    setRescheduleDate(newDate);
  };

  const filterCallTimes = (time: any) => {
    const selectedDate = new Date(time);
    return (
      selectedDate.getHours() >= START_HOUR &&
      selectedDate.getHours() <= END_HOUR &&
      selectedDate.getHours() !== LUNCH_HOUR
    );
  };

  const isWeekday = (date: any) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const setRescheduleFields = async (
    selectedTimeSlot: any,
    kickDoctorDetails: any
  ) => {
    try {
      const selectedDoctorAdmin = props.admins.find(
        (adminData: any) =>
          adminData["cpsoNum"] === props.selectedGroupData["cpsoNum"]
      );
      await props
        .dispatch(
          rescheduleAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            isService: props.selectedGroupData["isService"],
            scheduleId: props.selectedGroupData["scheduleId"],
            newDate: selectedTimeSlot,
            kickDoctorDetails: kickDoctorDetails,
            meta: props.sdMetaInfo,
            customForm: getStrippedValue(props.sdCustomForm),
            procedures: props.sdProcedures,
            phone: props.sdPhone,
            phoneExt: props.sdPhoneExt,
            fax: props.sdFax,
            faxExt: props.sdFaxExt,
            additionalInfo: props.sdExtraInfo,
            impAdditionalInfo: props.sdImpExtraInfo,
            flagResolved: props.selectedGroupDataType === "flagged-doctor",
            toReview:
              selectedDoctorAdmin["toReview"] ||
              checkToReview(
                selectedDoctorAdmin["additionalInfo"],
                props.sdExtraInfo
              ),
            impToReview:
              selectedDoctorAdmin["impToReview"] ||
              checkToReview(
                selectedDoctorAdmin["impAdditionalInfo"],
                props.sdImpExtraInfo
              ),
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Failed to reschedule doctor: ", err);
      props.setErrorMsg("Failed to reschedule doctor.");
      props.setIsSavingGroupData(false);
    }
  };

  const handleRescheduleWDateClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (checkUnsavedEmpty()) {
      if (rescheduleDate == null) {
        props.setErrorMsg("Date cannot be empty!");
      } else {
        props.setIsSavingGroupData(true);
        // const timeSlotDetails = computeTimeSlotDetails(rescheduleDate);
        setRescheduleFields(
          rescheduleDate,
          null
        );
      }
    }
  };

  const handlePerformActionClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (props.actionValue !== undefined) {
      switch (props.actionValue.value) {
        case "Save":
          handleSaveClick(event);
          break;
        case "Reschedule w/ date":
          handleRescheduleWDateClick(event);
          break;
        case "Flag":
          handleFlagClick(event);
          break;
        case "Mark Retired":
          handleRetireClick(event);
          break;
        case "Mark Reviewed":
          handleReviewedClick(event);
          break;
        case "Save Admin":
          handleSaveAdminClick(event);
          break;
        case "Hide Doctor":
          handleHideDoctorClick(event);
          break;
        case "Unhide Doctor":
          handleUnhideDoctorClick(event);
          break;
        case "Call Completed":
          handleCompletedClick(event);
          break;
        case "Reschedule (auto)":
          handleRescheduleClick(event);
          break;
        case "Mark To Fax":
          handleFaxClick(event);
          break;
        case "Email Sent":
          handleEmailClick(event);
          break;
        case "Remove Doctor":
          handleRemoveClick(event);
          break;
        case "Mark To Rescrape":
          handleRescrapeClick(event);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      {showRescheduleDateSelect && (
        <DatePicker
          showTimeSelect
          selected={rescheduleDate}
          dateFormat="MM/dd/yyyy h:mm aa"
          minDate={new Date()}
          className="rescheduleDatePicker"
          onChange={onRescheduleDateChange}
          disabled={props.disableEdits()}
          filterDate={isWeekday}
          filterTime={filterCallTimes}
          placeholderText={"Pick a date"}
          timeIntervals={60}
        />
      )}
      <Select<ActionOption>
        options={props.actionOptions}
        value={props.actionValue === undefined ? null : props.actionValue}
        className="actionSelection"
        isDisabled={props.disableEdits()}
        onChange={onActionValueChange}
        styles={actionOptionStyles}
        isClearable={true}
        placeholder="Select action..."
      />
      <Button
        variant="success"
        className="performActionBtn"
        onClick={handlePerformActionClick}
        disabled={props.disableEdits() || props.actionValue === undefined}
      >
        Perform
      </Button>
    </>
  );
}

export default ActionSelect;
