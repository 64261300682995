import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  InputGroup,
  FormControl,
  Modal,
  Tabs,
  Tab,
  Button,
  ListGroup,
  ButtonGroup,
  Spinner,
} from "react-bootstrap";
import Select, {
  OnChangeValue,
  ActionMeta,
  StylesConfig,
  GroupBase,
  SingleValue,
} from "react-select";
import CreatableSelect from "react-select/creatable";

import {
  AliasOption,
  ProcedureOption,
  SpecialityOption,
  FaxUnderlineOption,
  IsMulti,
  ProcedureTypeOption,
} from "../interfaces";

import { EmptyProcedureTypeOption, ProcedureTypeOptions } from "../config";

import {
  updateConfigSpecialityAction,
  createConfigProcedureAction,
  updateConfigProcedureAction,
} from "../app/actions";

import "../styles/EditConfigModal.css";
import StandardSelect from "./StandardSelect";
import StandardTextInput from "./StandardTextInput";

interface EditConfigModalProps {
  showEditConfigModal: any;
  handleCloseEditConfig: any;
  configSpecialties: any;
  configProcedures: any;
  selectedConfigSpeciality: any;
  setSelectedConfigSpeciality: any;
  selectedConfigProcedure: any;
  setSelectedConfigProcedure: any;
  isSavingConfigData: any;
  setIsSavingConfigData: any;
  allProcedureOptions: any;
  allAHProcedureOptions: any;
  allSpecialityOptions: any;
  dispatch: any;
}

function EditConfigModal(props: EditConfigModalProps) {
  const [editConfigKey, setEditConfigKey] = useState<string | undefined>(
    "specialities"
  );
  // Config Speciality State
  const [csAliases, setCSAliases] = useState<AliasOption[]>([]);
  const [csFaxVerb, setCSFaxVerb] = useState<string | null>(null);
  const [csSchedule, setCSSchedule] = useState<boolean>(true);
  const [csFaxUnderlines, setCSFaxUnderlines] = useState<FaxUnderlineOption[]>(
    []
  );
  const [csProcedures, setCSProcedures] = useState<ProcedureOption[]>([]);

  // Config Procedure State
  const [cpActive, setCPActive] = useState<boolean>(true);
  const [cpComplete, setCPComplete] = useState<boolean>(true);
  const [cpAliases, setCPAliases] = useState<AliasOption[]>([]);
  const [cpSpecialities, setCPSpecialities] = useState<SpecialityOption[]>([]);

  const [createProcedureName, setCreateProcedureName] = useState<string | null>(
    null
  );
  const [createProcedureType, setCreateProcedureType] = useState<
    ProcedureTypeOption | undefined
  >(() => {
    return ProcedureTypeOptions[0];
  });

  const [procedureNames, setProcedureNames] = useState<Set<string>>(new Set());
  const [ahProcedureNames, setAHProcedureNames] = useState<Set<string>>(
    new Set()
  );

  useEffect(() => {
    let newProcedureNames: Set<string> = new Set();
    props.allProcedureOptions.forEach((procedureOption: ProcedureOption) => {
      newProcedureNames.add(procedureOption.label.toLowerCase());
    });
    setProcedureNames(newProcedureNames);
  }, [props.allProcedureOptions]);

  useEffect(() => {
    let newAHProcedureNames: Set<string> = new Set();
    props.allAHProcedureOptions.forEach(
      (ahProcedureOption: ProcedureOption) => {
        newAHProcedureNames.add(ahProcedureOption.label.toLowerCase());
      }
    );
    setAHProcedureNames(newAHProcedureNames);
  }, [props.allAHProcedureOptions]);

  const [successMsg, setSuccessMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const clearMsgs = () => {
    if (errorMsg !== "") {
      setErrorMsg("");
    }
    if (successMsg !== "") {
      setSuccessMsg("");
    }
  };

  const onSelectSpeciality = (specialityData: any) => {
    clearMsgs();
    props.setSelectedConfigSpeciality(specialityData);
  };

  const onSelectProcedure = (procedureData: any) => {
    clearMsgs();
    props.setSelectedConfigProcedure(procedureData);
  };

  useEffect(() => {
    if (props.selectedConfigSpeciality != null) {
      let newAliases: AliasOption[] = [];
      props.selectedConfigSpeciality["aliases"].forEach((alias: any) => {
        newAliases.push({
          label: alias,
          value: alias,
        });
      });

      setCSAliases(newAliases);
      setCSFaxVerb(props.selectedConfigSpeciality["faxVerb"]);
      setCSSchedule(props.selectedConfigSpeciality["schedule"]);

      let newFaxUnderlines: FaxUnderlineOption[] = [];
      props.selectedConfigSpeciality["faxUnderlines"].forEach(
        (faxUnderline: any) => {
          newFaxUnderlines.push({
            label: faxUnderline,
            value: faxUnderline,
          });
        }
      );
      setCSFaxUnderlines(newFaxUnderlines);

      let newProcedures: ProcedureOption[] = [];
      props.selectedConfigSpeciality["procedures"].forEach(
        (procedureId: any) => {
          let procedureData = props.configProcedures.find(
            (configProcedure: any) => configProcedure["id"] === procedureId.id
          );
          if (procedureData) {
            newProcedures.push({
              label: procedureData["procedure"],
              value: procedureId.id,
            });
          }
        }
      );
      setCSProcedures(newProcedures);
    } else {
      setCSFaxVerb(null);
      setCSAliases([]);
      setCSProcedures([]);
      setCSFaxUnderlines([]);
      setCSSchedule(true);
    }
  }, [props.selectedConfigSpeciality]);

  useEffect(() => {
    if (props.selectedConfigProcedure != null) {
      let newAliases: AliasOption[] = [];
      props.selectedConfigProcedure["aliases"].forEach((alias: any) => {
        newAliases.push({
          label: alias,
          value: alias,
        });
      });

      setCPAliases(newAliases);
      setCPActive(props.selectedConfigProcedure["active"]);
      setCPComplete(props.selectedConfigProcedure["complete"]);

      let newSpecialities: SpecialityOption[] = [];
      props.selectedConfigProcedure["specialties"].forEach(
        (specialityId: any) => {
          let specialityData = props.configSpecialties.find(
            (configSpeciality: any) =>
              configSpeciality["id"] === specialityId.id
          );

          if (specialityData) {
            newSpecialities.push({
              label: specialityData["speciality"],
              value: specialityId.id,
            });
          }
        }
      );
      setCPSpecialities(newSpecialities);
    } else {
      setCPAliases([]);
      setCPSpecialities([]);
      setCPActive(true);
      setCPComplete(true);
    }
  }, [props.selectedConfigProcedure]);

  const renderConfigOptions = () => {
    let docElems: any[] = [];
    if (editConfigKey === "specialities") {
      props.configSpecialties.forEach((specialityData: any) => {
        docElems.push(
          <ListGroup.Item
            action
            variant={
              props.selectedConfigSpeciality &&
              props.selectedConfigSpeciality["id"] === specialityData["id"]
                ? "primary"
                : ""
            }
            key={specialityData["id"]}
            onClick={() => onSelectSpeciality(specialityData)}
            disabled={props.isSavingConfigData}
          >
            {specialityData["speciality"]}
          </ListGroup.Item>
        );
      });
    } else if (editConfigKey === "procedures") {
      props.configProcedures.forEach((procedureData: any) => {
        if (procedureData["type"] === "normal") {
          docElems.push(
            <ListGroup.Item
              action
              variant={
                props.selectedConfigProcedure &&
                props.selectedConfigProcedure["id"] === procedureData["id"]
                  ? "primary"
                  : ""
              }
              key={procedureData["id"]}
              onClick={() => onSelectProcedure(procedureData)}
              disabled={props.isSavingConfigData}
            >
              {procedureData["procedure"]}
            </ListGroup.Item>
          );
        }
      });
    } else if (editConfigKey === "allied-procedures") {
      props.configProcedures.forEach((procedureData: any) => {
        if (procedureData["type"] === "allied") {
          docElems.push(
            <ListGroup.Item
              action
              variant={
                props.selectedConfigProcedure &&
                props.selectedConfigProcedure["id"] === procedureData["id"]
                  ? "primary"
                  : ""
              }
              key={procedureData["id"]}
              onClick={() => onSelectProcedure(procedureData)}
              disabled={props.isSavingConfigData}
            >
              {procedureData["procedure"]}
            </ListGroup.Item>
          );
        }
      });
    }
    return (
      <ListGroup className="configList">
        {docElems.map((value: any, index: number) => {
          return value;
        })}
      </ListGroup>
    );
  };

  const specialityScheduleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearMsgs();
    setCSSchedule(event.currentTarget.checked);
  };

  const procedureActiveChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearMsgs();
    setCPActive(event.currentTarget.checked);
  };

  const procedureCompleteChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearMsgs();
    setCPComplete(event.currentTarget.checked);
  };

  const faxVerbChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearMsgs();
    setCSFaxVerb(event.currentTarget.value);
  };

  const csAliasOptionsChange = (
    newValue: OnChangeValue<AliasOption, IsMulti>,
    actionMeta: ActionMeta<AliasOption>
  ) => {
    clearMsgs();
    let newAliases: AliasOption[] = [];
    newValue.forEach((value) => {
      newAliases.push(value);
    });
    setCSAliases(newAliases);
  };

  const cpAliasOptionsChange = (
    newValue: OnChangeValue<AliasOption, IsMulti>,
    actionMeta: ActionMeta<AliasOption>
  ) => {
    clearMsgs();
    let newAliases: AliasOption[] = [];
    newValue.forEach((value) => {
      newAliases.push(value);
    });
    setCPAliases(newAliases);
  };

  const csFaxUnderlineOptionsChange = (
    newValue: OnChangeValue<FaxUnderlineOption, IsMulti>,
    actionMeta: ActionMeta<FaxUnderlineOption>
  ) => {
    clearMsgs();
    let newUnderlines: FaxUnderlineOption[] = [];
    newValue.forEach((value) => {
      newUnderlines.push(value);
    });
    setCSFaxUnderlines(newUnderlines);
  };

  const csProcedureOptionsChange = (
    newValue: OnChangeValue<ProcedureOption, IsMulti>,
    actionMeta: ActionMeta<ProcedureOption>
  ) => {
    clearMsgs();
    let newCSProcedures: ProcedureOption[] = [];
    newValue.forEach((value) => {
      newCSProcedures.push(value);
    });
    setCSProcedures(newCSProcedures);
  };

  const cpSpecialityOptionsChange = (
    newValue: OnChangeValue<ProcedureOption, IsMulti>,
    actionMeta: ActionMeta<ProcedureOption>
  ) => {
    clearMsgs();
    let newCPSpecialities: ProcedureOption[] = [];
    newValue.forEach((value) => {
      newCPSpecialities.push(value);
    });
    setCPSpecialities(newCPSpecialities);
  };

  const onSaveSpeciality = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (props.selectedConfigSpeciality) {
      props.setIsSavingConfigData(true);
      saveConfigSpecialityFields();
    }
  };

  const saveConfigSpecialityFields = async () => {
    try {
      await props
        .dispatch(
          updateConfigSpecialityAction({
            id: props.selectedConfigSpeciality["id"],
            schedule: csSchedule,
            faxVerb: csFaxVerb,
            aliases: csAliases,
            faxUnderlines: csFaxUnderlines,
            procedures: csProcedures,
            configProcedures: props.configProcedures,
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Speciality failed to update: ", err);
      setErrorMsg("Speciality failed to update.");
      props.setIsSavingConfigData(false);
    }
  };

  const onSaveProcedure = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (props.selectedConfigProcedure) {
      props.setIsSavingConfigData(true);
      saveConfigProcedureFields();
    }
  };

  const onCreateProcedure = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (
      createProcedureName == null ||
      createProcedureName.length === 0 ||
      createProcedureType === undefined
    ) {
      setErrorMsg("Fields cannot be empty");
    } else if (procedureNames.has(createProcedureName.toLowerCase())) {
      setErrorMsg("Procedure already exists");
    } else {
      props.setIsSavingConfigData(true);
      createConfigProcedureFields();
    }
  };

  const createConfigProcedureFields = async () => {
    try {
      await props
        .dispatch(
          createConfigProcedureAction({
            procedure: createProcedureName,
            type:
              createProcedureType === undefined
                ? ""
                : createProcedureType.value,
          })
        )
        .unwrap();
      setSuccessMsg("Successfully created new procedure");
      setCreateProcedureName(null);
      setCreateProcedureType(ProcedureTypeOptions[0]);
    } catch (err) {
      console.error("Procedure failed to create: ", err);
      setErrorMsg("Procedure failed to create.");
      props.setIsSavingConfigData(false);
    }
  };

  const saveConfigProcedureFields = async () => {
    try {
      await props
        .dispatch(
          updateConfigProcedureAction({
            id: props.selectedConfigProcedure["id"],
            active: cpActive,
            complete: cpComplete,
            aliases: cpAliases,
            specialties: cpSpecialities,
            configSpecialties: props.configSpecialties,
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Procedure failed to update: ", err);
      setErrorMsg("Procedure failed to update.");
      props.setIsSavingConfigData(false);
    }
  };

  const renderSelectedConfigOption = () => {
    if (editConfigKey === "specialities" && props.selectedConfigSpeciality) {
      return (
        <Tab.Content>
          <Row>
            <Col>
              <Form.Label htmlFor="speciality" className="formLabel">
                Speciality
              </Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  id="speciality"
                  value={props.selectedConfigSpeciality["speciality"]}
                  disabled={true}
                />
              </InputGroup>
            </Col>
            <Col>
              <Form.Label htmlFor="speciality" className="formLabel">
                Control
              </Form.Label>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label={csSchedule ? "Schedule" : "Don't Schedule"}
                  checked={csSchedule}
                  onChange={specialityScheduleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label htmlFor="speciality" className="formLabel">
                Fax Verb
              </Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  id="speciality"
                  value={csFaxVerb == null ? "" : csFaxVerb}
                  onChange={faxVerbChange}
                  disabled={props.isSavingConfigData}
                />
              </InputGroup>
            </Col>
            <Col>
              <Form.Label htmlFor="meta" className="formLabel">
                Fax Underlines
              </Form.Label>
              <InputGroup className="mb-3">
                <CreatableSelect<FaxUnderlineOption, IsMulti>
                  value={csFaxUnderlines}
                  isMulti={true}
                  className="metaSelection"
                  isDisabled={props.isSavingConfigData}
                  onChange={csFaxUnderlineOptionsChange}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label htmlFor="meta" className="formLabel">
                Aliases
              </Form.Label>
              <InputGroup className="mb-3">
                <CreatableSelect<AliasOption, IsMulti>
                  value={csAliases}
                  isMulti={true}
                  className="metaSelection"
                  isDisabled={props.isSavingConfigData}
                  onChange={csAliasOptionsChange}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label htmlFor="meta" className="formLabel">
                Procedures
              </Form.Label>
              <InputGroup className="mb-3">
                <Select<ProcedureOption, IsMulti>
                  options={props.allProcedureOptions}
                  value={csProcedures}
                  isMulti={true}
                  className="metaSelection"
                  isDisabled={props.isSavingConfigData}
                  onChange={csProcedureOptionsChange}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col className="previewButtonGroup" md={10}>
              {props.isSavingConfigData && (
                <Spinner animation="border" className="loadingSpinner" />
              )}
              <ButtonGroup>
                <Button
                  variant={"success"}
                  disabled={props.isSavingConfigData}
                  onClick={onSaveSpeciality}
                >
                  Save
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col className="previewButtonGroup">
              <p className="successMsg">{successMsg}</p>
              <p className="errorMsg">{errorMsg}</p>
            </Col>
          </Row>
        </Tab.Content>
      );
    } else if (
      (editConfigKey === "procedures" ||
        editConfigKey === "allied-procedures") &&
      props.selectedConfigProcedure
    ) {
      return (
        <Tab.Content>
          <Row>
            <Col>
              <Form.Label htmlFor="procedure" className="formLabel">
                Procedure
              </Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  id="procedure"
                  value={props.selectedConfigProcedure["procedure"]}
                  disabled={true}
                />
              </InputGroup>
            </Col>
            <Col>
              <Form.Label htmlFor="speciality" className="formLabel">
                Control
              </Form.Label>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  inline
                  label={cpActive ? "Visible" : "Not Visible"}
                  checked={cpActive}
                  onChange={procedureActiveChange}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  label={cpComplete ? "Complete" : "Not Complete"}
                  checked={cpComplete}
                  onChange={procedureCompleteChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label htmlFor="meta" className="formLabel">
                Aliases
              </Form.Label>
              <InputGroup className="mb-3">
                <CreatableSelect<AliasOption, IsMulti>
                  value={cpAliases}
                  isMulti={true}
                  className="metaSelection"
                  isDisabled={props.isSavingConfigData}
                  onChange={cpAliasOptionsChange}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label htmlFor="meta" className="formLabel">
                Specialties
              </Form.Label>
              <InputGroup className="mb-3">
                <Select<SpecialityOption, IsMulti>
                  options={props.allSpecialityOptions}
                  value={cpSpecialities}
                  isMulti={true}
                  className="metaSelection"
                  isDisabled={props.isSavingConfigData}
                  onChange={cpSpecialityOptionsChange}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col className="previewButtonGroup" md={10}>
              {props.isSavingConfigData && (
                <Spinner animation="border" className="loadingSpinner" />
              )}
              <ButtonGroup>
                <Button
                  variant={"success"}
                  disabled={props.isSavingConfigData}
                  onClick={onSaveProcedure}
                >
                  Save
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col className="previewButtonGroup">
              <p className="successMsg">{successMsg}</p>
              <p className="errorMsg">{errorMsg}</p>
            </Col>
          </Row>
        </Tab.Content>
      );
    }
  };

  const renderCreateForm = () => {
    if (editConfigKey === "create-procedure") {
      return (
        <div>
          <Row>
            <StandardTextInput
              label="Procedure Name"
              textValue={createProcedureName}
              setTextValue={setCreateProcedureName}
              clearMsgs={clearMsgs}
              disabled={props.isSavingConfigData}
            />
            <StandardSelect
              label="Procedure Type"
              selectValue={createProcedureType}
              setSelectValue={setCreateProcedureType}
              selectOptions={ProcedureTypeOptions}
              selectType={EmptyProcedureTypeOption}
              disable={() => props.isSavingConfigData}
              clearMsgs={clearMsgs}
              isMulti={false}
            />
          </Row>
          <Row>
            <Col></Col>
            <Col className="previewButtonGroup" md={10}>
              {props.isSavingConfigData && (
                <Spinner animation="border" className="loadingSpinner" />
              )}
              <ButtonGroup>
                <Button
                  variant={"success"}
                  disabled={props.isSavingConfigData}
                  onClick={onCreateProcedure}
                >
                  Create
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col className="previewButtonGroup">
              <p className="successMsg">{successMsg}</p>
              <p className="errorMsg">{errorMsg}</p>
            </Col>
          </Row>
        </div>
      );
    }
  };

  return (
    <Modal
      show={props.showEditConfigModal}
      onHide={props.handleCloseEditConfig}
      dialogClassName="editConfigModal"
      keyboard={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Config</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          activeKey={editConfigKey}
          onSelect={(k) => {
            if (k == null) {
              setEditConfigKey(undefined);
            } else {
              setEditConfigKey(k);
            }
            clearMsgs();
          }}
          className="mb-3"
        >
          <Tab eventKey="specialities" title="Specialities"></Tab>
          <Tab eventKey="procedures" title="Procedures"></Tab>
          <Tab eventKey="allied-procedures" title="Allied Procedures"></Tab>
          <Tab eventKey="create-procedure" title="Create Procedure"></Tab>
        </Tabs>
        <Row>
          {renderCreateForm()}
          <Col sm={5}>{renderConfigOptions()}</Col>
          <Col sm={7}>{renderSelectedConfigOption()}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleCloseEditConfig}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditConfigModal;
