import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from "firebase/firestore";

import {
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";

import {
  db
} from "../firebase.config";

import {
  retireDoctorAction,
  flagAction,
  faxAction,
  emailAction,
  rescheduleAction,
  completeAction,
  updateAvailabilityAction,
  saveAction,
  saveAdminAction,
  removeAction,
  reviewedAction,
  rescrapeAction,
  updateCentralServiceAction,
  createCentralServiceAction,
  hideDoctorAction,
} from "./actions";

const initialState = {
  schedules: [],
  schedulesMap: new Map(),
  status: "idle",
  error: null,
};

export const fetchSchedules = createAsyncThunk(
  "doctors/fetchSchedules",
  async (dataType: number) => {
    console.log("Loading schedule data");

    const q = query(collection(db, "schedules"), where("adminLoad", "array-contains", dataType));
    const querySnapshot = await getDocs(q);

    const newSchedules: any[] = [];

    querySnapshot.forEach((scheduleObj) => {
      let newScheduleData = scheduleObj.data();
      if (newScheduleData["date"] != null) {
        newScheduleData["date"] = newScheduleData["date"].toDate();
        newScheduleData["date"].setMilliseconds(0);
        let newDateHistory: any = [];
        newScheduleData["dateHistory"].forEach((dateObj: any) => {
          let newDateObj = dateObj.toDate();
          newDateObj.setMilliseconds(0);
          newDateHistory.push(newDateObj);
        });
        newScheduleData["dateHistory"] = newDateHistory;
      }

      if (newScheduleData["waitTimeRecorded"] != null) {
        newScheduleData["waitTimeRecorded"] = newScheduleData["waitTimeRecorded"].toDate();
        newScheduleData["waitTimeRecorded"].setMilliseconds(0);
      }
      if (newScheduleData["faxDate"] != null) {
        newScheduleData["faxDate"] = newScheduleData["faxDate"].toDate();
        newScheduleData["faxDate"].setMilliseconds(0);
      }
      newSchedules.push(newScheduleData);
    });

    console.log("Loaded schedule data");
    return newSchedules;
  }
);

export const fetchIndiviualSchedule = createAsyncThunk(
  "doctors/fetchIndiviualSchedule",
  async (scheduleIds: string[]) => {
    console.log("Loading individual schedule");
    console.log(scheduleIds);
    let allNewScheduleData = [];

    for (const scheduleId of scheduleIds) {
      const docRef = doc(db, "schedules", scheduleId);
      const docSnap = await getDoc(docRef);

      let newScheduleData: any = null;

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        newScheduleData = docSnap.data();

        if (newScheduleData["date"] != null) {
          newScheduleData["date"] = newScheduleData["date"].toDate();
          newScheduleData["date"].setMilliseconds(0);
          let newDateHistory: any = [];
          newScheduleData["dateHistory"].forEach((dateObj: any) => {
            let newDateObj = dateObj.toDate();
            newDateObj.setMilliseconds(0);
            newDateHistory.push(newDateObj);
          });
          newScheduleData["dateHistory"] = newDateHistory;
        }

        if (newScheduleData["waitTimeRecorded"] != null) {
          newScheduleData["waitTimeRecorded"] = newScheduleData["waitTimeRecorded"].toDate();
          newScheduleData["waitTimeRecorded"].setMilliseconds(0);
        }
        if (newScheduleData["faxDate"] != null) {
          newScheduleData["faxDate"] = newScheduleData["faxDate"].toDate();
          newScheduleData["faxDate"].setMilliseconds(0);
        }

        allNewScheduleData.push(newScheduleData);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }

    };

    console.log("Loaded individual schedule");
    return {
      "schedules": allNewScheduleData,
    };
  }
);

export const schedulesSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {
    computeMap: (state, {
      paylod
    }) => {
      state.schedulesMap = new Map();
      state.schedules.forEach((schedule: any) => {
        state.schedulesMap.set(schedule["scheduleId"], schedule);
      });
    },
    setWaitTime: (state, {
      payload
    }) => {
      const existingSchedule = state.schedules.find(schedule => schedule["scheduleId"] === payload["scheduleId"]);
      existingSchedule["waitTimeMsg"] = payload["waitTimeMsg"];
      existingSchedule["waitTimeStart"] = payload["waitTimeStart"];
      existingSchedule["waitTimeEnd"] = payload["waitTimeEnd"];
      existingSchedule["waitTimeUnit"] = payload["waitTimeUnit"];
      existingSchedule["waitTimeRecorded"] = payload["waitTimeRecorded"];
    },
    resolveFlag: (state, {
      payload
    }) => {
      if (payload["flagResolved"]) {
        const existingSchedule = state.schedules.find(schedule => schedule["scheduleId"] === payload["scheduleId"]);
        existingSchedule["isFlagged"] = false;
        existingSchedule["flagResolved"] = true;
      }
    },
    flagSchedule: (state, {
      payload
    }) => {
      const existingSchedule = state.schedules.find(schedule => schedule["scheduleId"] === payload["scheduleId"]);
      existingSchedule["flagMsg"] = payload["flagMsg"];
      existingSchedule["isFlagged"] = true;
      existingSchedule["flagResolved"] = false;
    },
    faxSchedule: (state, {
      payload
    }) => {
      const existingSchedule = state.schedules.find(schedule => schedule["scheduleId"] === payload["scheduleId"]);
      existingSchedule["numCalls"] += 1;
      existingSchedule["sendFax"] = true;
    },
    emailSchedule: (state, {
      payload
    }) => {
      const existingSchedule = state.schedules.find(schedule => schedule["scheduleId"] === payload["scheduleId"]);
      existingSchedule["numCalls"] += 1;
      existingSchedule["emailSent"] = true;
      existingSchedule["faxDate"] = payload["emailSentDate"];
    },
    reschedule: (state, {
      payload
    }) => {
      const existingSchedule = state.schedules.find(schedule => schedule["scheduleId"] === payload["scheduleId"]);
      existingSchedule["numCalls"] += 1;
      existingSchedule["numRescheduled"] += 1;
      existingSchedule["date"] = payload["newDate"];
      existingSchedule["dateHistory"].push(payload["newDate"]);
    },
    complete: (state, {
      payload
    }) => {
      const existingSchedule = state.schedules.find(schedule => schedule["scheduleId"] === payload["scheduleId"]);
      existingSchedule["isCurrent"] = false;
      if (payload["from"] === "call") {
        existingSchedule["numCalls"] += 1;
      }
    },
    newDates: (state, {
      payload
    }) => {
      const existingSchedule = state.schedules.find(schedule => schedule["scheduleId"] === payload["scheduleId"]);
      if (existingSchedule) {
        if (payload["setNewScheduleDates"]) {
          existingSchedule["date"] = payload["newScheduleDate"];
          existingSchedule["dateHistory"] = payload["newDateHistory"];
        }
      }
    },
    newSchedule: (state, {
      payload
    }) => {
      if (payload["newScheduleData"] != null) {
        state.schedules.push(payload["newScheduleData"]);
      }
    },
    moveDate: (state, {
      payload
    }) => {
      const existingSchedule = state.schedules.find(schedule => schedule["scheduleId"] === payload["scheduleId"]);
      let removeDate = existingSchedule["dateHistory"].find(
        (elem: any) => elem.getTime() === payload["oldDate"].getTime()
      );
      if (removeDate) {
        existingSchedule["dateHistory"].splice(
          existingSchedule["dateHistory"].indexOf(removeDate),
          1
        );
      }
      existingSchedule["date"] = payload["newDate"];
      existingSchedule["dateHistory"].push(payload["newDate"]);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSchedules.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSchedules.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        state.schedules = state.schedules.concat(action.payload);
        schedulesSlice.caseReducers.computeMap(state, action);
        state.status = "loaded";
      })
      .addCase(fetchSchedules.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchIndiviualSchedule.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(fetchIndiviualSchedule.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        action.payload["schedules"].forEach((actionSchedule: any) => {
          state.schedules = state.schedules.filter(schedules => schedules["scheduleId"] !== actionSchedule["scheduleId"]);
          state.schedules.push(actionSchedule);
        });
        schedulesSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(fetchIndiviualSchedule.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(retireDoctorAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(flagAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(faxAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(rescheduleAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(completeAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(updateAvailabilityAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(saveAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(removeAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(reviewedAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(saveAdminAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(rescrapeAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(emailAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(updateCentralServiceAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(createCentralServiceAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(hideDoctorAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(retireDoctorAction.fulfilled, (state, action) => {
        schedulesSlice.caseReducers.setWaitTime(state, {
          payload: {
            scheduleId: action.payload["scheduleId"],
            waitTimeMsg: action.payload["waitTimeMsg"],
            waitTimeStart: null,
            waitTimeEnd: null,
            waitTimeUnit: null,
            waitTimeRecorded: action.payload["waitTimeRecorded"],
          }
        });
        schedulesSlice.caseReducers.newDates(state, action);
        schedulesSlice.caseReducers.resolveFlag(state, action);
        schedulesSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(flagAction.fulfilled, (state, action) => {
        schedulesSlice.caseReducers.flagSchedule(state, action);
        schedulesSlice.caseReducers.newDates(state, action);
        schedulesSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(faxAction.fulfilled, (state, action) => {
        schedulesSlice.caseReducers.faxSchedule(state, action);
        schedulesSlice.caseReducers.resolveFlag(state, action);
        schedulesSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(rescheduleAction.fulfilled, (state, action) => {
        schedulesSlice.caseReducers.reschedule(state, action);
        const kickDoctorDetails = action.payload["kickDoctorDetails"];
        if (kickDoctorDetails != null) {
          schedulesSlice.caseReducers.moveDate(state, {
            payload: {
              scheduleId: kickDoctorDetails["scheduleId"],
              oldDate: kickDoctorDetails["oldDate"],
              newDate: kickDoctorDetails["newDate"],
            }
          });
        }
        schedulesSlice.caseReducers.resolveFlag(state, action);
        schedulesSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(completeAction.fulfilled, (state, action) => {
        schedulesSlice.caseReducers.newSchedule(state, action);
        schedulesSlice.caseReducers.setWaitTime(state, action);
        schedulesSlice.caseReducers.complete(state, action);
        schedulesSlice.caseReducers.newDates(state, action);
        const kickDoctorDetails = action.payload["kickDoctorDetails"];
        if (kickDoctorDetails != null) {
          schedulesSlice.caseReducers.moveDate(state, {
            payload: {
              scheduleId: kickDoctorDetails["scheduleId"],
              oldDate: kickDoctorDetails["oldDate"],
              newDate: kickDoctorDetails["newDate"],
            }
          });
        }
        schedulesSlice.caseReducers.resolveFlag(state, action);
        schedulesSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(updateAvailabilityAction.fulfilled, (state, action) => {
        state.status = "updated";
      })
      .addCase(saveAction.fulfilled, (state, action) => {
        state.status = "updated";
      })
      .addCase(removeAction.fulfilled, (state, action) => {
        schedulesSlice.caseReducers.resolveFlag(state, action);
        schedulesSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(reviewedAction.fulfilled, (state, action) => {
        state.status = "updated";
      })
      .addCase(saveAdminAction.fulfilled, (state, action) => {
        state.status = "updated";
      })
      .addCase(rescrapeAction.fulfilled, (state, action) => {
        schedulesSlice.caseReducers.resolveFlag(state, action);
        schedulesSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(emailAction.fulfilled, (state, action) => {
        schedulesSlice.caseReducers.emailSchedule(state, action);
        schedulesSlice.caseReducers.resolveFlag(state, action);
        schedulesSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(updateCentralServiceAction.fulfilled, (state, action) => {
        state.status = "updated";
      })
      .addCase(createCentralServiceAction.fulfilled, (state, action) => {
        schedulesSlice.caseReducers.newSchedule(state, action);
        schedulesSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(hideDoctorAction.fulfilled, (state, action) => {
        state.status = "updated";
      });
  },
});

export default schedulesSlice.reducer;

export const selectAllSchedules = state => state.schedules.schedules;
export const selectAllSchedulesMap = state => state.schedules.schedulesMap;