import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import ADashboard from "./ADashboard";
import Login from "./routes/Login";
import Logout from "./routes/Logout";
import { RequireAuth, ForwardAuth } from "./auth";
import RequestsDashboard from "./RequestsDashboard";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <ADashboard mode="normal" dataType={1} />
          </RequireAuth>
        }
      />
      {/* <Route
        path="/faxes"
        element={
          <RequireAuth>
            <ADashboard mode="faxes" dataType={2} />
          </RequireAuth>
        }
      /> */}
      <Route
        path="/admin"
        element={
          <RequireAuth>
            <ADashboard mode="admin" dataType={3} />
          </RequireAuth>
        }
      />
      <Route
        path="/admin/all"
        element={
          <RequireAuth>
            <ADashboard mode="admin" dataType={0} />
          </RequireAuth>
        }
      />
      <Route
        path="/requests"
        element={
          <RequireAuth>
            <RequestsDashboard mode="admin" />
          </RequireAuth>
        }
      />
      <Route
        path="/login"
        element={
          <ForwardAuth>
            <Login />
          </ForwardAuth>
        }
      />
      <Route
        path="/logout"
        element={
          <RequireAuth>
            <Logout />
          </RequireAuth>
        }
      />
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  );
}

export default App;
