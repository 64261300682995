import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  Spinner,
  FormControl,
} from "react-bootstrap";

import {
  updateGroupInfoAction,
  markGroupInfoReviewAction,
} from "../app/actions";

import "../styles/GroupCard.css";
import { checkToReview } from "../utils";

interface GroupCardProps {
  groupDataType: any;
  selectedGroupData: any;
  isSavingGroupData: any;
  setIsSavingGroupData: any;
  dispatch: any;
  groupInfo: any;
  adminMode: any;
}

function GroupCard(props: GroupCardProps) {
  const [sdGroupAllInfo, setSDGroupAllInfo] = useState<string>("");
  const [groupSuccessMsg, setGroupSuccessMsg] = useState<string>("");
  const [groupErrorMsg, setGroupErrorMsg] = useState<string>("");
  const [groupInfoReviewed, setGroupInfoReviewed] = useState<boolean>(false);

  useEffect(() => {
    if (props.selectedGroupData != null) {
      let groupInfo = props.groupInfo.find(
        (group: any) => group.key === props.selectedGroupData["key"]
      );
      if (groupInfo) {
        setSDGroupAllInfo(groupInfo["data"]["groupInfo"]);
        setGroupInfoReviewed(!groupInfo["data"]["toReview"]);
      } else {
        setSDGroupAllInfo("");
        setGroupInfoReviewed(true);
      }
    }
    props.setIsSavingGroupData(false);
  }, [props.selectedGroupData, props.groupInfo]);

  const clearMsgs = () => {
    setGroupSuccessMsg("");
    setGroupErrorMsg("");
  };

  const groupAllInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearMsgs();
    setSDGroupAllInfo(event.currentTarget.value);
  };

  const handleGroupInfoSaveClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setIsSavingGroupData(true);
    let groupInfo = props.groupInfo.find(
      (group: any) => group.key === props.selectedGroupData["key"]
    );
    if (
      groupInfo !== undefined &&
      groupInfo["data"]["groupInfo"] === sdGroupAllInfo
    ) {
      props.setIsSavingGroupData(false);
    }
    props.dispatch(
      updateGroupInfoAction({
        key: props.selectedGroupData["key"],
        groupInfo: sdGroupAllInfo,
        toReview:
          groupInfo === undefined ||
          groupInfo["data"]["toReview"] ||
          checkToReview(groupInfo["data"]["groupInfo"], sdGroupAllInfo),
      })
    );
  };

  const handleGroupInfoReviewClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    props.setIsSavingGroupData(true);
    props.dispatch(
      markGroupInfoReviewAction({
        key: props.selectedGroupData["key"],
      })
    );
  };

  return (
    <Col>
      <Row>
        <Form.Label htmlFor="allInfo" className="formLabel">
          Information for Entire Group
        </Form.Label>
        <InputGroup className="mb-3">
          <FormControl
            id="allInfo"
            value={sdGroupAllInfo}
            onChange={groupAllInfoChange}
            disabled={props.isSavingGroupData || props.adminMode}
            as="textarea"
            rows={10}
          />
        </InputGroup>
      </Row>
      <Row>
        <Col className="previewButtonGroup">
          {props.isSavingGroupData && (
            <Spinner animation="border" className="loadingSpinner" />
          )}
          {props.adminMode ? (
            <Button
              variant="primary"
              disabled={props.isSavingGroupData || groupInfoReviewed}
              onClick={handleGroupInfoReviewClick}
            >
              Mark Reviewed
            </Button>
          ) : (
            <Button
              variant="primary"
              disabled={props.isSavingGroupData}
              onClick={handleGroupInfoSaveClick}
            >
              Save
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="previewButtonGroup">
          <p className="successMsg">{groupSuccessMsg}</p>
          <p className="errorMsg">{groupErrorMsg}</p>
        </Col>
      </Row>
    </Col>
  );
}

export default GroupCard;
