import React, { useState, useEffect } from "react";
import { Modal, Spinner, Button } from "react-bootstrap";
import moment from "moment";
import ScheduleSelector from "react-schedule-selector";

import { WeekDays, MonScheduleDate } from "../utils";

import { updateAvailabilityAction } from "../app/actions";

import "../styles/AvailabilityForm.css";

interface AvailabilityFormProps {
  showAvailabilityModal: any;
  setShowAvailabilityModal: any;
  sdAvailability: any;
  groupDataType: any;
  selectedGroupData: any;
  dispatch: any;
}

function AvailabilityForm(props: AvailabilityFormProps) {
  const [sdSchedule, setSDSchedule] = useState<any>([]);
  const [isSavingSchedule, setIsSavingSchedule] = useState<boolean>(false);

  const parseSDSchedule = () => {
    // Create schedule selector
    let newSDSchedule: any[] = [];
    WeekDays.forEach((weekDay, index: number) => {
      if (props.sdAvailability[weekDay] !== undefined) {
        props.sdAvailability[weekDay].forEach((timeSlot: any) => {
          let startTime = moment(timeSlot["startTime"], ["HH:mm"]);
          let endTime = moment(timeSlot["endTime"], ["HH:mm"]);
          for (let i = startTime.hours(); i < endTime.hours(); i++) {
            let useDate = MonScheduleDate.clone().add(index, "days");
            let newTimeSlot = useDate.set({
              hour: i,
            });
            newSDSchedule.push(newTimeSlot.toDate());
          }
        });
      }
    });

    setSDSchedule(newSDSchedule);
  };

  useEffect(() => {
    if (props.sdAvailability !== undefined && props.sdAvailability !== null) {
      parseSDSchedule();
      if (isSavingSchedule) {
        setIsSavingSchedule(false);
        props.setShowAvailabilityModal(false);
        parseSDSchedule();
      }
    }
  }, [props.sdAvailability]);

  const handleCloseAvailabilityModal = () => {
    if (!isSavingSchedule) {
      props.setShowAvailabilityModal(false);
      parseSDSchedule();
    }
  };

  const handleScheduleChange = (newSchedule: any) => {
    if (!isSavingSchedule) {
      setSDSchedule(newSchedule);
    }
  };

  function compareDates(a: any, b: any) {
    if (a.getHours() < b.getHours()) return -1;
    if (a.getHours() > b.getHours()) return 1;
    return 0;
  }

  const parseScheduleToStandard = () => {
    let newAvail: any = {};
    let scheduleByDay: any = [];
    WeekDays.forEach((weekDay: any, index) => {
      scheduleByDay[index] = [];
      newAvail[weekDay] = [];
    });

    sdSchedule.forEach((newScheduleSlot: any) => {
      scheduleByDay[newScheduleSlot.getDay()].push(newScheduleSlot);
    });

    scheduleByDay.forEach((allHours: any, index: number) => {
      let startHourStore = -1;
      let expectedNextHour = -1;
      let sortedHourts = allHours;
      sortedHourts.sort(compareDates);
      sortedHourts.forEach((hour: any, index: number) => {
        let startHour = hour.getHours();
        if (expectedNextHour === -1) {
          startHourStore = startHour;
          expectedNextHour = startHour + 1;

          if (index === allHours.length - 1) {
            newAvail[WeekDays[hour.getDay()]].push({
              startTime: moment(startHourStore, ["H"]).format("HH:mm"),
              endTime: moment(expectedNextHour, ["H"]).format("HH:mm"),
              slotId: newAvail[WeekDays[hour.getDay()]].length,
            });

            startHourStore = startHour;
            expectedNextHour = startHour + 1;
          }
        } else {
          if (expectedNextHour !== startHour || index === allHours.length - 1) {
            let origNextHour = expectedNextHour;
            if (
              expectedNextHour === startHour &&
              index === allHours.length - 1
            ) {
              expectedNextHour += 1;
            }

            newAvail[WeekDays[hour.getDay()]].push({
              startTime: moment(startHourStore, ["H"]).format("HH:mm"),
              endTime: moment(expectedNextHour, ["H"]).format("HH:mm"),
              slotId: newAvail[WeekDays[hour.getDay()]].length,
            });

            if (origNextHour !== startHour && index === allHours.length - 1) {
              startHourStore = startHour;
              expectedNextHour = startHour + 1;

              newAvail[WeekDays[hour.getDay()]].push({
                startTime: moment(startHourStore, ["H"]).format("HH:mm"),
                endTime: moment(expectedNextHour, ["H"]).format("HH:mm"),
                slotId: newAvail[WeekDays[hour.getDay()]].length,
              });
            } else {
              startHourStore = startHour;
              expectedNextHour = startHour + 1;
            }
          } else {
            expectedNextHour += 1;
          }
        }
      });
    });
    return newAvail;
  };

  const checkStandardSchedule = (standardSchedule: any) => {
    let standard = true;
    WeekDays.forEach((weekDay) => {
      if (weekDay === "sat" || weekDay === "sun") {
        if (standardSchedule[weekDay].length > 0) {
          standard = false;
        }
      } else {
        if (standardSchedule[weekDay].length === 1) {
          let standardSlot = standardSchedule[weekDay][0];
          if (
            standardSlot["startTime"] !== "09:00" ||
            standardSlot["endTime"] !== "17:00"
          ) {
            standard = false;
          }
        } else {
          standard = false;
        }
      }
    });

    return standard;
  };

  const handleSaveAvailabilityClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    let standardSchedule = parseScheduleToStandard();
    setIsSavingSchedule(true);
    setAvailabilityFields(standardSchedule);
  };

  const setAvailabilityFields = async (standardSchedule: any) => {
    try {
      await props
        .dispatch(
          updateAvailabilityAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            availability: standardSchedule,
            hasSpecialAvailability: !checkStandardSchedule(standardSchedule),
          })
        )
        .unwrap();
    } catch (err) {
      console.error("Failed to save schedule: ", err);
      window.alert("Failed to save schedule.");
    }
  };

  return (
    <Modal
      show={props.showAvailabilityModal}
      onHide={handleCloseAvailabilityModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Schedule Selector </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ScheduleSelector
          selection={sdSchedule}
          startDate={MonScheduleDate}
          numDays={7}
          minTime={8}
          maxTime={22}
          hourlyChunks={1}
          onChange={handleScheduleChange}
          dateFormat={"ddd"}
        />
      </Modal.Body>
      <Modal.Footer>
        {isSavingSchedule && (
          <Spinner animation="border" className="loadingSpinner" />
        )}
        <Button
          variant="secondary"
          onClick={handleCloseAvailabilityModal}
          disabled={isSavingSchedule}
        >
          Close
        </Button>
        <Button
          variant="primary"
          disabled={isSavingSchedule}
          onClick={handleSaveAvailabilityClick}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AvailabilityForm;
