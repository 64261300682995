import moment from "moment";
import _ from "underscore";

const WeekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

const NUM_PER_HOUR = 15;
const HOURS_PER_DAY = 8;
export const START_HOUR = 9;
export const LUNCH_HOUR = 12;
const WORK_DAYS = 5;
export const END_HOUR = START_HOUR + HOURS_PER_DAY - 1;
const FRIDAY = 5;
const SATURDAY = 6;
const SUNDAY = 0;

// function getPhoneNumber(doctor: any) {
//   if (doctor != null) {
//     let phone = doctor["phone"];
//     let phoneExt = doctor["phoneExt"];
//     if (
//       phone != null &&
//       phoneExt !== undefined &&
//       phoneExt != null &&
//       phoneExt.length > 0
//     ) {
//       phone += " Ext. " + phoneExt;
//     }
//     return phone;
//   }
//   return "";
// }

function getPhoneNumber(phone: any, phoneExt: any) {
  if (
    phone != null &&
    phoneExt !== undefined &&
    phoneExt != null &&
    phoneExt.length > 0
  ) {
    phone += " Ext. " + phoneExt;
  }
  return phone;
}

function getCallNumber(selectedDoctorSchedule: any) {
  if (selectedDoctorSchedule) {
    return selectedDoctorSchedule["numCalls"] + 1;
  }
  return -1;
}

function nextWeekDay(timeSlot: moment.Moment) {
  let newTimeSlot = timeSlot;
  if (newTimeSlot.weekday() === FRIDAY) {
    newTimeSlot = newTimeSlot.add(3, "days");
  } else if (newTimeSlot.weekday() === SATURDAY) {
    newTimeSlot = newTimeSlot.add(2, "days");
  } else {
    newTimeSlot = newTimeSlot.add(1, "day");
  }
  return newTimeSlot;
}

function incrementTimeSlot(timeSlot: moment.Moment) {
  let newTimeSlot = timeSlot;

  // Sanity check - should not run
  if (newTimeSlot.weekday() === SATURDAY) {
    newTimeSlot = newTimeSlot.add(2, "days");
  } else if (newTimeSlot.weekday() === SUNDAY) {
    newTimeSlot = newTimeSlot.add(1, "day");
  }

  if (newTimeSlot.hours() >= END_HOUR) {
    // next day?
    newTimeSlot = nextWeekDay(newTimeSlot);
    newTimeSlot.set({
      hour: START_HOUR,
    });
  } else if (newTimeSlot.hours() < START_HOUR) {
    newTimeSlot.set({
      hour: START_HOUR,
    });
  } else {
    newTimeSlot = newTimeSlot.add(1, "hour");
    if (newTimeSlot.hours() === LUNCH_HOUR) {
      newTimeSlot = newTimeSlot.add(1, "hour");
    }
  }
  return newTimeSlot;
}

function checkTimeSlots(
  startSlot: moment.Moment,
  endSlot: moment.Moment,
  eventsData: Map<number, any>
): any | null {
  let tryTimeSlot = startSlot;

  let newFreeTimeSlot = null;
  let kickDoctorDetails = null;
  while (tryTimeSlot.hours() < endSlot.hours()) {
    // dont try lunch
    if (tryTimeSlot.hours() === LUNCH_HOUR) {
      tryTimeSlot.set({
        hour: tryTimeSlot.hours() + 1,
      });
      if (tryTimeSlot.hours() >= endSlot.hours()) {
        break;
      }
      continue;
    }

    if (eventsData.has(tryTimeSlot.toDate().getTime())) {
      let eventDataPhoneDoctors = eventsData.get(
        tryTimeSlot.toDate().getTime()
      );
      let numDocsSlot = 0;
      eventDataPhoneDoctors.forEach((docGroup: any, index: number) => {
        numDocsSlot += docGroup.length;
      });

      if (numDocsSlot < NUM_PER_HOUR) {
        newFreeTimeSlot = tryTimeSlot;
        break;
      } else {
        // Kick somebody out
        // Need to get scheduleData
        for (const [index, docGroup] of eventDataPhoneDoctors.entries()) {
          // Can only handle kicking individuals out
          if (
            docGroup.length === 1 &&
            !docGroup[0]["hasSpecialAvailability"] &&
            !docGroup[0]["isComplete"]
          ) {
            kickDoctorDetails = {
              docData: docGroup[0],
            };
            break;
          }
        }

        if (kickDoctorDetails != null) {
          newFreeTimeSlot = tryTimeSlot;
          break;
        }
      }
    } else {
      newFreeTimeSlot = tryTimeSlot;
      break;
    }

    tryTimeSlot.set({
      hour: tryTimeSlot.hours() + 1,
    });
  }

  if (newFreeTimeSlot != null) {
    return {
      newDate: newFreeTimeSlot.toDate(),
      kickDoctorDetails: kickDoctorDetails,
    };
  } else {
    return null;
  }
}

function getNextSpecialTimeSlot(
  startDate: Date,
  availability: any,
  eventsData: Map<number, any>
): any | null {
  let newFreeTimeSlotDetails = null;

  let startDateObj = nextWeekDay(moment(startDate)).set({
    hour: START_HOUR,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  let startWeekday = startDateObj.weekday();

  // If week full?
  let weekNum = 0;
  while (newFreeTimeSlotDetails == null) {
    let weekDayObj = startDateObj.clone().add(weekNum, "weeks");

    for (let i = 0; i < WeekDays.length; i++) {
      let currWeekIdx = (startWeekday + i) % WeekDays.length;
      let currWeekDay = WeekDays[currWeekIdx];
      let currDateObj = moment(weekDayObj.clone()).add(i, "days");
      if (currDateObj.day() === SATURDAY || currDateObj.day() === SUNDAY) {
        continue;
      }
      let sortedAvail = _.sortBy(availability[currWeekDay], "slotId");

      for (let j = 0; j < sortedAvail.length; j++) {
        let tryTimeSlot = sortedAvail[j];
        // Check this time slot
        let startTime = moment(tryTimeSlot["startTime"], ["HH:mm"]);
        let newStartHours = startTime.hours();
        if (newStartHours < START_HOUR) {
          newStartHours = START_HOUR;
        }
        if (newStartHours > END_HOUR) {
          newStartHours = END_HOUR;
        }
        let startSlot = currDateObj.clone().set({
          hours: newStartHours,
        });
        let endTime = moment(tryTimeSlot["endTime"], ["HH:mm"]);
        let newEndHours = endTime.hours();
        if (newEndHours < START_HOUR) {
          newEndHours = START_HOUR;
        }
        if (newEndHours > END_HOUR) {
          newEndHours = END_HOUR;
        }
        let endSlot = currDateObj.clone().set({
          hours: newEndHours,
        });

        let freeTimeSlotDetails = checkTimeSlots(
          startSlot,
          endSlot,
          eventsData
        );
        if (freeTimeSlotDetails != null) {
          newFreeTimeSlotDetails = freeTimeSlotDetails;
          break;
        }
      }

      if (newFreeTimeSlotDetails != null) {
        break;
      }
    }

    weekNum += 1;
  }

  return newFreeTimeSlotDetails;
}

// Does not account for holidays
function getNextFreeTimeSlot(
  startDate: Date,
  eventsData: Map<number, any>
): Date {
  let newFreeTimeSlot = null;

  let tryTimeSlot = nextWeekDay(moment(startDate)).set({
    hour: START_HOUR,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  while (eventsData.has(tryTimeSlot.toDate().getTime())) {
    let eventDataPhoneDoctors = eventsData.get(tryTimeSlot.toDate().getTime());
    let numDocsSlot = 0;
    eventDataPhoneDoctors.forEach((docGroup: any, index: number) => {
      numDocsSlot += docGroup.length;
    });

    if (numDocsSlot < NUM_PER_HOUR) {
      break;
    }

    tryTimeSlot = incrementTimeSlot(tryTimeSlot);
  }
  newFreeTimeSlot = tryTimeSlot.toDate();

  return newFreeTimeSlot;
}

const MonScheduleDate = moment().set({
  year: 2010,
  month: 2,
  date: 28,
  day: 0,
  hour: 9,
  minute: 0,
  second: 0,
  millisecond: 0,
});

const checkToReview = (version1: any, version2: any) => {
  if (version1 == null && version2 == null) {
    return false;
  } else if (version1 != null && version2 != null) {
    return version1.trim() !== version2.trim();
  } else {
    return true;
  }
};

const getStrippedValue = (value: any) => {
  if (value == null) {
    return null;
  } else {
    return value.trim();
  }
};

export {
  getNextFreeTimeSlot,
  MonScheduleDate,
  WeekDays,
  getNextSpecialTimeSlot,
  getPhoneNumber,
  getCallNumber,
  checkToReview,
  getStrippedValue,
};
