import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { WaitTimeMsgOption } from "../interfaces";
import Select, { SingleValue, ActionMeta } from "react-select";
import { WaitTimeMsgOptions } from "../config";

import "../styles/WaitTimeMsgForm.css";

interface WaitTimeMsgFormProps {
  label?: string;
  waitTimeMsgValue: any;
  setWaitTimeMsgValue: any;
  disable: any;
  selectedGroupData?: any;
  clearMsgs: any;
}

function WaitTimeMsgForm(props: WaitTimeMsgFormProps) {
  const onChange = (
    waitTimeMsgValue: SingleValue<WaitTimeMsgOption>,
    actionMeta: ActionMeta<WaitTimeMsgOption>
  ) => {
    props.clearMsgs();
    if (waitTimeMsgValue) {
      props.setWaitTimeMsgValue(waitTimeMsgValue);
    } else {
      props.setWaitTimeMsgValue(undefined);
    }
  };

  return (
    <Col>
      <Form.Label htmlFor="meta" className="formLabel">
        {props.label ? props.label : "Wait Time Msg"}
      </Form.Label>
      <InputGroup className="mb-3">
        <Select<WaitTimeMsgOption>
          options={WaitTimeMsgOptions}
          value={props.waitTimeMsgValue}
          className="waitTimeMsg"
          isDisabled={props.disable()}
          isClearable={true}
          onChange={onChange}
        />
      </InputGroup>
    </Col>
  );
}

export default WaitTimeMsgForm;
