import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "../styles/Login.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signInWithEmailAndPassword } from "../firebase.config";

function Login() {
  let navigate = useNavigate();
  let params = useParams();
  const { state } = useLocation();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [user, loading, error] = useAuthState(auth);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMsg("");
    setEmail(event.currentTarget.value);
  };

  const changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMsg("");
    setPassword(event.currentTarget.value);
  };

  const performSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.log(err);
      setErrorMsg("Error signing in. Try again");
    }
  };

  const signIn = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (email.length === 0) {
      setErrorMsg("Email must not be empty!");
    } else if (password.length === 0) {
      setErrorMsg("Password must not be empty!");
    } else {
      performSignIn();
    }
  };

  return (
    <div className="loginBody" id="loginContainer">
      <div className={"loginContainer"}>
        <div className="loginform-container sign-in-container">
          <form className="loginForm" action="#">
            <h1 className="loginHeader">Sign in</h1>
            <input
              className="loginInput"
              placeholder="Email"
              type="email"
              value={email}
              onChange={changeEmail}
            />
            <input
              className="loginInput"
              placeholder="Password"
              type={"password"}
              value={password}
              onChange={changePassword}
            />
            {errorMsg.length > 0 && <p>{errorMsg}</p>}
            <button className="loginBtn" onClick={signIn}>
              Sign In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
