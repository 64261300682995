import React, { useState, useEffect } from "react";
import { Spinner, Badge } from "react-bootstrap";
import {
  Calendar,
  Views,
  momentLocalizer,
  stringOrDate,
} from "react-big-calendar";

import moment from "moment";
import {
  TEvent,
  ProcedureOption,
  ProcedureSpecialityOption,
  SearchOption,
  SpecialityOption,
  CityOption,
  SearchOptionDoctor,
  CreateSearchOption,
} from "./interfaces";

import {
  selectAllDoctors,
  selectAllDoctorsMap,
  fetchDoctors,
} from "./app/doctorsSlice";
import {
  selectAllAdmin,
  selectAllAdminMap,
  fetchAdmin,
  fetchIndiviualAdmin,
} from "./app/adminSlice";
import {
  selectAllSchedules,
  selectAllSchedulesMap,
  fetchSchedules,
  fetchIndiviualSchedule,
} from "./app/schedulesSlice";
import {
  selectAllGroupInfo,
  fetchGroupInfo,
  selectAllGroupInfoMap,
} from "./app/groupInfoSlice";
import {
  selectAllConfigSpecialties,
  selectAllConfigProcedures,
  fetchConfig,
  selectAllConfigCities,
} from "./app/configSlice";
import {
  selectAllCentralServices,
  fetchCentralServices,
  selectAllCentralServicesMap,
} from "./app/centralServicesSlice";
import {
  selectAllImaging,
  fetchImaging,
  selectAllImagingMap,
} from "./app/imagingSlice";

import { EXTRA_CITIES, RegionToRegionKey } from "./constants";

import AllDoctorNames from "./data/all-doctor-names.json";

import GroupModal from "./components/GroupModal";
import EditConfigModal from "./components/EditConfigModal";
import CreateModal from "./components/CreateModal";
import ToolBar from "./components/ToolBar";

import { useAppDispatch, useAppSelector } from "./app/store";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./ADashboard.css";

// Setup the localizer by providing the moment Object
const localizer = momentLocalizer(moment);

interface ADashboardProps {
  mode: string;
  dataType: number;
}

function ADashboard(props: ADashboardProps) {
  const now = new Date();
  const dispatch = useAppDispatch();

  // Raw data from DB
  const doctors = useAppSelector(selectAllDoctors);
  const doctorsMap = useAppSelector(selectAllDoctorsMap);
  const doctorsStatus = useAppSelector((state: any) => state.doctors.status);

  const admins = useAppSelector(selectAllAdmin);
  const adminsMap = useAppSelector(selectAllAdminMap);
  const adminsStatus = useAppSelector((state: any) => state.admins.status);

  const schedules = useAppSelector(selectAllSchedules);
  const schedulesMap = useAppSelector(selectAllSchedulesMap);
  const schedulesStatus = useAppSelector(
    (state: any) => state.schedules.status
  );

  const groupInfo = useAppSelector(selectAllGroupInfo);
  const groupInfoMap = useAppSelector(selectAllGroupInfoMap);
  const groupInfoStatus = useAppSelector(
    (state: any) => state.groupInfo.status
  );

  const configSpecialties = useAppSelector(selectAllConfigSpecialties);
  const configProcedures = useAppSelector(selectAllConfigProcedures);
  const configCities = useAppSelector(selectAllConfigCities);
  const configStatus = useAppSelector((state: any) => state.config.status);

  const centralServices = useAppSelector(selectAllCentralServices);
  const centralServicesMap = useAppSelector(selectAllCentralServicesMap);
  const centralServicesStatus = useAppSelector(
    (state: any) => state.centralServices.status
  );

  const imaging = useAppSelector(selectAllImaging);
  const imagingMap = useAppSelector(selectAllImagingMap);
  const imagingStatus = useAppSelector((state: any) => state.imaging.status);

  // Derived data
  const [procedureList, setProcedureList] = useState<
    Map<string, ProcedureSpecialityOption[]>
  >(new Map());
  const [cityToCityData, setCityToCityData] = useState<Map<string, any>>(
    new Map()
  );

  // Calendar state
  const [events, setEvents] = useState<TEvent[]>([]);
  const [eventsMap, setEventsMap] = useState<Map<number, any>>(new Map());

  // Local state
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingDBData, setLoadingDBData] = useState<boolean>(true);
  const [loadingSearchData, setLoadingSearchData] = useState<boolean>(false);
  const [adminMode, setAdminMode] = useState<boolean>(() => {
    return props.mode === "admin";
  });

  const [allSearchData, setAllSearchData] = useState<SearchOption[]>([]);
  const [allEmailData, setAllEmailData] = useState<any>(null);
  const [allFlaggedData, setAllFlaggedData] = useState<any>(null);
  const [allAdditionalInfoReviewData, setAllAdditionalInfoReviewData] =
    useState<any>(null);
  const [allImpAdditionalInfoReviewData, setAllImpAdditionalInfoReviewData] =
    useState<any>(null);
  const [allGroupInfoReviewData, setAllGroupInfoReviewData] =
    useState<any>(null);
  const [allRescrapeData, setAllRescrapeData] = useState<any>(null);
  const [allHideDoctorData, setAllHideDoctorData] = useState<any>(null);
  const [allMetaSpecialtiesData, setAllMetaSpecialtiesData] =
    useState<any>(null);
  const [allCentralSearchData, setAllCentralSearchData] = useState<
    CreateSearchOption[]
  >([]);
  const [allImagingSearchData, setAllImagingSearchData] = useState<
    CreateSearchOption[]
  >([]);

  const [allProcedureOptions, setAllProcedureOptions] = useState<
    ProcedureOption[]
  >([]);
  const [allAHProcedureOptions, setAllAHProcedureOptions] = useState<
    ProcedureOption[]
  >([]);
  const [allSpecialityOptions, setAllSpecialityOptions] = useState<
    SpecialityOption[]
  >([]);
  const [allCityOptions, setAllCityOptions] = useState<CityOption[]>([]);

  // Modals
  const [showGroupModal, setShowGroupModal] = useState<boolean>(false);
  const [groupModalHeader, setGroupModalHeader] = useState<string>("");

  const [showEditConfigModal, setShowEditConfigModal] =
    useState<boolean>(false);
  const [selectedConfigProcedure, setSelectedConfigProcedure] =
    useState<any>(null);
  const [selectedConfigSpeciality, setSelectedConfigSpeciality] =
    useState<any>(null);

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [createDataType, setCreateDataType] =
    useState<string>("central-services");

  const [groupDataType, setGroupDataType] = useState<string>("");
  const [allGroupData, setAllGroupData] = useState<any>(null);
  const [selectedGroupData, setSelectedGroupData] = useState<any>(null);
  const [selectedGroupDataType, setSelectedGroupDataType] =
    useState<string>("");

  // Shared state
  const [isSavingGroupData, setIsSavingGroupData] = useState<boolean>(false);
  const [isSavingConfigData, setIsSavingConfigData] = useState<boolean>(false);

  const [selectedSearchData, setSelectedSearchData] =
    useState<SearchOption | null>(null);

  // LOAD INITIAL DATA
  useEffect(() => {
    if (doctorsStatus === "idle") {
      dispatch(fetchDoctors(props.dataType));
    }
  }, [doctorsStatus, dispatch]);

  useEffect(() => {
    if (adminsStatus === "idle") {
      dispatch(fetchAdmin(props.dataType));
    }
  }, [adminsStatus, dispatch]);

  useEffect(() => {
    if (schedulesStatus === "idle") {
      dispatch(fetchSchedules(props.dataType));
    }
  }, [schedulesStatus, dispatch]);

  useEffect(() => {
    if (groupInfoStatus === "idle") {
      dispatch(fetchGroupInfo());
    }
  }, [groupInfoStatus, dispatch]);

  useEffect(() => {
    if (configStatus === "idle") {
      dispatch(fetchConfig());
    }
  }, [configStatus, dispatch]);

  useEffect(() => {
    if (centralServicesStatus === "idle") {
      dispatch(fetchCentralServices());
    }
  }, [centralServicesStatus, dispatch]);

  useEffect(() => {
    if (imagingStatus === "idle") {
      dispatch(fetchImaging());
    }
  }, [imagingStatus, dispatch]);

  useEffect(() => {
    if (
      doctorsStatus === "loaded" &&
      adminsStatus === "loaded" &&
      schedulesStatus === "loaded" &&
      groupInfoStatus === "loaded" &&
      configStatus === "loaded" &&
      centralServicesStatus === "loaded" &&
      imagingStatus === "loaded"
    ) {
      setLoadingDBData(false);
    }
  }, [
    doctorsStatus,
    adminsStatus,
    schedulesStatus,
    groupInfoStatus,
    configStatus,
    centralServicesStatus,
    imagingStatus,
  ]);

  const calculateSearchShowDoctor = (cpsoNum: any, loadNull: boolean) => {
    let enhancedSearchData: any = null;
    let docData: any = null;
    if (cpsoNum.startsWith("service-")) {
      docData = centralServicesMap.get(cpsoNum.replace("service-", ""));
    } else {
      docData = doctorsMap.get(cpsoNum);
    }
    let hasAllSchedules = true;
    let adminData = null;
    if (docData) {
      adminData = adminsMap.get(docData["cpsoNum"]);
      adminData["scheduleHistory"].forEach((schedule: any) => {
        if (!schedulesMap.has(schedule.id)) {
          hasAllSchedules = false;
        }
      });
    } else {
      hasAllSchedules = false;
    }

    if (hasAllSchedules) {
      let scheduleData = null;
      let scheduleId = null;

      if (adminData["currScheduleRef"] != null) {
        scheduleId = adminData["currScheduleRef"].id;
        scheduleData = schedulesMap.get(scheduleId);
      }

      let isComplete = false;

      enhancedSearchData = {
        fullName: docData["fullName"],
        cpsoNum: docData["cpsoNum"],
        scheduleId: scheduleId,
        isComplete: isComplete,
      };

      if (scheduleData != null) {
        enhancedSearchData = enhanceDocData(
          docData,
          adminData,
          scheduleData,
          null
        );
      }

      if (adminMode) {
        isComplete = false;
      } else {
        if (scheduleData != null && scheduleData !== undefined) {
          if (scheduleData["isCurrent"]) {
            if (
              scheduleData["waitTimeRecorded"] != null ||
              (scheduleData["isFlagged"] && !scheduleData["flagResolved"])
            ) {
              isComplete = true;
            }
          } else {
            isComplete = true;
          }
        } else {
          isComplete = true;
        }
      }

      enhancedSearchData["isComplete"] = isComplete;
    } else {
      if (loadNull) {
        return null;
      }
    }

    return enhancedSearchData;
  };

  const deriveConfigOptions = () => {
    console.log("Deriving config options");
    let newProcedureOptions: ProcedureOption[] = [];
    let newAHProcedureOptions: ProcedureOption[] = [];
    configProcedures.forEach((procedureData: any) => {
      if (procedureData["type"] === "normal") {
        newProcedureOptions.push({
          value: procedureData["id"],
          label: procedureData["procedure"],
        });
      } else if (procedureData["type"] === "allied") {
        newAHProcedureOptions.push({
          value: procedureData["id"],
          label: procedureData["procedure"],
        });
      }
    });
    setAllProcedureOptions(newProcedureOptions);
    setAllAHProcedureOptions(newAHProcedureOptions);

    let newSpecialityOptions: SpecialityOption[] = [];
    configSpecialties.forEach((specialityData: any) => {
      newSpecialityOptions.push({
        value: specialityData["id"],
        label: specialityData["speciality"],
      });
    });
    setAllSpecialityOptions(newSpecialityOptions);

    if (selectedConfigSpeciality !== null) {
      let newConfigSpeciality = configSpecialties.find(
        (configSpeciality: any) =>
          configSpeciality["id"] === selectedConfigSpeciality["id"]
      );
      if (newConfigSpeciality !== undefined) {
        setSelectedConfigSpeciality(newConfigSpeciality);
      }
    }

    if (selectedConfigProcedure !== null) {
      let newConfigProcedure = configProcedures.find(
        (configProcedure: any) =>
          configProcedure["id"] === selectedConfigProcedure["id"]
      );
      if (newConfigProcedure !== undefined) {
        setSelectedConfigProcedure(newConfigProcedure);
      }
    }
    console.log("Derived config options");
  };

  const deriveProcedureList = () => {
    console.log("Deriving procedure list");
    let newProcedureList: Map<string, ProcedureSpecialityOption[]> = new Map();
    configSpecialties.forEach((specialityData: any) => {
      let procedures: ProcedureSpecialityOption[] = [];
      specialityData["procedures"].forEach((procedure: any) => {
        let procedureData = configProcedures.find(
          (cProcedure: any) => cProcedure.id === procedure.id
        );
        if (procedureData !== undefined) {
          procedures.push({
            value: procedureData["procedure"],
            label: procedureData["procedure"],
          });
        }
      });
      newProcedureList.set(
        specialityData["speciality"].toLowerCase(),
        procedures
      );
      specialityData["aliases"].forEach((alias: any) => {
        newProcedureList.set(alias.toLowerCase(), procedures);
      });
    });
    console.log("Derived procedure list");
    // console.log(newProcedureList);
    setProcedureList(newProcedureList);
  };

  const deriveCityData = () => {
    console.log("Deriving city data");
    let newCityData: Map<string, any> = new Map();
    let newCityOptions: CityOption[] = [];

    configCities.forEach((cityData: any) => {
      cityData["searchCities"].forEach((searchCity: any) => {
        newCityData.set(searchCity, cityData);

        newCityOptions.push({
          label: searchCity,
          value: searchCity,
        });
      });
    });

    EXTRA_CITIES.forEach((city: string) => {
      newCityOptions.push({
        label: city,
        value: city,
      });
    });
    newCityOptions.sort((a: any, b: any) =>
      a["label"].localeCompare(b["label"])
    );

    console.log("Derived city data");
    // console.log(newCityData);
    setCityToCityData(newCityData);
    setAllCityOptions(newCityOptions);
  };

  const enhanceDocData = (
    oDocData: any,
    adminData: any,
    scheduleData: any,
    eventsKey: any
  ) => {
    let useEventsKey = eventsKey;
    let currDateTime = null;
    if (scheduleData["date"] != null) {
      currDateTime = scheduleData["date"].getTime();
    }
    if (useEventsKey == null) {
      useEventsKey = currDateTime;
    }
    let useEventsKeyDate = new Date(useEventsKey);

    let docData = JSON.parse(JSON.stringify(oDocData));
    let isCurrent = scheduleData["isCurrent"];
    let flagStatus = scheduleData["isFlagged"];
    if (
      scheduleData["flagResolved"] &&
      scheduleData["waitTimeRecorded"] != null
    ) {
      flagStatus = true;
    }
    let faxStatus = scheduleData["sendFax"] || scheduleData["faxSent"];
    // TODO: Remove
    // if (
    //   faxStatus &&
    //   scheduleData["date"] != null &&
    //   scheduleData["date"].getMonth() === 7 &&
    //   (scheduleData["date"].getDate() === 29 ||
    //     scheduleData["date"].getDate() === 30)
    // ) {
    //   if (!scheduleData["sendFax"]) {
    //     faxStatus = false;
    //   }
    // }
    let emailStatus = scheduleData["emailSent"];

    let hasSpecialAvailability = adminData["hasSpecialAvailability"];
    let isActive = adminData["active"];
    let isRetired = adminData["retired"];
    let markedToScrape = adminData["rescrape"];
    let minRescheduleDays = adminData["minRescheduleDays"];
    let scheduleHistoryIdx = -1;
    let isException = false;

    if (useEventsKeyDate != null) {
      let isSaturday = useEventsKeyDate.getDay() === 0;
      isException =
        isSaturday &&
        isActive &&
        !markedToScrape &&
        !scheduleData["sendFax"] &&
        docData["waitTimeStart"] == null &&
        (docData["waitTimeMsg"] == null ||
          docData["waitTimeMsg"] === "Pending Update") &&
        !scheduleData["isFlagged"];
    }
    adminData["scheduleHistory"].forEach(
      (scheduleHistoryRef: any, idx: any) => {
        if (scheduleHistoryRef.id === scheduleData["scheduleId"]) {
          scheduleHistoryIdx = idx;
        }
      }
    );
    let isUpdateCall = scheduleHistoryIdx > 0;
    let lastCallDate: any = null;
    let nextCallDate: any = null;
    if (adminData["currScheduleRef"] != null) {
      nextCallDate = schedulesMap.get(adminData["currScheduleRef"].id);
      if (nextCallDate !== undefined) {
        nextCallDate = nextCallDate["date"];
      }
    }

    if (scheduleHistoryIdx > 0) {
      lastCallDate = schedulesMap.get(
        adminData["scheduleHistory"][scheduleHistoryIdx - 1].id
      );

      if (lastCallDate !== undefined) {
        if (lastCallDate["waitTimeRecorded"] != null) {
          lastCallDate = lastCallDate["waitTimeRecorded"];
        } else {
          lastCallDate = lastCallDate["date"];
        }
      }
    }

    if (
      !isException &&
      (useEventsKey !== currDateTime ||
        flagStatus ||
        !isCurrent ||
        faxStatus ||
        !isActive ||
        isRetired ||
        emailStatus ||
        markedToScrape)
    ) {
      docData["isComplete"] = true;
    } else {
      docData["isComplete"] = false;
    }

    let regionKey: any = "";
    let waitTimeKey: any = 0;
    if (docData["location"] !== undefined && docData["location"] != null) {
      let docRegion: any = cityToCityData.get(
        docData["location"]["searchCity"]
      );
      if (docRegion !== undefined) {
        regionKey = RegionToRegionKey.get(docRegion["region"]);
        if (regionKey === undefined) {
          regionKey = "";
        }
      }
    }
    if(docData["waitTimeStart"] != null) {
      waitTimeKey = docData["waitTimeStart"];
      if(docData["waitTimeUnit"] === "weeks"){
        waitTimeKey = waitTimeKey * 7;
      } else if(docData["waitTimeUnit"] === "months"){
        waitTimeKey = waitTimeKey * 30;
      }
    } else {
      waitTimeKey = 100000;
    }
    if(docData["cpsoNum"] === "107648"){
      console.log("docData");
      console.log(docData);
      console.log(waitTimeKey);
    }
    docData["regionKey"] = regionKey;
    docData["waitTimeKey"] = waitTimeKey;

    docData["isUpdateCall"] = isUpdateCall;
    docData["lastCallDate"] = lastCallDate;
    docData["nextCallDate"] = nextCallDate;
    docData["rescheduled"] = useEventsKey !== currDateTime;
    docData["flagStatus"] = flagStatus;
    docData["faxStatus"] = faxStatus;
    docData["emailStatus"] = emailStatus;
    docData["minRescheduleDays"] = minRescheduleDays;

    if (docData["rescheduled"]) {
      docData["flagStatus"] = false;
      docData["faxStatus"] = false;
      docData["emailStatus"] = false;
    }
    docData["isCurrent"] = isCurrent;

    docData["scheduleId"] = scheduleData["scheduleId"];
    docData["scheduleDate"] = useEventsKeyDate;
    docData["hasSpecialAvailability"] = hasSpecialAvailability;

    if (!isCurrent) {
      docData["waitTimeStart"] = scheduleData["waitTimeStart"];
      docData["waitTimeEnd"] = scheduleData["waitTimeEnd"];
      docData["waitTimeUnit"] = scheduleData["waitTimeUnit"];
      docData["waitTimeMsg"] = scheduleData["waitTimeMsg"];
    }

    // docData["isService"] = docData["cpsoNum"].includes("service-");

    return docData;
  };

  useEffect(() => {
    if (
      doctorsStatus === "updated" &&
      adminsStatus === "updated" &&
      schedulesStatus === "updated" &&
      centralServicesStatus === "updated"
    ) {
      computeDerivedData();
      setIsSavingGroupData(false);
    }
  }, [doctorsStatus, adminsStatus, schedulesStatus, centralServicesStatus]);

  useEffect(() => {
    if (groupInfoStatus === "updated") {
      computeDerivedData();
      setIsSavingGroupData(false);
    }
  }, [groupInfoStatus]);

  useEffect(() => {
    if (configStatus === "updated") {
      deriveProcedureList();
      deriveConfigOptions();
      setIsSavingConfigData(false);
    }
  }, [configStatus]);

  useEffect(() => {
    if (
      centralServicesStatus === "updated" &&
      adminsStatus === "updated" &&
      schedulesStatus === "updated"
    ) {
      computeDerivedData();
      setIsSavingGroupData(false);
    }
  }, [centralServicesStatus, adminsStatus, schedulesStatus]);

  useEffect(() => {
    if (imagingStatus === "updated") {
      computeDerivedData();
      setIsSavingGroupData(false);
    }
  }, [imagingStatus]);

  const deriveOtherData = () => {
    console.log("Deriving email data");
    console.log("Deriving flagged data");
    console.log("Deriving additional info review data");
    console.log("Deriving rescrape doctor data");
    let emailPhoneData: Map<string, any> = new Map();
    let flaggedPhoneData: Map<string, any> = new Map();
    let additionalInfoReviewPhoneData: Map<string, any> = new Map();
    let impAdditionalInfoReviewPhoneData: Map<string, any> = new Map();
    let groupInfoReviewPhoneData: Map<string, any> = new Map();
    let rescrapePhoneData: Map<string, any> = new Map();
    let metaSpecialtiesPhoneData: Map<string, any> = new Map();
    let hideDoctorPhoneData: Map<string, any> = new Map();

    admins.forEach((adminData: any) => {
      // console.log("cpsoNum");
      // console.log(cpsoNum);
      if (adminData["currScheduleRef"] != null) {
        const scheduleId = adminData["currScheduleRef"].id;
        const scheduleData = schedulesMap.get(scheduleId);
        if (scheduleData !== undefined) {
          let oDocData = undefined;
          if (adminData["cpsoNum"].startsWith("service-")) {
            oDocData = centralServicesMap.get(
              adminData["cpsoNum"].replace("service-", "")
            );
          } else {
            oDocData = doctorsMap.get(adminData["cpsoNum"]);
          }

          if (oDocData !== undefined) {
            let emailDocData = enhanceDocData(
              oDocData,
              adminData,
              scheduleData,
              null
            );
            let phoneKey = emailDocData["phone"];
            if (emailDocData["phoneExt"] !== "") {
              phoneKey += "*" + emailDocData["phoneExt"];
            }

            // Email data
            if (
              scheduleData["emailSent"] ||
              (scheduleData["flagMsg"] != null &&
                scheduleData["flagMsg"].includes("@"))
            ) {
              if (emailPhoneData.has(phoneKey)) {
                emailPhoneData.get(phoneKey).push(emailDocData);
              } else {
                emailPhoneData.set(phoneKey, [emailDocData]);
              }
            }

            // Flagged data

            if (
              scheduleData["isFlagged"] &&
              !scheduleData["flagResolved"] &&
              emailDocData["location"] !== null
            ) {
              let flaggedDocData = JSON.parse(JSON.stringify(emailDocData));
              if (adminMode) {
                flaggedDocData["isComplete"] = false;
              }
              if (flaggedPhoneData.has(phoneKey)) {
                flaggedPhoneData.get(phoneKey).push(flaggedDocData);
              } else {
                flaggedPhoneData.set(phoneKey, [flaggedDocData]);
              }
            }

            // Additional info review data
            if (
              emailDocData["waitTimeStart"] != null &&
              adminData["toReview"] &&
              adminData["active"] &&
              !scheduleData["isFlagged"] &&
              emailDocData["location"] !== null
            ) {
              let additionalInfoDocData = JSON.parse(
                JSON.stringify(emailDocData)
              );
              if (adminMode) {
                additionalInfoDocData["isComplete"] = false;
              }
              if (additionalInfoReviewPhoneData.has(phoneKey)) {
                additionalInfoReviewPhoneData
                  .get(phoneKey)
                  .push(additionalInfoDocData);
              } else {
                additionalInfoReviewPhoneData.set(phoneKey, [
                  additionalInfoDocData,
                ]);
              }
            }

            // Imp Additional info review data
            if (
              emailDocData["waitTimeStart"] != null &&
              adminData["impToReview"] &&
              adminData["active"] &&
              !scheduleData["isFlagged"] &&
              emailDocData["location"] !== null
            ) {
              let impAdditionalInfoDocData = JSON.parse(
                JSON.stringify(emailDocData)
              );
              if (adminMode) {
                impAdditionalInfoDocData["isComplete"] = false;
              }
              if (impAdditionalInfoReviewPhoneData.has(phoneKey)) {
                impAdditionalInfoReviewPhoneData
                  .get(phoneKey)
                  .push(impAdditionalInfoDocData);
              } else {
                impAdditionalInfoReviewPhoneData.set(phoneKey, [
                  impAdditionalInfoDocData,
                ]);
              }
            }

            // Rescrape doctor data
            if (adminData["rescrape"]) {
              let rescrapeDocData = JSON.parse(JSON.stringify(emailDocData));
              if (rescrapePhoneData.has(phoneKey)) {
                rescrapePhoneData.get(phoneKey).push(rescrapeDocData);
              } else {
                rescrapePhoneData.set(phoneKey, [rescrapeDocData]);
              }
            }

            // Hide doctor data
            if (emailDocData["hideCard"]) {
              let hideDoctorDocData = JSON.parse(JSON.stringify(emailDocData));
              hideDoctorDocData["isComplete"] = true;
              if (hideDoctorPhoneData.has(phoneKey)) {
                hideDoctorPhoneData.get(phoneKey).push(hideDoctorDocData);
              } else {
                hideDoctorPhoneData.set(phoneKey, [hideDoctorDocData]);
              }
            }

            // Meta specialties doctor data
            if (
              oDocData["metaSpecialties"].length > 0 &&
              emailDocData["location"] !== null
            ) {
              let metaSpecialtiesDocData = JSON.parse(
                JSON.stringify(emailDocData)
              );
              if (adminMode) {
                metaSpecialtiesDocData["isComplete"] = false;
              }
              if (metaSpecialtiesPhoneData.has(phoneKey)) {
                metaSpecialtiesPhoneData
                  .get(phoneKey)
                  .push(metaSpecialtiesDocData);
              } else {
                metaSpecialtiesPhoneData.set(phoneKey, [
                  metaSpecialtiesDocData,
                ]);
              }
            }

            // Group info data
            let groupInfo = groupInfoMap.get(phoneKey);
            if (
              groupInfo !== undefined &&
              groupInfo["toReview"] &&
              emailDocData["location"] !== null
            ) {
              let groupInfoDocData = JSON.parse(JSON.stringify(emailDocData));
              if (adminMode) {
                groupInfoDocData["isComplete"] = false;
              }
              if (groupInfoReviewPhoneData.has(phoneKey)) {
                groupInfoReviewPhoneData.get(phoneKey).push(groupInfoDocData);
              } else {
                groupInfoReviewPhoneData.set(phoneKey, [groupInfoDocData]);
              }
            }
          }
        }
      }
    });

    const newEmailData: any = {
      doctors: emailPhoneData,
    };
    setAllEmailData(newEmailData);

    const newFlaggedData: any = {
      doctors: flaggedPhoneData,
    };
    setAllFlaggedData(newFlaggedData);

    const newAdditionalInfoData: any = {
      doctors: additionalInfoReviewPhoneData,
    };
    setAllAdditionalInfoReviewData(newAdditionalInfoData);

    const newImpAdditionalInfoData: any = {
      doctors: impAdditionalInfoReviewPhoneData,
    };
    setAllImpAdditionalInfoReviewData(newImpAdditionalInfoData);

    const newRescrapeData: any = {
      doctors: rescrapePhoneData,
    };
    setAllRescrapeData(newRescrapeData);

    const newHideDoctorData: any = {
      doctors: hideDoctorPhoneData,
    };
    setAllHideDoctorData(newHideDoctorData);

    const newMetaSpecialtiesData: any = {
      doctors: metaSpecialtiesPhoneData,
    };
    setAllMetaSpecialtiesData(newMetaSpecialtiesData);

    const newGroupInfoReviewData: any = {
      doctors: groupInfoReviewPhoneData,
    };
    setAllGroupInfoReviewData(newGroupInfoReviewData);

    console.log("Email data loaded");
    console.log("Flagged data loaded");
    console.log("Additional info review data loaded");
    console.log("Rescrape data loaded");
    console.log("Review group info data loaded");

    if (allGroupData != null) {
      if (groupDataType === "email") {
        setAllGroupData(newEmailData);
        if (
          selectedGroupData != null &&
          selectedGroupDataType === "email-doctor"
        ) {
          let phoneKey = selectedGroupData["phone"];
          if (selectedGroupData["phoneExt"] !== "") {
            phoneKey += "*" + selectedGroupData["phoneExt"];
          }
          let newSelectedGroupData = newEmailData.doctors.get(phoneKey);
          if (newSelectedGroupData !== undefined) {
            newSelectedGroupData = newSelectedGroupData.find(
              (doc: any) => doc["cpsoNum"] === selectedGroupData["cpsoNum"]
            );
            if (newSelectedGroupData !== undefined) {
              setSelectedGroupData(newSelectedGroupData);
            } else {
              setSelectedGroupData(null);
            }
          } else {
            setSelectedGroupData(null);
          }
        }
      } else if (groupDataType === "flagged") {
        setAllGroupData(newFlaggedData);
        if (
          selectedGroupData != null &&
          selectedGroupDataType === "flagged-doctor"
        ) {
          let phoneKey = selectedGroupData["phone"];
          if (selectedGroupData["phoneExt"] !== "") {
            phoneKey += "*" + selectedGroupData["phoneExt"];
          }
          let newSelectedGroupData = newFlaggedData.doctors.get(phoneKey);
          if (newSelectedGroupData !== undefined) {
            newSelectedGroupData = newSelectedGroupData.find(
              (doc: any) => doc["cpsoNum"] === selectedGroupData["cpsoNum"]
            );
            if (newSelectedGroupData !== undefined) {
              setSelectedGroupData(newSelectedGroupData);
            } else {
              setSelectedGroupData(null);
            }
          } else {
            setSelectedGroupData(null);
          }
        }
      } else if (groupDataType === "additionalinfo") {
        setAllGroupData(newAdditionalInfoData);
        if (
          selectedGroupData != null &&
          selectedGroupDataType === "additionalinfo-doctor"
        ) {
          let phoneKey = selectedGroupData["phone"];
          if (selectedGroupData["phoneExt"] !== "") {
            phoneKey += "*" + selectedGroupData["phoneExt"];
          }
          let newSelectedGroupData =
            newAdditionalInfoData.doctors.get(phoneKey);
          if (newSelectedGroupData !== undefined) {
            newSelectedGroupData = newSelectedGroupData.find(
              (doc: any) => doc["cpsoNum"] === selectedGroupData["cpsoNum"]
            );
            if (newSelectedGroupData !== undefined) {
              setSelectedGroupData(newSelectedGroupData);
            } else {
              setSelectedGroupData(null);
            }
          } else {
            setSelectedGroupData(null);
          }
        }
      } else if (groupDataType === "impadditionalinfo") {
        setAllGroupData(newImpAdditionalInfoData);
        if (
          selectedGroupData != null &&
          selectedGroupDataType === "impadditionalinfo-doctor"
        ) {
          let phoneKey = selectedGroupData["phone"];
          if (selectedGroupData["phoneExt"] !== "") {
            phoneKey += "*" + selectedGroupData["phoneExt"];
          }
          let newSelectedGroupData =
            newImpAdditionalInfoData.doctors.get(phoneKey);
          if (newSelectedGroupData !== undefined) {
            newSelectedGroupData = newSelectedGroupData.find(
              (doc: any) => doc["cpsoNum"] === selectedGroupData["cpsoNum"]
            );
            if (newSelectedGroupData !== undefined) {
              setSelectedGroupData(newSelectedGroupData);
            } else {
              setSelectedGroupData(null);
            }
          } else {
            setSelectedGroupData(null);
          }
        }
      } else if (groupDataType === "rescrape") {
        setAllGroupData(newRescrapeData);
        if (
          selectedGroupData != null &&
          selectedGroupDataType === "rescrape-doctor"
        ) {
          let phoneKey = selectedGroupData["phone"];
          if (selectedGroupData["phoneExt"] !== "") {
            phoneKey += "*" + selectedGroupData["phoneExt"];
          }
          let newSelectedGroupData = newRescrapeData.doctors.get(phoneKey);
          if (newSelectedGroupData !== undefined) {
            newSelectedGroupData = newSelectedGroupData.find(
              (doc: any) => doc["cpsoNum"] === selectedGroupData["cpsoNum"]
            );
            if (newSelectedGroupData !== undefined) {
              setSelectedGroupData(newSelectedGroupData);
            } else {
              setSelectedGroupData(null);
            }
          } else {
            setSelectedGroupData(null);
          }
        }
      } else if (groupDataType === "hidden") {
        setAllGroupData(newHideDoctorData);
        if (
          selectedGroupData != null &&
          selectedGroupDataType === "hidden-doctor"
        ) {
          let phoneKey = selectedGroupData["phone"];
          if (selectedGroupData["phoneExt"] !== "") {
            phoneKey += "*" + selectedGroupData["phoneExt"];
          }
          let newSelectedGroupData = newHideDoctorData.doctors.get(phoneKey);
          if (newSelectedGroupData !== undefined) {
            newSelectedGroupData = newSelectedGroupData.find(
              (doc: any) => doc["cpsoNum"] === selectedGroupData["cpsoNum"]
            );
            if (newSelectedGroupData !== undefined) {
              setSelectedGroupData(newSelectedGroupData);
            } else {
              setSelectedGroupData(null);
            }
          } else {
            setSelectedGroupData(null);
          }
        }
      } else if (groupDataType === "meta-specialties") {
        setAllGroupData(newMetaSpecialtiesData);
        if (
          selectedGroupData != null &&
          selectedGroupDataType === "meta-specialties-doctor"
        ) {
          let phoneKey = selectedGroupData["phone"];
          if (selectedGroupData["phoneExt"] !== "") {
            phoneKey += "*" + selectedGroupData["phoneExt"];
          }
          let newSelectedGroupData =
            newMetaSpecialtiesData.doctors.get(phoneKey);
          if (newSelectedGroupData !== undefined) {
            newSelectedGroupData = newSelectedGroupData.find(
              (doc: any) => doc["cpsoNum"] === selectedGroupData["cpsoNum"]
            );
            if (newSelectedGroupData !== undefined) {
              setSelectedGroupData(newSelectedGroupData);
            } else {
              setSelectedGroupData(null);
            }
          } else {
            setSelectedGroupData(null);
          }
        }
      } else if (groupDataType === "reviewgroupinfo") {
        setAllGroupData(newGroupInfoReviewData);

        if (
          selectedGroupData != null &&
          selectedGroupDataType === "reviewgroupinfo-doctor"
        ) {
          let phoneKey = selectedGroupData["phone"];
          if (selectedGroupData["phoneExt"] !== "") {
            phoneKey += "*" + selectedGroupData["phoneExt"];
          }

          let newSelectedGroupData =
            newGroupInfoReviewData.doctors.get(phoneKey);

          if (newSelectedGroupData !== undefined) {
            newSelectedGroupData = newSelectedGroupData.find(
              (doc: any) => doc["cpsoNum"] === selectedGroupData["cpsoNum"]
            );
            if (newSelectedGroupData !== undefined) {
              setSelectedGroupData(newSelectedGroupData);
            } else {
              setSelectedGroupData(null);
            }
          } else {
            setSelectedGroupData(null);
          }
        }
      }
    }
  };

  const deriveCentralSearchData = () => {
    console.log("Deriving central search data");
    var newCentralSearchData: CreateSearchOption[] = [];
    centralServices.forEach((centralService: any) => {
      let adminData = adminsMap.get(centralService["cpsoNum"]);
      if (adminData !== undefined) {
        // let scheduleData = null;
        let scheduleId = null;
        if (adminData["currScheduleRef"] != null) {
          scheduleId = adminData["currScheduleRef"].id;
          // scheduleData = schedulesMap.get(scheduleId);
        }
        newCentralSearchData.push({
          label: centralService["fullName"],
          fullName: centralService["fullName"],
          value: centralService["serviceId"],
          cpsoNum: centralService["cpsoNum"],
          scheduleId: scheduleId,
          isComplete: false,
        });
      }
    });
    console.log("Central search data loaded");
    setAllCentralSearchData(newCentralSearchData);

    if (
      selectedGroupData != null &&
      selectedGroupDataType === "central-service"
    ) {
      let newSelectedGroupData = newCentralSearchData.find(
        (doc: any) => doc["value"] === selectedGroupData["value"]
      );
      console.log(newSelectedGroupData);
      if (newSelectedGroupData !== undefined) {
        setSelectedGroupData(newSelectedGroupData);
      } else {
        setSelectedGroupData(null);
      }
    }
  };

  const deriveImagingSearchData = () => {
    console.log("Deriving imaging search data");
    var newImagingSearchData: CreateSearchOption[] = [];
    imaging.forEach((imagingService: any) => {
      newImagingSearchData.push({
        label: imagingService["fullName"],
        fullName: imagingService["fullName"],
        value: imagingService["serviceId"],
        cpsoNum: imagingService["cpsoNum"],
        scheduleId: null,
        isComplete: false,
      });
    });
    console.log("Imaging search data loaded");
    setAllImagingSearchData(newImagingSearchData);

    if (
      selectedGroupData != null &&
      selectedGroupDataType === "imaging-service"
    ) {
      let newSelectedGroupData = newImagingSearchData.find(
        (doc: any) => doc["value"] === selectedGroupData["value"]
      );
      console.log(newSelectedGroupData);
      if (newSelectedGroupData !== undefined) {
        setSelectedGroupData(newSelectedGroupData);
      } else {
        setSelectedGroupData(null);
      }
    }
  };

  const deriveSearchData = () => {
    console.log("Deriving search data");
    var newSearchData: SearchOption[] = [];

    for (var doctorName in AllDoctorNames) {
      let cpsoNums: any =
        AllDoctorNames[doctorName as keyof typeof AllDoctorNames];

      let newSearchDoctors: any = [];
      cpsoNums.forEach((doctorId: string) => {
        let newShowDoctor = calculateSearchShowDoctor(doctorId, false);
        if (newShowDoctor != null) {
          newSearchDoctors.push(newShowDoctor);
        }
      });

      newSearchData.push({
        label: doctorName,
        value: doctorName,
        fullName: doctorName,
        doctorIds: cpsoNums,
        doctors: newSearchDoctors,
      });
    }

    centralServices.forEach((centralService: any) => {
      let cpsoNums = [centralService["cpsoNum"]];

      let newSearchDoctors: any = [];
      cpsoNums.forEach((doctorId: string) => {
        let newShowDoctor = calculateSearchShowDoctor(doctorId, false);
        if (newShowDoctor != null) {
          newSearchDoctors.push(newShowDoctor);
        }
      });

      newSearchData.push({
        label: centralService["fullName"],
        value: centralService["fullName"],
        fullName: centralService["fullName"],
        doctorIds: cpsoNums,
        doctors: newSearchDoctors,
      });
    });

    // var doctorsByName: Map<string, any> = new Map();
    // doctors.forEach((docData: any) => {
    //   let newSameNameDocs = doctorsByName.get(docData["fullName"]);
    //   if (newSameNameDocs !== undefined) {
    //     newSameNameDocs.push(docData);
    //   } else {
    //     newSameNameDocs = [docData];
    //   }
    //   doctorsByName.set(docData["fullName"], newSameNameDocs);
    // });

    // doctorsByName.forEach((sameNameDocData, docName) => {
    //   let sameNameDoctors: SearchOptionDoctor[] = [];
    //   sameNameDocData.forEach((docData: any) => {
    //     let adminData = adminsMap.get(docData["cpsoNum"]);
    //     let scheduleData = null;
    //     let scheduleId = null;
    //     if (adminData["currScheduleRef"] != null) {
    //       scheduleId = adminData["currScheduleRef"].id;
    //       scheduleData = schedulesMap.get(scheduleId);
    //     }

    //     let isComplete = false;

    //     if (adminMode) {
    //       isComplete = false;
    //     } else {
    //       if (scheduleData != null && scheduleData !== undefined) {
    //         if (
    //           (scheduleData["faxSent"] || scheduleData["emailSent"]) &&
    //           scheduleData["isCurrent"]
    //         ) {
    //           if (
    //             scheduleData["waitTimeRecorded"] != null ||
    //             (scheduleData["isFlagged"] && !scheduleData["flagResolved"])
    //           ) {
    //             isComplete = true;
    //           }
    //         } else {
    //           isComplete = true;
    //         }
    //       } else {
    //         isComplete = true;
    //       }
    //     }

    //     sameNameDoctors.push({
    //       fullName: docName,
    //       cpsoNum: docData["cpsoNum"],
    //       scheduleId: scheduleId,
    //       isComplete: isComplete,
    //     });
    //   });

    //   let hasInCompleteDoc =
    //     sameNameDoctors.filter(
    //       (sameNameDoc: SearchOptionDoctor) => !sameNameDoc.isComplete
    //     ).length > 0;
    //   if (hasInCompleteDoc) {
    //     newSearchData.push({
    //       label: docName,
    //       value: docName,
    //       fullName: docName,
    //       doctors: sameNameDoctors,
    //     });
    //   }
    // });

    console.log("Search data loaded");
    // console.log(newSearchData);
    setAllSearchData(newSearchData);
    // fill in doctors that exist

    if (allGroupData != null && groupDataType === "search") {
      setAllGroupData(newSearchData);

      if (selectedSearchData != null) {
        // set doctors field
        let newSearchDoctors: any = [];
        selectedSearchData["doctorIds"].forEach((doctorId: string) => {
          let newShowDoctor = calculateSearchShowDoctor(doctorId, false);
          if (newShowDoctor != null) {
            newSearchDoctors.push(newShowDoctor);
          }
        });
        selectedSearchData.doctors = newSearchDoctors;
        if (selectedGroupDataType === "search-doctor") {
          if (newSearchDoctors.length > 0) {
            if (selectedGroupData == null) {
              setSelectedGroupData(newSearchDoctors[0]);
            } else {
              let newSelectedGroupData = newSearchDoctors.find(
                (doc: any) => doc["cpsoNum"] === selectedGroupData["cpsoNum"]
              );
              if (newSelectedGroupData !== undefined) {
                setSelectedGroupData(newSelectedGroupData);
              } else {
                setSelectedGroupData(null);
              }
            }
          }
        }
      }
    }
    setLoadingSearchData(false);
  };

  const deriveEvents = () => {
    console.log("Deriving events");
    var newEvents: TEvent[] = [];
    let eventsData: Map<number, any> = new Map();
    let groupDoctorsData: Map<number, any> = new Map();

    schedules.forEach((scheduleData: any) => {
      if (scheduleData["date"] != null) {
        let adminData = adminsMap.get(scheduleData["cpsoNum"]);

        scheduleData["dateHistory"].forEach((eventDate: any) => {
          const eventsKey = eventDate.getTime();
          let oDocData = undefined;
          if (scheduleData["cpsoNum"].startsWith("service-")) {
            oDocData = centralServicesMap.get(
              scheduleData["cpsoNum"].replace("service-", "")
            );
          } else {
            oDocData = doctorsMap.get(scheduleData["cpsoNum"]);
          }

          if (oDocData !== undefined) {
            let docData = enhanceDocData(
              oDocData,
              adminData,
              scheduleData,
              eventsKey
            );

            if (groupDoctorsData.has(eventsKey)) {
              groupDoctorsData.get(eventsKey).push(docData);
            } else {
              groupDoctorsData.set(eventsKey, [docData]);
            }
          }
        });
      }
    });

    // Group doctors with the same number together
    groupDoctorsData.forEach((value, key) => {
      let startDate = new Date(key);
      let phoneData: Map<string, any> = new Map();
      value.forEach((doctor: any) => {
        let phoneKey = doctor["phone"];
        if (doctor["phoneExt"] !== "") {
          phoneKey += "*" + doctor["phoneExt"];
        }
        if (phoneData.has(phoneKey)) {
          phoneData.get(phoneKey).push(doctor);
        } else {
          phoneData.set(phoneKey, [doctor]);
        }
      });

      let callNum = value.length;
      let title = "Call " + value.length + " clinic";
      if (callNum !== 1) {
        title += "s";
      }

      if (startDate.getFullYear() === 2022 && startDate.getMonth() === 7 && startDate.getDate() === 7) {
        if (startDate.getHours() === 10) {
          title = "Never Called (" + value.length + ")";
        } else if (startDate.getHours() === 11) {
          title = "Faxed Before July (" + value.length + ")";
        } else if (startDate.getHours() === 12) {
          title = "Faxed After July (" + value.length + ")";
        } else if (startDate.getHours() === 13) {
          title = "Previously Flagged (" + value.length + ")";
        }
      }

      let newEvent = {
        id: newEvents.length,
        title: title,
        start: startDate,
        end: moment(startDate).add(1, "hours").toDate(),
        doctors: phoneData,
      };
      eventsData.set(startDate.getTime(), phoneData);

      newEvents.push(newEvent);
    });
    console.log("Events loaded");
    // console.log(newEvents);

    setEvents(newEvents);
    if (allGroupData != null && groupDataType === "event") {
      let newAllGroupData = newEvents.find(
        (event) => event.start.getTime() === allGroupData.start.getTime()
      );
      if (newAllGroupData !== undefined) {
        setAllGroupData(newAllGroupData);
        if (selectedGroupData != null && selectedGroupDataType === "doctor") {
          let phoneKey = selectedGroupData["phone"];
          if (selectedGroupData["phoneExt"] !== "") {
            phoneKey += "*" + selectedGroupData["phoneExt"];
          }
          let newSelectedGroupData = newAllGroupData.doctors.get(phoneKey);
          if (newSelectedGroupData !== undefined) {
            newSelectedGroupData = newSelectedGroupData.find(
              (doc: any) => doc["cpsoNum"] === selectedGroupData["cpsoNum"]
            );
            if (newSelectedGroupData !== undefined) {
              setSelectedGroupData(newSelectedGroupData);
            } else {
              setSelectedGroupData(null);
            }
          } else {
            setSelectedGroupData(null);
          }
        }
      }
    }

    setEventsMap(eventsData);
  };

  const computeDerivedData = () => {
    deriveEvents();
    deriveSearchData();
    deriveCentralSearchData();
    deriveImagingSearchData();
    deriveOtherData();
  };

  useEffect(() => {
    if (cityToCityData.size > 0) {
      computeDerivedData();
    }
  }, [cityToCityData]);

  useEffect(() => {
    if (!loadingDBData) {
      deriveProcedureList();
      deriveCityData();
      deriveConfigOptions();
      setLoading(false);
    }
  }, [loadingDBData]);

  const expandEventCard = (
    event: TEvent,
    e: React.SyntheticEvent<HTMLElement>
  ): void => {
    if (event.doctors != null) {
      setShowGroupModal(true);
      setGroupModalHeader(
        "Calls for " +
          event.start.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
      );
      setAllGroupData(event);
      setSelectedGroupData(null);
      setSelectedGroupDataType("");
      setGroupDataType("event");
    }
  };

  const handleCloseGroupModal = () => {
    setShowGroupModal(false);
    setGroupModalHeader("");
    setGroupDataType("");
    setAllGroupData(null);
    setSelectedGroupData(null);
    setSelectedGroupDataType("");
  };

  const handleCloseEditConfig = () => {
    setSelectedConfigProcedure(null);
    setSelectedConfigSpeciality(null);
    setShowEditConfigModal(false);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    setSelectedGroupData(null);
    setSelectedGroupDataType("");
  };

  const eventStyleGetther = (
    event: TEvent,
    start: stringOrDate,
    end: stringOrDate,
    isSelected: boolean
  ): React.HTMLAttributes<HTMLDivElement> => {
    let eventComplete = true;

    if (event.doctors != null) {
      event.doctors.forEach((docGroup: any, index: number) => {
        docGroup.forEach((eDoctor: any) => {
          if (!eDoctor["isComplete"]) {
            eventComplete = false;
          }
        });
      });
    } else {
      eventComplete = false;
    }

    var style = {
      backgroundColor: eventComplete ? "#3fa34d" : "#3174ad",
    };
    return {
      style: style,
    };
  };

  return (
    <div>
      {loading ? (
        <div className="homeLoadingSpinner">
          <Spinner animation="grow" />
        </div>
      ) : (
        <div className="myMain">
          <Badge className="versionBadge" bg="secondary">
            v. 1.4.4
          </Badge>
          <ToolBar
            adminMode={adminMode}
            setShowGroupModal={setShowGroupModal}
            setGroupModalHeader={setGroupModalHeader}
            setAllGroupData={setAllGroupData}
            allSearchData={allSearchData}
            setSelectedGroupData={setSelectedGroupData}
            setSelectedGroupDataType={setSelectedGroupDataType}
            setGroupDataType={setGroupDataType}
            allEmailData={allEmailData}
            allFlaggedData={allFlaggedData}
            allAdditionalInfoReviewData={allAdditionalInfoReviewData}
            allImpAdditionalInfoReviewData={allImpAdditionalInfoReviewData}
            allGroupInfoReviewData={allGroupInfoReviewData}
            setShowEditConfigModal={setShowEditConfigModal}
            allRescrapeData={allRescrapeData}
            allHideDoctorData={allHideDoctorData}
            allMetaSpecialtiesData={allMetaSpecialtiesData}
            setShowCreateModal={setShowCreateModal}
            mode={props.mode}
            setSelectedSearchData={setSelectedSearchData}
          />
          <Calendar
            events={events}
            views={[
              Views.MONTH,
              Views.WORK_WEEK,
              Views.WEEK,
              Views.DAY,
              Views.AGENDA,
            ]}
            defaultView={Views.WEEK}
            step={30}
            min={new Date(0, 0, 0, 9, 0, 0)}
            max={new Date(0, 0, 0, 17, 0, 0)}
            showMultiDayTimes
            onSelectEvent={expandEventCard}
            defaultDate={now}
            localizer={localizer}
            style={{ height: "93vh" }}
            eventPropGetter={eventStyleGetther}
          />

          {/* Group Modal */}
          <GroupModal
            showGroupModal={showGroupModal}
            setShowGroupModal={setShowGroupModal}
            groupModalHeader={groupModalHeader}
            setGroupModalHeader={setGroupModalHeader}
            allGroupData={allGroupData}
            setAllGroupData={setAllGroupData}
            groupDataType={groupDataType}
            selectedGroupData={selectedGroupData}
            setSelectedGroupData={setSelectedGroupData}
            selectedGroupDataType={selectedGroupDataType}
            setSelectedGroupDataType={setSelectedGroupDataType}
            handleCloseGroupModal={handleCloseGroupModal}
            dispatch={dispatch}
            doctors={doctors}
            doctorsMap={doctorsMap}
            admins={admins}
            adminsMap={adminsMap}
            schedules={schedules}
            schedulesMap={schedulesMap}
            procedureList={procedureList}
            isSavingGroupData={isSavingGroupData}
            setIsSavingGroupData={setIsSavingGroupData}
            eventsMap={eventsMap}
            groupInfo={groupInfo}
            allSearchData={allSearchData}
            adminMode={adminMode}
            allProcedureOptions={allProcedureOptions}
            allSpecialityOptions={allSpecialityOptions}
            calculateSearchShowDoctor={calculateSearchShowDoctor}
            selectedSearchData={selectedSearchData}
            setSelectedSearchData={setSelectedSearchData}
            loadingSearchData={loadingSearchData}
            setLoadingSearchData={setLoadingSearchData}
            centralServices={centralServices}
            cityToCityData={cityToCityData}
          />

          {/* Edit Config Modal */}
          <EditConfigModal
            showEditConfigModal={showEditConfigModal}
            handleCloseEditConfig={handleCloseEditConfig}
            configSpecialties={configSpecialties}
            configProcedures={configProcedures}
            selectedConfigSpeciality={selectedConfigSpeciality}
            setSelectedConfigSpeciality={setSelectedConfigSpeciality}
            selectedConfigProcedure={selectedConfigProcedure}
            setSelectedConfigProcedure={setSelectedConfigProcedure}
            isSavingConfigData={isSavingConfigData}
            setIsSavingConfigData={setIsSavingConfigData}
            allProcedureOptions={allProcedureOptions}
            allAHProcedureOptions={allAHProcedureOptions}
            allSpecialityOptions={allSpecialityOptions}
            dispatch={dispatch}
          />

          <CreateModal
            showCreateModal={showCreateModal}
            handleCloseCreateModal={handleCloseCreateModal}
            procedureList={procedureList}
            allSpecialityOptions={allSpecialityOptions}
            allProcedureOptions={allProcedureOptions}
            allCityOptions={allCityOptions}
            adminMode={adminMode}
            allCentralSearchData={allCentralSearchData}
            centralServicesMap={centralServicesMap}
            allImagingSearchData={allImagingSearchData}
            imagingMap={imagingMap}
            selectedGroupData={selectedGroupData}
            setSelectedGroupData={setSelectedGroupData}
            selectedGroupDataType={selectedGroupDataType}
            setSelectedGroupDataType={setSelectedGroupDataType}
            isSavingGroupData={isSavingGroupData}
            setIsSavingGroupData={setIsSavingGroupData}
            dispatch={dispatch}
          />
        </div>
      )}
    </div>
  );
}

export default ADashboard;
