import React, { useState, useEffect } from "react";
import {
  Modal,
  Spinner,
  Button,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";

import { CopyToClipboard } from "react-copy-to-clipboard";
import TouchableOpacity from "./TouchableOpacity";
import { MdContentCopy } from "react-icons/md";

import "../styles/EmailTemplateModal.css";

interface EmailTemplateModalProps {
  showEmailTemplateModal: any;
  setShowEmailTemplateModal: any;
  groupDataType: any;
  selectedGroupData: any;
  selectedGroupDataType: any;
  procedureList: any;
}

function EmailTemplateModal(props: EmailTemplateModalProps) {
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailContent, setEmailContent] = useState<string>("");
  const [specialityProcedures, setSpecialityProcedures] = useState<string[]>(
    []
  );

  const handleCloseEmailTemplateModal = () => {
    props.setShowEmailTemplateModal(false);
  };

  useEffect(() => {
    if (
      props.selectedGroupData !== null &&
      props.groupDataType === "event" &&
      props.selectedGroupDataType === "doctor" &&
      props.showEmailTemplateModal
    ) {
      let specialityProcedures: string[] = [];
      props.selectedGroupData["specialties"].forEach((specialty: any) => {
        let newOptions = props.procedureList.get(specialty.toLowerCase());
        if (newOptions !== undefined) {
          newOptions.forEach((option: any) => {
            specialityProcedures.push(option.value);
          });
        }
      });
      setSpecialityProcedures(specialityProcedures);

      // let newEmailContent = "";
      // newEmailContent += `To Office of: Dr. ${props.selectedGroupData["fullName"]}\n`;
      // newEmailContent += "\n";
      // newEmailContent +=
      //   "To help us manage referrals, could you kindly tell us your current average wait time (for non-urgent referrals): \n";
      // newEmailContent += "\n";
      // newEmailContent += `Could you please also indicate if Dr. ${props.selectedGroupData["fullName"]} performs any of the following procedures:\n`;
      // specialityProcedures.forEach((procedure) => {
      //   newEmailContent += `  - ${procedure}\n`;
      // });

      // newEmailContent += "\n";
      // newEmailContent += "Thank you,\n";
      // newEmailContent += "Dr. Ali Qamar\n";
      // setEmailContent(newEmailContent);

      // let newEmailSubject = `Dr. ${props.selectedGroupData["fullName"]} - Information`;
      // setEmailSubject(newEmailSubject);
    }
  }, [
    props.selectedGroupData,
    props.groupDataType,
    props.showEmailTemplateModal,
    props.selectedGroupDataType,
    props.procedureList,
  ]);

  return (
    <Modal
      show={props.showEmailTemplateModal}
      onHide={handleCloseEmailTemplateModal}
      dialogClassName="emailTemplateModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Email Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label htmlFor="emailContent">Subject</Form.Label>{" "}
        <InputGroup className="mb-3">
          {props.selectedGroupData !== null &&
            props.groupDataType === "event" &&
            props.selectedGroupDataType === "doctor" &&
            props.showEmailTemplateModal && (
              <FormControl
                id="emailContent"
                value={`Dr. ${props.selectedGroupData["fullName"]} - Information`}
                disabled={true}
              />
            )}
        </InputGroup>
        <Form.Label htmlFor="emailContent">Content</Form.Label>
        {props.selectedGroupData !== null &&
          props.groupDataType === "event" &&
          props.selectedGroupDataType === "doctor" &&
          props.showEmailTemplateModal && (
            <div className="emailContent">
              <p>To Office of: Dr. {props.selectedGroupData["fullName"]}</p>
              <br />
              <p>
                To help us manage referrals, could you kindly tell us your
                current average wait time (for non-urgent referrals):{" "}
              </p>
              <br />
              <p>
                Could you please also indicate if Dr.{" "}
                {props.selectedGroupData["fullName"]} performs any of the
                following procedures:
              </p>
              <ul>
                {specialityProcedures.map((procedure) => {
                  return <li>{procedure}</li>;
                })}
              </ul>
              <br />
              <p>Thank you,</p>
              <p>Dr. Ali Qamar</p>
            </div>
          )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseEmailTemplateModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EmailTemplateModal;
