import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup, FormControl } from "react-bootstrap";

import "../styles/WaitTimeForm.css";

interface WaitTimeFormProps {
  label?: string;
  waitTimeStartValue: any;
  setWaitTimeStartValue: any;
  waitTimeEndValue: any;
  setWaitTimeEndValue: any;
  waitTimeUnitValue: any;
  setWaitTimeUnitValue: any;
  disable: any;
  selectedGroupData?: any;
  clearMsgs: any;
  isAdminMode: any;
}

function WaitTimeForm(props: WaitTimeFormProps) {
  const onWaitTimeStartChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let newWaitTimeStart = event.currentTarget.valueAsNumber;
    props.clearMsgs();
    if (isNaN(newWaitTimeStart)) {
      if (props.waitTimeStartValue === props.waitTimeEndValue) {
        props.setWaitTimeEndValue(null);
      }
      props.setWaitTimeStartValue(null);
    }
    if (newWaitTimeStart > 0) {
      props.setWaitTimeStartValue(newWaitTimeStart);
      if (props.waitTimeEndValue == null) {
        props.setWaitTimeEndValue(newWaitTimeStart);
      }
    }
  };

  const onWaitTimeEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newWaitTimeEnd = event.currentTarget.valueAsNumber;
    props.clearMsgs();
    if (isNaN(newWaitTimeEnd)) {
      props.setWaitTimeEndValue(null);
    }
    if (newWaitTimeEnd > 0) {
      props.setWaitTimeEndValue(newWaitTimeEnd);
    }
  };

  const onWaitTimeUnitChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    props.setWaitTimeUnitValue(event.currentTarget.value);
  };

  return (
    <Col>
      <Form.Label htmlFor="waitTime" className="formLabel">
        {props.label ? props.label : "Wait Time"}
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl
          id="waitTimeStart"
          value={
            props.waitTimeStartValue == null ? "" : props.waitTimeStartValue
          }
          type="number"
          onChange={onWaitTimeStartChange}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          autoComplete={"off"}
          disabled={props.disable()}
        />
        <InputGroup.Text>-</InputGroup.Text>
        <FormControl
          id="waitTimeEnd"
          type="number"
          value={props.waitTimeEndValue == null ? "" : props.waitTimeEndValue}
          onChange={onWaitTimeEndChange}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          autoComplete={"off"}
          disabled={props.disable()}
        />
        <Form.Select
          value={props.waitTimeUnitValue}
          onChange={onWaitTimeUnitChange}
          disabled={props.disable()}
        >
          {props.isAdminMode && <option value="days">days</option>}
          <option value="weeks">weeks</option>
          <option value="months">months</option>
        </Form.Select>
      </InputGroup>
      {/* )} */}
    </Col>
  );
}

export default WaitTimeForm;
