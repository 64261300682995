import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { MetaOption, IsMulti } from "../interfaces";
import Select, {
  OnChangeValue,
  ActionMeta,
  StylesConfig,
  GroupBase,
  SingleValue,
} from "react-select";
import {
  MetaOptions,
  RETIRED_VALUE,
  CLOSED_CLINIC_VALUE,
  CUSTOM_FORM_VALUE,
  NOT_ACCEPTING_VALUE,
  URGENT_ONLY_VALUE,
  WALK_IN_VALUE,
  VARIABLE_VALUE,
} from "../config";

import "../styles/MetaTagsForm.css";

interface MetaTagsFormProps {
  metaTagsValue: any;
  setMetaTagsValue: any;
  disable: any;
  selectedGroupData: any;
  clearMsgs: any;
  sdExtraInfo: any;
  setSDExtraInfo: any;
}

function MetaTagsForm(props: MetaTagsFormProps) {
  let [useOptions, setUseOptions] = useState<MetaOption[]>(MetaOptions);

  useEffect(() => {
    let newOptions: MetaOption[] = MetaOptions;
    props.metaTagsValue.forEach((value: any) => {
      if (value.value === NOT_ACCEPTING_VALUE) {
        newOptions = newOptions.filter(
          (option: MetaOption) =>
            option.value !== URGENT_ONLY_VALUE &&
            option.value !== WALK_IN_VALUE &&
            option.value !== VARIABLE_VALUE
        );
      }
      if (value.value === URGENT_ONLY_VALUE) {
        newOptions = newOptions.filter(
          (option: MetaOption) =>
            option.value !== NOT_ACCEPTING_VALUE &&
            option.value !== WALK_IN_VALUE &&
            option.value !== VARIABLE_VALUE
        );
      }
      if (value.value === WALK_IN_VALUE) {
        newOptions = newOptions.filter(
          (option: MetaOption) =>
            option.value !== NOT_ACCEPTING_VALUE &&
            option.value !== URGENT_ONLY_VALUE &&
            option.value !== VARIABLE_VALUE
        );
      }
      if (value.value === VARIABLE_VALUE) {
        newOptions = newOptions.filter(
          (option: MetaOption) =>
            option.value !== NOT_ACCEPTING_VALUE &&
            option.value !== URGENT_ONLY_VALUE &&
            option.value !== WALK_IN_VALUE
        );
      }
    });

    if (props.selectedGroupData != null) {
      // if (!props.selectedGroupData["isService"]) {
      //   newOptions = newOptions.filter(
      //     (option: MetaOption) => option.value !== VARIABLE_VALUE
      //   );
      // }
    }
    setUseOptions(newOptions);
  }, [props.metaTagsValue]);

  const onChange = (
    newValue: OnChangeValue<MetaOption, IsMulti>,
    actionMeta: ActionMeta<MetaOption>
  ) => {
    props.clearMsgs();
    let newMeta: MetaOption[] = [];
    let addInfo = false;

    newValue.forEach((value) => {
      newMeta.push(value);
      if (value.value === CUSTOM_FORM_VALUE) {
        addInfo = true;
      }
    });
    if (addInfo) {
      let newInfo = props.sdExtraInfo;
      if (newInfo == null) {
        newInfo = "Uses custom referral form";
      } else {
        if (!newInfo.includes("Uses custom referral form")) {
          newInfo += " Uses custom referral form";
        }
      }
      props.setSDExtraInfo(newInfo);
    }
    props.setMetaTagsValue(newMeta);
  };

  const metaOptionStyles: StylesConfig<
    MetaOption,
    IsMulti,
    GroupBase<MetaOption>
  > = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color:
          data.value === RETIRED_VALUE || data.value === CLOSED_CLINIC_VALUE
            ? "red"
            : "black",
      };
    },
  };

  return (
    <Col>
      <Form.Label htmlFor="meta" className="formLabel">
        Meta Tags
      </Form.Label>
      <InputGroup className="mb-3">
        <Select<MetaOption, IsMulti>
          options={useOptions}
          value={props.metaTagsValue}
          isMulti={true}
          className="metaSelection"
          isDisabled={props.disable()}
          onChange={onChange}
          styles={metaOptionStyles}
        />
      </InputGroup>
    </Col>
  );
}

export default MetaTagsForm;
