import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import {
  GroupedProcedureOption,
  ProcedureOption,
  ProcedureSpecialityOption,
  IsMulti,
} from "../interfaces";
import Select, { OnChangeValue, ActionMeta } from "react-select";

import "../styles/ProceduresForm.css";

interface ProceduresFormProps {
  procedureValue: any;
  setProcedureValue: any;
  disable: any;
  selectedSpecialties: any;
  procedureList: any;
  clearMsgs: any;
  allProcedureOptions: any;
  adminMode: any;
  hasInternalMedicine: any;
}

function ProceduresForm(props: ProceduresFormProps) {
  const [procedureOptions, setProcedureOptions] = useState<
    GroupedProcedureOption[]
  >([]);

  const onChange = (
    newValue: OnChangeValue<
      ProcedureOption | ProcedureSpecialityOption,
      IsMulti
    >,
    actionMeta: ActionMeta<ProcedureOption | ProcedureSpecialityOption>
  ) => {
    props.clearMsgs();
    let newProcedure: ProcedureOption[] = [];
    newValue.forEach((value: any) => {
      newProcedure.push(value);
    });
    props.setProcedureValue(newProcedure);
  };

  useEffect(() => {
    let specialityProcedureOptions: ProcedureSpecialityOption[] = [];

    if (props.selectedSpecialties != null) {
      props.selectedSpecialties.forEach((specialty: any) => {
        let newOptions = props.procedureList.get(specialty.toLowerCase());
        if (newOptions !== undefined) {
          newOptions.forEach((newOption: any) => {
            let optionExists = specialityProcedureOptions.find(
              (pOption: ProcedureSpecialityOption) =>
                pOption.value === newOption.value
            );
            if (optionExists === undefined) {
              specialityProcedureOptions.push(newOption);
            }
          });
        }
      });
    }

    let otherProcedureOptions: ProcedureOption[] = [];
    if (props.adminMode || props.hasInternalMedicine) {
      let specialityProcedureOptionValues: Set<string> = new Set();
      specialityProcedureOptions.forEach(
        (specialityProcedureOption: ProcedureSpecialityOption) => {
          specialityProcedureOptionValues.add(specialityProcedureOption.label);
        }
      );

      props.allProcedureOptions.forEach((procedureOption: any) => {
        if (!specialityProcedureOptionValues.has(procedureOption["label"])) {
          otherProcedureOptions.push({
            value: procedureOption["label"],
            label: procedureOption["label"],
          });
        }
      });
    }

    // allProcedureOptions

    let selectedProcedureOptions: GroupedProcedureOption[] = [
      {
        label: "Speciality Procedures",
        options: specialityProcedureOptions,
      },
      {
        label: "Other Procedures",
        options: otherProcedureOptions,
      },
    ];

    setProcedureOptions(selectedProcedureOptions);
  }, [props.selectedSpecialties, props.procedureList]);

  const groupStyles: any = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const groupBadgeStyles: any = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data: GroupedProcedureOption) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <Col>
      <Form.Label htmlFor="procedures" className="formLabel">
        Procedures
      </Form.Label>
      <InputGroup className="mb-3">
        <Select<
          ProcedureOption | ProcedureSpecialityOption,
          IsMulti,
          GroupedProcedureOption
        >
          options={procedureOptions}
          value={props.procedureValue}
          isMulti={true}
          className="procedureSelection"
          isDisabled={props.disable()}
          onChange={onChange}
          formatGroupLabel={formatGroupLabel}
        />
      </InputGroup>
    </Col>
  );
}

export default ProceduresForm;
