import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Tab,
  ListGroup,
  Badge,
  Accordion,
  Spinner,
} from "react-bootstrap";
import { matchSorter } from "match-sorter";

import {
  AiOutlineCheckCircle,
  AiFillFlag,
  AiFillClockCircle,
  AiOutlineMail,
} from "react-icons/ai";
import Select, {
  OnChangeValue,
  ActionMeta,
  StylesConfig,
  GroupBase,
  SingleValue,
  InputActionMeta,
} from "react-select";
import { BsFillCalendarEventFill } from "react-icons/bs";
import { BiBuildings } from "react-icons/bi";
import { IoIosSend } from "react-icons/io";

import { getCallNumber } from "../utils";

import {
  MetaOption,
  ProcedureOption,
  ProcedureSpecialityOption,
  ActionOption,
  SearchOption,
  MetaSpecialityOption,
  WaitTimeMsgOption,
  SpecialityOption,
  SearchOptionDoctor,
  FilterGroupOption,
} from "../interfaces";

import { fetchIndiviualDoctor } from "../app/doctorsSlice";
import { fetchIndiviualAdmin } from "../app/adminSlice";

import { SpecialityColorMap } from "../constants";

import SingleCard from "./SingleCard";
import FlagModal from "./FlagModal";
import GroupCard from "./GroupCard";

import "../styles/GroupModal.css";
import FullCard from "./FullCard";

import {
  MetaOptions,
  NOT_ACCEPTING_VALUE,
  RETIRED_VALUE,
  CLOSED_CLINIC_VALUE,
  AllActionOptions,
  NUM_CALLS_BEFORE_FAX,
  MetaSpecialityOptions,
  WaitTimeMsgOptions,
  URGENT_ONLY_VALUE,
  WALK_IN_VALUE,
  VARIABLE_VALUE,
  FilterGroupOptions,
} from "../config";
import AvailabilityForm from "./AvailabilityForm";
import SearchCard from "./SearchCard";
import EmailTemplateModal from "./EmailTemplateModal";
import { fetchIndividualCentralService } from "../app/centralServicesSlice";

interface GroupModalProps {
  showGroupModal: any;
  setShowGroupModal: any;
  groupModalHeader: any;
  setGroupModalHeader: any;
  allGroupData: any;
  setAllGroupData: any;
  groupDataType: any;
  selectedGroupData: any;
  setSelectedGroupData: any;
  selectedGroupDataType: any;
  setSelectedGroupDataType: any;
  handleCloseGroupModal: any;
  dispatch: any;
  doctors: any;
  doctorsMap: any;
  admins: any;
  adminsMap: any;
  schedules: any;
  schedulesMap: any;
  procedureList: any;
  isSavingGroupData: any;
  setIsSavingGroupData: any;
  eventsMap: any;
  groupInfo: any;
  allSearchData: any;
  adminMode: any;
  allProcedureOptions: any;
  allSpecialityOptions: any;
  calculateSearchShowDoctor: any;
  selectedSearchData: any;
  setSelectedSearchData: any;
  loadingSearchData: any;
  setLoadingSearchData: any;
  centralServices: any;
  cityToCityData: any;
}

const KWGC_CITIES = [
  "Waterloo",
  "Kitchener",
  "Guelph",
  "Cambridge",
];

const DURHAM_CITIES = [
  "Ajax",
  "Bowmanville",
  "Brooklin",
  "Courtice",
  "Oshawa",
  "Pickering",
  "Port Perry",
  "Prince Albert",
  "Uxbridge",
  "Whitby",
]

const GTA_CITIES = [
  "Mississauga",
  "Oakville",
  "Milton",
  "Burlington"
]

function GroupModal(props: GroupModalProps) {
  const [numDataShown, setNumDataShown] = useState<number>(0);
  // Search State
  const [showSearchOptions, setShowSearchOptions] = useState(false);
  const [useSearchOptions, setUseSearchOptions] = useState<SearchOption[]>([]);

  // Shared state
  const [showFlagModal, setShowFlagModal] = useState<boolean>(false);
  const [showFullCardModal, setShowFullCardModal] = useState<boolean>(false);
  const [showAvailabilityModal, setShowAvailabilityModal] =
    useState<boolean>(false);
  const [showEmailTemplateModal, setShowEmailTemplateModal] =
    useState<boolean>(false);

  // Form Values
  const [actionOptions, setActionOptions] = useState<ActionOption[]>([]);
  const [actionValue, setActionValue] = useState<ActionOption | undefined>(
    undefined
  );

  const [sdWaitTimeStart, setSDWaitTimeStart] = useState<number | null>(null);
  const [sdWaitTimeEnd, setSDWaitTimeEnd] = useState<number | null>(null);
  const [sdWaitTimeUnit, setSDWaitTimeUnit] = useState<string>("weeks");
  const [sdWaitTimeMsg, setSDWaitTimeMsg] = useState<
    WaitTimeMsgOption | undefined
  >(undefined);

  const [sdProcedures, setSDProcedures] = useState<ProcedureSpecialityOption[]>(
    []
  );
  const [sdMetaSpecialties, setSDMetaSpecialties] = useState<
    MetaSpecialityOption[]
  >([]);
  const [sdSearchSpecialties, setSDSearchSpecialties] = useState<
    SpecialityOption[]
  >([]);
  const [sdMetaInfo, setSDMetaInfo] = useState<MetaOption[]>([]);
  const [sdExtraInfo, setSDExtraInfo] = useState<string | null>(null);
  const [sdImpExtraInfo, setSDImpExtraInfo] = useState<string | null>(null);
  const [sdFinalExtraInfo, setSDFinalExtraInfo] = useState<string | null>(null);
  const [sdAvailability, setSDAvailability] = useState<any>({});
  const [sdCustomForm, setSDCustomForm] = useState<string | null>(null);

  const [sdFax, setSDFax] = useState<string>("");
  const [sdFaxExt, setSDFaxExt] = useState<string>("");
  const [sdPhone, setSDPhone] = useState<string>("");
  const [sdPhoneExt, setSDPhoneExt] = useState<string>("");
  const [editPhoneFax, setEditPhoneFax] = useState<boolean>(false);

  const [selectedGroupFilter, setSelectedGroupFilter] = useState<
    FilterGroupOption | undefined
  >(undefined);

  useEffect(() => {
    setUseSearchOptions(props.allSearchData);
  }, [props.allSearchData]);

  useEffect(() => {
    setSelectedGroupFilter(undefined);
  }, [props.groupDataType]);

  const onSelectDoctor = (doctor: any) => {
    if (props.groupDataType === "event") {
      props.setSelectedGroupDataType("doctor");
    } else if (props.groupDataType === "email") {
      props.setSelectedGroupDataType("email-doctor");
    } else if (props.groupDataType === "flagged") {
      props.setSelectedGroupDataType("flagged-doctor");
    } else if (props.groupDataType === "additionalinfo") {
      props.setSelectedGroupDataType("additionalinfo-doctor");
    } else if (props.groupDataType === "impadditionalinfo") {
      props.setSelectedGroupDataType("impadditionalinfo-doctor");
    } else if (props.groupDataType === "reviewgroupinfo") {
      props.setSelectedGroupDataType("reviewgroupinfo-doctor");
    } else if (props.groupDataType === "rescrape") {
      props.setSelectedGroupDataType("rescrape-doctor");
    } else if (props.groupDataType === "hidden") {
      props.setSelectedGroupDataType("hidden-doctor");
    } else if (props.groupDataType === "meta-specialties") {
      props.setSelectedGroupDataType("meta-specialties-doctor");
    }
    props.setSelectedGroupData(doctor);
  };

  useEffect(() => {
    if (props.selectedGroupData != null) {
      if (props.groupDataType === "event") {
        if (props.selectedGroupDataType === "doctor") {
          selectedDoctorEffect(props.selectedGroupData);
        }
      } else if (props.groupDataType === "flagged") {
        if (props.selectedGroupDataType === "flagged-doctor") {
          selectedDoctorEffect(props.selectedGroupData);
        }
      } else if (props.groupDataType === "email") {
        if (props.selectedGroupDataType === "email-doctor") {
          selectedDoctorEffect(props.selectedGroupData);
        }
      } else if (props.groupDataType === "additionalinfo") {
        if (props.selectedGroupDataType === "additionalinfo-doctor") {
          selectedDoctorEffect(props.selectedGroupData);
        }
      } else if (props.groupDataType === "impadditionalinfo") {
        if (props.selectedGroupDataType === "impadditionalinfo-doctor") {
          selectedDoctorEffect(props.selectedGroupData);
        }
      } else if (props.groupDataType === "reviewgroupinfo") {
        if (props.selectedGroupDataType === "reviewgroupinfo-doctor") {
          selectedDoctorEffect(props.selectedGroupData);
        }
      } else if (props.groupDataType === "search") {
        let selectedDoctor = null;
        if (props.selectedGroupData["cpsoNum"].startsWith("service-")) {
          selectedDoctor = props.centralServices.find(
            (doctor: any) =>
              doctor["cpsoNum"] === props.selectedGroupData["cpsoNum"]
          );
        } else {
          selectedDoctor = props.doctors.find(
            (doctor: any) =>
              doctor["cpsoNum"] === props.selectedGroupData["cpsoNum"]
          );
        }
        selectedDoctorEffect(selectedDoctor);
      } else if (props.groupDataType === "rescrape") {
        if (props.selectedGroupDataType === "rescrape-doctor") {
          selectedDoctorEffect(props.selectedGroupData);
        }
      } else if (props.groupDataType === "hidden") {
        if (props.selectedGroupDataType === "hidden-doctor") {
          selectedDoctorEffect(props.selectedGroupData);
        }
      } else if (props.groupDataType === "meta-specialties") {
        if (props.selectedGroupDataType === "meta-specialties-doctor") {
          selectedDoctorEffect(props.selectedGroupData);
        }
      }
    }
  }, [props.selectedGroupData]);

  const filterActionOptions = (metaInfoValue: MetaOption[]) => {
    let newActionOptions: ActionOption[] = AllActionOptions;
    let isNotAccepting = false;
    let isUrgentOnly = false;
    let isWalkIn = false;
    let isVariable = false;
    let forceFlag = false;
    let forceRetire = false;

    metaInfoValue.forEach((metaInfo: any) => {
      if (metaInfo.value === NOT_ACCEPTING_VALUE) {
        isNotAccepting = true;
      } else if (metaInfo.value === URGENT_ONLY_VALUE) {
        isUrgentOnly = true;
      } else if (metaInfo.value === WALK_IN_VALUE) {
        isWalkIn = true;
      } else if (metaInfo.value === VARIABLE_VALUE) {
        isVariable = true;
      } else if (metaInfo.value === RETIRED_VALUE) {
        forceRetire = true;
      } else if (metaInfo.value === CLOSED_CLINIC_VALUE) {
        forceFlag = true;
      }
    });

    if (!props.adminMode) {
      newActionOptions = newActionOptions.filter(
        (actionOption: ActionOption) => !actionOption.isAdminAction
      );
    }

    if (!props.adminMode) {
      if (forceRetire) {
        newActionOptions = newActionOptions.filter(
          (actionOption: ActionOption) => actionOption.value === "Mark Retired"
        );
        if (actionValue !== undefined && actionValue.value !== "Mark Retired") {
          setActionValue(undefined);
        }
      } else if (forceFlag) {
        newActionOptions = newActionOptions.filter(
          (actionOption: ActionOption) => actionOption.value === "Flag"
        );
        if (actionValue !== undefined && actionValue.value !== "Flag") {
          setActionValue(undefined);
        }
      }
    } else {
      if (
        props.groupDataType === "search" ||
        props.groupDataType === "reviewgroupinfo" ||
        props.groupDataType === "meta-specialties"
      ) {
        newActionOptions = newActionOptions.filter(
          (actionOption: ActionOption) =>
            actionOption.value === "Save Admin" ||
            actionOption.value === "Flag" ||
            actionOption.value === "Hide Doctor" ||
            actionOption.value === "Unhide Doctor"
        );
      } else {
        newActionOptions = newActionOptions.filter(
          (actionOption: ActionOption) => actionOption.value !== "Save Admin"
        );
        if (
          props.groupDataType !== "additionalinfo" &&
          props.groupDataType !== "impadditionalinfo"
        ) {
          newActionOptions = newActionOptions.filter(
            (actionOption: ActionOption) =>
              actionOption.value !== "Hide Doctor" &&
              actionOption.value !== "Unhide Doctor"
          );
        }
      }
    }

    const selectedDoctor = props.selectedGroupData;
    if (selectedDoctor != null) {
      const selectedDoctorSchedule = props.schedules.find(
        (schedule: any) =>
          schedule["scheduleId"] === selectedDoctor["scheduleId"]
      );
      let allowReschedule = true;
      if (selectedDoctorSchedule !== undefined) {
        const callNumber = getCallNumber(selectedDoctorSchedule);
        if (callNumber >= NUM_CALLS_BEFORE_FAX) {
          allowReschedule = false;
        }
      }

      if (!allowReschedule) {
        newActionOptions = newActionOptions.filter(
          (actionOption: ActionOption) =>
            actionOption.value !== "Reschedule (auto)"
        );
      }
    }

    if (props.groupDataType === "search") {
      if (!props.adminMode) {
        newActionOptions = newActionOptions.filter(
          (actionOption: ActionOption) =>
            actionOption.value !== "Reschedule (auto)" &&
            actionOption.value !== "Mark To Fax" &&
            actionOption.value !== "Reschedule w/ date" &&
            actionOption.value !== "Email Sent"
        );
      }
    }
    if (props.groupDataType === "flagged") {
      newActionOptions = newActionOptions.filter(
        (actionOption: ActionOption) =>
          actionOption.value !== "Reschedule (auto)" &&
          actionOption.value !== "Flag"
      );
    } else {
      newActionOptions = newActionOptions.filter(
        (actionOption: ActionOption) => actionOption.value !== "Remove Doctor"
      );
    }

    if (
      props.groupDataType === "additionalinfo" ||
      props.groupDataType === "impadditionalinfo"
    ) {
      newActionOptions = newActionOptions.filter(
        (actionOption: ActionOption) =>
          actionOption.value === "Mark Reviewed" ||
          actionOption.value === "Hide Doctor" ||
          actionOption.value === "Unhide Doctor"
      );
    } else {
      newActionOptions = newActionOptions.filter(
        (actionOption: ActionOption) => actionOption.value !== "Mark Reviewed"
      );
    }

    if (isNotAccepting) {
      setSDWaitTimeMsg(WaitTimeMsgOptions[2]);
    } else if (isUrgentOnly) {
      setSDWaitTimeMsg(WaitTimeMsgOptions[1]);
    } else if (isWalkIn) {
      setSDWaitTimeMsg(WaitTimeMsgOptions[5]);
    } else if (isVariable) {
      setSDWaitTimeMsg(WaitTimeMsgOptions[0]);
    }

    setActionOptions(newActionOptions);
  };

  useEffect(() => {
    filterActionOptions(sdMetaInfo);
  }, [sdMetaInfo]);

  const selectedDoctorEffect = (selectedDoctor: any) => {
    if (selectedDoctor != null) {
      let newWaitTimeMsg: WaitTimeMsgOption | undefined = undefined;
      if (selectedDoctor["waitTimeMsg"] != null) {
        newWaitTimeMsg = WaitTimeMsgOptions.find(
          (elem) => elem.label === selectedDoctor["waitTimeMsg"]
        );
      }
      if (props.adminMode) {
        setSDWaitTimeMsg(newWaitTimeMsg);
      }

      setEditPhoneFax(false);
      setSDPhone(selectedDoctor["phone"]);
      setSDPhoneExt(selectedDoctor["phoneExt"]);
      setSDFax(selectedDoctor["fax"]);
      setSDFaxExt(selectedDoctor["faxExt"]);

      setSDWaitTimeStart(selectedDoctor["waitTimeStart"]);
      setSDWaitTimeEnd(selectedDoctor["waitTimeEnd"]);
      setSDFinalExtraInfo(selectedDoctor["additionalInfo"]);
      setSDCustomForm(selectedDoctor["customForm"]);
      if (selectedDoctor["waitTimeUnit"] == null) {
        setSDWaitTimeUnit("weeks");
      } else {
        setSDWaitTimeUnit(selectedDoctor["waitTimeUnit"]);
      }
      let newProcedureOptions: ProcedureSpecialityOption[] = [];
      selectedDoctor["procedures"].forEach((procedure: any) => {
        newProcedureOptions.push({
          label: procedure,
          value: procedure,
        });
      });
      setSDProcedures(newProcedureOptions);

      let newMetaSpecialityInfo: MetaSpecialityOption[] = [];
      selectedDoctor["metaSpecialties"].forEach(
        (metaSpecialVal: any, index: number) => {
          let metaSpecialOption: any = MetaSpecialityOptions.find(
            (elem) => elem.label === metaSpecialVal
          );
          if (metaSpecialOption !== undefined) {
            newMetaSpecialityInfo.push(metaSpecialOption);
          } else {
            newMetaSpecialityInfo.push({
              value: MetaSpecialityOptions.length + index,
              label: metaSpecialVal,
            });
          }
        }
      );
      setSDMetaSpecialties(newMetaSpecialityInfo);

      let newSearchSpecialityInfo: SpecialityOption[] = [];
      selectedDoctor["searchSpecialties"].forEach((speciality: any) => {
        newSearchSpecialityInfo.push({
          label: speciality,
          value: speciality,
        });
      });
      setSDSearchSpecialties(newSearchSpecialityInfo);

      // Doctor Admin Effect
      const selectedDoctorAdmin = props.admins.find(
        (adminData: any) => adminData["cpsoNum"] === selectedDoctor["cpsoNum"]
      );

      let newMetaInfo: MetaOption[] = [];
      if (selectedDoctorAdmin !== undefined) {
        setSDExtraInfo(selectedDoctorAdmin["additionalInfo"]);
        setSDImpExtraInfo(selectedDoctorAdmin["impAdditionalInfo"]);
        setSDAvailability(selectedDoctorAdmin["availability"]);
        selectedDoctorAdmin["meta"].forEach((metaVal: any) => {
          let metaOption = MetaOptions.find((elem) => elem.value === metaVal);
          if (metaOption !== undefined) {
            newMetaInfo.push(metaOption);
          }
        });
        setSDMetaInfo(newMetaInfo);
      }
    } else {
      setSDWaitTimeMsg(undefined);
      setSDWaitTimeStart(null);
      setSDWaitTimeEnd(null);
      setSDWaitTimeUnit("weeks");
      setSDProcedures([]);
      setSDMetaSpecialties([]);
      setSDSearchSpecialties([]);
      setSDFinalExtraInfo(null);
      setSDCustomForm(null);
      setSDExtraInfo(null);
      setSDImpExtraInfo(null);
      setSDMetaInfo([]);
      setSDAvailability(new Map());
      setActionOptions([]);
      setSDPhone("");
      setEditPhoneFax(false);
      setSDPhoneExt("");
      setSDFax("");
      setSDFaxExt("");
    }
    setActionValue(undefined);
  };

  const disableEdits = (): boolean => {
    return props.isSavingGroupData || props.selectedGroupData["isComplete"];
  };

  const onSelectDoctorGroup = (index: any, docGroup: any) => {
    let newDocGroup: any = {
      key: index,
      doctors: docGroup,
    };
    props.setSelectedGroupDataType("doctor-group");
    props.setSelectedGroupData(newDocGroup);
  };

  const getListItem = (singleProfile: any) => {
    if (singleProfile) {
      let useSingleProfile = singleProfile;
      let selectDoctor = props.selectedGroupData;
      let listOnSelectDoctor: any = onSelectDoctor;

      return (
        <ListGroup.Item
          action
          variant={
            selectDoctor &&
            selectDoctor["cpsoNum"] === useSingleProfile["cpsoNum"]
              ? "primary"
              : ""
          }
          eventKey={useSingleProfile["cpsoNum"]}
          key={useSingleProfile["cpsoNum"]}
          active={false}
          onClick={() => listOnSelectDoctor(useSingleProfile)}
          disabled={props.isSavingGroupData}
        >
          {useSingleProfile["isService"] && (
            <BiBuildings color="blue" size={20} className="completeIcon" />
          )}
          {useSingleProfile["isComplete"] && (
            <AiOutlineCheckCircle
              color="green"
              size={20}
              className="completeIcon"
            />
          )}
          {!useSingleProfile["isComplete"] &&
            useSingleProfile["hasSpecialAvailability"] && (
              <AiFillClockCircle
                color="#084290"
                size={20}
                className="completeIcon"
              />
            )}
          {useSingleProfile["flagStatus"] && (
            <AiFillFlag color="red" size={20} className="completeIcon" />
          )}
          {useSingleProfile["rescheduled"] && (
            <BsFillCalendarEventFill
              color="purple"
              size={20}
              className="completeIcon"
            />
          )}
          {useSingleProfile["faxStatus"] && (
            <IoIosSend color="blue" size={20} className="completeIcon" />
          )}
          {useSingleProfile["emailStatus"] && (
            <AiOutlineMail color="blue" size={20} className="completeIcon" />
          )}
          {props.adminMode && useSingleProfile["regionKey"] !== "" && (
            <b>{"(" + useSingleProfile["regionKey"] + ") "}</b>
          )}
          {useSingleProfile["location"] !== null &&
            useSingleProfile["location"]["city"] && (
              <b>{"(" + useSingleProfile["location"]["city"] + ") "}</b>
            )}
          {useSingleProfile["fullName"]}
          {useSingleProfile["specialties"].map(
            (speciality: string, index: number) => {
              if (!SpecialityColorMap.has(speciality)) {
                console.log("FIX COLOUR:" + speciality);
              }
              return (
                <Badge
                  pill
                  key={useSingleProfile["cpsoNum"] + index + speciality}
                  style={{
                    backgroundColor: SpecialityColorMap.get(speciality),
                  }}
                  bg={""}
                  className="doctorSpecialtyBadge"
                >
                  {speciality}
                </Badge>
              );
            }
          )}
        </ListGroup.Item>
      );
    }
  };

  const renderAllGroupData = () => {
    if (props.allGroupData && props.groupDataType.length > 0) {
      let docElems: any[] = [];
      let dataKey = "";
      let newReview = 0;
      if (
        props.groupDataType === "event" ||
        props.groupDataType === "email" ||
        props.groupDataType === "flagged" ||
        props.groupDataType === "additionalinfo" ||
        props.groupDataType === "impadditionalinfo" ||
        props.groupDataType === "reviewgroupinfo" ||
        props.groupDataType === "rescrape" ||
        props.groupDataType === "hidden" ||
        props.groupDataType === "meta-specialties"
      ) {
        dataKey = "doctors";
      }
      if (props.allGroupData[dataKey] !== undefined) {
        props.allGroupData[dataKey].forEach((groupData: any, index: number) => {

          // check if group data passes selected filter
          let passesFilter = true;
          let shortestWaitTimeKey: any = null;

          if(selectedGroupFilter){
            passesFilter = false;
            groupData.forEach((singleProfile: any) => {
              if(shortestWaitTimeKey == null || singleProfile["waitTimeKey"] < shortestWaitTimeKey){
                shortestWaitTimeKey = singleProfile["waitTimeKey"];
              }

                if(selectedGroupFilter.value === "Never Reviewed"){
                  let adminData = props.adminsMap.get(singleProfile["cpsoNum"]);
                  if(adminData && !adminData["reviewedOnce"] && adminData["scheduleHistory"].length === 2 && singleProfile["additionalInfo"] == null){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Not Hidden"){
                  if(!singleProfile["hideCard"]){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Central Services"){
                  if(singleProfile["isService"]){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Custom Region (KWGC)"){
                  if(singleProfile["location"] != null && KWGC_CITIES.includes(singleProfile["location"]["city"])){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Custom Region (Durham)"){
                  if(singleProfile["location"] != null && DURHAM_CITIES.includes(singleProfile["location"]["city"])){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Custom Region (Ottawa)"){
                  if(singleProfile["location"] != null && singleProfile["location"]["city"] === "Ottawa"){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Custom Region (Mississauga/Oakville/Milton/Burlington)"){
                  if(singleProfile["location"] != null && GTA_CITIES.includes(singleProfile["location"]["city"])){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Custom Region (Hamilton)"){
                  if(singleProfile["location"] != null && props.cityToCityData.get(singleProfile["location"]["searchCity"]) && props.cityToCityData.get(singleProfile["location"]["searchCity"])["region"] === "Hamilton Region"){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Custom Region (Niagara)"){
                  if(singleProfile["location"] != null && props.cityToCityData.get(singleProfile["location"]["searchCity"]) && props.cityToCityData.get(singleProfile["location"]["searchCity"])["region"] === "Niagara Region"){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Custom Region (Toronto)"){
                  if(singleProfile["location"] != null && props.cityToCityData.get(singleProfile["location"]["searchCity"]) && props.cityToCityData.get(singleProfile["location"]["searchCity"])["region"] === "Toronto & York Region"){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Custom Region (Simcoe)"){
                  if(singleProfile["location"] != null && props.cityToCityData.get(singleProfile["location"]["searchCity"]) && props.cityToCityData.get(singleProfile["location"]["searchCity"])["region"] === "Simcoe Region"){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Custom Region (GTA)"){
                  if(singleProfile["location"] != null && props.cityToCityData.get(singleProfile["location"]["searchCity"]) && props.cityToCityData.get(singleProfile["location"]["searchCity"])["region"] === "GTA Region"){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Custom Region (Windsor)"){
                  if(singleProfile["location"] != null && (singleProfile["location"]["city"] === "Windsor" || singleProfile["location"]["city"] === "Tecumseh" || singleProfile["location"]["city"] === "Leamington")){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Meta Tag (closed/no longer here)"){
                  let adminData = props.adminsMap.get(singleProfile["cpsoNum"]);
                  if(adminData && adminData["meta"].includes(CLOSED_CLINIC_VALUE)){
                    passesFilter = true;
                  }
                } else if(selectedGroupFilter.value === "Flag Keyword (triage)"){
                  let adminData = props.adminsMap.get(singleProfile["cpsoNum"]);
                  if(adminData && adminData["currScheduleRef"]){
                    let currScheduleData = props.schedulesMap.get(adminData["currScheduleRef"].id);
                    if(currScheduleData && currScheduleData["flagMsg"] && currScheduleData["flagMsg"].includes("triage")){
                      passesFilter = true;
                    }
                  }
                } else if(selectedGroupFilter.value === "Flag Keyword (central)"){
                  let adminData = props.adminsMap.get(singleProfile["cpsoNum"]);
                  if(adminData && adminData["currScheduleRef"]){
                    let currScheduleData = props.schedulesMap.get(adminData["currScheduleRef"].id);
                    if(currScheduleData && currScheduleData["flagMsg"] && currScheduleData["flagMsg"].includes("central")){
                      passesFilter = true;
                    }
                  }
                }
                else if(selectedGroupFilter.value === "Flag Keyword (send fax)"){
                  let adminData = props.adminsMap.get(singleProfile["cpsoNum"]);
                  if(adminData && adminData["currScheduleRef"]){
                    let currScheduleData = props.schedulesMap.get(adminData["currScheduleRef"].id);
                    if(currScheduleData && currScheduleData["flagMsg"] && currScheduleData["flagMsg"].includes("send fax")){
                      passesFilter = true;
                    }
                  }
                }
                else if(selectedGroupFilter.value === "Flag Keyword (variable)"){
                  let adminData = props.adminsMap.get(singleProfile["cpsoNum"]);
                  if(adminData && adminData["currScheduleRef"]){
                    let currScheduleData = props.schedulesMap.get(adminData["currScheduleRef"].id);
                    if(currScheduleData && currScheduleData["flagMsg"] && currScheduleData["flagMsg"].includes("variable")){
                      passesFilter = true;
                    }
                  }
                } else if(selectedGroupFilter.value === "Custom Speciality (Psychiatry)"){
                  passesFilter = singleProfile["specialties"].includes("Psychiatry");
                }
            });
        } else {
          groupData.forEach((singleProfile: any) => {
            if(shortestWaitTimeKey == null || singleProfile["waitTimeKey"] < shortestWaitTimeKey){
              shortestWaitTimeKey = singleProfile["waitTimeKey"];
            }
          });
        }

        if(passesFilter){
          newReview += groupData.length;
          if (groupData.length === 1) {
            docElems.push({
              sortKey: index,
              elements: getListItem(groupData[0]),
              regionKey: groupData[0]["regionKey"],
              waitTimeKey: shortestWaitTimeKey,
            });
          } else {
            let groupElements = (
              <Accordion>
                <Accordion.Item eventKey={"groupCall-" + index}>
                  <Accordion.Header>
                    Group Call ({groupData.length})
                  </Accordion.Header>
                  <Accordion.Body>
                    <ListGroup.Item
                      action
                      variant={
                        props.selectedGroupData &&
                        props.selectedGroupData["key"] === index
                          ? "primary"
                          : ""
                      }
                      eventKey={index}
                      active={false}
                      onClick={() => onSelectDoctorGroup(index, groupData)}
                      disabled={props.isSavingGroupData}
                    >
                      Fill In For Group
                    </ListGroup.Item>
                    {groupData.map((singleProfile: any, index: number) => {
                      return getListItem(singleProfile);
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
            docElems.push({
              sortKey: index,
              elements: groupElements,
              regionKey: groupData[0]["regionKey"],
              waitTimeKey: shortestWaitTimeKey,
            });
          }
        }
        });
      }
      // sort docElements first on regionKey, then waitTimeKey, then sortKey


      docElems.sort((a, b) => {
            // First, sort by regionKey (string)
            if (a.regionKey < b.regionKey) {
              return -1;
            } else if (a.regionKey > b.regionKey) {
              return 1;
            }

            // If regionKey is the same, sort by waitTimeKey (number)
            if(props.adminMode){
              if (a.waitTimeKey < b.waitTimeKey) {
                return -1;
              } else if (a.waitTimeKey > b.waitTimeKey) {
                return 1;
              }
            }

            // If waitTimeKey is also the same, sort by sortKey (string)
            if (a.sortKey < b.sortKey) {
              return -1;
            } else if (a.sortKey > b.sortKey) {
              return 1;
            }

            // If all keys are the same, no sorting is needed
            return 0;
      });

      if(numDataShown !== newReview){
        setNumDataShown(newReview);
      }
      return (
        <ListGroup className="selectedDoctorListGroup">
          {docElems.map((value: any, index: number) => {
            return value.elements;
          })}
        </ListGroup>
      );
    }
  };

  const handleSearchInputChange = (
    newValue: string,
    actionMeta: InputActionMeta
  ): void => {
    if (newValue.length >= 3) {
      setShowSearchOptions(true);
    } else {
      setShowSearchOptions(false);
    }

    let useOptions: any = props.allSearchData;

    if (actionMeta.action === "input-change") {
      if (newValue === "") {
        setUseSearchOptions(useOptions);
      } else {
        let newUseOptions: any = matchSorter<any>(useOptions, newValue, {
          keys: ["label"],
          threshold: matchSorter.rankings.WORD_STARTS_WITH,
        });
        setUseSearchOptions(newUseOptions);
      }
    } else if (actionMeta.action === "menu-close") {
      setUseSearchOptions(useOptions);
    }
  };

  const onSearchDataChange = (
    searchSelectValue: SingleValue<SearchOption>,
    actionMeta: ActionMeta<SearchOption>
  ) => {
    // clearMsgs();

    let showDoctor: any = null;
    if (searchSelectValue) {
      props.setSelectedSearchData(searchSelectValue);
      // figure out what the group data should be, by checking if it exists or fetching it
      let fetchCPSONums: string[] = [];
      searchSelectValue["doctorIds"].forEach((doctorId: string) => {
        let newShowDoctor = props.calculateSearchShowDoctor(doctorId, true);
        if (newShowDoctor == null) {
          fetchCPSONums.push(doctorId);
        } else {
          if (showDoctor == null) {
            showDoctor = newShowDoctor;
          }
        }
      });

      if (fetchCPSONums.length > 0) {
        props.setLoadingSearchData(true);
        let docCPSONums: string[] = fetchCPSONums.filter(
          (cpsoNum: string) => !cpsoNum.startsWith("service-")
        );
        let serviceCPSONums: string[] = fetchCPSONums.filter(
          (cpsoNum: string) => cpsoNum.startsWith("service-")
        );
        if (serviceCPSONums.length > 0) {
          props.dispatch(fetchIndividualCentralService(serviceCPSONums));
        }
        if (docCPSONums.length > 0) {
          props.dispatch(fetchIndiviualDoctor(docCPSONums));
        }
        props.dispatch(fetchIndiviualAdmin(fetchCPSONums));
      }

      if (showDoctor != null) {
        props.setSelectedGroupData(showDoctor);
      } else {
        props.setSelectedGroupData(null);
      }
      props.setSelectedGroupDataType("search-doctor");
    } else {
      props.setSelectedGroupData(null);
      props.setSelectedSearchData(null);
      props.setSelectedGroupDataType("");
    }
  };

  const onGroupFilterChange = (
    newFilterGroupOption: SingleValue<FilterGroupOption>,
    actionMeta: ActionMeta<FilterGroupOption>
  ) => {
    props.setSelectedGroupData(null);
    if(newFilterGroupOption){
      setSelectedGroupFilter(newFilterGroupOption);
    } else {
      setSelectedGroupFilter(undefined)
    }
  }


  const onSelectMultipleLocationSearchDoctor = (searchDoctor: any) => {
    props.setSelectedGroupDataType("search-doctor");
    props.setSelectedGroupData(searchDoctor);
  };

  const renderMultipleLocationOptions = () => {
    if (
      props.selectedSearchData != null &&
      props.selectedSearchData.doctors.length > 1
    ) {
      return (
        <ListGroup horizontal className="selectedSearchDoctorListGroup">
          {props.selectedSearchData.doctors.map((doctorOption: any) => {
            const docData = props.doctors.find(
              (doctor: any) => doctor["cpsoNum"] === doctorOption["cpsoNum"]
            );
            if (docData !== undefined) {
              let label = docData["fullName"];
              if (docData["location"] != null) {
                label += " - " + docData["location"]["city"];
              }
              return (
                <ListGroup.Item
                  action
                  variant={
                    props.selectedGroupData &&
                    props.selectedGroupData["cpsoNum"] === docData["cpsoNum"]
                      ? "primary"
                      : ""
                  }
                  eventKey={docData["cpsoNum"]}
                  key={docData["cpsoNum"]}
                  active={false}
                  onClick={() =>
                    onSelectMultipleLocationSearchDoctor(doctorOption)
                  }
                  disabled={props.isSavingGroupData}
                >
                  {label}
                </ListGroup.Item>
              );
            }
          })}
        </ListGroup>
      );
    }
  };

  const getModalTitle = () => {
    if((props.groupDataType === "event" ||
    props.groupDataType === "email" ||
    props.groupDataType === "flagged" ||
    props.groupDataType === "additionalinfo" ||
    props.groupDataType === "impadditionalinfo" ||
    props.groupDataType === "rescrape" ||
    props.groupDataType === "hidden" ||
    props.groupDataType === "meta-specialties")){
      return props.groupModalHeader + " (" + numDataShown + ")";
    } else {
      return props.groupModalHeader;
    }
  }

  return (
    <>
      <EmailTemplateModal
        showEmailTemplateModal={showEmailTemplateModal}
        setShowEmailTemplateModal={setShowEmailTemplateModal}
        groupDataType={props.groupDataType}
        selectedGroupData={props.selectedGroupData}
        selectedGroupDataType={props.selectedGroupDataType}
        procedureList={props.procedureList}
      />
      <Modal
        show={props.showGroupModal}
        onHide={props.handleCloseGroupModal}
        dialogClassName="groupModal"
        style={{
          zIndex:
            showFlagModal || showFullCardModal || showEmailTemplateModal
              ? 1050
              : 1055,
        }}
        // style={{ zIndex: showFlagModal || showScheduleModal ? 1050 : 1055 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
          {(
            props.groupDataType === "flagged" ||
            props.groupDataType === "additionalinfo" ||
            props.groupDataType === "impadditionalinfo" ||
            props.groupDataType === "reviewgroupinfo" ||
            props.groupDataType === "rescrape" ||
            props.groupDataType === "hidden" ||
            props.groupDataType === "meta-specialties"
          ) && (
          <Select<FilterGroupOption>
            options={FilterGroupOptions}
            value={selectedGroupFilter}
            className="groupFilterSelect"
            isDisabled={false}
            isClearable={true}
            onChange={onGroupFilterChange}
          />    )}
        </Modal.Header>
        <Modal.Body>
          {props.groupDataType === "search" && (
            <>
              <Select<SearchOption>
                name="doctorSearch"
                className="doctorSearch"
                isClearable={true}
                isSearchable={true}
                options={showSearchOptions ? useSearchOptions : []}
                value={props.selectedSearchData}
                onChange={onSearchDataChange}
                noOptionsMessage={() =>
                  showSearchOptions ? "No options" : null
                }
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                openMenuOnFocus={false}
                openMenuOnClick={false}
                onInputChange={handleSearchInputChange}
                placeholder={"Search for a doctor..."}
              />
              {props.loadingSearchData && <Spinner animation="grow" />}
              {renderMultipleLocationOptions()}
              <SearchCard
                groupDataType={props.groupDataType}
                selectedGroupData={props.selectedGroupData}
                isSavingGroupData={props.isSavingGroupData}
                setIsSavingGroupData={props.setIsSavingGroupData}
                dispatch={props.dispatch}
                schedules={props.schedules}
                procedureList={props.procedureList}
                admins={props.admins}
                setShowFlagModal={setShowFlagModal}
                sdMetaInfo={sdMetaInfo}
                setSDMetaInfo={setSDMetaInfo}
                sdExtraInfo={sdExtraInfo}
                setSDExtraInfo={setSDExtraInfo}
                sdImpExtraInfo={sdImpExtraInfo}
                setSDImpExtraInfo={setSDImpExtraInfo}
                eventsMap={props.eventsMap}
                setShowFullCardModal={setShowFullCardModal}
                sdProcedures={sdProcedures}
                setSDProcedures={setSDProcedures}
                disableEdits={disableEdits}
                sdWaitTimeStart={sdWaitTimeStart}
                setSDWaitTimeStart={setSDWaitTimeStart}
                sdWaitTimeEnd={sdWaitTimeEnd}
                setSDWaitTimeEnd={setSDWaitTimeEnd}
                sdWaitTimeUnit={sdWaitTimeUnit}
                setSDWaitTimeUnit={setSDWaitTimeUnit}
                actionOptions={actionOptions}
                setActionOptions={setActionOptions}
                actionValue={actionValue}
                setActionValue={setActionValue}
                doctors={props.doctors}
                selectedGroupDataType={props.selectedGroupDataType}
                sdMetaSpecialties={sdMetaSpecialties}
                setSDMetaSpecialties={setSDMetaSpecialties}
                adminMode={props.adminMode}
                sdWaitTimeMsg={sdWaitTimeMsg}
                setSDWaitTimeMsg={setSDWaitTimeMsg}
                sdFinalExtraInfo={sdFinalExtraInfo}
                setSDFinalExtraInfo={setSDFinalExtraInfo}
                sdCustomForm={sdCustomForm}
                setSDCustomForm={setSDCustomForm}
                allProcedureOptions={props.allProcedureOptions}
                sdSearchSpecialties={sdSearchSpecialties}
                setSDSearchSpecialties={setSDSearchSpecialties}
                allSpecialityOptions={props.allSpecialityOptions}
                sdPhone={sdPhone}
                setSDPhone={setSDPhone}
                sdPhoneExt={sdPhoneExt}
                setSDPhoneExt={setSDPhoneExt}
                sdFax={sdFax}
                setSDFax={setSDFax}
                sdFaxExt={sdFaxExt}
                setSDFaxExt={setSDFaxExt}
                editPhoneFax={editPhoneFax}
                setEditPhoneFax={setEditPhoneFax}
              />
            </>
          )}
          {(props.groupDataType === "event" ||
            props.groupDataType === "email" ||
            props.groupDataType === "flagged" ||
            props.groupDataType === "additionalinfo" ||
            props.groupDataType === "impadditionalinfo" ||
            props.groupDataType === "reviewgroupinfo" ||
            props.groupDataType === "rescrape" ||
            props.groupDataType === "hidden" ||
            props.groupDataType === "meta-specialties") && (
            <Tab.Container>
              <Row>
                <Col sm={5}>{renderAllGroupData()}</Col>
                <Col sm={7}>
                  {props.selectedGroupData &&
                    (props.selectedGroupDataType === "doctor" ||
                      props.selectedGroupDataType === "email-doctor" ||
                      props.selectedGroupDataType === "flagged-doctor" ||
                      props.selectedGroupDataType === "additionalinfo-doctor" ||
                      props.selectedGroupDataType ===
                        "impadditionalinfo-doctor" ||
                      props.selectedGroupDataType ===
                        "reviewgroupinfo-doctor" ||
                      props.selectedGroupDataType === "rescrape-doctor" ||
                      props.selectedGroupDataType === "hidden-doctor" ||
                      props.selectedGroupDataType ===
                        "meta-specialties-doctor") && (
                      <SingleCard
                        groupDataType={props.groupDataType}
                        selectedGroupData={props.selectedGroupData}
                        isSavingGroupData={props.isSavingGroupData}
                        setIsSavingGroupData={props.setIsSavingGroupData}
                        dispatch={props.dispatch}
                        schedules={props.schedules}
                        procedureList={props.procedureList}
                        admins={props.admins}
                        setShowFlagModal={setShowFlagModal}
                        sdMetaInfo={sdMetaInfo}
                        setSDMetaInfo={setSDMetaInfo}
                        sdExtraInfo={sdExtraInfo}
                        setSDExtraInfo={setSDExtraInfo}
                        sdImpExtraInfo={sdImpExtraInfo}
                        setSDImpExtraInfo={setSDImpExtraInfo}
                        eventsMap={props.eventsMap}
                        setShowFullCardModal={setShowFullCardModal}
                        sdProcedures={sdProcedures}
                        setSDProcedures={setSDProcedures}
                        disableEdits={disableEdits}
                        sdWaitTimeStart={sdWaitTimeStart}
                        setSDWaitTimeStart={setSDWaitTimeStart}
                        sdWaitTimeEnd={sdWaitTimeEnd}
                        setSDWaitTimeEnd={setSDWaitTimeEnd}
                        sdWaitTimeUnit={sdWaitTimeUnit}
                        setSDWaitTimeUnit={setSDWaitTimeUnit}
                        actionOptions={actionOptions}
                        setActionOptions={setActionOptions}
                        actionValue={actionValue}
                        setActionValue={setActionValue}
                        selectedGroupDataType={props.selectedGroupDataType}
                        sdMetaSpecialties={sdMetaSpecialties}
                        setSDMetaSpecialties={setSDMetaSpecialties}
                        adminMode={props.adminMode}
                        sdWaitTimeMsg={sdWaitTimeMsg}
                        setSDWaitTimeMsg={setSDWaitTimeMsg}
                        sdFinalExtraInfo={sdFinalExtraInfo}
                        setSDFinalExtraInfo={setSDFinalExtraInfo}
                        sdCustomForm={sdCustomForm}
                        setSDCustomForm={setSDCustomForm}
                        allProcedureOptions={props.allProcedureOptions}
                        setShowEmailTemplateModal={setShowEmailTemplateModal}
                        sdSearchSpecialties={sdSearchSpecialties}
                        setSDSearchSpecialties={setSDSearchSpecialties}
                        allSpecialityOptions={props.allSpecialityOptions}
                        sdPhone={sdPhone}
                        setSDPhone={setSDPhone}
                        sdPhoneExt={sdPhoneExt}
                        setSDPhoneExt={setSDPhoneExt}
                        sdFax={sdFax}
                        setSDFax={setSDFax}
                        sdFaxExt={sdFaxExt}
                        setSDFaxExt={setSDFaxExt}
                        editPhoneFax={editPhoneFax}
                        setEditPhoneFax={setEditPhoneFax}
                      />
                    )}
                  {props.selectedGroupData &&
                    props.selectedGroupDataType === "doctor-group" && (
                      <GroupCard
                        groupDataType={props.groupDataType}
                        selectedGroupData={props.selectedGroupData}
                        isSavingGroupData={props.isSavingGroupData}
                        setIsSavingGroupData={props.setIsSavingGroupData}
                        dispatch={props.dispatch}
                        groupInfo={props.groupInfo}
                        adminMode={props.adminMode}
                      />
                    )}
                </Col>
              </Row>
            </Tab.Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleCloseGroupModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <FlagModal
        showFlagModal={showFlagModal}
        setShowFlagModal={setShowFlagModal}
        groupDataType={props.groupDataType}
        selectedGroupData={props.selectedGroupData}
        setIsSavingGroupData={props.setIsSavingGroupData}
        sdMetaInfo={sdMetaInfo}
        sdExtraInfo={sdExtraInfo}
        sdImpExtraInfo={sdImpExtraInfo}
        sdCustomForm={sdCustomForm}
        dispatch={props.dispatch}
        sdProcedures={sdProcedures}
        admins={props.admins}
        schedules={props.schedules}
        sdPhone={sdPhone}
        sdPhoneExt={sdPhoneExt}
        sdFax={sdFax}
        sdFaxExt={sdFaxExt}
      />
      <FullCard
        showFullCardModal={showFullCardModal}
        setShowFullCardModal={setShowFullCardModal}
        groupDataType={props.groupDataType}
        selectedGroupData={props.selectedGroupData}
        selectedGroupDataType={props.selectedGroupDataType}
        sdProcedures={sdProcedures}
        setSDProcedures={setSDProcedures}
        disableEdits={disableEdits}
        procedureList={props.procedureList}
        sdMetaInfo={sdMetaInfo}
        setSDMetaInfo={setSDMetaInfo}
        sdExtraInfo={sdExtraInfo}
        setSDExtraInfo={setSDExtraInfo}
        sdImpExtraInfo={sdImpExtraInfo}
        setSDImpExtraInfo={setSDImpExtraInfo}
        sdWaitTimeStart={sdWaitTimeStart}
        setSDWaitTimeStart={setSDWaitTimeStart}
        sdWaitTimeEnd={sdWaitTimeEnd}
        setSDWaitTimeEnd={setSDWaitTimeEnd}
        sdWaitTimeUnit={sdWaitTimeUnit}
        setSDWaitTimeUnit={setSDWaitTimeUnit}
        sdAvailability={sdAvailability}
        schedules={props.schedules}
        showAvailabilityModal={showAvailabilityModal}
        setShowAvailabilityModal={setShowAvailabilityModal}
        adminMode={props.adminMode}
        doctors={props.doctors}
        allProcedureOptions={props.allProcedureOptions}
        sdSearchSpecialties={sdSearchSpecialties}
        setSDSearchSpecialties={setSDSearchSpecialties}
        allSpecialityOptions={props.allSpecialityOptions}
        centralServices={props.centralServices}
        sdPhone={sdPhone}
        setSDPhone={setSDPhone}
        sdPhoneExt={sdPhoneExt}
        setSDPhoneExt={setSDPhoneExt}
        sdFax={sdFax}
        setSDFax={setSDFax}
        sdFaxExt={sdFaxExt}
        setSDFaxExt={setSDFaxExt}
        editPhoneFax={editPhoneFax}
        setEditPhoneFax={setEditPhoneFax}
      />
      <AvailabilityForm
        showAvailabilityModal={showAvailabilityModal}
        setShowAvailabilityModal={setShowAvailabilityModal}
        sdAvailability={sdAvailability}
        groupDataType={props.groupDataType}
        selectedGroupData={props.selectedGroupData}
        dispatch={props.dispatch}
      />
    </>
  );
}

export default GroupModal;
