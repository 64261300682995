import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup, FormControl } from "react-bootstrap";

import "../styles/CustomFormForm.css";

interface CustomFormFormProps {
  customFormValue: any;
  setCustomFormValue: any;
  disable: any;
  selectedGroupData: any;
  clearMsgs: any;
}

function CustomFormForm(props: CustomFormFormProps) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.clearMsgs();
    props.setCustomFormValue(event.currentTarget.value);
  };

  return (
    <Col>
      <Form.Label htmlFor="extra" className="formLabel">
        Custom Form (link only)
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl
          id="extraInfo"
          value={props.customFormValue == null ? "" : props.customFormValue}
          onChange={onChange}
          as="textarea"
          rows={1}
          disabled={props.disable()}
        />
      </InputGroup>
    </Col>
  );
}

export default CustomFormForm;
