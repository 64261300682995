import {
  collection,
  getDocs,
  doc,
  updateDoc,
  query,
  where,
  getDoc
} from "firebase/firestore";

import {
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";

import {
  db
} from "../firebase.config";

import {
  retireDoctorAction,
  flagAction,
  faxAction,
  emailAction,
  rescheduleAction,
  completeAction,
  updateAvailabilityAction,
  saveAction,
  removeAction,
  saveAdminAction,
  reviewedAction,
  rescrapeAction,
  hideDoctorAction,
} from "./actions";

const initialState = {
  doctors: [],
  doctorsMap: new Map(),
  status: "idle",
  error: null,
};

export const fetchDoctors = createAsyncThunk(
  "doctors/fetchDoctors",
  async (dataType: number) => {
    console.log("Loading doc data");
    const q = query(collection(db, "doctor-profiles"), where("adminLoad", "array-contains", dataType));
    const querySnapshot = await getDocs(q);

    const newDocData: any[] = [];

    querySnapshot.forEach((docObj) => {
      let newDoc = docObj.data();
      newDoc["isService"] = false;
      newDocData.push(newDoc);
    });

    console.log("Loaded doc data");
    return newDocData;
  }
);

export const fetchIndiviualDoctor = createAsyncThunk(
  "doctors/fetchIndiviualDoctor",
  async (cpsoNums: string[]) => {
    console.log("Loading individual doc");
    console.log(cpsoNums);

    let allDocData = [];

    for (const cpsoNum of cpsoNums) {

      const docRef = doc(db, "doctor-profiles", cpsoNum);
      const docSnap = await getDoc(docRef);

      let newDocData: any = null;

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        newDocData = docSnap.data();
        newDocData["isService"] = false;

        allDocData.push(newDocData);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }

    }

    console.log("Loaded individual doc");
    return {
      "doctors": allDocData
    };
  }
);

export const doctorsSlice = createSlice({
  name: "doctors",
  initialState,
  reducers: {
    computeMap: (state, {
      paylod
    }) => {
      state.doctorsMap = new Map();
      state.doctors.forEach((doctor: any) => {
        state.doctorsMap.set(doctor["cpsoNum"], doctor);
      });
    },
    setUsual: (state, {
      payload
    }) => {
      const existingDoctor = state.doctors.find(doctor => doctor["cpsoNum"] === payload["cpsoNum"])
      existingDoctor["customForm"] = payload["customForm"];
      existingDoctor["procedures"] = payload["procedures"];
      existingDoctor["phone"] = payload["phone"];
      existingDoctor["phoneExt"] = payload["phoneExt"];
      existingDoctor["fax"] = payload["fax"];
      existingDoctor["faxExt"] = payload["faxExt"];
    },
    setWaitTimeAndProcedures: (state, {
      payload
    }) => {
      const existingDoctor = state.doctors.find(doctor => doctor["cpsoNum"] === payload["cpsoNum"])
      existingDoctor["waitTimeMsg"] = payload["waitTimeMsg"];
      existingDoctor["waitTimeStart"] = payload["waitTimeStart"];
      existingDoctor["waitTimeEnd"] = payload["waitTimeEnd"];
      existingDoctor["waitTimeUnit"] = payload["waitTimeUnit"];
      existingDoctor["hideCard"] = false;
    },
    setReviewInfo: (state, {
      payload
    }) => {
      const existingDoctor = state.doctors.find(doctor => doctor["cpsoNum"] === payload["cpsoNum"])
      existingDoctor["additionalInfo"] = payload["finalAdditionalInfo"];
      existingDoctor["metaSpecialties"] = payload["metaSpecialties"];
      existingDoctor["searchSpecialties"] = payload["searchSpecialties"];
    },
    setHideDoctor: (state, {
      payload
    }) => {
      const existingDoctor = state.doctors.find(doctor => doctor["cpsoNum"] === payload["cpsoNum"])
      existingDoctor["hideCard"] = payload["hideCard"];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDoctors.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchDoctors.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        state.doctors = state.doctors.concat(action.payload);
        doctorsSlice.caseReducers.computeMap(state, action);
        state.status = "loaded";
      })
      .addCase(fetchDoctors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchIndiviualDoctor.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(fetchIndiviualDoctor.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        // filter out if already exists
        action.payload["doctors"].forEach((actionDoctor: any) => {
          state.doctors = state.doctors.filter(doctor => doctor["cpsoNum"] !== actionDoctor["cpsoNum"]);
          state.doctors.push(actionDoctor);
        });
        doctorsSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(fetchIndiviualDoctor.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(retireDoctorAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(flagAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(faxAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(rescheduleAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(completeAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(updateAvailabilityAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(saveAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(removeAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(saveAdminAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(reviewedAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(rescrapeAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(emailAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(hideDoctorAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(retireDoctorAction.fulfilled, (state, action) => {
        if (!action.payload["isService"]) {
          doctorsSlice.caseReducers.setWaitTimeAndProcedures(state, {
            payload: {
              cpsoNum: action.payload["cpsoNum"],
              waitTimeMsg: action.payload["waitTimeMsg"],
              waitTimeStart: null,
              waitTimeEnd: null,
              waitTimeUnit: null,
              procedures: action.payload["procedures"],
            }
          });
          doctorsSlice.caseReducers.setUsual(state, action);
          doctorsSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(flagAction.fulfilled, (state, action) => {
        if (!action.payload["isService"]) {
          doctorsSlice.caseReducers.setUsual(state, action);
          doctorsSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(faxAction.fulfilled, (state, action) => {
        if (!action.payload["isService"]) {
          doctorsSlice.caseReducers.setUsual(state, action);
          doctorsSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(rescheduleAction.fulfilled, (state, action) => {
        if (!action.payload["isService"]) {
          doctorsSlice.caseReducers.setUsual(state, action);
          doctorsSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(completeAction.fulfilled, (state, action) => {
        if (!action.payload["isService"]) {
          doctorsSlice.caseReducers.setWaitTimeAndProcedures(state, action);
          doctorsSlice.caseReducers.setUsual(state, action);
          doctorsSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(updateAvailabilityAction.fulfilled, (state, action) => {
        state.status = "updated";
      })
      .addCase(saveAction.fulfilled, (state, action) => {
        if (!action.payload["isService"]) {
          doctorsSlice.caseReducers.setUsual(state, action);
          doctorsSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(removeAction.fulfilled, (state, action) => {
        if (!action.payload["isService"]) {
          doctorsSlice.caseReducers.setUsual(state, action);
          doctorsSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(saveAdminAction.fulfilled, (state, action) => {
        if (!action.payload["isService"]) {
          doctorsSlice.caseReducers.setReviewInfo(state, action);
          doctorsSlice.caseReducers.setUsual(state, action);
          doctorsSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(reviewedAction.fulfilled, (state, action) => {
        if (!action.payload["isService"]) {
          doctorsSlice.caseReducers.setReviewInfo(state, action);
          doctorsSlice.caseReducers.setUsual(state, action);
          doctorsSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(rescrapeAction.fulfilled, (state, action) => {
        state.status = "updated";
      })
      .addCase(emailAction.fulfilled, (state, action) => {
        if (!action.payload["isService"]) {
          doctorsSlice.caseReducers.setUsual(state, action);
          doctorsSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(hideDoctorAction.fulfilled, (state, action) => {
        if (!action.payload["isService"]) {
          doctorsSlice.caseReducers.setHideDoctor(state, action);
          doctorsSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      });
  },
});

export default doctorsSlice.reducer;

export const selectAllDoctors = state => state.doctors.doctors;
export const selectAllDoctorsMap = state => state.doctors.doctorsMap;