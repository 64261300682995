import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  Spinner,
  FormControl,
  Container,
  Modal,
} from "react-bootstrap";
import moment from "moment";

import {
  renderFullName,
  renderPhone,
  renderGender,
  renderSpeciality,
  renderLanguages,
  renderCPSONum,
  renderAddress,
  renderFax,
} from "./FormElements";

import { getPhoneNumber, getCallNumber } from "../utils";

import ProceduresForm from "./ProceduresForm";
import MetaTagsForm from "./MetaTagsForm";
import AdditionalInfoForm from "./AdditionalInfoForm";
import WaitTimeForm from "./WaitTimeForm";

import "../styles/FullCard.css";
import PhoneForm from "./PhoneForm";

interface FullCardProps {
  showFullCardModal: any;
  setShowFullCardModal: any;
  groupDataType: any;
  selectedGroupData: any;
  selectedGroupDataType: any;
  sdProcedures: any;
  setSDProcedures: any;
  disableEdits: any;
  procedureList: any;
  sdMetaInfo: any;
  setSDMetaInfo: any;
  sdExtraInfo: any;
  setSDExtraInfo: any;
  sdImpExtraInfo: any;
  setSDImpExtraInfo: any;
  sdWaitTimeStart: any;
  setSDWaitTimeStart: any;
  sdWaitTimeEnd: any;
  setSDWaitTimeEnd: any;
  sdWaitTimeUnit: any;
  setSDWaitTimeUnit: any;
  sdAvailability: any;
  schedules: any;
  showAvailabilityModal: any;
  setShowAvailabilityModal: any;
  adminMode: any;
  doctors: any;
  allProcedureOptions: any;
  sdSearchSpecialties: any;
  setSDSearchSpecialties: any;
  allSpecialityOptions: any;
  centralServices: any;
  sdPhone: any;
  setSDPhone: any;
  sdPhoneExt: any;
  setSDPhoneExt: any;
  sdFax: any;
  setSDFax: any;
  sdFaxExt: any;
  setSDFaxExt: any;
  editPhoneFax: any;
  setEditPhoneFax: any;
}

function FullCard(props: FullCardProps) {
  const [fullCardSuccessMsg, setFullCardSuccessMsg] = useState<string>("");
  const [fullCardErrorMsg, setFullCardErrorMsg] = useState<string>("");

  const clearMsgs = () => {
    setFullCardSuccessMsg("");
    setFullCardErrorMsg("");
  };

  const handleCloseFullCardModal = () => props.setShowFullCardModal(false);

  const renderAvailTimeSlots = (timeSlots: any) => {
    let timeSlotInfo = "";
    timeSlots.forEach((timeSlot: any, index: number) => {
      if (index > 0) {
        timeSlotInfo += ", ";
      }
      let startTimeStr = moment(timeSlot["startTime"], ["HH:mm"]).format(
        "hh:mm A"
      );
      let endTimeStr = moment(timeSlot["endTime"], ["HH:mm"]).format("hh:mm A");
      timeSlotInfo += startTimeStr + " - " + endTimeStr;
    });
    return <p>{timeSlotInfo}</p>;
  };

  const renderAvailability = () => {
    if (Object.keys(props.sdAvailability).length > 0) {
      return (
        <Col>
          <Form.Label htmlFor="procedures" className="formLabel">
            Availability
          </Form.Label>
          <Button
            variant="outline-primary"
            onClick={() => props.setShowAvailabilityModal(true)}
            style={{ float: "right" }}
            disabled={props.disableEdits()}
          >
            Edit
          </Button>
          <div style={{ display: "flex" }}>
            <Form.Check
              id="mon"
              type="switch"
              label="Mon"
              style={{ marginRight: "10px" }}
              checked={props.sdAvailability["mon"].length > 0}
            />
            {renderAvailTimeSlots(props.sdAvailability["mon"])}
          </div>
          <div style={{ display: "flex" }}>
            <Form.Check
              id="tue"
              type="switch"
              label="Tue"
              style={{ marginRight: "10px" }}
              checked={props.sdAvailability["tue"].length > 0}
            />
            {renderAvailTimeSlots(props.sdAvailability["tue"])}
          </div>
          <div style={{ display: "flex" }}>
            <Form.Check
              id="wed"
              type="switch"
              label="Wed"
              style={{ marginRight: "10px" }}
              checked={props.sdAvailability["wed"].length > 0}
            />
            {renderAvailTimeSlots(props.sdAvailability["wed"])}
          </div>
          <div style={{ display: "flex" }}>
            <Form.Check
              id="thu"
              type="switch"
              label="Thu"
              style={{ marginRight: "10px" }}
              checked={props.sdAvailability["thu"].length > 0}
            />
            {renderAvailTimeSlots(props.sdAvailability["thu"])}
          </div>
          <div style={{ display: "flex" }}>
            <Form.Check
              id="fri"
              type="switch"
              label="Fri"
              style={{ marginRight: "10px" }}
              checked={props.sdAvailability["fri"].length > 0}
            />
            {renderAvailTimeSlots(props.sdAvailability["fri"])}
          </div>
          <div style={{ display: "flex" }}>
            <Form.Check
              id="sat"
              type="switch"
              label="Sat"
              style={{ marginRight: "10px" }}
              checked={props.sdAvailability["sat"].length > 0}
            />
            {renderAvailTimeSlots(props.sdAvailability["sat"])}
          </div>
          <div style={{ display: "flex" }}>
            <Form.Check
              id="sun"
              type="switch"
              label="Sun"
              style={{ marginRight: "10px" }}
              checked={props.sdAvailability["sun"].length > 0}
            />
            {renderAvailTimeSlots(props.sdAvailability["sun"])}
          </div>
        </Col>
      );
    }
  };

  const renderSelectedFullData = () => {
    if (
      (props.selectedGroupDataType === "doctor" ||
        props.selectedGroupDataType === "flagged-doctor" ||
        props.selectedGroupDataType === "additionalinfo-doctor" ||
        props.selectedGroupDataType === "impadditionalinfo-doctor" ||
        props.selectedGroupDataType === "reviewgroupinfo-doctor" ||
        props.selectedGroupDataType === "search-doctor" ||
        props.selectedGroupDataType === "rescrape-doctor" ||
        props.selectedGroupDataType === "hidden-doctor" ||
        props.selectedGroupDataType === "meta-specialties-doctor") &&
      props.selectedGroupData != null
    ) {
      let selectedDoctor: any = null;
      let selectedDoctorSchedule: any = null;
      if (props.selectedGroupDataType === "search-doctor") {
        if (props.selectedGroupData["cpsoNum"].startsWith("service-")) {
          selectedDoctor = props.centralServices.find(
            (doctor: any) =>
              doctor["cpsoNum"] === props.selectedGroupData["cpsoNum"]
          );
        } else {
          selectedDoctor = props.doctors.find(
            (doctor: any) =>
              doctor["cpsoNum"] === props.selectedGroupData["cpsoNum"]
          );
        }
        selectedDoctorSchedule = props.schedules.find(
          (schedule: any) =>
            schedule["scheduleId"] === props.selectedGroupData["scheduleId"]
        );
      } else {
        selectedDoctor = props.selectedGroupData;
        selectedDoctorSchedule = props.schedules.find(
          (schedule: any) =>
            schedule["scheduleId"] === selectedDoctor["scheduleId"]
        );
      }

      const allEdits =
        props.adminMode &&
        (props.selectedGroupDataType === "search-doctor" ||
          props.selectedGroupDataType === "additionalinfo-doctor" ||
          props.selectedGroupDataType === "impadditionalinfo-doctor" ||
          props.selectedGroupDataType === "reviewgroupinfo-doctor");

      const disableWaitTimeEdits =
        props.adminMode &&
        (props.selectedGroupDataType === "additionalinfo-doctor" ||
          props.selectedGroupDataType === "impadditionalinfo-doctor" ||
          props.selectedGroupDataType === "reviewgroupinfo-doctor" ||
          props.selectedGroupDataType === "search-doctor");

      return (
        <Container>
          <Row>
            {renderFullName(selectedDoctor["fullName"])}
            {renderGender(selectedDoctor["gender"])}
            {renderLanguages(selectedDoctor["languages"])}
            {renderCPSONum(selectedDoctor["cpsoNum"])}
          </Row>
          <Row>
            {renderSpeciality(selectedDoctor["specialties"], true)}
            <ProceduresForm
              procedureValue={props.sdProcedures}
              setProcedureValue={props.setSDProcedures}
              disable={props.disableEdits}
              hasInternalMedicine={selectedDoctor["specialties"].includes(
                "Internal Medicine"
              )}
              selectedSpecialties={
                selectedDoctor
                  ? selectedDoctor["specialties"].concat(
                      selectedDoctor["searchSpecialties"]
                    )
                  : null
              }
              procedureList={props.procedureList}
              clearMsgs={clearMsgs}
              allProcedureOptions={props.allProcedureOptions}
              adminMode={props.adminMode}
            />
          </Row>
          <Row>
            {renderAddress(selectedDoctor["location"])}
            <Col>
              <Row>
                {renderPhone(
                  getPhoneNumber(props.sdPhone, props.sdPhoneExt),
                  getCallNumber(selectedDoctorSchedule),
                  selectedDoctor["location"] !== null &&
                    selectedDoctor["location"] !== undefined
                    ? selectedDoctor["location"]["city"]
                    : "",
                  true,
                  props.editPhoneFax,
                  props.setEditPhoneFax,
                  props.disableEdits
                )}
                {renderFax(getPhoneNumber(props.sdFax, props.sdFaxExt), props.editPhoneFax, props.setEditPhoneFax, props.disableEdits)}
              </Row>
              {props.editPhoneFax && <Row>
                <PhoneForm
                  phoneLabel="Phone"
                  phoneStr={props.sdPhone}
                  setPhoneStr={props.setSDPhone}
                  phoneExtStr={props.sdPhoneExt}
                  setPhoneExtStr={props.setSDPhoneExt}
                  disabled={props.disableEdits()}
                />
                <PhoneForm
                  phoneLabel="Fax"
                  phoneStr={props.sdFax}
                  setPhoneStr={props.setSDFax}
                  phoneExtStr={props.sdFaxExt}
                  setPhoneExtStr={props.setSDFaxExt}
                  disabled={props.disableEdits()}
                />
          </Row>}
              <Row>
                <MetaTagsForm
                  metaTagsValue={props.sdMetaInfo}
                  setMetaTagsValue={props.setSDMetaInfo}
                  selectedGroupData={props.selectedGroupData}
                  clearMsgs={clearMsgs}
                  sdExtraInfo={props.sdExtraInfo}
                  setSDExtraInfo={props.setSDExtraInfo}
                  disable={() => disableWaitTimeEdits || props.disableEdits()}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <WaitTimeForm
                  waitTimeStartValue={props.sdWaitTimeStart}
                  setWaitTimeStartValue={props.setSDWaitTimeStart}
                  waitTimeEndValue={props.sdWaitTimeEnd}
                  setWaitTimeEndValue={props.setSDWaitTimeEnd}
                  waitTimeUnitValue={props.sdWaitTimeUnit}
                  setWaitTimeUnitValue={props.setSDWaitTimeUnit}
                  disable={() => disableWaitTimeEdits || props.disableEdits()}
                  selectedGroupData={props.selectedGroupData}
                  clearMsgs={clearMsgs}
                  isAdminMode={props.adminMode}
                />
              </Row>
              <Row>
                <AdditionalInfoForm
                  extraInfoValue={props.sdImpExtraInfo}
                  setExtraInfoValue={props.setSDImpExtraInfo}
                  disable={props.adminMode ? () => true : props.disableEdits}
                  selectedGroupData={props.selectedGroupData}
                  clearMsgs={clearMsgs}
                  headerLabel={"Important Additional Information"}
                />
              </Row>
              <Row>
                <AdditionalInfoForm
                  extraInfoValue={props.sdExtraInfo}
                  setExtraInfoValue={props.setSDExtraInfo}
                  disable={
                    props.adminMode ? () => true : () => props.disableEdits()
                  }
                  selectedGroupData={props.selectedGroupData}
                  clearMsgs={clearMsgs}
                  headerLabel={"Other Additional Information"}
                />
              </Row>
            </Col>
            {renderAvailability()}
          </Row>
          <Row>
            <Col className="previewButtonGroup">
              <p className="successMsg">{fullCardSuccessMsg}</p>
              <p className="errorMsg">{fullCardErrorMsg}</p>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return null;
    }
  };

  return (
    <Modal
      show={props.showFullCardModal}
      onHide={handleCloseFullCardModal}
      dialogClassName="fullCardModal"
      style={{ zIndex: props.showAvailabilityModal ? 1050 : 1055 }}
    >
      <Modal.Header closeButton>
        {props.selectedGroupData && (
          <Modal.Title>{props.selectedGroupData["fullName"]}</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>{renderSelectedFullData()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseFullCardModal}>
          Close
        </Button>
        {/* <Button variant="primary" onClick={handleCloseFullCardModal}>
          Save
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default FullCard;
