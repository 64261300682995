import { Form, InputGroup, FormControl, Row, Col, Button } from "react-bootstrap";

import "../styles/FormElements.css";

export const renderLastCallDate = (lastCallDate: any) => {
  return (
    <Col>
      <Form.Label
        htmlFor="lastCallDate"
        className="formLabel"
        style={{ color: "#EC7505" }}
      >
        Last Wait Time Recorded
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl
          id="lastCallDate"
          value={lastCallDate.toString()}
          disabled={true}
        />
      </InputGroup>
    </Col>
  );
};

export const renderNextCallDate = (nextCallDate: any) => {
  return (
    <Col>
      <Form.Label
        htmlFor="nextCallDate"
        className="formLabel"
        style={{ color: "#2b9348" }}
      >
        Next Call Date
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl
          id="nextCallDate"
          value={nextCallDate.toString()}
          disabled={true}
        />
      </InputGroup>
    </Col>
  );
};

export const renderFaxDate = (faxDate: any) => {
  return (
    <Col>
      <Form.Label
        htmlFor="faxDate"
        className="formLabel"
        style={{ color: "blue" }}
      >
        Fax Sent Date
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl id="faxDate" value={faxDate.toString()} disabled={true} />
      </InputGroup>
    </Col>
  );
};

export const renderFlagMessage = (flagMsg: any) => {
  return (
    <Col>
      <Form.Label htmlFor="flaggedMsg" className="flagMsg">
        Flag Message
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl
          id="flaggedMsg"
          value={flagMsg}
          disabled={true}
          as="textarea"
        />
      </InputGroup>
    </Col>
  );
};

export const renderRescrapeDate = (rescrapeDate: any) => {
  return (
    <Col>
      <Form.Label htmlFor="flaggedMsg" className="flagMsg">
        Set to Rescrape On
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl id="flaggedMsg" value={rescrapeDate} disabled={true} />
      </InputGroup>
    </Col>
  );
};

export const renderFullName = (fullName: string) => {
  return (
    <Col>
      <Form.Label htmlFor="fullName" className="formLabel">
        Full Name
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl id="fullName" value={fullName} disabled={true} />
      </InputGroup>
    </Col>
  );
};

export const renderGender = (genderValue: string) => {
  return (
    <Col>
      <Form.Label htmlFor="gender" className="formLabel">
        Gender
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl id="gender" value={genderValue} disabled={true} />
      </InputGroup>
    </Col>
  );
};

export const renderLanguages = (allLanguages: string[]) => {
  return (
    <Col>
      <Form.Label htmlFor="languages" className="formLabel">
        Languages
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl
          id="languages"
          value={allLanguages !== undefined ? allLanguages.join(", ") : ""}
          disabled={true}
        />
      </InputGroup>
    </Col>
  );
};

export const renderCPSONum = (cpsoNum: string) => {
  return (
    <Col>
      <Form.Label htmlFor="cpsoNum" className="formLabel">
        CPSO #
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl id="cpsoNum" value={cpsoNum} disabled={true} />
      </InputGroup>
    </Col>
  );
};

export const renderPhone = (
  phone: string,
  callNumber: number,
  city: string,
  disabled: boolean,
  editOn: boolean,
  setEditOn: any,
  disable: any
) => {
  const onEditClicked = () => {
    setEditOn(!editOn);
  }

  return (
    <Col>
      <Form.Label htmlFor="phone" className="formLabel">
        Phone {callNumber >= 0 && `(call #${callNumber})`} - [{city}]
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl id="phone" value={phone} disabled={disabled} />
        <Button variant="outline-secondary" id="button-addon2" onClick={onEditClicked} disabled={disable()}>
          {!editOn ? "Edit" : "Close Edit"}
        </Button>
      </InputGroup>
    </Col>
  );
};

export const renderSpeciality = (specialties: any, disabled: boolean) => {
  return (
    <Col>
      <Form.Label htmlFor="speciality" className="formLabel">
        Speciality
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl
          id="speciality"
          value={specialties !== undefined ? specialties.join(", ") : ""}
          disabled={disabled}
        />
      </InputGroup>
    </Col>
  );
};

export const renderAddress = (location: any) => {
  if (location !== undefined) {
    return (
      <Col>
        <Form.Label htmlFor="location0Line" className="formLabel">
          Address
        </Form.Label>
        <InputGroup className="mb-3">
          <FormControl
            id="location0Line"
            value={location["0Line"]}
            disabled={true}
          />
        </InputGroup>
        {location["1Line"] && (
          <InputGroup className="mb-3">
            <FormControl
              id="location1Line"
              value={location["1Line"]}
              disabled={true}
            />
          </InputGroup>
        )}
        {location["2Line"] && (
          <InputGroup className="mb-3">
            <FormControl
              id="location2Line"
              value={location["2Line"]}
              disabled={true}
            />
          </InputGroup>
        )}
        {location["3Line"] && (
          <InputGroup className="mb-3">
            <FormControl
              id="location3Line"
              value={location["3Line"]}
              disabled={true}
            />
          </InputGroup>
        )}
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <FormControl id="city" value={location["city"]} disabled={true} />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup className="mb-3">
              <FormControl
                id="province"
                value={location["province"]}
                disabled={true}
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup className="mb-3">
              <FormControl
                id="postalCode"
                value={location["postalCode"]}
                disabled={true}
              />
            </InputGroup>
          </Col>
        </Row>
      </Col>
    );
  }
};

export const renderFax = (fax: string, editOn: boolean, setEditOn: any, disable: any) => {
  const onEditClicked = () => {
    setEditOn(!editOn);
  }

  return (
    <Col>
      <Form.Label htmlFor="fax" className="formLabel">
        Fax
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl id="fax" value={fax} disabled={true} />
        <Button variant="outline-secondary" id="button-addon2" onClick={onEditClicked} disabled={disable()}>
          {!editOn ? "Edit" : "Close Edit"}
        </Button>
      </InputGroup>
    </Col>
  );
};
