import React, { useState, useEffect } from "react";
import {
  Spinner,
  Badge,
  Nav,
  ListGroup,
  Row,
  Col,
  Form,
  FormControl,
  InputGroup,
  Button,
} from "react-bootstrap";
import Select, { SingleValue, ActionMeta } from "react-select";

import moment from "moment";

import { AiOutlineCheckCircle } from "react-icons/ai";

import {
  selectAllRequests,
  fetchRequests,
  selectAllRequestsMap,
  selectAllTrialRequests,
  selectAllDataRequests,
} from "./app/requestsSlice";
import {
  markRequestReviewAction,
  markTrialRequestReviewAction,
} from "./app/actions";

import { useAppDispatch, useAppSelector } from "./app/store";

import "./RequestsDashboard.css";
import StandardTextInput from "./components/StandardTextInput";
import { TrialUserTypeOptions } from "./config";
import { TrialUserTypeOption } from "./interfaces";

interface RequestsDashboardProps {
  mode: string;
}

const REQUEST_TYPES = [
  {
    key: "contact",
    label: "Contact Form",
  },
  {
    key: "trial",
    label: "Trial Requests",
  },
  {
    key: "data",
    label: "Data (SEO) Requests",
  },
  {
    key: "demo",
    label: "Demo Form",
  },
  {
    key: "signup",
    label: "Sign Up Form",
  },
];

function RequestsDashboard(props: RequestsDashboardProps) {
  const now = new Date();
  const dispatch = useAppDispatch();

  // Raw data from DB
  const requests = useAppSelector(selectAllRequests);
  const trialRequests = useAppSelector(selectAllTrialRequests);
  const dataRequests = useAppSelector(selectAllDataRequests);
  const requestsMap = useAppSelector(selectAllRequestsMap);
  const requestsStatus = useAppSelector((state: any) => state.requests.status);

  // Local state
  const [loading, setLoading] = useState<boolean>(true);
  const [adminMode, setAdminMode] = useState<boolean>(() => {
    return props.mode === "admin";
  });
  const [isSavingData, setIsSavingData] = useState<boolean>(false);
  const [selectedRequestType, setSelectedRequestType] = useState<string | null>(
    REQUEST_TYPES[0].key
  );
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [selectedTrialUserType, setSelectedTrialUserType] = useState<
    TrialUserTypeOption | undefined
  >(undefined);
  const [allRequests, setAllRequests] = useState<any>(null);
  const [allTrialRequests, setAllTrialRequests] = useState<any>(null);
  const [allDataRequests, setAllDataRequests] = useState<any>(null);
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [errorMsg, ErrorMsg] = useState<string>("");

  const onTrialUserTypeChange = (
    trialUserTypeValue: SingleValue<TrialUserTypeOption>,
    actionMeta: ActionMeta<TrialUserTypeOption>
  ) => {
    if (trialUserTypeValue) {
      setSelectedTrialUserType(trialUserTypeValue);
    } else {
      setSelectedTrialUserType(undefined);
    }
  };

  useEffect(() => {
    let newAllRequests = requests.map((a: any) => ({ ...a }));
    newAllRequests.sort(
      (a: any, b: any) => b["data"]["date"] - a["data"]["date"]
    );
    setAllRequests(newAllRequests);
  }, [requests]);

  useEffect(() => {
    let newAllTrialRequests = trialRequests.map((a: any) => ({ ...a }));
    newAllTrialRequests.sort(
      (a: any, b: any) => b["data"]["created"] - a["data"]["created"]
    );
    setAllTrialRequests(newAllTrialRequests);
  }, [trialRequests]);

  useEffect(() => {
    let newallDataRequests = dataRequests.map((a: any) => ({ ...a }));
    newallDataRequests.sort(
      (a: any, b: any) => b["data"]["created"] - a["data"]["created"]
    );
    setAllDataRequests(newallDataRequests);
  }, [dataRequests]);

  const changeRequestType = (
    eventKey: string | null,
    e: React.SyntheticEvent<unknown>
  ): void => {
    if (eventKey !== selectedRequestType) {
      setSelectedRequest(null);
    }
    setSelectedRequestType(eventKey);
    setSelectedTrialUserType(undefined);
  };

  // LOAD INITIAL DATA
  useEffect(() => {
    if (requestsStatus === "idle") {
      dispatch(fetchRequests());
    }
  }, [requestsStatus, dispatch]);

  useEffect(() => {
    if (requestsStatus === "loaded") {
      setLoading(false);
    }
  }, [requestsStatus]);

  useEffect(() => {
    if (requestsStatus === "updated") {
      setIsSavingData(false);
      if (selectedRequest) {
        let useRequests = requests;
        if (selectedRequestType === "trial") {
          useRequests = trialRequests;
        }
        let newRequest = useRequests.find(
          (request: any) => request["key"] === selectedRequest["key"]
        );
        if (newRequest !== undefined) {
          setSelectedRequest(newRequest);
        } else {
          setSelectedRequest(null);
        }
      }
    }
  }, [requestsStatus]);

  const onSelectRequest = (request: any) => {
    setSelectedRequest(request);
  };

  useEffect(() => {
    if (selectedRequest) {
      let requestData = selectedRequest["data"];
      let foundOption = false;
      TrialUserTypeOptions.forEach((option: TrialUserTypeOption) => {
        if (!foundOption && option.value === requestData["userType"]) {
          setSelectedTrialUserType(option);
          foundOption = true;
        }
      });
      if (!foundOption) {
        setSelectedTrialUserType(undefined);
      }
    }
  }, [selectedRequest]);

  const handleReviewClick = () => {
    setIsSavingData(true);
    if (selectedRequestType === "trial") {
      let selectedUserTypeText = "";
      if (selectedTrialUserType) {
        selectedUserTypeText = selectedTrialUserType.value;
      }
      dispatch(
        markTrialRequestReviewAction({
          key: selectedRequest["key"],
          date: new Date(),
          userType: selectedUserTypeText,
        })
      );
    } else {
      dispatch(
        markRequestReviewAction({
          key: selectedRequest["key"],
          date: new Date(),
        })
      );
    }
  };

  const renderSelectedRequest = () => {
    if (selectedRequest) {
      let requestData = selectedRequest["data"];
      return (
        <Row className="selectedRequestContainer">
          {!requestData["toReview"] && requestData["reviewedDate"] != null && (
            <Row>
              <StandardTextInput
                label="Date Reviewed"
                textValue={requestData["reviewedDate"]}
                setTextValue={() => null}
                clearMsgs={() => null}
                disabled={true}
                labelColor={"green"}
              />
            </Row>
          )}
          <Row>
            <StandardTextInput
              label="Name"
              textValue={requestData["name"]}
              setTextValue={() => null}
              clearMsgs={() => null}
              disabled={true}
            />
            <StandardTextInput
              label="Date Received"
              textValue={requestData["date"] || requestData["created"]}
              setTextValue={() => null}
              clearMsgs={() => null}
              disabled={true}
            />
          </Row>

          {selectedRequestType === "trial" && (
            <Row>
              <StandardTextInput
                label="CPSO/NP #"
                textValue={requestData["cpsoNum"]}
                setTextValue={() => null}
                clearMsgs={() => null}
                disabled={true}
              />
              <StandardTextInput
                label="Access Code"
                textValue={requestData["accessCode"]}
                setTextValue={() => null}
                clearMsgs={() => null}
                disabled={true}
              />
              <Col>
                <Form.Label className="formLabel">User Type</Form.Label>
                <InputGroup className="mb-3">
                  <Select<TrialUserTypeOption>
                    options={TrialUserTypeOptions}
                    value={
                      selectedTrialUserType === undefined
                        ? null
                        : selectedTrialUserType
                    }
                    className="trialUserTypeSelect"
                    isDisabled={isSavingData || selectedTrialUserType !== undefined}
                    isClearable={true}
                    onChange={onTrialUserTypeChange}
                  />
                </InputGroup>
              </Col>
            </Row>
          )}

          <Row>
            <StandardTextInput
              label="Email"
              textValue={requestData["email"]}
              setTextValue={() => null}
              clearMsgs={() => null}
              disabled={true}
            />
          </Row>
          {requestData["message"] !== undefined && (
            <Row>
              <StandardTextInput
                label="Message"
                textValue={requestData["message"]}
                setTextValue={() => null}
                clearMsgs={() => null}
                disabled={true}
                istextArea={true}
                textAreaLines={10}
              />
            </Row>
          )}

    {selectedRequestType === "data" && (
        <>
         <StandardTextInput
                label="Num Requests"
                textValue={requestData["numRequests"]}
                setTextValue={() => null}
                clearMsgs={() => null}
                disabled={true}
              />
        {requestData["pagesRequested"].map((page: any, index: any) => {

          return <>
          <h5>Request # {index + 1}</h5>
          <hr />
          <Row>
              <StandardTextInput
                label="Page Type"
                textValue={page["type"]}
                setTextValue={() => null}
                clearMsgs={() => null}
                disabled={true}
              />
              <StandardTextInput
                label="cposNum"
                textValue={page["cpsoNum"]}
                setTextValue={() => null}
                clearMsgs={() => null}
                disabled={true}
              />
               <StandardTextInput
                label="Speciality"
                textValue={page["speciality"]}
                setTextValue={() => null}
                clearMsgs={() => null}
                disabled={true}
              />

          </Row>
          <Row>
          <StandardTextInput
                label="User Type"
                textValue={page["user_type"]}
                setTextValue={() => null}
                clearMsgs={() => null}
                disabled={true}
              />
              <StandardTextInput
                label="Date Requested"
                textValue={page["requestDate"]}
                setTextValue={() => null}
                clearMsgs={() => null}
                disabled={true}
              />
              <StandardTextInput
                label="City"
                textValue={page["city"]}
                setTextValue={() => null}
                clearMsgs={() => null}
                disabled={true}
              />
          </Row>
          </>
        })}
        </>
    )}


          <Row>
            <Col className="previewButtonGroup">
              {isSavingData && (
                <Spinner animation="border" className="loadingSpinner" />
              )}
              <Button
                variant="success"
                disabled={isSavingData || !requestData["toReview"]}
                onClick={handleReviewClick}
              >
                Mark Reviewed
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="previewButtonGroup">
              <p className="successMsg">{successMsg}</p>
              <p className="errorMsg">{errorMsg}</p>
            </Col>
          </Row>
        </Row>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {loading ? (
        <div className="homeLoadingSpinner">
          <Spinner animation="grow" />
        </div>
      ) : (
        <div className="myMain">
          <Nav
            fill
            variant="tabs"
            onSelect={changeRequestType}
            defaultActiveKey={REQUEST_TYPES[0].key}
          >
            {REQUEST_TYPES.map((requestType: any) => {
              return (
                <Nav.Item>
                  <Nav.Link eventKey={requestType.key}>
                    {requestType.label}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
          <Row>
            <Col sm={4}>
              <ListGroup>
                {allRequests.map((request: any) => {
                  if (request["data"]["type"] === selectedRequestType) {
                    return (
                      <ListGroup.Item
                        action
                        variant={
                          selectedRequest &&
                          selectedRequest["key"] === request["key"]
                            ? "primary"
                            : ""
                        }
                        eventKey={request["key"]}
                        key={request["key"]}
                        active={false}
                        onClick={() => onSelectRequest(request)}
                        disabled={isSavingData}
                      >
                        {request["data"]["email"]}
                        {!request["data"]["toReview"] && (
                          <AiOutlineCheckCircle
                            color="green"
                            size={20}
                            className="completeIcon"
                          />
                        )}
                      </ListGroup.Item>
                    );
                  }
                })}
                {selectedRequestType === "trial" &&
                  allTrialRequests.map((request: any) => {
                    return (
                      <ListGroup.Item
                        action
                        variant={
                          selectedRequest &&
                          selectedRequest["key"] === request["key"]
                            ? "primary"
                            : ""
                        }
                        eventKey={request["key"]}
                        key={request["key"]}
                        active={false}
                        onClick={() => onSelectRequest(request)}
                        disabled={isSavingData}
                      >
                        {request["data"]["email"]}
                        {" (" + request["data"]["userType"] + ")"}
                        {!request["data"]["toReview"] && (
                          <AiOutlineCheckCircle
                            color="green"
                            size={20}
                            className="completeIcon"
                          />
                        )}
                      </ListGroup.Item>
                    );
                  })}
                {selectedRequestType === "data" &&
                  allDataRequests.map((request: any) => {
                    return (
                      <ListGroup.Item
                        action
                        variant={
                          selectedRequest &&
                          selectedRequest["key"] === request["key"]
                            ? "primary"
                            : ""
                        }
                        eventKey={request["key"]}
                        key={request["key"]}
                        active={false}
                        onClick={() => onSelectRequest(request)}
                        disabled={isSavingData}
                      >
                        {request["data"]["email"]}
                        {request["data"]["toReview"] !== undefined && !request["data"]["toReview"] && (
                          <AiOutlineCheckCircle
                            color="green"
                            size={20}
                            className="completeIcon"
                          />
                        )}
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Col>
            <Col sm={7}>{renderSelectedRequest()}</Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default RequestsDashboard;
