import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import { flagAction } from "../app/actions";

import "../styles/FlagModal.css";
import { checkToReview, getStrippedValue } from "../utils";

interface FlagModalProps {
  showFlagModal: any;
  setShowFlagModal: any;
  groupDataType: any;
  selectedGroupData: any;
  setIsSavingGroupData: any;
  sdMetaInfo: any;
  sdExtraInfo: any;
  sdImpExtraInfo: any;
  sdCustomForm: any;
  sdProcedures: any;
  dispatch: any;
  admins: any;
  schedules: any;
  sdPhone: any;
  sdPhoneExt: any;
  sdFax: any;
  sdFaxExt: any;
}

function FlagModal(props: FlagModalProps) {
  const [sdFlagMsg, setSDFlagMsg] = useState<string>("");
  const [flagSuccessMsg, setFlagSuccessMsg] = useState<string>("");
  const [flagErrorMsg, setFlagErrorMsg] = useState<string>("");

  const clearMsgs = () => {
    setFlagSuccessMsg("");
    setFlagErrorMsg("");
  };
  const handleCloseFlagModal = () => {
    clearMsgs();
    props.setShowFlagModal(false);
  };
  const selectedDoctor = props.selectedGroupData;
  //   if (props.groupDataType == "event") {
  //     selectedDoctor = props.selectedGroupData;
  //   }

  const flagMsgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearMsgs();
    setSDFlagMsg(event.currentTarget.value);
  };

  const flagDoctor = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (sdFlagMsg.length === 0) {
      setFlagErrorMsg("Flag message cannot be empty");
    } else {
      props.setIsSavingGroupData(true);
      setFlagFields();
    }
  };

  const setFlagFields = async () => {
    try {
      const selectedDoctorAdmin = props.admins.find(
        (adminData: any) =>
          adminData["cpsoNum"] === props.selectedGroupData["cpsoNum"]
      );
      const selectedDoctorSchedule = props.schedules.find(
        (schedule: any) =>
          schedule["scheduleId"] === props.selectedGroupData["scheduleId"]
      );

      await props
        .dispatch(
          flagAction({
            cpsoNum: props.selectedGroupData["cpsoNum"],
            isService: props.selectedGroupData["isService"],
            scheduleId: props.selectedGroupData["scheduleId"],
            flagMsg: sdFlagMsg,
            meta: props.sdMetaInfo,
            customForm: getStrippedValue(props.sdCustomForm),
            additionalInfo: props.sdExtraInfo,
            impAdditionalInfo: props.sdImpExtraInfo,
            procedures: props.sdProcedures,
            phone: props.sdPhone,
            phoneExt: props.sdPhoneExt,
            fax: props.sdFax,
            faxExt: props.sdFaxExt,
            toReview:
              selectedDoctorAdmin["toReview"] ||
              checkToReview(
                selectedDoctorAdmin["additionalInfo"],
                props.sdExtraInfo
              ),
            impToReview:
              selectedDoctorAdmin["impToReview"] ||
              checkToReview(
                selectedDoctorAdmin["impAdditionalInfo"],
                props.sdImpExtraInfo
              ),
            scheduleCurrentDate: selectedDoctorSchedule["date"],
            scheduleCurrentDateHistory: selectedDoctorSchedule["dateHistory"],
          })
        )
        .unwrap();
      props.setShowFlagModal(false);
    } catch (err) {
      console.error("Failed to flag doctor: ", err);
      setFlagErrorMsg("Failed to flag doctor.");
    }
  };

  return (
    <Modal
      show={props.showFlagModal}
      onHide={handleCloseFlagModal}
      dialogClassName="flagModal"
      centered
    >
      <Modal.Header closeButton>
        {selectedDoctor && (
          <Modal.Title>Flag {selectedDoctor["fullName"]}</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Col>
          <Form.Label htmlFor="flagReason" className="formLabel">
            Flag Reason
          </Form.Label>
          <InputGroup className="mb-3">
            <FormControl
              id="flagReason"
              value={sdFlagMsg}
              onChange={flagMsgChange}
              as="textarea"
              rows={3}
            />
          </InputGroup>
        </Col>
        <Row>
          <Col className="previewButtonGroup">
            <p className="successMsg">{flagSuccessMsg}</p>
            <p className="errorMsg">{flagErrorMsg}</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseFlagModal}>
          Close
        </Button>
        <Button variant="danger" onClick={flagDoctor}>
          Flag
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FlagModal;
