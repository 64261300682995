import {
  MetaOption,
  MetaSpecialityOption,
  ActionOption,
  WaitTimeMsgOption,
  SpecialityOption,
  CityOption,
  ProcedureTypeOption,
  TrialUserTypeOption,
  FilterGroupOption,
} from "./interfaces";

export const CUSTOM_FORM_VALUE = 1;
export const NOT_ACCEPTING_VALUE = 2;
export const RETIRED_VALUE = 4;
export const CLOSED_CLINIC_VALUE = 5;
export const WALK_IN_VALUE = 6;
export const URGENT_ONLY_VALUE = 7;
export const VARIABLE_VALUE = 8;

export const EmptySpecialityOption: SpecialityOption = {
  label: "",
  value: "",
};

export const EmptyCityOption: CityOption = {
  label: "",
  value: "",
};

export const EmptyProcedureTypeOption: ProcedureTypeOption = {
  label: "",
  value: "",
};

export const EmptyWaitTimeMsgOption: WaitTimeMsgOption = {
  label: "",
  value: 0,
};

export const MetaOptions: MetaOption[] = [
  { value: CUSTOM_FORM_VALUE, label: "Uses custom form" },
  { value: NOT_ACCEPTING_VALUE, label: "Not accepting new patients" },
  { value: WALK_IN_VALUE, label: "Walk-In" },
  { value: VARIABLE_VALUE, label: "Variable Wait Time" },
  { value: URGENT_ONLY_VALUE, label: "Urgent Only" },
  { value: 3, label: "Has backline number" },
  { value: RETIRED_VALUE, label: "Retired doctor" },
  {
    value: CLOSED_CLINIC_VALUE,
    label: "Closed clinic/Doctor no longer works at clinic",
  },
];

export const ProcedureTypeOptions: ProcedureTypeOption[] = [
  { value: "normal", label: "normal" },
  { value: "allied", label: "allied" },
];

export const AllActionOptionValues: string[] = [
  "Save",
  "Call Completed",
  "Reschedule (auto)",
  "Email Sent",
  "Flag",
  "Mark To Fax",
  "Mark Retired",
  "Reschedule w/ date",
];

export const AllAdminActionOptionValues: string[] = [
  "Save Admin",
  "Remove Doctor",
  "Mark Reviewed",
  "Mark To Rescrape",
  "Hide Doctor",
  "Unhide Doctor",
];

let newActions: ActionOption[] = [];
AllActionOptionValues.forEach((actionValue: string) => {
  newActions.push({
    label: actionValue,
    value: actionValue,
    isAdminAction: false,
  });
});
AllAdminActionOptionValues.forEach((actionValue: string) => {
  newActions.push({
    label: actionValue,
    value: actionValue,
    isAdminAction: true,
  });
});

export const AllActionOptions: ActionOption[] = newActions;

export const MetaSpecialityOptions: MetaSpecialityOption[] = [
  { value: 1, label: "Limited Practice" },
  { value: 2, label: "Sub-specialized" },
  { value: 3, label: "Focus: Cardiology" },
  { value: 4, label: "Focus: Rheumatology" },
  { value: 5, label: "Focus: Endocrinology" },
  { value: 6, label: "Focus: Gastroenterology" },
];

export const WaitTimeMsgOptions: WaitTimeMsgOption[] = [
  { value: 1, label: "Variable" },
  { value: 2, label: "Urgent Only" },
  { value: 3, label: "Not Accepting" },
  { value: 4, label: "Retired" },
  { value: 5, label: "Pending Update" },
  { value: 6, label: "Walk-In" },
  { value: 7, label: "Insufficient data" },
  { value: 8, label: "Centralized Service Only" },
];

export const FilterGroupOptions: FilterGroupOption[] = [
  { value: "Never Reviewed", label: "Never Reviewed" },
  { value: "Not Hidden", label: "Not Hidden" },
  { value: "Central Services", label: "Central Services" },
  { value: "Custom Region (KWGC)", label: "Custom Region (KWGC)" },
  { value: "Custom Region (Durham)", label: "Custom Region (Durham)" },
  { value: "Custom Region (Hamilton)", label: "Custom Region (Hamilton)" },
  { value: "Custom Region (Niagara)", label: "Custom Region (Niagara)" },
  { value: "Custom Region (Toronto)", label: "Custom Region (Toronto)" },
  { value: "Custom Region (Simcoe)", label: "Custom Region (Simcoe)" },
  { value: "Custom Region (Ottawa)", label: "Custom Region (Ottawa)" },
  { value: "Custom Region (Windsor)", label: "Custom Region (Windsor)" },
  { value: "Custom Region (GTA)", label: "Custom Region (GTA)" },
  { value: "Custom Speciality (Psychiatry)", label: "Custom Speciality (Psychiatry)" },
  { value: "Custom Region (Mississauga/Oakville/Milton/Burlington)", label: "Custom Region (Mississauga/Oakville/Milton/Burlington)" },
  { value: "Meta Tag (closed/no longer here)", label: "Meta Tag (closed/no longer here)" },
  { value: "Flag Keyword (triage)", label: "Flag Keyword (triage)" },
  { value: "Flag Keyword (central)", label: "Flag Keyword (central)" },
  { value: "Flag Keyword (send fax)", label: "Flag Keyword (send fax)" },
  { value: "Flag Keyword (variable)", label: "Flag Keyword (variable)" },
];

export const TrialUserTypeOptions: TrialUserTypeOption[] = [
  { value: "Family Doctor", label: "Family Doctor" },
  { value: "NP", label: "NP" },
  { value: "Office Admin", label: "Office Admin" },
  { value: "Specialist", label: "Specialist" },
  { value: "Invalid CPSO", label: "Invalid CPSO" },
  { value: "Patient", label: "Patient" },
  { value: "Other", label: "Other" },
];

export const NUM_CALLS_BEFORE_FAX = 3;

export const NOT_ACCEPTING_DAYS = 6 * 30 + 15;
export const URGENT_ONLY_DAYS = 6 * 30;
export const WALK_IN_DAYS = 4 * 30;

// Call within 20% of lower bound
export const WAIT_TIME_LOWER_PERCENT = 0.8;

export const MINIMUM_SCHEDULE_DAYS = 4 * 30;

export const FLAGGED_RESCRAPE_DAYS = 3 * 30;
