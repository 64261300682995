import {
  collection,
  getDocs
} from "firebase/firestore";

import {
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";

import {
  db
} from "../firebase.config";

import {
  updateConfigSpecialityAction,
  updateConfigProcedureAction,
  createConfigProcedureAction,
} from "./actions";

const initialState = {
  configSpecialties: [],
  configProcedures: [],
  configCities: [],
  status: "idle",
  error: null,
};

export const fetchConfig = createAsyncThunk(
  "config/fetchConfig",
  async () => {
    console.log("Loading config data");
    const querySnapshot = await getDocs(collection(db, "config-specialties"));
    const newSpecialityData: any[] = [];

    querySnapshot.forEach((specialityObj) => {
      newSpecialityData.push(specialityObj.data());
    });

    newSpecialityData.sort((a: any, b: any) =>
      a["speciality"].localeCompare(b["speciality"])
    )

    const procedureQuerySnapshot = await getDocs(
      collection(db, "config-procedures")
    );
    const newProcedureData: any[] = [];
    procedureQuerySnapshot.forEach((procedureObj) => {
      newProcedureData.push(procedureObj.data());
    });

    newProcedureData.sort((a: any, b: any) =>
      a["procedure"].localeCompare(b["procedure"])
    )

    const citiesQuerySnapshot = await getDocs(
      collection(db, "config-cities")
    );
    const newCitiesData: any[] = [];
    citiesQuerySnapshot.forEach((citiesObj) => {
      newCitiesData.push(citiesObj.data());
    });

    newCitiesData.sort((a: any, b: any) =>
      a["city"].localeCompare(b["city"])
    )

    console.log("Loaded config data");
    return {
      "specialties": newSpecialityData,
      "procedures": newProcedureData,
      "cities": newCitiesData,
    };
  }
);

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    updateConfigSpeciality: (state, {
      payload
    }) => {
      const existingSpeciality = state.configSpecialties.find(configSpeciality => configSpeciality["id"] === payload["id"])
      existingSpeciality["schedule"] = payload["schedule"]
      existingSpeciality["faxVerb"] = payload["faxVerb"]
      existingSpeciality["aliases"] = payload["aliases"]
      existingSpeciality["faxUnderlines"] = payload["faxUnderlines"]
      existingSpeciality["procedures"] = payload["procedures"]

      payload["newProcedureRefs"].forEach((newProcedureRef) => {
        const existingProcedureRef = state.configProcedures.find(configProcedure => configProcedure["id"] === newProcedureRef["id"])
        existingProcedureRef["specialties"].push(payload["specialityDocRef"])
      })

      payload["removedProcedureRefs"].forEach((removedProcedureRef) => {
        const existingProcedureRef = state.configProcedures.find(configProcedure => configProcedure["id"] === removedProcedureRef["id"])
        existingProcedureRef["specialties"] = existingProcedureRef["specialties"].filter((speciality) => speciality["id"] !== payload["id"])
      })
    },
    updateConfigProcedure: (state, {
      payload
    }) => {
      const existingProcedure = state.configProcedures.find(configProcedure => configProcedure["id"] === payload["id"])
      existingProcedure["active"] = payload["active"]
      existingProcedure["complete"] = payload["complete"]
      existingProcedure["aliases"] = payload["aliases"]
      existingProcedure["specialties"] = payload["specialties"]

      payload["newSpecialtiesRefs"].forEach((newSpecialityRef) => {
        const existingSpecialityRef = state.configSpecialties.find(configSpeciality => configSpeciality["id"] === newSpecialityRef["id"])
        existingSpecialityRef["procedures"].push(payload["procedureDocRef"])
      })

      payload["removedSpecialtiesRefs"].forEach((removedSpecialityRef) => {
        const existingSpecialityRef = state.configSpecialties.find(configSpeciality => configSpeciality["id"] === removedSpecialityRef["id"])
        existingSpecialityRef["procedures"] = existingSpecialityRef["procedures"].filter((speciality) => speciality["id"] !== payload["id"])
      })
    },
    newProcedure: (state, {
      payload
    }) => {
      if (payload["newProcedureData"] != null) {
        state.configProcedures.push(payload["newProcedureData"]);
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchConfig.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateConfigSpecialityAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(updateConfigProcedureAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(createConfigProcedureAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(fetchConfig.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        state.configSpecialties = state.configSpecialties.concat(action.payload["specialties"]);
        state.configProcedures = state.configProcedures.concat(action.payload["procedures"]);
        state.configCities = state.configCities.concat(action.payload["cities"]);
        state.status = "loaded";
      })
      .addCase(fetchConfig.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateConfigSpecialityAction.fulfilled, (state, action) => {
        configSlice.caseReducers.updateConfigSpeciality(state, action);
        state.status = "updated";
      })
      .addCase(updateConfigProcedureAction.fulfilled, (state, action) => {
        configSlice.caseReducers.updateConfigProcedure(state, action);
        state.status = "updated";
      })
      .addCase(createConfigProcedureAction.fulfilled, (state, action) => {
        configSlice.caseReducers.newProcedure(state, action);
        state.status = "updated";
      });
  },
});

export default configSlice.reducer;

export const selectAllConfig = state => state.config;
export const selectAllConfigSpecialties = state => state.config.configSpecialties;
export const selectAllConfigProcedures = state => state.config.configProcedures;
export const selectAllConfigCities = state => state.config.configCities;