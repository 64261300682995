import {
    collection,
    getDocs,
    doc,
    updateDoc
} from "firebase/firestore";

import {
    createSlice,
    createAsyncThunk
} from "@reduxjs/toolkit";

import {
    updateImagingAction,
} from "./actions";

import {
    db
} from "../firebase.config";

const initialState = {
    imaging: [],
    imagingMap: new Map(),
    status: "idle",
    error: null,
};

export const fetchImaging = createAsyncThunk(
    "imaging/fetchImaging",
    async () => {
        console.log("Loading imaging data");
        const querySnapshot = await getDocs(collection(db, "imaging-services"));
        const newImagingData: any[] = [];

        querySnapshot.forEach((docObj) => {
            newImagingData.push(docObj.data());
        });

        console.log("Loaded imaging data");
        return newImagingData;
    }
);

export const imagingSlice = createSlice({
    name: "imaging",
    initialState,
    reducers: {
        computeMap: (state, {
            payload
        }) => {
            state.imagingMap = new Map();
            state.imaging.forEach((imagingService: any) => {
                state.imagingMap.set(imagingService["serviceId"], imagingService);
            });
        },
        updateImaging: (state, {
            payload
        }) => {
            const existingImaging = state.imaging.find(imagingService => imagingService["serviceId"] === payload["serviceId"])
            if (existingImaging) {
                existingImaging["fullName"] = payload["fullName"];
                existingImaging["location"] = payload["location"];
                existingImaging["phone"] = payload["phone"];
                existingImaging["phoneExt"] = payload["phoneExt"];
                existingImaging["fax"] = payload["fax"];
                existingImaging["faxExt"] = payload["faxExt"];
                existingImaging["specialties"] = payload["specialties"];
                existingImaging["metaSpecialties"] = payload["metaSpecialties"];
                existingImaging["procedures"] = payload["procedures"];
                existingImaging["link"] = payload["link"];
                existingImaging["customForm"] = payload["customForm"];
                existingImaging["additionalInfo"] = payload["additionalInfo"];
                existingImaging["priority2WaitTimeStart"] = payload["priority2WaitTimeStart"];
                existingImaging["priority2WaitTimeEnd"] = payload["priority2WaitTimeEnd"];
                existingImaging["priority2WaitTimeUnit"] = payload["priority2WaitTimeUnit"];
                existingImaging["priority2WaitTimeMsg"] = payload["priority2WaitTimeMsg"];
                existingImaging["priority3WaitTimeStart"] = payload["priority3WaitTimeStart"];
                existingImaging["priority3WaitTimeEnd"] = payload["priority3WaitTimeEnd"];
                existingImaging["priority3WaitTimeUnit"] = payload["priority3WaitTimeUnit"];
                existingImaging["priority3WaitTimeMsg"] = payload["priority3WaitTimeMsg"];
                existingImaging["waitTimeStart"] = payload["waitTimeStart"];
                existingImaging["waitTimeEnd"] = payload["waitTimeEnd"];
                existingImaging["waitTimeUnit"] = payload["waitTimeUnit"];
                existingImaging["waitTimeMsg"] = payload["waitTimeMsg"];
                // existingImaging["waitTimeRecorded"] = payload["waitTimeRecorded"];
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchImaging.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(updateImagingAction.pending, (state, action) => {
                state.status = "updating";
            })
            .addCase(fetchImaging.fulfilled, (state, action) => {
                // Add any fetched posts to the array
                state.imaging = state.imaging.concat(action.payload);
                imagingSlice.caseReducers.computeMap(state, action);
                state.status = "loaded";
            })
            .addCase(fetchImaging.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(updateImagingAction.fulfilled, (state, action) => {
                imagingSlice.caseReducers.updateImaging(state, action);
                imagingSlice.caseReducers.computeMap(state, action);
                state.status = "updated";
            });
    },
});

export default imagingSlice.reducer;

export const selectAllImaging = state => state.imaging.imaging;
export const selectAllImagingMap = state => state.imaging.imagingMap;