import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { MetaSpecialityOption, IsMulti } from "../interfaces";
import CreatableSelect from "react-select/creatable";
import Select, { OnChangeValue, ActionMeta } from "react-select";
import { MetaSpecialityOptions } from "../config";

import "../styles/MetaSpecialtiesForm.css";

interface MetaSpecialtiesFormProps {
  metaSpecialtiesValue: any;
  setMetaSpecialtiesValue: any;
  disable: any;
  clearMsgs: any;
}

function MetaSpecialtiesForm(props: MetaSpecialtiesFormProps) {
  const onChange = (
    newValue: OnChangeValue<MetaSpecialityOption, IsMulti>,
    actionMeta: ActionMeta<MetaSpecialityOption>
  ) => {
    props.clearMsgs();
    let newMetaSpecialties: MetaSpecialityOption[] = [];
    newValue.forEach((value) => {
      newMetaSpecialties.push(value);
    });
    props.setMetaSpecialtiesValue(newMetaSpecialties);
  };

  return (
    <Col>
      <Form.Label htmlFor="meta" className="formLabel">
        Meta Speciality
      </Form.Label>
      <InputGroup className="mb-3">
        <CreatableSelect<MetaSpecialityOption, IsMulti>
          options={MetaSpecialityOptions}
          value={props.metaSpecialtiesValue}
          isMulti={true}
          className="metaSelection"
          isDisabled={props.disable()}
          onChange={onChange}
        />
      </InputGroup>
    </Col>
  );
}

export default MetaSpecialtiesForm;
