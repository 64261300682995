import {
  collection,
  getDocs
} from "firebase/firestore";

import {
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";

import {
  updateGroupInfoAction,
  markGroupInfoReviewAction
} from "./actions"

import {
  db
} from "../firebase.config";

const initialState = {
  groupInfo: [],
  groupInfoMap: new Map(),
  status: "idle",
  error: null,
};

export const fetchGroupInfo = createAsyncThunk(
  "groupInfo/fetchGroupInfo",
  async () => {
    console.log("Loading group info data");
    const querySnapshot = await getDocs(collection(db, "doctor-group-info"));
    const newGroupInfoData: any[] = [];

    querySnapshot.forEach((groupInfoObj) => {
      newGroupInfoData.push({
        key: groupInfoObj.id,
        data: groupInfoObj.data()
      });
    });

    console.log("Loaded group info data");
    return newGroupInfoData;
  }
);

export const groupInfoSlice = createSlice({
  name: "groupInfo",
  initialState,
  reducers: {
    computeMap: (state, {
      payload
    }) => {
      state.groupInfoMap = new Map();
      state.groupInfo.forEach((group: any) => {
        state.groupInfoMap.set(group["key"], group["data"]);
      });
    },
    updateGroupInfo: (state, {
      payload
    }) => {
      const existingGroupInfo = state.groupInfo.find(group => group["key"] === payload["key"])
      if (existingGroupInfo) {
        existingGroupInfo["data"]["groupInfo"] = payload["groupInfo"];
        existingGroupInfo["data"]["toReview"] = payload["toReview"];
      } else {
        state.groupInfo.push({
          key: payload["key"],
          data: {
            groupInfo: payload["groupInfo"],
            toReview: payload["toReview"],
          }
        })
      }
    },
    markReviewed: (state, {
      payload
    }) => {
      const existingGroupInfo = state.groupInfo.find(group => group["key"] === payload["key"])
      if (existingGroupInfo) {
        existingGroupInfo["data"]["toReview"] = false;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGroupInfo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchGroupInfo.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        state.groupInfo = state.groupInfo.concat(action.payload);
        groupInfoSlice.caseReducers.computeMap(state, action);
        state.status = "loaded";
      })
      .addCase(fetchGroupInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateGroupInfoAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(markGroupInfoReviewAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(updateGroupInfoAction.fulfilled, (state, action) => {
        groupInfoSlice.caseReducers.updateGroupInfo(state, action);
        groupInfoSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(markGroupInfoReviewAction.fulfilled, (state, action) => {
        groupInfoSlice.caseReducers.markReviewed(state, action);
        groupInfoSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      });
  },
});

export default groupInfoSlice.reducer;

export const selectAllGroupInfo = state => state.groupInfo.groupInfo;
export const selectAllGroupInfoMap = state => state.groupInfo.groupInfoMap;