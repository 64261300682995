import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup, FormControl } from "react-bootstrap";

import "../styles/AdditionalInfoForm.css";

interface AdditionalInfoFormProps {
  extraInfoValue: any;
  setExtraInfoValue: any;
  disable: any;
  selectedGroupData: any;
  clearMsgs: any;
  headerLabel: any;
}

function AdditionalInfoForm(props: AdditionalInfoFormProps) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.clearMsgs();
    props.setExtraInfoValue(event.currentTarget.value);
  };

  return (
    <Col>
      <Form.Label htmlFor="extra" className="formLabel">
        {props.headerLabel}
      </Form.Label>
      <InputGroup className="mb-3">
        <FormControl
          id="extraInfo"
          value={props.extraInfoValue == null ? "" : props.extraInfoValue}
          onChange={onChange}
          as="textarea"
          rows={3}
          disabled={props.disable()}
        />
      </InputGroup>
    </Col>
  );
}

export default AdditionalInfoForm;
