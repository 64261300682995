import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { SpecialityOption, IsMulti } from "../interfaces";
import Select, { OnChangeValue, ActionMeta, SingleValue } from "react-select";

import "../styles/StandardSelect.css";

interface StandardSelectProps {
  label: string;
  selectValue: any;
  setSelectValue: any;
  selectOptions: any;
  selectType: any;
  disable: any;
  clearMsgs: any;
  isMulti: boolean;
}

function StandardSelect(props: StandardSelectProps) {
  const SelectType = props.selectType;

  const onMultiChange = (
    newValue: OnChangeValue<typeof SelectType, IsMulti>,
    actionMeta: ActionMeta<typeof SelectType>
  ) => {
    props.clearMsgs();
    let newSelectValues: typeof SelectType[] = [];
    newValue.forEach((value) => {
      newSelectValues.push(value);
    });
    props.setSelectValue(newSelectValues);
  };

  const onChange = (
    newValue: SingleValue<typeof SelectType>,
    actionMeta: ActionMeta<typeof SelectType>
  ) => {
    props.clearMsgs();
    if (newValue) {
      props.setSelectValue(newValue);
    } else {
      props.setSelectValue(undefined);
    }
  };

  return (
    <Col>
      <Form.Label htmlFor="meta" className="formLabel">
        {props.label}
      </Form.Label>
      <InputGroup className="mb-3">
        {props.isMulti ? (
          <Select<typeof SelectType, IsMulti>
            options={props.selectOptions}
            value={props.selectValue}
            isMulti={true}
            className="standardSelect"
            isDisabled={props.disable()}
            onChange={onMultiChange}
          />
        ) : (
          <Select<typeof SelectType>
            options={props.selectOptions}
            value={props.selectValue === undefined ? null : props.selectValue}
            className="standardSelect"
            isDisabled={props.disable()}
            onChange={onChange}
            isClearable={true}
          />
        )}
      </InputGroup>
    </Col>
  );
}

export default StandardSelect;
