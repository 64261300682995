// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCptiO8_mTkETLHiZiifb-tH5NTeUO00Ts",
  authDomain: "docdir-dashboard.firebaseapp.com",
  projectId: "docdir-dashboard",
  storageBucket: "docdir-dashboard.appspot.com",
  messagingSenderId: "419896979663",
  appId: "1:419896979663:web:11aeb89f93caa4f2ff47ba",
};

const apiFirebaseConfig = {
  apiKey: "AIzaSyB25ZYa9WF7gDca34Y_wiw2Nu0tQ_FDoZg",
  authDomain: "relaymd-api.firebaseapp.com",
  projectId: "relaymd-api",
  storageBucket: "relaymd-api.appspot.com",
  messagingSenderId: "492758999293",
  appId: "1:492758999293:web:ff5f76903d870643a85e94",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const apiApp = initializeApp(apiFirebaseConfig, "api");
const auth = getAuth(app);
const db = getFirestore(app);
const apiDB = getFirestore(apiApp);

const logout = () => {
  signOut(auth);
};

export { auth, db, apiDB, signInWithEmailAndPassword, logout };
