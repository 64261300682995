import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { logout } from "../firebase.config";

function Logout() {
  useEffect(() => {
    logout();
  }, []);

  return <p></p>;
}

export default Logout;
