import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup, FormControl} from "react-bootstrap";
import Input, {
  parsePhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input/input";
import {
  GroupedProcedureOption,
  ProcedureOption,
  ProcedureSpecialityOption,
  IsMulti,
} from "../interfaces";
import Select, { OnChangeValue, ActionMeta } from "react-select";

import "../styles/PhoneForm.css";
import { Value } from "react-phone-number-input";

interface PhoneFormProps {
  phoneLabel: any;
  phoneStr: any;
  phoneExtStr: any;
  setPhoneStr: any;
  setPhoneExtStr: any;
  disabled: any;
}

function PhoneForm(props: PhoneFormProps) {
  const [phoneValue, setPhoneValue] = useState<Value | undefined>(undefined);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);

  useEffect(() => {
    if (props.phoneStr === "") {
      setPhoneValue(undefined);
    } else {
      const parsedPhoneNum = parsePhoneNumber(props.phoneStr, "CA");
      if (parsedPhoneNum !== undefined) {
        setPhoneValue(parsedPhoneNum.number);
      }
    }
  }, [props.phoneStr]);

  useEffect(() => {
    if (phoneValue) {
      setIsValidPhone(isPossiblePhoneNumber(phoneValue.toString(), "CA"));
    }
  }, [phoneValue]);

  const onPhoneValueChange = (value: Value) => {
    if (value !== undefined) {
      const parsedPhoneNum = parsePhoneNumber(value.toString(), "CA");
      if (parsedPhoneNum !== undefined) {
        props.setPhoneStr(parsedPhoneNum.formatNational());
      }
      setIsValidPhone(isPossiblePhoneNumber(value.toString(), "CA"));
    } else {
      props.setPhoneStr("");
    }
  };

  const onPhoneExtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.currentTarget.value === undefined){
      props.setPhoneExtStr("");
    } else {
      props.setPhoneExtStr(event.currentTarget.value);
    }
  };

  return (
    <Col>
      <Form.Label htmlFor="phone" className="formLabel">
        {props.phoneLabel}
        {!isValidPhone && (
          <span style={{ color: "red", marginTop: "0" }}>
            {" (Invalid " + props.phoneLabel + " number)"}
          </span>
        )}
      </Form.Label>
      <InputGroup className="mb-3">
        <Input
          // international={false}
          country="CA"
          placeholder="Enter phone number"
          value={phoneValue}
          onChange={onPhoneValueChange}
          disabled={props.disabled}
        />
        <FormControl
          placeholder="Ext"
          type="number"
          value={props.phoneExtStr}
          onChange={onPhoneExtChange}
          disabled={props.disabled}
        />
      </InputGroup>
    </Col>
  );
}

export default PhoneForm;
