import { useSelector, useDispatch, TypedUseSelectorHook } from "react-redux";

import { configureStore } from "@reduxjs/toolkit";
import doctorsReducer from "./doctorsSlice";
import adminReducer from "./adminSlice";
import schedulesReducer from "./schedulesSlice";
import groupInfoReducer from "./groupInfoSlice";
import configReducer from "./configSlice";
import centralServicesReducer from "./centralServicesSlice";
import imagingReducer from "./imagingSlice";
import requestsReducer from "./requestsSlice";

const store = configureStore({
  reducer: {
    doctors: doctorsReducer,
    admins: adminReducer,
    schedules: schedulesReducer,
    groupInfo: groupInfoReducer,
    config: configReducer,
    centralServices: centralServicesReducer,
    imaging: imagingReducer,
    requests: requestsReducer,
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
