import {
  collection,
  getDocs,
  doc,
  updateDoc,
  getDoc
} from "firebase/firestore";

import {
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";

import {
  retireDoctorAction,
  flagAction,
  faxAction,
  emailAction,
  rescheduleAction,
  completeAction,
  updateAvailabilityAction,
  saveAction,
  removeAction,
  saveAdminAction,
  reviewedAction,
  rescrapeAction,
  hideDoctorAction,
  updateCentralServiceAction,
  createCentralServiceAction
} from "./actions";

import {
  db
} from "../firebase.config";
import {fetchIndiviualDoctor} from "./doctorsSlice";

const initialState = {
  centralServices: [],
  centralServicesMap: new Map(),
  status: "idle",
  error: null,
};

export const fetchCentralServices = createAsyncThunk(
  "centralServices/fetchCentralServices",
  async () => {
    console.log("Loading central services data");
    const querySnapshot = await getDocs(collection(db, "central-services"));
    const newCentralServicesData: any[] = [];

    querySnapshot.forEach((docObj) => {
      let newCentralService = docObj.data();
      newCentralService["isService"] = true;
      newCentralServicesData.push(newCentralService);
    });

    console.log("Loaded central services data");
    return newCentralServicesData;
  }
);

export const fetchIndividualCentralService = createAsyncThunk(
  "centralServices/fetchIndividualCentralService",
  async (cpsoNums: string[]) => {
    console.log("Loading individual central services");
    console.log(cpsoNums);

    let allServiceData = [];

    for (const cpsoNum of cpsoNums) {

      const docRef = doc(db, "central-services", cpsoNum.replace("service-", ""));
      const docSnap = await getDoc(docRef);

      let newCentralService: any = null;

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        newCentralService = docSnap.data();
        newCentralService["isService"] = true;

        allServiceData.push(newCentralService);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }

    }

    console.log("Loaded individual central service");
    return {
      "services": allServiceData
    };
  }
);

export const centralServicesSlice = createSlice({
  name: "centralServices",
  initialState,
  reducers: {
    computeMap: (state, {
      payload
    }) => {
      state.centralServicesMap = new Map();
      state.centralServices.forEach((centralService: any) => {
        state.centralServicesMap.set(centralService["serviceId"], centralService);
      });
    },
    updateCentralService: (state, {
      payload
    }) => {
      const existingCentralService = state.centralServices.find(centralService => centralService["serviceId"] === payload["serviceId"])
      if (existingCentralService) {
        existingCentralService["fullName"] = payload["fullName"];
        existingCentralService["location"] = payload["location"];
        existingCentralService["phone"] = payload["phone"];
        existingCentralService["phoneExt"] = payload["phoneExt"];
        existingCentralService["fax"] = payload["fax"];
        existingCentralService["faxExt"] = payload["faxExt"];
        existingCentralService["specialties"] = payload["specialties"];
        existingCentralService["metaSpecialties"] = payload["metaSpecialties"];
        existingCentralService["procedures"] = payload["procedures"];
        existingCentralService["link"] = payload["link"];
        existingCentralService["customForm"] = payload["customForm"];
        existingCentralService["additionalInfo"] = payload["additionalInfo"];
        existingCentralService["intakeWaitTimeStart"] = payload["intakeWaitTimeStart"];
        existingCentralService["intakeWaitTimeEnd"] = payload["intakeWaitTimeEnd"];
        existingCentralService["intakeWaitTimeUnit"] = payload["intakeWaitTimeUnit"];
        existingCentralService["intakeWaitTimeMsg"] = payload["intakeWaitTimeMsg"];
        existingCentralService["waitTimeStart"] = payload["waitTimeStart"];
        existingCentralService["waitTimeEnd"] = payload["waitTimeEnd"];
        existingCentralService["waitTimeUnit"] = payload["waitTimeUnit"];
        existingCentralService["waitTimeMsg"] = payload["waitTimeMsg"];
      }
    },
    newCentralService: (state, {
      payload
    }) => {
      if (payload["newServiceData"] != null) {
        state.centralServices.push(payload["newServiceData"]);
      }
    },
    setUsual: (state, {
      payload
    }) => {
      const existingService = state.centralServices.find(service => service["cpsoNum"] === payload["cpsoNum"])
      existingService["customForm"] = payload["customForm"];
      existingService["procedures"] = payload["procedures"];
    },
    setWaitTimeAndProcedures: (state, {
      payload
    }) => {
      const existingService = state.centralServices.find(service => service["cpsoNum"] === payload["cpsoNum"])
      existingService["waitTimeMsg"] = payload["waitTimeMsg"];
      existingService["waitTimeStart"] = payload["waitTimeStart"];
      existingService["waitTimeEnd"] = payload["waitTimeEnd"];
      existingService["waitTimeUnit"] = payload["waitTimeUnit"];
    },
    setReviewInfo: (state, {
      payload
    }) => {
      const existingService = state.centralServices.find(service => service["cpsoNum"] === payload["cpsoNum"])
      existingService["additionalInfo"] = payload["finalAdditionalInfo"];
      existingService["metaSpecialties"] = payload["metaSpecialties"];
      existingService["searchSpecialties"] = payload["searchSpecialties"];
    },
    setHideDoctor: (state, {
      payload
    }) => {
      const existingService = state.centralServices.find(service => service["cpsoNum"] === payload["cpsoNum"])
      existingService["hideCard"] = payload["hideCard"];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCentralServices.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateCentralServiceAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(createCentralServiceAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(fetchCentralServices.fulfilled, (state, action) => {
        // Add any fetched posts to the array
        state.centralServices = state.centralServices.concat(action.payload);
        centralServicesSlice.caseReducers.computeMap(state, action);
        state.status = "loaded";
      })
      .addCase(fetchCentralServices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(retireDoctorAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(flagAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(faxAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(rescheduleAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(completeAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(updateAvailabilityAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(saveAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(removeAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(saveAdminAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(reviewedAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(rescrapeAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(emailAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(hideDoctorAction.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(fetchIndiviualDoctor.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(fetchIndiviualDoctor.fulfilled, (state, action) => {
        state.status = "updated";
      })
      .addCase(fetchIndiviualDoctor.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchIndividualCentralService.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(fetchIndividualCentralService.fulfilled, (state, action) => {
        action.payload["services"].forEach((actionService: any) => {
          state.centralServices = state.centralServices.filter(service => service["cpsoNum"] !== actionService["cpsoNum"]);
          state.centralServices.push(actionService);
        });
        centralServicesSlice.caseReducers.computeMap(state, action);
        state.status = "updated";
      })
      .addCase(fetchIndividualCentralService.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(retireDoctorAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.setWaitTimeAndProcedures(state, {
            payload: {
              cpsoNum: action.payload["cpsoNum"],
              waitTimeMsg: action.payload["waitTimeMsg"],
              waitTimeStart: null,
              waitTimeEnd: null,
              waitTimeUnit: null,
              procedures: action.payload["procedures"],
            }
          });
          centralServicesSlice.caseReducers.setUsual(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(flagAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.setUsual(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(faxAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.setUsual(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(rescheduleAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.setUsual(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(completeAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.setWaitTimeAndProcedures(state, action);
          centralServicesSlice.caseReducers.setUsual(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(updateAvailabilityAction.fulfilled, (state, action) => {
        state.status = "updated";
      })
      .addCase(saveAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.setUsual(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(removeAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.setUsual(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(saveAdminAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.setReviewInfo(state, action);
          centralServicesSlice.caseReducers.setUsual(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(reviewedAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.setReviewInfo(state, action);
          centralServicesSlice.caseReducers.setUsual(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(rescrapeAction.fulfilled, (state, action) => {
        state.status = "updated";
      })
      .addCase(emailAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.setUsual(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(hideDoctorAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.setHideDoctor(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(updateCentralServiceAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.updateCentralService(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      })
      .addCase(createCentralServiceAction.fulfilled, (state, action) => {
        if (action.payload["isService"]) {
          centralServicesSlice.caseReducers.newCentralService(state, action);
          centralServicesSlice.caseReducers.computeMap(state, action);
        }
        state.status = "updated";
      });
  },
});

export default centralServicesSlice.reducer;

export const selectAllCentralServices = state => state.centralServices.centralServices;
export const selectAllCentralServicesMap = state => state.centralServices.centralServicesMap;